import {
  AlignmentType,
  Document,
  HeadingLevel,
  HeightRule,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextDirection,
  VerticalAlign,
  WidthType,
} from 'docx';
import { sortOnCode } from 'utils/sorting';
import Control, { ApplicabilityReasons } from 'models/control';
import ISOControl from 'models/isoControl';
import Norm, { ISONorms } from 'models/norm';
import { TFunction } from 'i18next';
import { DocumentColors, footers, headers, pageLandscape, paragraphStyles, tableBorders } from './documentStyles';

const getChilds = (isoControls: ISOControl[], parent: ISOControl): ISOControl[] => {
  const childs: ISOControl[] = [];
  addLeavesRecursive(isoControls, parent, childs);

  return childs;
};

const addLeavesRecursive = (isoControls: ISOControl[], parent: ISOControl, allChilds: ISOControl[]): ISOControl[] => {
  const children = isoControls.filter((i) => i.parentISOControlId === parent.isoControlId);
  children.sort((a, b) => sortOnCode(a.code, b.code));

  for (const child of children) {
    const childrenOfChild = addLeavesRecursive(isoControls, child, allChilds);
    if (childrenOfChild.length === 0) {
      allChilds.push(child);
    }
  }

  return children;
};

const getControlImplementationState = (
  isoControls: ISOControl[],
  child: ISOControl,
  t: TFunction<string[]>,
): string => {
  if (!child.control) return t('control:SoAState.No');
  if (child.control.applicabilityReason.includes(ApplicabilityReasons.OutOfScope)) {
    return t('control:SoAState.OutOfScope');
  }

  return Control.getImplementationStateText(child.control.state, t);
};

const renderTabelISOControls = (isoControls: ISOControl[], t: TFunction<string[]>): TableRow[] => {
  const controlsData: TableRow[] = [];

  //get alle top level parents of the requested norm and sort on code
  const parentISOControls: ISOControl[] = isoControls.filter(
    (isoControl: ISOControl) =>
      isoControl.parentISOControlId === undefined && isoControl.isoNormId === ISONorms.ISO270012022,
  );

  parentISOControls.sort((a, b) => sortOnCode(a.code, b.code));
  parentISOControls.forEach((parent: ISOControl) => {
    const childs: ISOControl[] = getChilds(isoControls, parent);

    childs.forEach((child: ISOControl, index: number) => {
      // for each child, display the data

      const tabelCell: TableCell[] = [];
      if (index === 0) {
        tabelCell.push(
          new TableCell({
            children: [
              new Paragraph({
                text: parent.name,
                style: 'TableDetailBold',
                spacing: {
                  after: 100,
                },
              }),
            ],
            rowSpan: childs.length,
          }),
        );
      }
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: child.code,
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: child.name,
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: child.control?.applicabilityReason.includes(ApplicabilityReasons.OutOfScope)
                ? t('translation:General.Words.No')
                : t('translation:General.Words.Yes'),
              style: 'TableHeading',
              spacing: {
                after: 100,
              },
              alignment: AlignmentType.CENTER,
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: getControlImplementationState(isoControls, child, t),
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
              alignment: AlignmentType.CENTER,
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: child.control?.applicabilityReason.includes(ApplicabilityReasons.OutOfScope)
                ? child.control?.outOfScopeReason || ''
                : Control.getApplicabilityReasonText(
                    child.control?.applicabilityReason ?? [ApplicabilityReasons.Basic],
                    t,
                  ),
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
            }),
          ],
        }),
      );

      controlsData.push(
        new TableRow({
          children: tabelCell,
        }),
      );
    });
  });

  return controlsData;
};

export const generateSoAISO27001_2022 = (norm: Norm, isoControls: ISOControl[], t: TFunction<string[]>): Document => {
  const pageProps = {
    properties: pageLandscape,
    headers: headers,
    footers: footers,
  };

  const page1 = {
    ...pageProps,
    children: [
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.Placeholders.Logo'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.StartPage.Title'),
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 2000,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.Placeholders.Company'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: norm.name,
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.Placeholders.Date'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 2000,
        },
        style: 'Normal Text',
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.Placeholders.Version'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
        style: 'Normal Text',
      }),
    ],
  };

  const page2 = {
    ...pageProps,
    children: [
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.IntroPage.Introduction.Label'),
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.IntroPage.Introduction.Detail', { norm: norm.name }),
        style: 'Normal Text',
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.IntroPage.Management.Label'),
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
        spacing: {
          before: 1000,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.IntroPage.Management.Detail'),
        style: 'Normal Text',
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.IntroPage.Scope.Label'),
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
        spacing: {
          before: 1000,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_ISO27001_2022:Statement.Placeholders.Scope'),
        style: 'Normal Text',
        spacing: {
          before: 200,
        },
      }),
    ],
  };

  const page3 = {
    ...pageProps,
    children: [
      new Paragraph({
        text: `${t('doc_SoA_ISO27001_2022:Statement.StartPage.Title')} - ${norm.name}`,
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 200,
        },
      }),
      new Table({
        borders: tableBorders,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.TableHeading'),
                    style: 'TableHeading',
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                columnSpan: 2,
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowOne.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
                width: {
                  size: 25,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowOne.ColumnTwo', {
                      norm: norm.name,
                    }),
                    style: 'TableDetail',
                  }),
                ],
                width: {
                  size: 75,
                  type: WidthType.PERCENTAGE,
                },
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowTwo.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowTwo.ColumnTwo'),
                    style: 'TableDetail',
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowThree.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowThree.ColumnTwo'),
                    style: 'TableDetail',
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowFour.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.LegendTable.RowFour.ColumnTwo'),
                    style: 'TableDetail',
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new Paragraph({
        text: '',
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
      }),
      new Table({
        borders: tableBorders,
        rows: [
          new TableRow({
            height: {
              rule: HeightRule.ATLEAST,
              value: 2000,
            },
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingOne'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                columnSpan: 3,
                verticalAlign: VerticalAlign.BOTTOM,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingTwo'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                    alignment: AlignmentType.START,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                width: {
                  size: 800,
                  type: WidthType.DXA,
                },
                textDirection: TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT,
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t(
                      'doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingThree',
                    ),
                    style: 'TableHeading',
                    spacing: {
                      after: 200,
                    },
                    alignment: AlignmentType.START,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                width: {
                  size: 800,
                  type: WidthType.DXA,
                },
                textDirection: TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT,
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingFour'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                width: {
                  size: 1600,
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.BOTTOM,
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.SecurityTable.RowOne.ColumnOne'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading2,
                },
                width: {
                  size: 20,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.SecurityTable.RowOne.ColumnTwo'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading2,
                },
                width: {
                  size: 10,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_ISO27001_2022:Statement.ApplicabilityPage.SecurityTable.RowOne.ColumnThree'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading2,
                },
                width: {
                  size: 30,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '',
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading2,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '',
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading2,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '',
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading2,
                },
              }),
            ],
          }),
          ...renderTabelISOControls(isoControls, t),
        ],
      }),
    ],
  };

  const doc = new Document({
    background: {
      color: undefined,
    },
    styles: { paragraphStyles },
    sections: [page1, page2, page3],
  });

  return doc;
};
