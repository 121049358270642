import React, { useState, useContext, useEffect } from 'react';
import { Text, ITextStyles, getTheme, Stack } from '@fluentui/react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { darkTheme, lightTheme } from 'globalThemes';
import { downArrowIcon, globalFontBoldWeight, upArrowIcon } from 'globalStyles';
import AppContext from 'App/AppContext';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import { toBool } from 'utils/string';

interface IAccordionProps {
  defaultActive?: boolean;
  setActiveKey?: string; 
  active?: boolean;  
  title: string;
  disabled?: boolean;
  showBorder?: boolean; 
  summary?: string;
  cacheKey?: LocalStorageKeys;
  children: React.ReactNode;
  onToggle?: (expanded: boolean) => void;
}

const Accordion = (props: IAccordionProps) => {
  const getDefaultState = (): boolean => {
    //1. When cacheKey is provided, try to load the state from local cache
    //2. If this fails or not provided, use defaultActive
    //3. If not provied, set to false
    let defState: boolean = false;

    if (props.cacheKey) {
      try {
        const cachedState = getLocalStorageData(appContext, props.cacheKey);
        defState = toBool(cachedState);
      } catch {
        if (props.defaultActive) defState = props.defaultActive;
      }
    } else if (props.defaultActive) {
      defState = props.defaultActive;
    }

    return defState;
  };

  const saveState = () => {
    if (props.cacheKey) {
      try {
        setLocalStorageData(appContext, props.cacheKey, activeState.toString());
      } catch {
        //ignore
      }
    }
  };

  const [activeState, setActiveState] = useState<boolean>(getDefaultState());
  const [firstTimeLoad, setFirstTimeLoad] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const theme = getTheme();

  useEffect(() => {
    if (props.setActiveKey) {
      setActiveState(true);
    }
  }, [props.setActiveKey]);

  useEffect(() => {
    if (!firstTimeLoad) {
      saveState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeState]);

  useEffect(() => {
    if (props.disabled) {
      setActiveState(false);
    }
  }, [props.disabled]);

  useEffect(() => {
    if (props.active !== undefined) {
      if (props.defaultActive !== undefined && !firstTimeLoad) {
        setFirstTimeLoad(true);
      } else {
        setActiveState(props.active);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active]);

  const getTextStylesTitle = (disabled: boolean): ITextStyles => {
    return {
      root: {
        fontWeight: globalFontBoldWeight,
        color: disabled
          ? appContext.useDarkMode
            ? darkTheme.palette?.neutralLighter
            : lightTheme.palette?.neutralQuaternary
          : undefined,
      },
    };
  };

  const getTextStylesSummary = (disabled: boolean): ITextStyles => {
    return {
      root: {
        color: disabled
          ? appContext.useDarkMode
            ? darkTheme.palette?.neutralLighter
            : lightTheme.palette?.neutralQuaternary
          : undefined,
        paddingRight: 10,
      },
    };
  };

  const toggleAccordion = () => {
    if (!props.disabled) {
      setActiveState(!activeState);
      if (props.onToggle && !firstTimeLoad) {
        props.onToggle(!activeState);
      }
    }
  };

  const getTitle = (): string => {
    return props.title;
  };

  const getSummary = (): string => {
    if (!props.summary || activeState) {
      return '';
    } else {
      return ' (' + props.summary + ')';
    }
  };

  //
  // Main render
  //
  return (
    <Stack
      onFocusCapture={() => {
        if (!activeState) {
          toggleAccordion();
        }
      }}
    >
      <Stack.Item>
        <Stack
          onClick={toggleAccordion}
          horizontal
          horizontalAlign="space-between"
          styles={{
            root: {
              background: appContext.useDarkMode ? theme.palette.neutralPrimaryAlt : theme.palette.themeLighterAlt,
              borderRadius: props.showBorder ? '3px' : undefined,
              border: props.showBorder ? `1px solid ${theme.palette.neutralLight}` : undefined,
              cursor: 'pointer',
              height: 36,
              padding: 7,
            },
          }}
        >
          <Stack horizontal style={{ overflow: 'hidden', paddingRight: 10 }}>
            <Stack.Item>
              <Text nowrap styles={getTextStylesTitle(props.disabled ?? false)} variant="medium">
                {getTitle()}
              </Text>
            </Stack.Item>
            &nbsp;
            <Text block nowrap styles={getTextStylesSummary(props.disabled ?? false)} variant="medium">
              {getSummary()}
            </Text>
          </Stack>
          <Stack.Item>{activeState ? <FontIcon {...downArrowIcon} /> : <FontIcon {...upArrowIcon} />}</Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item
        grow
        styles={{
          root: {
            borderRadius: props.showBorder ? '3px' : undefined,
            borderLeft: activeState && props.showBorder ? `1px solid ${theme.palette.neutralLight}` : undefined,
            borderRight: activeState && props.showBorder ? `1px solid ${theme.palette.neutralLight}` : undefined,
            borderBottom: activeState && props.showBorder ? `1px solid ${theme.palette.neutralLight}` : undefined,
            maxHeight: activeState ? 2000 : 0,
            transition: activeState ? 'max-height 0.7s ease-in' : undefined,
            overflow: 'hidden',
          },
        }}
      >
        {props.children}
      </Stack.Item>
    </Stack>
  );
};

export default Accordion;
