import { IAppContext } from 'App/AppContext';
import RiskState_Translation from './riskState_Translation';
import UserLanguage from './userLanguage';
import { IDropdownOption } from '@fluentui/react';

export enum RiskStateId {
  New = 1,
  Analysis = 2,
  InProgress = 3,
  Review = 4,
  Accepted = 5,
  Rejected = 6,
  Archived = 7,
  NotAccepted = 99,
  Default = -1,
}

export const getValueByKey = (key: string): RiskStateId => {
  const indexOfS = Object.keys(RiskStateId).indexOf(key);
  const value = Object.values(RiskStateId)[indexOfS];

  return value as RiskStateId;
};

export const getRiskStatusTextList = (appContext: IAppContext): IDropdownOption<RiskState>[] => {
  return appContext.globalDataCache.riskStates.items.map((rs) => {
    return {
      key: rs.riskStateId,
      text: rs.state,
    };
  });
};

export default class RiskState {
  riskStateId: RiskStateId;

  completed: boolean;

  sortOrder: number;

  trans: RiskState_Translation[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  state: string;

  riskCount?: number;

  constructor() {
    this.riskStateId = RiskStateId.Default;
    this.completed = false;
    this.sortOrder = -1;
    this.state = '';
    this.trans = [];
    this.transIdx = -1;
  }

  getTransIdx(langCode: string, defLangCode: string = UserLanguage.getFallBack()): number {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        const t: RiskState_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return i;
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        const t: RiskState_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return i;
        }
      }
    }

    return -1;
  }

  getTrans(langCode: string, defLangCode: string = UserLanguage.getFallBack()): RiskState_Translation | undefined {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        const t: RiskState_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return this.trans[i];
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        const t: RiskState_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return this.trans[i];
        }
      }
    }

    return undefined;
  }

  clone = (): RiskState => {
    const output = new RiskState();
    output.riskStateId = this.riskStateId;
    output.completed = this.completed;
    output.sortOrder = this.sortOrder;
    output.transIdx = this.transIdx;
    output.state = this.state;
    output.trans = this.trans.map((t) => t.clone());

    return output;
  };
}
