import { Fragment, useContext, useState } from 'react';
import {
  Stack,
  FontIcon,
  SelectionMode,
  Label,
  DetailsListLayoutMode,
  ActionButton,
  ScrollablePane,
  ScrollbarVisibility,
  Text,
} from '@fluentui/react';
import {
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackTokensGapExtraSmall,
  globalStackStylesHeight100,
  newIcon,
  globalStackItemStylesPaddingSceneScroll,
  deleteIcon,
} from 'globalStyles';
import Task, { TaskTypes } from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import Tag from 'models/tag';
import SubTaskList from '../SubTaskList.';
import SingleTask from '../SingleTask';
import { newGuid } from 'utils/guid';
import { TaskDetailAssignment } from './Details/TaskDetailAssignment';
import { TaskDetailName } from './Details/TaskDetailName';
import { TaskDetailTags } from './Details/TaskDetailTags';
import { TaskDetailDescription } from './Details/TaskDetailDecription';
import { TaskDetailStatus } from './Details/TaskDetailStatus';
import { TaskDetailStart } from './Details/TaskDetailStart';
import { TaskDetailDeadline } from './Details/TaskDetailDeadline';
import { iconFontSize } from './Details/TaskDetailStyles';
import AuthSchemaPicker from 'components/Pickers/AuthSchemaPicker';

interface IEventTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  isActionPending: boolean;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  windowLevel?: number;
  navigateExternal?: boolean;
  canUpdate: boolean;
}

export const EventTaskDetail = (props: IEventTaskDetail) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'control', 'translation']);
  const [newSubTask, setNewSubTask] = useState<Task | undefined>(undefined);
  const [editSubTask, setEditSubTask] = useState<Task | undefined>(undefined);
  const [selectedSubTasks, setSelectedSubTasks] = useState<Task[]>([]);

  //
  // Workflow steps
  //
  const addSubTask = () => {
    const newSubTask = new Task();
    newSubTask.taskType = TaskTypes.Event;
    newSubTask.taskTypeId = props.task.taskTypeId;
    newSubTask.taskMasterId = props.task.taskId;
    newSubTask.taskStates = appContext.globalDataCache.taskStates.items;
    newSubTask.taskStateId = newSubTask.getFirstState();
    newSubTask.id = newGuid();
    newSubTask.creatorId = appContext.user.id;
    newSubTask.creator = appContext.user;
    newSubTask.ownerId = appContext.user.id;
    newSubTask.owner = appContext.user;
    setNewSubTask(newSubTask);
  };

  const onAddSubTask = (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      if (isNew) {
        const newTask = props.task.clone();
        if (!newTask.instances) newTask.instances = [];
        newTask.instances.push(task);
        props.onUpdate(newTask);
        setNewSubTask(undefined);
      }
    }
  };

  const onEditSubTask = (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      onUpdateSubTask(task);
    }
  };

  const onRemoveSubTask = (task: Task) => {
    //not implemented, removing is done through the subtask list
  };

  const onUpdateSubTask = (task: Task) => {
    const newTask = props.task.clone();
    if (!newTask.instances) return;
    newTask.instances = newTask.instances.map((i) => (i.id === task.id ? task : i));
    props.onUpdate(newTask);
  };

  const removeSelectedSubTasks = () => {
    const newTask = props.task.clone();
    if (!newTask.instances) return;
    newTask.instances = newTask.instances.filter(
      (instance) => !selectedSubTasks.some((selectedSubTask) => selectedSubTask.id === instance.id),
    );
    props.onUpdate(newTask);
    if (newTask.instances.length === 0) {
      setSelectedSubTasks([]);
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall} styles={{ root: { paddingRight: 10 } }}>
            <TaskDetailName
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailTags
              orgTask={props.orgTask}
              task={props.task}
              tags={props.tags}
              canUpdate={props.canUpdate}
              addTagToTaskState={props.addTagToTaskState}
              removeTagFromTaskState={props.removeTagFromTaskState}
              isActionPending={props.isActionPending}
            />
            <TaskDetailDescription
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
            />
            {props.task.isSeries() && (
              <Fragment>
                <Stack
                  horizontal
                  tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
                  verticalAlign="start"
                >
                  <Stack.Item>
                    {!appContext.isMobileView && <FontIcon iconName="FavoriteStarFill" style={iconFontSize} />}
                  </Stack.Item>
                  <Stack.Item>
                    <AuthSchemaPicker
                      selectedAuthSchemaId={props.task.authSchemaId}
                      onSelect={(schema) => {
                        const copyTask = props.task.clone();
                        copyTask.authSchemaId = schema?.authSchemaId;
                        props.onUpdate(copyTask);
                      }}
                    />
                  </Stack.Item>
                  <Stack horizontal grow>
                    <TaskDetailStatus
                      task={props.task}
                      orgTask={props.orgTask}
                      canUpdate={props.canUpdate}
                      onUpdate={props.onUpdate}
                      onChangeCompletionDate={props.onChangeCompletionDate}
                      showCompleted={props.task.taskId > 0}
                      controlsOnly={true}
                    />
                  </Stack>
                </Stack>
                <TaskDetailStart
                  orgTask={props.orgTask}
                  task={props.task}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  getErrorMessage={props.getErrorMessage}
                  hideEndTime={true}
                />
                <Stack.Item>
                  <Stack
                    horizontal
                    tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
                    verticalAlign="start"
                    styles={globalStackStylesHeight100}
                  >
                    <Stack.Item>
                      {!appContext.isMobileView && <FontIcon iconName="WorkFlow" style={iconFontSize} />}
                    </Stack.Item>
                    <Stack.Item>
                      <Stack>
                        <Label>{t('task:Subtasks.Label')}</Label>
                        {props.task.instancesEnabled && (
                          <Stack horizontal tokens={globalStackTokensGapSmall}>
                            <ActionButton
                              onClick={() => addSubTask()}
                              iconProps={newIcon}
                              text={t('task:Subtasks.AddTask')}
                            />
                            <ActionButton
                              iconProps={deleteIcon}
                              text={t('tasks:TabAllTasks.Commands.Delete')}
                              disabled={selectedSubTasks.length === 0}
                              onClick={() => removeSelectedSubTasks()}
                            />
                          </Stack>
                        )}
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                {(props.isActionPending || props.task.getShowinstances()) && (
                  <Stack.Item grow>
                    <SubTaskList
                      subTasks={props.task.instances ?? []}
                      selectionMode={SelectionMode.multiple}
                      isLoading={props.isActionPending}
                      onSubTaskClick={(task) => {
                        setEditSubTask(task);
                      }}
                      updateSubTasks={(newInstances) => {
                        const newTask = props.task.clone();
                        newTask.instances = newInstances;
                        props.onUpdate(newTask);
                      }}
                      layoutMode={DetailsListLayoutMode.fixedColumns}
                      onChangeSelection={setSelectedSubTasks}
                    />
                  </Stack.Item>
                )}
                {!props.task.getShowinstances() && !props.isActionPending && (
                  <Stack.Item grow>
                    <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                      <Stack.Item>
                        <Text>{t('task:Subtasks.Disabled')}</Text>
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                )}
              </Fragment>
            )}
            {newSubTask && (
              <SingleTask
                isOpen={newSubTask !== undefined}
                task={newSubTask}
                close={() => setNewSubTask(undefined)}
                onSave={onAddSubTask}
                onRemove={() => setNewSubTask(undefined)}
                onUpdate={onUpdateSubTask}
                windowLevel={(props.windowLevel ?? 0) + 1}
                disallowSave={true}
                navigateExternal={props.navigateExternal}
              />
            )}
            {editSubTask && (
              <SingleTask
                isOpen={editSubTask !== undefined}
                task={editSubTask}
                close={() => setEditSubTask(undefined)}
                onSave={onEditSubTask}
                onRemove={onRemoveSubTask}
                onUpdate={onUpdateSubTask}
                windowLevel={(props.windowLevel ?? 0) + 1}
                disallowSave={true}
                navigateExternal={props.navigateExternal}
              />
            )}
            {!props.task.isSeries() && (
              <Fragment>
                <TaskDetailAssignment
                  orgTask={props.orgTask}
                  task={props.task}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  getErrorMessage={props.getErrorMessage}
                />
                <TaskDetailStatus
                  task={props.task}
                  orgTask={props.orgTask}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  onChangeCompletionDate={props.onChangeCompletionDate}
                  showCompleted={props.task.taskId > 0}
                />
                <TaskDetailStart
                  orgTask={props.orgTask}
                  task={props.task}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  getErrorMessage={props.getErrorMessage}
                />
                <TaskDetailDeadline
                  orgTask={props.orgTask}
                  task={props.task}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  getErrorMessage={props.getErrorMessage}
                />
              </Fragment>
            )}
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
