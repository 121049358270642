import { IAppContext } from 'App/AppContext';
import Task from 'models/tasks/task';
import { getDateTimeDiffDays, addDateDays } from 'utils/datetime';
import { newGuidNil } from 'utils/guid';
import { FilterTaskGroupKeys } from './FilterTaskGroupKeys';
import { TaskTagFilterGroupAnd } from 'models/userSetting';
import { EntityTypes } from 'models/entity';

export const controlIdSearchPrepend = 'controlId_';
export const themeIdSearchPrepend = 'themeId_';

export const startDatesList: string[] = ['startToday', 'startNextWeek', 'startNextMonth', 'startNextQuarter'];
export const deadLineList: string[] = ['deadlineTooLate', 'deadlineToday', 'deadlineThisWeek', 'deadlineNextWeek'];

const applySingleStartDateFilter = (startDateFilter: string, task: Task): boolean => {
  const daysDiff = getDateTimeDiffDays(task.startDateTime, new Date());
  if (startDateFilter === startDatesList[0]) return daysDiff === 0;
  if (startDateFilter === startDatesList[1]) {
    const daysToEndOfThisWeek = 7 - new Date().getDay();

    return daysDiff > 0 && daysDiff <= daysToEndOfThisWeek + 7;
  }
  if (startDateFilter === startDatesList[2]) {
    const daysToEndOfThisWeek = 7 - new Date().getDay();
    const daysToEndOfThisMonth = 31 - new Date().getDate();

    return daysDiff > daysToEndOfThisWeek + 7 && daysDiff <= daysToEndOfThisMonth + 31;
  }
  if (startDateFilter === startDatesList[3]) {
    const daysToEndOfThisMonth = 31 - new Date().getDate();
    const daysToEndOfThisQuarter = 31 - new Date().getDate() + 2 * 31;

    return daysDiff > daysToEndOfThisMonth + 31 && daysDiff < daysToEndOfThisQuarter + 3 * 31;
  }

  return false;
};

const applyMultipleStartDateFilters = (startDateFilters: string[], task: Task): boolean => {
  if (startDateFilters.length === 0) return true;

  for (let i = 0; i < startDateFilters.length; i++) {
    if (applySingleStartDateFilter(startDateFilters[i], task)) return true;
  }

  return false;
};

const applySingleDeadlineFilter = (deadlineFilter: string, task: Task): boolean => {
  if (deadlineFilter === deadLineList[0]) return task.getMinutesOverDeadline(new Date()) > 0;
  if (deadlineFilter === deadLineList[1])
    return getDateTimeDiffDays(task.getDeadline(), addDateDays(new Date(), 1)) === 1;
  if (deadlineFilter === deadLineList[2])
    return (
      getDateTimeDiffDays(task.getDeadline(), addDateDays(new Date(), 1)) <= 7 &&
      getDateTimeDiffDays(task.getDeadline(), addDateDays(new Date(), 1)) > 1
    );
  if (deadlineFilter === deadLineList[3]) return getDateTimeDiffDays(task.getDeadline(), new Date()) > 7;

  return false;
};

const applyMultipleDeadlineFilter = (deadlineFilters: string[], task: Task): boolean => {
  if (deadlineFilters.length === 0) return true;

  for (let i = 0; i < deadlineFilters.length; i++) {
    if (applySingleDeadlineFilter(deadlineFilters[i], task)) return true;
  }

  return false;
};

const applySingleFollowUplistfilter = (followUpFilter: string, task: Task): boolean => {
  if (followUpFilter === 'true') return task.followUp;
  if (followUpFilter === 'false') return !task.followUp;

  return false;
};

const applyMultipleFollowUpfilter = (followUpFilter: string[], task: Task): boolean => {
  if (followUpFilter.length === 0) return true;

  for (let i = 0; i < followUpFilter.length; i++) {
    if (applySingleFollowUplistfilter(followUpFilter[i], task)) return true;
  }

  return false;
};

const applyMultipleAssignmentfilter = (assignmentFilters: string[], task: Task): boolean => {
  if (assignmentFilters.length === 0) return true;

  let userCheck: boolean = false;
  if (assignmentFilters.includes('notassigned')) {
    userCheck = !task.isAssigned();
  } else {
    userCheck = task.userId ? assignmentFilters.includes(task.userId) : false;
  }

  return userCheck;
};

const applyMultipleSharedfilter = (sharedFilters: string[], task: Task): boolean => {
  if (sharedFilters.length === 0) return true;

  let userCheck: boolean = false;
  if (sharedFilters.includes('everyone')) {
    userCheck = !task.authSchemaId;
  } else {
    userCheck = task.authSchemaId ? sharedFilters.includes(task.authSchemaId.toString()) : false;
  }

  return userCheck;
};

const applyMultipleTagFilter = (
  tagFilterList: string[],
  task: Task,
  useAndForTagGroups: boolean,
  appContext: IAppContext,
): boolean => {
  if (useAndForTagGroups) {
    const tags = appContext.globalDataCache.tags.getItemsForId(tagFilterList.map((id) => Number(id)));

    for (let idx = 0; idx < tagFilterList.length; idx++) {
      const tag = appContext.globalDataCache.tags.get(Number(tagFilterList[idx]));
      const duplicates = tags.filter((t) => t.tagName === tag.tagName);
      if (duplicates.length === 1) {
        //AND
        if (!task.tagIds?.some((id) => id === tag.tagId)) {
          return false;
        }
      } else {
        //OR
        if (!task.tagIds?.some((t) => duplicates?.some((tag) => tag.tagId === t))) {
          return false;
        }
      }
    }

    return true;
  }

  //use OR
  return tagFilterList.some((t) => task.tagIds?.some((id) => id.toString() === t));
};

const applyMultipleStatusFilter = (statusFilters: string[], task: Task): boolean => {
  if (statusFilters.length === 0) return true;
  if (statusFilters.includes('open')) {
    return !task.completed || statusFilters.includes(task.taskStateId.toString());
  } else {
    return statusFilters.includes(task.taskStateId.toString());
  }
};

export const filterTasks = (filters: string[], tasks: Task[], appContext: IAppContext): Task[] => {
  if (!tasks || tasks.length === 0) return tasks;

  const output: Task[] = [];
  const startDateFilterList: string[] = [];
  const deadlineFilterList: string[] = [];
  const followUpFilterList: string[] = [];
  const assignmentFilterList: string[] = [];
  const statusFilterList: string[] = [];
  const tagFilterList: string[] = [];
  const controlFilterList: string[] = [];
  const themeFilterList: string[] = [];
  const standardFilterList: string[] = [];
  const sharedFilterList: string[] = [];
  const assetFilterList: string[] = [];
  const riskFilterList: string[] = [];
  const processFilterList: string[] = [];
  const objectiveFilterList: string[] = [];

  //get setting from user settings
  let useAndForTagGroups = appContext.globalDataCache.userSettings.get(TaskTagFilterGroupAnd) as boolean;

  filters.forEach((filter: string) => {
    const filterGroup = filter.split('$');
    const filterType = filterGroup[0] + '$';
    const filterValue = filterGroup[1];

    switch (filterType) {
      case FilterTaskGroupKeys.assignment:
        assignmentFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.status:
        statusFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.startDate:
        startDateFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.deadline:
        deadlineFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.followUp:
        followUpFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.tag:
        tagFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.shared:
        sharedFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.context:
        if (filterValue.startsWith(controlIdSearchPrepend)) controlFilterList.push(filterValue);
        else if (filterValue.startsWith(themeIdSearchPrepend)) themeFilterList.push(filterValue);

        const typeid= filterValue.split('_');
        const type = Number(typeid[0]);
        const id = Number(typeid[1]);
        if (type && id && !isNaN(type) && !isNaN(id)) {
          switch (type) {
            case EntityTypes.Process:
              processFilterList.push(id.toString());
              break;
            case EntityTypes.Objective:
              objectiveFilterList.push(id.toString());
              break;
            case EntityTypes.Asset:
              assetFilterList.push(id.toString());
              break;
            case EntityTypes.Risk:
              riskFilterList.push(id.toString());
              break;
            default:
              break;
          }
        }

        break;
      case FilterTaskGroupKeys.standard:
        standardFilterList.push(filterValue);
        break;
      case FilterTaskGroupKeys.useAndForTagGroups:
        //get the useAndForTagGroups filter from the filter
        //if not found, get it from the user settings
        useAndForTagGroups = filterValue === 'true';

        break;
      default:
        break;
    }
  });

  tasks.forEach((task: Task) => {
    let startDateFilterCheck = true;
    let deadlineFilterCheck = true;
    let followUpFilterCheck = true;
    let assignmentFilterCheck = true;
    let statusFilterCheck = true;
    let tagFilterCheck = true;
    let controlFilterCheck = true;
    let themeFilterCheck = true;
    let standardFilterCheck = true;
    let sharedFilterCheck = true;
    let assetFilterCheck = true;
    let riskFilterCheck = true;
    let processFilterCheck = true;
    let objectiveFilterCheck = true;

    if (startDateFilterList.length > 0 && !applyMultipleStartDateFilters(startDateFilterList, task))
      startDateFilterCheck = false;
    if (deadlineFilterList.length > 0 && !applyMultipleDeadlineFilter(deadlineFilterList, task))
      deadlineFilterCheck = false;
    if (followUpFilterList.length > 0 && !applyMultipleFollowUpfilter(followUpFilterList, task))
      followUpFilterCheck = false;
    if (assignmentFilterList.length > 0 && !applyMultipleAssignmentfilter(assignmentFilterList, task))
      assignmentFilterCheck = false;
    if (statusFilterList.length > 0 && !applyMultipleStatusFilter(statusFilterList, task)) statusFilterCheck = false;
    if (sharedFilterList.length > 0 && !applyMultipleSharedfilter(sharedFilterList, task)) sharedFilterCheck = false;

    if (tagFilterList.length > 0 && !applyMultipleTagFilter(tagFilterList, task, useAndForTagGroups, appContext))
      tagFilterCheck = false;

    if (
      standardFilterList.length > 0 &&
      !standardFilterList.some((t) => task.normIds?.some((id) => id.toString() === t))
    ) {
      standardFilterCheck = false;
    }

    //
    // Context filters
    //
    if (
      controlFilterList.length > 0 &&
      !controlFilterList.some((t) => task.controlIds?.some((id) => controlIdSearchPrepend + id.toString() === t))
    ) {
      controlFilterCheck = false;
    }

    if (
      themeFilterList.length > 0 &&
      !themeFilterList.some((t) => task.controlIds?.some((id) => themeIdSearchPrepend + id.toString() === t))
    ) {
      themeFilterCheck = false;
    }

    if (assetFilterList.length > 0 && !assetFilterList.some((t) => task.assetIds?.some((id) => id.toString() === t))) {
      assetFilterCheck = false;
    }

    if (riskFilterList.length > 0 && !riskFilterList.some((t) => task.riskIds?.some((id) => id.toString() === t))) {
      riskFilterCheck = false;
    }

    if (
      processFilterList.length > 0 &&
      !processFilterList.some((t) => task.processIds?.some((id) => id.toString() === t))
    ) {
      processFilterCheck = false;
    }

    if (
      objectiveFilterList.length > 0 &&
      !objectiveFilterList.some((t) => task.objectiveIds?.some((id) => id.toString() === t))
    ) {
      objectiveFilterCheck = false;
    }

    // Final check
    if (
      startDateFilterCheck &&
      deadlineFilterCheck &&
      followUpFilterCheck &&
      assignmentFilterCheck &&
      statusFilterCheck &&
      tagFilterCheck &&
      controlFilterCheck &&
      themeFilterCheck &&
      standardFilterCheck &&
      sharedFilterCheck &&
      assetFilterCheck &&
      riskFilterCheck &&
      processFilterCheck &&
      objectiveFilterCheck
    )
      output.push(task);
  });

  return output;
};

export const setFilterFromURLParams = (appContext: IAppContext): string[] => {
  const queryParams = new URLSearchParams(window.location.search);
  const filters: string[] = [];

  if (queryParams.has('user')) {
    queryParams
      .get('user')!
      .split(',')
      .forEach((id: string) => {
        if (id === newGuidNil()) {
          id = 'notassigned';
        }
        filters.push(FilterTaskGroupKeys.assignment + id);
      });
  }

  if (queryParams.has('open')) {
    filters.push(FilterTaskGroupKeys.status + 'open');
  }

  if (queryParams.has('status')) {
    const id = queryParams.get('status');
    if (id && Number.parseInt(id)) {
      filters.push(FilterTaskGroupKeys.status + id);
    }
  }

  if (queryParams.has('tags')) {
    const tags: string[] = [];
    queryParams
      .get('tags')!
      .split(',')
      .forEach((tag: string) => {
        tags.push(tag);
      });

    //filter can be a tag id or format 'group^value'
    for (let idx = 0; idx < tags.length; idx++) {
      const tagId = Number.parseInt(tags[idx]);
      if (tagId) {
        filters.push(FilterTaskGroupKeys.tag + tagId);
      } else {
        const tagValues = tags[idx].split('^');
        if (tagValues.length === 2) {
          const tagGroup = tagValues[0];
          const tagValue = tagValues[1];
          const tag = appContext.globalDataCache.tags.getForValue(tagGroup, tagValue);
          if (tag) {
            filters.push(FilterTaskGroupKeys.tag + tag.tagId);
          }
        }
      }
    }
  }

  if (queryParams.has('deadline')) {
    const id = queryParams.get('deadline');
    if (id) {
      filters.push(FilterTaskGroupKeys.deadline + id);
    }
  }

  if (queryParams.has('startDate')) {
    const id = queryParams.get('startDate');
    if (id) {
      filters.push(FilterTaskGroupKeys.startDate + id);
    }
  }

  return filters;
};
