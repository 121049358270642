import { Callout, Text, IconButton, Stack, IContextualMenuProps } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall, editIcon, deleteIcon, moreIcon } from 'globalStyles';
import { CircleRingIcon, CallOutRowStyle, SkypeCircleCheckIcon, StatusErrorFullIcon } from './CheckListStyles';
import './CheckListItemRowStyles.css';
import { CalloutInformation } from './Checklist';
import { TaskCheckListItem, TaskCheckListItemState } from 'models/tasks/taskHelperClasses';

interface ICheckListItemRow {
  index: number;
  setShowIcon: (_itm: number | undefined) => void;
  statusButtonId: string;
  editButtonId: string;
  showIcon: number | undefined;
  isCallOutOpen: TaskCheckListItem | undefined;
  setIsCallOutOpen: (_itm: TaskCheckListItem | undefined) => void;
  item: TaskCheckListItem;
  allowEdit: boolean;
  allowFill: boolean;
  onComment: (item: TaskCheckListItem) => void;
  onDelete: (item: TaskCheckListItem) => void;
  updateState: (item: TaskCheckListItem, state: TaskCheckListItemState) => void;
  callOutInfo?: CalloutInformation;
  setCallOutInfo: (item: CalloutInformation | undefined) => void;
}

const getStatusIcon = (state?: TaskCheckListItemState, id?: string): JSX.Element => {
  if (state === TaskCheckListItemState.Success) return <IconButton id={id} iconProps={SkypeCircleCheckIcon} />;
  if (state === TaskCheckListItemState.Failed) return <IconButton id={id} iconProps={StatusErrorFullIcon} />;
  if (state === TaskCheckListItemState.ToDo) return <IconButton id={id} iconProps={CircleRingIcon} />;

  return <p />;
};

export const CheckListItemRow = (props: ICheckListItemRow) => {
  const { t } = useTranslation(['task', 'translation']);

  const updateCheckListItemState = (state: TaskCheckListItemState) => {
    props.updateState(props.item, state);
    props.setIsCallOutOpen(undefined);
  };

  const itemEditMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'edit',
        text: t('task:CheckList.Operations.Edit'),
        iconProps: editIcon,
        onClick: () => {
          if (!props.item) return;

          props.setCallOutInfo({ item: props.item, buttonId: props.editButtonId });
        },
      },
      {
        key: 'delete',
        text: t('task:CheckList.Operations.Remove'),
        iconProps: deleteIcon,
        onClick: () => {
          if (!props.item) return;
          props.onDelete(props.item);
        },
      },
    ],
    directionalHintFixed: true,
    beakWidth: 0,
  };

  const getOperationOptions = () => {
    if (!props.allowFill || props.showIcon !== props.index) return <span />;

    return <IconButton iconProps={moreIcon} menuProps={itemEditMenuProps} />;
  };

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      verticalAlign="start"
      onMouseOver={() => {
        props.setShowIcon(props.index);
      }}
      onMouseLeave={() => {
        props.setShowIcon(undefined);
      }}
      id={props.editButtonId}
    >
      <Stack.Item
        onClick={() => {
          props.setIsCallOutOpen(props.item);
        }}
      >
        {props.allowEdit && props.showIcon === props.index && <div className="row-drag-handle"></div>}
        {getStatusIcon(props.item.state, props.statusButtonId)}
      </Stack.Item>
      <Stack.Item styles={{ root: { paddingTop: 3, width: '100%' } }}>
        {props.item?.description ? (
          <Text styles={{ root: { whiteSpace: 'pre-wrap' } }}>{props.item.description}</Text>
        ) : (
          t('task:CheckList.NewDescription')
        )}
      </Stack.Item>
      <Stack.Item styles={{ root: { minWidth: '50px' } }}>{getOperationOptions()}</Stack.Item>

      {props.allowFill && props.isCallOutOpen && props.isCallOutOpen.id === props.item.id && (
        <Callout
          styles={{
            root: {
              padding: '10px 12px',
            },
          }}
          setInitialFocus
          target={`#${props.statusButtonId}`}
          onDismiss={() => {
            props.setIsCallOutOpen(undefined);
          }}
          beakWidth={16}
        >
          <Stack>
            <Stack
              verticalAlign="center"
              horizontal
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateCheckListItemState(TaskCheckListItemState.Success);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={SkypeCircleCheckIcon} />
              <Stack.Item>{t('task:CheckList.Success')}</Stack.Item>
            </Stack>
            <Stack
              verticalAlign="center"
              horizontal
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateCheckListItemState(TaskCheckListItemState.Failed);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={StatusErrorFullIcon} />
              <Stack>{t('task:CheckList.Failed')}</Stack>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateCheckListItemState(TaskCheckListItemState.ToDo);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={CircleRingIcon} />
              <Stack> {t('task:CheckList.Todo')}</Stack>
            </Stack>
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};
