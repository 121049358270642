import React, { Fragment, FunctionComponent, useContext } from 'react';
import {
  Text,
  Stack,
  Spinner,
  SpinnerSize,
  getTheme,
  ComboBox,
  Label,
  IComboBoxOption,
  ChoiceGroup,
  IChoiceGroupOption,
} from '@fluentui/react';

import AppContext from 'App/AppContext';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import { useTranslation } from 'react-i18next';
import { globalComboContainerHeight, globalStackTokensGapMedium } from 'globalStyles';
import { IWidgetRendererProps } from '../WidgetRenderer';
import { HorizontalBarChart, IChartProps } from '@fluentui/react-charting';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import AppError from 'utils/appError';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetWidgetDataLibraryReadStats } from 'services/Api/dashboardService';
import { Widget63DTO, WidgetLibraryReadStatsConfigViewType } from 'models/dto/widgets/widget63DTO';
import { LinkPreviewModalOrUrl } from 'components/Links/Preview/LinkPreviewModalOrUrl';
import { apiGetLink } from 'services/Api/linkService';
import ResourceLink from 'models/resourceLink';
import { sortOnString } from 'utils/sorting';

export class WidgetLibraryReadStatsConfig {
  viewMode: WidgetLibraryReadStatsConfigViewType;

  listId: number;

  constructor() {
    this.viewMode = WidgetLibraryReadStatsConfigViewType.Best;
    this.listId = 0;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.viewMode = newRawConfig.viewMode ?? WidgetLibraryReadStatsConfigViewType.Best;
        this.listId = newRawConfig.listId ?? 0;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetLibraryReadStatsConfig {
    const newConfig = new WidgetLibraryReadStatsConfig();
    newConfig.viewMode = this.viewMode;

    return newConfig;
  }
}

interface IWidgetLibraryReadStatsProps extends IWidgetRendererProps {}

const WidgetLibraryReadStats: FunctionComponent<IWidgetLibraryReadStatsProps> = (
  props: IWidgetLibraryReadStatsProps,
) => {
  const { t } = useTranslation(['translation', 'widgets', 'kb']);
  const appContext = React.useContext(AppContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Widget63DTO | undefined>(undefined);
  const [err, setErr] = React.useState<string | undefined>(undefined);
  const [linkIdToShow, setLinkIdToShow] = React.useState<number | undefined>(undefined);
  const [linkToShow, setLinkToShow] = React.useState<ResourceLink | undefined>(undefined);
  const [config, setConfig] = React.useState<WidgetLibraryReadStatsConfig | undefined>(undefined);
  const theme = getTheme();

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  React.useEffect(() => {
    if (!linkIdToShow) {
      setLinkIdToShow(undefined);
    } else {
      getLinkToShow(linkIdToShow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkIdToShow]);

  const getLinkToShow = async (linkid: number) => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const link = await apiGetLink(linkid, accessToken, appContext.globalDataCache);
      setLinkToShow(link);
    } catch {
      appContext.showNotification(t('widgets:LibraryReadStats.LinkNotFound'));
    }
  };

  const loadConfig = (): WidgetLibraryReadStatsConfig => {
    const newConfig = new WidgetLibraryReadStatsConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      setErr(undefined);

      const config = loadConfig();
      setConfig(config);

      if (config) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const data = await apiGetWidgetDataLibraryReadStats(config.viewMode, config.listId, accessToken);
        setData(data);
      }
    } catch (err) {
      const appErr = AppError.fromGraphError(err);
      const graphErr = getGraphSharepointErrorMsg(appErr, t);
      if (graphErr) {
        setErr(graphErr);
      } else {
        appContext.setError(appErr);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getData = (): IChartProps[] => {
    const dataPoints: IChartProps[] = [];
    if (!data || !data.links || !data.linkReadCount || !data.maxReadCount || !data.linkUniqueUserCount) {
      return dataPoints;
    }

    for (let idx = 0; idx < data.links.length; idx++) {
      const link = data.links[idx];
      const count = data.linkReadCount[idx];
      const userCount = data.linkUniqueUserCount[idx];

      const newPoint: IChartProps = {
        chartTitle: link.linkName,
        chartData: [
          {
            legend: link.linkName,
            horizontalBarChartdata: { x: count, y: data.maxReadCount },
            color: theme.palette.themePrimary,
            xAxisCalloutData: t('widgets:LibraryReadStats.UniqueUsers', { count: userCount }),
            yAxisCalloutData: `${((count / data.maxReadCount) * 100).toFixed(0)}%`,
            onClick: () => setLinkIdToShow(link.linkId),
          },
        ],
      };

      dataPoints.push(newPoint);
    }

    return dataPoints;
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (err) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{err}</Text>
      </Stack>
    );
  }

  const points = getData();
  const hasData = points.length > 0;

  return (
    <Fragment>
      <Stack verticalFill>
        {!hasData && (
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <Text>{t('widgets:LibraryReadStats.NoData')}</Text>
          </Stack>
        )}
        {hasData && (
          <Stack.Item grow>
            <HorizontalBarChart
              data={points}
              hideRatio={[true, false]}
              chartDataMode={config?.viewMode === WidgetLibraryReadStatsConfigViewType.Worst ? 'fraction' : undefined}
            />
          </Stack.Item>
        )}
      </Stack>
      <LinkPreviewModalOrUrl
        link={linkToShow}
        isOpen={linkToShow !== undefined}
        onClose={() => setLinkIdToShow(undefined)}
      />
    </Fragment>
  );
};

export default WidgetLibraryReadStats;

//
// Config
//

interface IWidgetConfigLibraryReadStatsProps extends IWidgetConfigRendererProps {}

export const WidgetConfigLibraryReadStats: FunctionComponent<IWidgetConfigLibraryReadStatsProps> = (
  props: IWidgetConfigLibraryReadStatsProps,
) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedList, setSelectedList] = React.useState<number>(0);
  const [selectedViewMode, setSelectedViewMode] = React.useState<WidgetLibraryReadStatsConfigViewType>(
    WidgetLibraryReadStatsConfigViewType.Best,
  );

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadConfig = (): WidgetLibraryReadStatsConfig => {
    const newConfig = new WidgetLibraryReadStatsConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      await appContext.globalDataCache.lists.getItems();

      const config = loadConfig();
      setSelectedList(config.listId);
      setSelectedViewMode(config.viewMode);

      setIsLoading(true);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const config = getConfig(selectedViewMode, selectedList);
    props.onUpdateConfig(JSON.stringify(config), true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewMode, selectedList]);

  const getConfig = (viewMode: WidgetLibraryReadStatsConfigViewType, listId: number): WidgetLibraryReadStatsConfig => {
    const config = new WidgetLibraryReadStatsConfig();
    config.viewMode = viewMode;
    config.listId = listId;

    return config;
  };

  const getLibraryCategoryOptions = (): IComboBoxOption[] => {
    const lists: IComboBoxOption[] = [];

    lists.push({
      key: 0,
      data: undefined,
      text: t('widgets:LibraryReadStats.Config.AllCategories'),
    });

    lists.push(
      ...appContext.globalDataCache.lists.items
        .filter((list) => !list.isVirtual)
        .sort((a, b) => sortOnString(a.name, b.name))
        .map((list) => {
          return {
            key: list.listId,
            data: list,
            text: list.name,
          };
        }),
    );

    return lists;
  };

  const onChangeViewMode = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    const period = Number.parseInt(option?.key ?? WidgetLibraryReadStatsConfigViewType.Best.toString());
    setSelectedViewMode(period);
  };

  const viewModeOptions: IChoiceGroupOption[] = [
    { key: WidgetLibraryReadStatsConfigViewType.Best.toString(), text: t('widgets:LibraryReadStats.Config.TopBest') },
    { key: WidgetLibraryReadStatsConfigViewType.Worst.toString(), text: t('widgets:LibraryReadStats.Config.TopWorst') },
  ];

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <Label>{t('widgets:LibraryReadStats.Config.ViewMode')}</Label>
        <ChoiceGroup selectedKey={selectedViewMode.toString()} options={viewModeOptions} onChange={onChangeViewMode} />
      </Stack.Item>
      <Stack.Item>
        <Label>{t('widgets:LibraryReadStats.Config.Category')}</Label>
        <ComboBox
          styles={globalComboContainerHeight}
          autoComplete={'on'}
          options={getLibraryCategoryOptions()}
          selectedKey={selectedList}
          disabled={false}
          onChange={(ev, option) => {
            setSelectedList(option?.key as number);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
