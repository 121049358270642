import { Stack, Link, Spinner, SpinnerSize, Text, FontIcon, getTheme } from '@fluentui/react';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import { Fragment, useContext, useState } from 'react';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetLink } from 'services/Api/linkService';
import { LinkPreviewModalOrUrl } from 'components/Links/Preview/LinkPreviewModalOrUrl';
import { SearchResult } from 'models/search/SearchResult';
import { EntityTypes } from 'models/entity';

interface IGlobalSearchResultLinkProps {
  result: SearchResult;
}

export const GlobalSearchResultLink = (props: IGlobalSearchResultLinkProps) => {
  const appContext = useContext(AppContext);
  const [linkToShow, setLinkToShow] = useState<ResourceLink | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = getTheme();

  const loadData = async (linkId: number | undefined) => {
    try {
      if (!linkId) return;

      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const resourceLink = await apiGetLink(linkId, accessToken, appContext.globalDataCache);
      if (resourceLink && resourceLink.linkId) {
        setLinkToShow(resourceLink);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  //
  // Main render
  //
  if (props.result.spName === props.result.name) {
    return null;
  }

  return (
    <Stack>
      <Stack horizontal tokens={globalStackTokensGapExtraSmall} verticalAlign="center">
        {props.result.entityType === EntityTypes.Link && <Text>{props.result.spName}</Text>}
        {props.result.entityType !== EntityTypes.Link && (
          <Fragment>
            <FontIcon iconName={'Link'} style={{ color: theme.palette.themePrimary }} />
            <Link
              underline
              onClick={(ev) => {
                ev.stopPropagation();
                loadData(props.result.spLinkId);
              }}
            >
              {props.result.spName}
            </Link>
          </Fragment>
        )}
        {isLoading && <Spinner size={SpinnerSize.small} />}
        <LinkPreviewModalOrUrl
          isOpen={linkToShow !== undefined}
          onClose={() => setLinkToShow(undefined)}
          link={linkToShow}
          confirmOpen={false}
          enableLinks={true}
        />
      </Stack>
    </Stack>
  );
};
