import { mapFromSearchResults } from 'mappings/searchMapping';
import { SearchResultDTO } from 'models/dto/search/SearchResultDTO';
import http from './httpService';
import AppError from 'utils/appError';
import { SearchResult } from 'models/search/SearchResult';
import { SearchOptionsDTO } from 'models/dto/search/SearchOptionsDTO';
import { EntityTypes } from 'models/entity';

export async function apiGetGlobalSearchResult(
  text: string,
  type: EntityTypes,
  sharePointSearch: boolean,
  accessToken: string,
): Promise<SearchResult[]> {
  try {
    const options = new SearchOptionsDTO();
    options.text = text;
    options.sharePointSearch = sharePointSearch;
    options.entityTypes = [type];

    const ar = await http.post<SearchResultDTO[]>(`/search`, options, http.getRequestConfig(accessToken));
    if (!ar || !ar.data) return [];

    return mapFromSearchResults(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
