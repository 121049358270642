import Joi from 'joi';
import { EntityStatus } from 'models/entityStatus';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import Task from '../tasks/task';
import { Objective_Translation } from './objective_Translation';
import Group from 'models/group';
import User from 'models/user';
import Process from 'models/process/process';
import { TFunction } from 'i18next';
import { getEntityStatusText } from 'globalFunctions';
import Dashboard from 'models/dashboard';
import KPI from 'models/kpi/kpi';
import { sortOnChapter, sortOnCode } from 'utils/sorting';
import { IOwner } from 'models/owner';
import { IDropdownOption } from '@fluentui/react';

export enum ObjectiveStatus {
  Todo = 0,
  Designing = 1,
  Monitoring = 2,
}

export const getObjectiveStatusText = (
  item: Objective | ObjectiveStatus | undefined,
  t: TFunction<string[]>,
): string => {
  if (item !== undefined) {
    let state: ObjectiveStatus;
    if (typeof item === 'number') {
      state = item;
    } else {
      state = item.state;
    }

    switch (state) {
      case ObjectiveStatus.Todo:
        return t('objective:Status.ToDo');
      case ObjectiveStatus.Designing:
        return t('objective:Status.Designing');
      case ObjectiveStatus.Monitoring:
        return t('objective:Status.Monitoring');
    }
  }

  return '';
};

export const getObjectiveStatusTextList = (t: TFunction<string[]>): IDropdownOption<ObjectiveStatus>[] => {
  return [
    {
      key: ObjectiveStatus.Todo,
      text: getObjectiveStatusText(ObjectiveStatus.Todo, t),
    },
    {
      key: ObjectiveStatus.Designing,
      text: getObjectiveStatusText(ObjectiveStatus.Designing, t),
    },
    {
      key: ObjectiveStatus.Monitoring,
      text: getObjectiveStatusText(ObjectiveStatus.Monitoring, t),
    },
  ];
};

export default class Objective implements IOwner {
  objectiveId: number;

  authSchemaId?: number;

  code: string;

  parentObjectiveId?: number;

  commentTrailId: number;

  auditTrailId: number;

  groupId?: string;

  group?: Group;

  ownerId?: string;

  ownerRoleId?: string;

  owner?: User;

  state: ObjectiveStatus;

  dashboard?: Dashboard;

  tasks: Task[];

  tagIds: number[];

  processes: Process[];

  kpis: KPI[];

  trans?: Objective_Translation[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description: string;

  constructor() {
    this.objectiveId = -1;
    this.code = '';
    this.name = '';
    this.description = '';
    this.parentObjectiveId = -1;
    this.commentTrailId = -1;
    this.auditTrailId = -1;
    this.state = ObjectiveStatus.Todo;
    this.tasks = [];
    this.tagIds = [];
    this.transIdx = -1;
    this.processes = [];
    this.kpis = [];
  }

  clone(): Objective {
    const newItem = new Objective();
    newItem.objectiveId = this.objectiveId;
    newItem.parentObjectiveId = this.parentObjectiveId;
    newItem.code = this.code;
    newItem.name = this.name;
    newItem.description = this.description;
    newItem.state = this.state;
    newItem.groupId = this.groupId;
    newItem.ownerId = this.ownerId;
    newItem.commentTrailId = this.commentTrailId;
    newItem.auditTrailId = this.auditTrailId;
    newItem.transIdx = this.transIdx;
    newItem.trans = this.trans ? this.trans.map((tran) => tran.clone()) : undefined;
    newItem.tasks = this.tasks.map((task) => task.clone());
    newItem.tagIds = [...this.tagIds];
    newItem.processes = this.processes.map((p) => p.clone());
    newItem.kpis = this.kpis.map((k) => k.clone());
    newItem.dashboard = this.dashboard?.clone();
    newItem.authSchemaId = this.authSchemaId;
    newItem.ownerRoleId = this.ownerRoleId;

    return newItem;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  static getObjectiveStateText = (state: EntityStatus, t: TFunction<string[]>): string => {
    return getEntityStatusText(state, t);
  };

  static getDefAuthSchema(items: Objective[], parent: Objective | undefined): number | undefined {
    if (parent) return parent.authSchemaId;
    if (items.length > 0) {
      const sortedItems = [...items];
      sortedItems.sort((a, b) => sortOnChapter(a.code, b.code));

      return sortedItems[sortedItems.length - 1].authSchemaId;
    }

    return undefined;
  }

  isEqual(item: Objective) {
    if (item.objectiveId !== this.objectiveId) return false;
    if (item.parentObjectiveId !== this.parentObjectiveId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (item.authSchemaId !== this.authSchemaId) return false;
    if (item.ownerRoleId !== this.ownerRoleId) return false;

    return true;
  }

  static isChildOf = (objectivees: Objective[], parent: Objective, objective: Objective | undefined): boolean => {
    let current: Objective | undefined = objective;
    let max: number = 0;

    while (current !== undefined && current.parentObjectiveId !== undefined && max < 100) {
      if (current.parentObjectiveId === parent.objectiveId) {
        return true;
      }

      current = objectivees.find((c) => c.objectiveId === current?.parentObjectiveId);
      max++;
    }

    return false;
  };

  static sortOnLevelAndCode = (allEntities: Objective[], entities: Objective[]): Objective[] => {
    //create a has table for fast lookup
    const itemDict: Record<number, Objective> = {};

    allEntities.forEach((item) => {
      itemDict[item.objectiveId] = item;
    });

    //show all items without parent or items with parent which is not found
    const allParents = entities.filter((item) => {
      const parentId = item.parentObjectiveId;
      if (!parentId || !itemDict[parentId]) return true;

      return false;
    });

    //add all sorted children recursively to the root items
    const output = this.addAllSortedChildren(allEntities, allParents);

    return output;
  };

  private static addAllSortedChildren = (allEntities: Objective[], allParents: Objective[]): Objective[] => {
    const output: Objective[] = [];
    const newItems = allParents.slice().sort((a, b) => {
      return sortOnCode(a.code, b.code);
    });

    for (let i = 0; i < newItems.length; i++) {
      const aItem = newItems[i];
      const children = this.getChildren(allEntities, aItem).sort((a, b) => sortOnCode(a.code, b.code));
      output.push(newItems[i]);
      const sortedChildren = this.addAllSortedChildren(allEntities, children);
      output.push(...sortedChildren);
    }

    return output;
  };

  private static getChildren = (allEntities: Objective[], item: Objective): Objective[] => {
    return allEntities.filter((_item) => _item.parentObjectiveId === item.objectiveId);
  };
}
