import { ApiErrorInfo } from 'models/apiErrorInfo';
import { ApiErrorInfoDTO } from 'models/dto/apiErrorInfoDTO';

export const mapFromApiErrorInfoDictionary = (apiErrorInfo: {
  [id: number]: ApiErrorInfoDTO;
}): { [id: number]: ApiErrorInfo } => {
  if (!apiErrorInfo) return {};
  const output: { [id: number]: ApiErrorInfo } = {};

  for (const key in apiErrorInfo) {
    output[key] = mapFromApiErrorInfo(apiErrorInfo[key]);
  }

  return output;
};

export const mapFromApiErrorInfo = (apiErrorInfo: ApiErrorInfoDTO): ApiErrorInfo => {
  const output = new ApiErrorInfo();
  if (!apiErrorInfo) return output;
  
  output.level = apiErrorInfo.level;
  output.code = apiErrorInfo.code;
  output.message = apiErrorInfo.message;
  output.details = apiErrorInfo.details;

  return output;
};
