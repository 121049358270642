import {
  Callout,
  ICalloutContentStyles,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Target,
  Link,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import KPIList from 'components/KPI/KPIList';
import KPI from 'models/kpi/kpi';
import KPIData from 'models/kpi/kpiData';
import { useEffect, useState , useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { apiGetKPIs } from 'services/Api/kpiService';
import { apiRequest } from 'services/Auth/authConfig';
import { sortOnString } from 'utils/sorting';

interface IEventFormKPIPickerProps {
  isOpen: boolean;
  target: Target;
  onSelect: (item: KPI) => void;
  onDismiss: () => void;
  existingKPIs?: number[];
  showSystem?: boolean;
  onAddNewKPI?: () => void;
}

const KPIPicker = (props: IEventFormKPIPickerProps) => {
  const { t } = useTranslation(['translation', 'kpis', 'classifications']);
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [kpis, setKPIs] = useState<KPI[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedKPI, setSelectedKPI] = useState<KPI | undefined>(undefined);

  //
  // Effects
  //
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);

      await appContext.globalDataCache.tags.getItems();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let kpis = await apiGetKPIs(accessToken, undefined);

      if (!props.showSystem) {
        kpis = kpis.filter((k) => !k.isSystemType());
      }

      //add a data record to each KPI
      kpis.forEach((k) => {
        const newData = new KPIData();
        newData.kpi = k;
        k.data = [newData];
      });

      kpis.sort((a, b) => sortOnString(a.name, b.name));
      setKPIs(kpis);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: '10px 12px',
      minHeight: 300,
      height: '50vh',
      maxHeight: '80vh',
      minWidth: 300,
      width: '50vw',
    },
  };

  const navigateToKPIs = () => {
    history.push('/organization/kpis');
  };

  //
  // Main render
  //
  return (
    <Callout
      styles={calloutStyle}
      hidden={!props.isOpen}
      setInitialFocus
      gapSpace={0}
      target={props.target}
      onDismiss={() => props.onDismiss()}
      dismissOnTargetClick
    >
      <Stack verticalFill>
        {!isLoading && kpis.length === 0 && (
          <Stack verticalFill verticalAlign="center" horizontalAlign="center">
            <Link onClick={() => navigateToKPIs()}>{t('kpis:Picker.KPILink')}</Link>
          </Stack>
        )}
        {isLoading && (
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <Spinner size={SpinnerSize.large} />
          </Stack>
        )}
        {!isLoading && kpis.length > 0 && (
          <Stack.Item grow>
            <KPIList
              kpis={kpis}
              onSelectKPI={(item) => setSelectedKPI(item)}
              isLoading={isLoading}
              showSearch={true}
              existingKPIs={props.existingKPIs}
              onAddNewKPI={props.onAddNewKPI}
              onDoubleClickKPI={(item) => {
                setSelectedKPI(item);
                props.onSelect(item);
              }}
            />
          </Stack.Item>
        )}
        <Separator />
        <Stack.Item>
          <PrimaryButton
            disabled={selectedKPI === undefined}
            onClick={() => {
              if (selectedKPI) {
                props.onSelect(selectedKPI);
              }
            }}
          >
            {t('translation:General.Button.Select')}
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </Callout>
  );
};

export default KPIPicker;
