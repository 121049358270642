export function hasParentNodeId(node: Element | null, id: string): boolean {
  let parent = node;
  
  while (parent) {
    if (parent.id === id) {
      return true;
    }
    parent = parent.parentElement;
  }

  return false;
}
