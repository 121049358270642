import { globalOrgUnitId, globalDefOrgLang, globalUserLang } from 'App/AppContextProvider';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import AppError from 'utils/appError';
import Config from '../Config/configService';
import logger from '../Logging/logService';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { nullsToUndefined } from 'utils/object';

//create global instance of Axios
const instance = axios.create({
  baseURL: Config.get('Api.BaseURL'),
});

//connect the retry plugin and configure
axiosRetry(instance, { retries: 3, retryDelay: exponentialDelay });

//error to remember last stack trace
let lastStack: Error | undefined = undefined;

//log each request to the global client log
instance.interceptors.request.use((request) => {
  if (request) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const count = ((request['axios-retry'] as any)?.retryCount ?? 0) as number;
    const msg = `${request.method?.toUpperCase() ?? 'METHOD'} ${request.url ?? 'URL'} (${count})`;
    logger.debug(msg);
    //remember last stack trace
    lastStack = new Error('Thrown at:');
  }

  return request;
});

//log each error to the global client log
instance.interceptors.response.use(undefined, (error) => {
  if (lastStack) {
    //append last known stack trace
    error.stack = `${error.stack}\n${lastStack.stack}`;
  }
  logger.error(new AppError(JSON.stringify(error)));

  return Promise.reject(error);
});

//replace all null values with undefined
instance.interceptors.response.use((respons) => {
  try {
    respons.data = nullsToUndefined(respons.data);
  } catch {
    //ignore
  }

  return respons;
});

//get a request configuration object for the back-end API
function getRequestConfig(accessToken: string, orgUnitId?: string, customerId?: string): AxiosRequestConfig {
  const config: AxiosRequestConfig = {};
  const headers = new AxiosHeaders();

  //set access token
  headers.set('authorization', 'Bearer ' + accessToken);

  //org unit can be supplied to individual requests, overwriting the global setting
  if (globalOrgUnitId || orgUnitId) {
    headers.set('orgunitid', orgUnitId ?? globalOrgUnitId);
  }

  //set global UI language
  if (globalUserLang) {
    headers.set('userlang', globalUserLang);
  }

  //set default organizational language
  if (globalDefOrgLang) {
    headers.set('deforglang', globalDefOrgLang);
  }

  //set requested customer
  if (customerId) {
    headers.set('customerid', customerId);
  }

  config.headers = headers.toJSON();

  return config;
}

const httpService = {
  get: instance.get,
  put: instance.put,
  post: instance.post,
  delete: instance.delete,
  getRequestConfig,
};

export default httpService;
