import EntityDTO from 'models/dto/entityDTO';
import Control_TranslationDTO from './control_TranslationDTO';
import IdListDTO from './IdListDTO';
import TaskDTO from 'models/dto/tasks/taskDTO';
import { ControlTypes, ApplicabilityReasons, ImplementationStates } from 'models/control';
import { EntityStatus } from 'models/entityStatus';
import ISOControlDTO from './isoControlDTO';
import DashboardDTO from './dashboardDTO';
import { newGuidNil } from 'utils/guid';

export default class ControlDTO {
  tenantId: string;

  controlId: number;

  code: string;

  parentControlId?: number;

  controlType?: ControlTypes;

  recurringPattern?: string;

  state: EntityStatus;

  applicabilityReason: IdListDTO;

  implementationState: ImplementationStates;

  groupId?: string;

  ownerId?: string;

  ownerRoleId?: string;

  commentTrailId: number;

  auditTrailId: number;

  normIds?: IdListDTO;

  isoControlIds?: IdListDTO;

  dashboard?: DashboardDTO;

  inheritTenantId?: string;

  inheritControlId?: number;

  trans?: Control_TranslationDTO[];

  tasks?: TaskDTO[];

  tagIds?: IdListDTO;

  monitoringParent?: EntityDTO;

  isoControls?: ISOControlDTO[];

  constructor() {
    this.tenantId = newGuidNil();
    this.controlId = 0;
    this.code = '';
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.state = EntityStatus.Todo;
    this.applicabilityReason = new IdListDTO([ApplicabilityReasons.Basic.toString()]);
    this.implementationState = ImplementationStates.Implemented;
  }
}

export class ControlTaskStatsDTO {
  controlId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.controlId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}

export class ControlAlertStatsDTO {
  controlId: number;

  kpiId: number;

  alertsFiredCount: number;

  constructor() {
    this.controlId = 0;
    this.kpiId = 0;
    this.alertsFiredCount = 0;
  }
}

