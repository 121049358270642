import { Stack, IconButton, Separator, CommandButton, TooltipHost } from '@fluentui/react';
import AppContext from 'App/AppContext';
import WarningMessage from 'components/Notification/WarningMessage';
import {
  globalStackStylesHeight100PaddingSmall,
  globalStackTokensGapSmall,
  refreshIcon,
  sharepointIcon,
} from 'globalStyles';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphSharepointPagesRequest } from 'services/Auth/authConfig';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import { graphGetPagesForSite, graphGetSite } from 'services/Graph/graphService';
import AppError from 'utils/appError';
import DirectoryParents from './DirectoryParents';
import MySitePages from './MySitePages';
import MySites, { MySiteAuthTypes } from './MySites';
import { ISitePage, ISite } from 'services/Graph/SharepointInterfaces';
import ResourceLink from 'models/resourceLink';
import { navigateToExternalUrl } from 'utils/url';

export interface ParentSiteInformation {
  tenantId?: string;
  siteId: string;
}

interface ISharepointSitePagePickerProps {
  invalidAuthToken: () => void;
  onSelect: (items: ISitePage[]) => void;
  parentSite?: ParentSiteInformation;
  listPages: ResourceLink[];
  existingLinks: ResourceLink[];
  entityContext: boolean;
  listPagesLoading: boolean;
  linkToEdit?: ResourceLink;
  singleSelect?: boolean;
}

const SharepointSitePagePicker = (props: ISharepointSitePagePickerProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['sharepoint', 'translation']);
  const [selectedSite, setSelectedSite] = useState<ISite | undefined>(undefined);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [sitePages, setSitePages] = useState<ISitePage[]>([]);
  const [sitePagesDisplay, setSitePagesDisplay] = useState<ISitePage[]>([]);
  const [spError, setSPError] = useState<string | undefined>(undefined);
  const [parentInfo, setParentInfo] = useState<ParentSiteInformation | undefined>(undefined);
  const [overrideShowOnlyExistingPages, setOverrideShowOnlyExistingPages] = useState<boolean>(false);

  //
  // Loading
  //
  const checkToken = (token: string): boolean => {
    if (!token) {
      props.invalidAuthToken();
      appContext.showNotification(t('translation:General.Notifications.TryAgainAfterConsent'));

      return false;
    }

    return true;
  };

  const loadParentInformation = async () => {
    if (isBusy || !props.parentSite) return;
    try {
      setIsBusy(true);
      setSPError(undefined);

      const graphInterface = await appContext.getGraphInterface(
        graphSharepointPagesRequest.scopes,
        props.parentSite.tenantId,
      );
      if (!checkToken(graphInterface.accessToken)) return;
      const _site: ISite = await graphGetSite(graphInterface.client, props.parentSite.siteId);
      if (_site) {
        _site.tenantId = props.parentSite.tenantId;
      }
      setSelectedSite(_site);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsBusy(false);
    }
  };

  const fetchPages = async () => {
    if (selectedSite === undefined || !selectedSite.id) return;
    try {
      setIsBusy(true);
      setSPError(undefined);

      //get all the pages in the site
      const graphInterface = await appContext.getGraphInterface(
        graphSharepointPagesRequest.scopes,
        selectedSite.tenantId,
      );
      if (!checkToken(graphInterface.accessToken)) return;
      const _pages: ISitePage[] = await graphGetPagesForSite(graphInterface.client, selectedSite.id, false);

      _pages.forEach((l) => (l.tenantId = selectedSite.tenantId));
      setSitePages(_pages);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsBusy(false);
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    if (props.parentSite) {
      //changing the parentFolder prop triggers a reload: check whether this is not only a new object but has also different content
      if (props.parentSite.siteId !== parentInfo?.siteId || props.parentSite.tenantId !== parentInfo?.tenantId) {
        setParentInfo(props.parentSite);
        loadParentInformation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentSite]);

  useEffect(() => {
    if (!props.listPagesLoading) {
      let override = overrideShowOnlyExistingPages;
      //when there are no listpages, set override to true because this is what the user would do immediately
      if (props.listPages.length === 0) {
        override = true;
        setOverrideShowOnlyExistingPages(true);
      }
      const newPages = filterPages(sitePages, override);
      setSitePagesDisplay(newPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listPagesLoading, sitePages]);

  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite]);

  //
  // Functions
  //
  const onOpenSharePoint = () => {
    if (!selectedSite?.webUrl) return;
    navigateToExternalUrl(selectedSite.webUrl + '/SitePages', '', '');
  };

  const updateSiteSelection = (site?: ISite) => {
    setSelectedSite(site);
    setSitePages([]);
  };

  const showAllPagesNotInLibrary = () => {
    const newPages = filterPages(sitePages, true);
    setSitePagesDisplay(newPages);
    setOverrideShowOnlyExistingPages(true);
  };

  const filterPages = (pages: ISitePage[], overrideShowOnlyPagesInLibrary: boolean): ISitePage[] => {
    let newPages: ISitePage[] = [...pages];
    if (props.entityContext && !overrideShowOnlyPagesInLibrary) {
      //show only pages that are already in the library and not in the list with existing items
      newPages = pages.filter((p) => props.listPages.some((l) => l.pageId === p.id));
    }

    return newPages;
  };

  const onRefresh = () => {
    fetchPages();
    setOverrideShowOnlyExistingPages(false);
  };

  const selectSitePage = (items?: ISitePage[]) => {
    if (items) props.onSelect(items);
  };

  //
  // Render
  //
  return (
    <Stack verticalFill>
      {/* Body Left and Right*/}
      <Stack.Item grow>
        <Stack horizontal styles={globalStackStylesHeight100PaddingSmall} tokens={globalStackTokensGapSmall}>
          {/* Left Groups Part*/}
          {!props.parentSite && (
            <Stack.Item styles={{ root: { width: '30%', padding: 5 } }}>
              <MySites
                onSelect={(item) => updateSiteSelection(item)}
                selectedSite={selectedSite}
                checkToken={checkToken}
                authType={MySiteAuthTypes.Page}
              />
              <Separator vertical />
            </Stack.Item>
          )}
          {/* Right Part*/}
          <Stack.Item grow styles={{ root: { width: !props.parentSite ? '70%' : '100%', padding: 5 } }}>
            <Stack verticalFill>
              {(props.parentSite || selectedSite) && (
                <Stack.Item>
                  <Stack horizontal wrap horizontalAlign="space-between">
                    <Stack.Item>
                      <DirectoryParents
                        parents={[]}
                        site={selectedSite}
                        onSelectDrive={() => {}}
                        onSelectDriveFolder={() => {}}
                        onBack={() => {}}
                        onSelectSite={() => {}}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      {!overrideShowOnlyExistingPages && props.entityContext && (
                        <TooltipHost content={t('sharepoint:SitePagePicker.ShowAllFilter')}>
                          <CommandButton
                            iconProps={{ iconName: 'ClearFilter' }}
                            onClick={() => showAllPagesNotInLibrary()}
                          ></CommandButton>
                        </TooltipHost>
                      )}
                      {selectedSite && (
                        <TooltipHost content={t('sharepoint:CommandBar.OpenSharePoint')}>
                          <IconButton iconProps={sharepointIcon} onClick={onOpenSharePoint} />
                        </TooltipHost>
                      )}
                      <TooltipHost content={t('sharepoint:CommandBar.Refresh')}>
                        <IconButton iconProps={refreshIcon} onClick={onRefresh} />
                      </TooltipHost>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              )}
              {spError && !isBusy && (
                <Stack verticalFill>
                  <WarningMessage message={spError} onDismiss={undefined}></WarningMessage>
                </Stack>
              )}
              {!spError && selectedSite && (
                <Stack.Item grow>
                  <MySitePages
                    pages={sitePagesDisplay}
                    onSelect={(items: ISitePage[]) => {
                      selectSitePage(items);
                    }}
                    isListLoading={isBusy}
                    existingLinks={props.existingLinks}
                    linkToEdit={props.linkToEdit}
                    singleSelect={props.singleSelect}
                  />
                </Stack.Item>
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default SharepointSitePagePicker;
