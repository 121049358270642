import http from './httpService';
import AppError from 'utils/appError';
import {
  mapFromTasks,
  mapToTask,
  mapFromTask,
  mapFromTaskTemplateScene,
  mapFromDateRange,
  mapToTasks,
  mapFromTasksArray,
  mapFromTaskTasks,
  mapFromTaskContext,
  mapFromTaskCheckListConversion,
} from 'mappings/taskMapping';
import AssigneeDTO from 'models/dto/assigneeDTO';
import { toApiDateRequired, toApiDateTimeOptional, toApiDateTimeRequired } from 'utils/datetime';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import DateRange from 'models/dateRange';
import DateRangeDTO from 'models/dto/dateRangeDTO';
import IdListDTO from 'models/dto/IdListDTO';
import OutlookTasks from 'models/outlook/outlookTasks';
import OutlookTasksDTO from 'models/dto/outlook/outlookTasks';
import { mapFromOutlookTasks } from 'mappings/outlook/outlookTasksMapping';
import TaskTypeDTO from 'models/dto/tasks/taskTypeDTO';
import { mapFromTaskType, mapFromTaskTypeForm, mapFromTaskTypes, mapToTaskType } from 'mappings/taskTypeMapping';
import TaskType from 'models/tasks/taskType';
import TaskDTO from 'models/dto/tasks/taskDTO';
import TaskTemplateSceneDTO from 'models/dto/tasks/taskTemplateSceneDTO';
import TasksDTO from 'models/dto/tasks/tasksDTO';
import Task from 'models/tasks/task';
import TaskTemplateScene from 'models/tasks/taskTemplateScene';
import Tasks from 'models/tasks/tasks';
import { TaskRelationship, TaskTask } from 'models/tasks/taskTask';
import { TaskTaskDTO } from 'models/dto/tasks/taskTaskDTO';
import TaskTypeFormElementDTO from 'models/dto/tasks/taskTypeFormElementDTO';
import TaskTypeFormElement from 'models/tasks/taskTypeFormElement';
import { mapFromApprovals } from 'mappings/approvalMapping';
import { Approval } from 'models/approval';
import { TaskContext } from 'models/tasks/taskContext';
import { TaskContextDTO } from 'models/dto/tasks/taskContextDTO';
import { TaskCheckListConversionDTO } from 'models/dto/tasks/taskChecklistConversionDTO';
import { TaskCheckListConversion } from 'models/tasks/taskChecklistConversion';

export async function apiAddTask(
  task: Task,
  schedule: boolean,
  accessToken: string,
  globalDataCache: GlobalDataCache,
  year?: number,
): Promise<Task> {
  try {
    const taskDTO = mapToTask(task);
    const ar = await http.post<TaskDTO>(
      `/tasks?schedule=${schedule}&year=${year ?? 0}`,
      taskDTO,
      http.getRequestConfig(accessToken),
    );

    return mapFromTask(ar.data, globalDataCache) as Task;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTask(
  task: Task,
  schedule: boolean,
  accessToken: string,
  globalDataCache: GlobalDataCache,
  year?: number,
): Promise<Task | undefined> {
  try {
    const taskDTO = mapToTask(task);
    const ar = await http.put<TaskDTO>(
      `/tasks?schedule=${schedule}&year=${year ?? 0}`,
      taskDTO,
      http.getRequestConfig(accessToken),
    );

    if (ar.data) {
      return mapFromTask(ar.data, globalDataCache);
    } else {
      return undefined;
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTasks(
  tasks: Task[],
  schedule: boolean,
  accessToken: string,
  globalDataCache: GlobalDataCache,
  year?: number,
): Promise<Tasks> {
  try {
    const tasksDTO = mapToTasks(tasks);
    const ar = await http.put<TasksDTO>(
      `/tasks/bulk?schedule=${schedule}&year=${year ?? 0}`,
      tasksDTO,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTask(task: Task, accessToken: string): Promise<void> {
  try {
    await http.post(`/tasks/delete/${task.taskId}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForControl(
  controlId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/control/${controlId}?start=${toApiDateTimeRequired(start)}&end=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForTag(
  tagId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(`/tasks/tag/${tagId}`, http.getRequestConfig(accessToken));

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForTheme(
  themeId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/theme/${themeId}?start=${toApiDateTimeRequired(start)}&end=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForRisk(
  riskId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/risk/${riskId}?start=${toApiDateTimeRequired(start)}&end=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForRiskViewSchedule(
  riskId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/risk/${riskId}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForControlAudit(
  controlId: number,
  startDate: Date | undefined,
  endDate: Date | undefined,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(`/tasks/control/${controlId}/monitor`, {
      ...http.getRequestConfig(accessToken),
      params: {
        startDate: toApiDateTimeOptional(startDate),
        endDate: toApiDateTimeOptional(endDate),
      },
    });

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForThemeAudit(
  themeId: number,
  startDate: Date | undefined,
  endDate: Date | undefined,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(`/tasks/theme/${themeId}/monitor`, {
      ...http.getRequestConfig(accessToken),
      params: {
        startDate: toApiDateTimeOptional(startDate),
        endDate: toApiDateTimeOptional(endDate),
      },
    });

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForProcess(
  processId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/process/${processId}?start=${toApiDateTimeRequired(start)}&end=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForProcessViewSchedule(
  processId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/process/${processId}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(
        end,
      )}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForObjective(
  objectiveId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/objective/${objectiveId}?start=${toApiDateTimeRequired(start)}&end=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForObjectiveViewSchedule(
  objectiveId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/objective/${objectiveId}/schedule?startDate=${toApiDateTimeRequired(
        start,
      )}&endDate=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForAsset(
  assetId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/asset/${assetId}?start=${toApiDateTimeRequired(start)}&end=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForAssetViewSchedule(
  assetId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/asset/${assetId}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(
        end,
      )}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForOwner(
  ownerId: string,
  includeCompleted: boolean,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    let url = `/tasks/owner/${ownerId}?includeCompleted=${includeCompleted}`;
    if (start) url += `&startDate=${toApiDateTimeOptional(start)}`;
    if (end) url += `&endDate=${toApiDateTimeOptional(end)}`;

    const ar = await http.get<TasksDTO>(url, http.getRequestConfig(accessToken));

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForOwnerViewSchedule(
  ownerId: string,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/owner/${ownerId}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(
        end,
      )}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForUserViewSchedule(
  userId: string,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/user/${userId}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForControlViewSchedule(
  controlId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/control/${controlId}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(
        end,
      )}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForThemeViewSchedule(
  themeId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/theme/${themeId}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(
        end,
      )}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetMonitoringTasksViewSchedule(
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetMonitoringTasksForIdViewSchedule(
  id: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/${id}/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForUser(
  userId: string,
  includeCompleted: boolean,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    let url = `/tasks/user/${userId}?includeCompleted=${includeCompleted}`;
    if (start) url += `&startDate=${toApiDateTimeOptional(start)}`;
    if (end) url += `&endDate=${toApiDateTimeOptional(end)}`;

    const ar = await http.get<TasksDTO>(url, http.getRequestConfig(accessToken));

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForUsers(
  userIds: string[],
  includeCompleted: boolean,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const body: IdListDTO = new IdListDTO([...new Set(userIds)]); //remove duplicates

    let url = `/tasks/users?includeCompleted=${includeCompleted}`;
    if (start) url += `&startDate=${toApiDateTimeOptional(start)}`;
    if (end) url += `&endDate=${toApiDateTimeOptional(end)}`;

    const ar = await http.post<TasksDTO>(url, body, http.getRequestConfig(accessToken));

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForUsersViewSchedule(
  userIds: string[],
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const body: IdListDTO = new IdListDTO([...new Set(userIds)]); //remove duplicates
    const ar = await http.post<TasksDTO>(
      `/tasks/users/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(end)}`,
      body,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTaskForEvent(
  eventId: string,
  eventDate: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<OutlookTasks> {
  try {
    const apiEventDate: string = toApiDateTimeRequired(eventDate);
    const ar = await http.get<OutlookTasksDTO>(
      `/tasks/event/${eventId}/${apiEventDate}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromOutlookTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasks(
  includeCompleted: boolean,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    let url = `/tasks?includeCompleted=${includeCompleted}`;
    if (start) url += `&startDate=${toApiDateTimeOptional(start)}`;
    if (end) url += `&endDate=${toApiDateTimeOptional(end)}`;

    const ar = await http.get<TasksDTO>(url, http.getRequestConfig(accessToken));

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksViewSchedule(
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `/tasks/all/schedule?startDate=${toApiDateTimeRequired(start)}&endDate=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTasksForId(
  taskIds: number[],
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const idList = new IdListDTO(taskIds.map((i) => i.toString()));
    const ar = await http.post<TasksDTO>(`/tasks/id`, idList, http.getRequestConfig(accessToken));

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetSingleTask(
  taskId: number,
  preferSeries: boolean,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Tasks> {
  try {
    const ar = await http.get<TasksDTO>(
      `tasks/${taskId}?preferSeries=${preferSeries}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTaskKanbanState(task: Task, accessToken: string): Promise<Task> {
  try {
    const assignee: AssigneeDTO = new AssigneeDTO();

    await http.put<TaskDTO>(
      `/tasks/${task.taskId}/?taskState=${task.taskStateId}&sortOrder=${task.sortOrder}`,
      assignee,
      http.getRequestConfig(accessToken),
    );

    return task;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTaskKanbanAssignee(task: Task, accessToken: string): Promise<Task> {
  try {
    let assignee: AssigneeDTO | undefined;

    if (task.user) {
      assignee = new AssigneeDTO(task.user.id, task.user.name, task.user.email, 'user');
    } else {
      assignee = undefined;
    }

    await http.put<TaskDTO>(
      `/tasks/${task.taskId}/?sortOrder=${task.sortOrder}`,
      assignee,
      http.getRequestConfig(accessToken),
    );

    return task;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateTaskEvent(task: Task, accessToken: string): Promise<string> {
  try {
    const taskDTO = mapToTask(task);
    const ar = await http.post<string>(`/tasks/event/${task.taskId}`, taskDTO, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTaskEvent(task: Task, accessToken: string): Promise<void> {
  try {
    const taskDTO = mapToTask(task);
    await http.post<TaskDTO>(`/tasks/event/delete/${task.taskId}`, taskDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTasksEvent(tasks: Task[], accessToken: string): Promise<void> {
  try {
    const idList = new IdListDTO(tasks.map((t) => t.taskId.toString()));
    await http.post<TaskDTO>(`/tasks/event/delete/bulk`, idList, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTaskCompleted(task: Task, accessToken: string): Promise<void> {
  try {
    const taskDTO = mapToTask(task);
    await http.put<TaskDTO>(`/tasks/completed`, taskDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTaskChecklist(task: Task, accessToken: string): Promise<void> {
  try {
    const taskDTO = mapToTask(task);
    await http.put<TaskDTO>('/tasks/checklist', taskDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTaskTemplates(
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<TaskTemplateScene> {
  try {
    const ar = await http.get<TaskTemplateSceneDTO>(`/tasks/templates`, http.getRequestConfig(accessToken));

    return mapFromTaskTemplateScene(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetActiveTaskTemplates(
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Task[]> {
  try {
    const ar = await http.get<TaskDTO[]>(`/tasks/templates/active`, http.getRequestConfig(accessToken));

    return mapFromTasksArray(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTaskInstances(
  taskId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Task[]> {
  try {
    const ar = await http.get<TaskDTO[]>(`/tasks/${taskId}/instances`, http.getRequestConfig(accessToken));

    return mapFromTasksArray(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTaskInstancesByCount(
  taskId: number,
  count: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Task[]> {
  try {
    const ar = await http.get<TaskDTO[]>(
      `/tasks/${taskId}/instances/schedule?count=${count}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasksArray(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTaskInstancesByDate(
  taskId: number,
  start: Date,
  end: Date,
  count: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Task[]> {
  try {
    const ar = await http.get<TaskDTO[]>(
      `/tasks/${taskId}/instances/schedule?start=${toApiDateRequired(start)}&end=${toApiDateRequired(
        end,
      )}&count=${count}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTasksArray(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTaskRescheduleRange(taskId: number, accessToken: string): Promise<DateRange> {
  try {
    const ar = await http.get<DateRangeDTO>(`/tasks/${taskId}/reschedule`, http.getRequestConfig(accessToken));

    return mapFromDateRange(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiConvertTaskChecklistToForm(
  taskId: number,
  trans_checklist: string,
  trans_checklist_item: string,
  accessToken: string,
): Promise<TaskCheckListConversion | undefined> {
  try {
    const dto = new TaskCheckListConversionDTO();
    dto.taskId = taskId;
    dto.trans_FormSuffix = trans_checklist;
    dto.trans_KPISuffix = trans_checklist_item;

    const ar = await http.post<TaskCheckListConversionDTO>(
      `/tasks/checklist/convert`,
      dto,
      http.getRequestConfig(accessToken),
    );
    if (!ar || !ar.data) return undefined;

    return mapFromTaskCheckListConversion(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateTaskSchedule(
  auditYear: number,
  removeOnly: boolean,
  accessToken: string,
): Promise<void> {
  try {
    await http.post(
      `/tasks/createschedule?year=${auditYear}&removeOnly=${removeOnly}`,
      null,
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWebLinkForEvent(task: Task, accessToken: string): Promise<string> {
  try {
    const ar = await http.get(`tasks/event/weblink/${task.taskId}`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export const apiGetTaskRelations = async (
  taskId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<TaskTask[]> => {
  try {
    const ar = await http.get<TaskTaskDTO[]>(`/tasks/relations/${taskId}`, http.getRequestConfig(accessToken));

    return mapFromTaskTasks(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetCompletedMonitoringTasks = async (
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Tasks> => {
  try {
    const ar = await http.get<TasksDTO>(`/tasks/schedule/completed`, http.getRequestConfig(accessToken));

    return mapFromTasks(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetTaskTypes = async (accessToken: string): Promise<TaskType[]> => {
  try {
    const ar = await http.get<TaskTypeDTO[]>(`/tasks/types`, http.getRequestConfig(accessToken));

    return mapFromTaskTypes(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiAddTaskType = async (taskType: TaskType, accessToken: string): Promise<TaskType> => {
  try {
    const taskTypeDTO = mapToTaskType(taskType);
    const ar = await http.post<TaskTypeDTO>(`/tasks/types`, taskTypeDTO, http.getRequestConfig(accessToken));

    return mapFromTaskType(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiUpdateTaskType = async (taskType: TaskType, accessToken: string): Promise<TaskType> => {
  try {
    const taskTypeDTO = mapToTaskType(taskType);
    const ar = await http.put<TaskTypeDTO>(`/tasks/types`, taskTypeDTO, http.getRequestConfig(accessToken));

    return mapFromTaskType(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiRemoveTaskType = async (id: number, accessToken: string): Promise<void> => {
  try {
    await http.post<TaskTypeDTO>(`/tasks/types/delete/${id}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetTaskTypeForm = async (taskTypeId: number, accessToken: string): Promise<TaskTypeFormElement[]> => {
  try {
    const ar = await http.get<TaskTypeFormElementDTO[]>(
      `/tasks/types/${taskTypeId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTaskTypeForm(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetTaskTypeFormAndData = async (
  taskTypeId: number,
  entityType: number,
  entityId: number,
  accessToken: string,
): Promise<TaskTypeFormElement[]> => {
  try {
    const ar = await http.get<TaskTypeFormElementDTO[]>(
      `/tasks/types/${taskTypeId}/${entityType}/${entityId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTaskTypeForm(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetTasksForTypeApproval = async (
  taskType: TaskType,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Approval[]> => {
  try {
    const ar = await http.get<TaskDTO[]>(
      `/tasks/types/${taskType.taskTypeId}/approval`,
      http.getRequestConfig(accessToken),
    );

    return mapFromApprovals(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetTaskApprovalComments = async (
  taskId: number,
  accessToken: string,
): Promise<Record<number, string>> => {
  try {
    const ar = await http.get<Record<number, string>>(
      `/tasks/${taskId}/approval/comments`,
      http.getRequestConfig(accessToken),
    );

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export async function apiAddTaskRelation(
  taskIdFrom: number,
  taskIdTo: number,
  relationship: TaskRelationship,
  accessToken: string,
): Promise<void> {
  try {
    const body = new TaskTaskDTO();
    body.taskIdFrom = taskIdFrom;
    body.taskIdTo = taskIdTo;
    body.relationship = relationship;

    await http.post<TaskTaskDTO>(`/tasks/relation`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export const apiGetTaskContext = async (
  taskId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<TaskContext> => {
  try {
    const ar = await http.get<TaskContextDTO>(`/tasks/context/${taskId}`, http.getRequestConfig(accessToken));

    return mapFromTaskContext(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export async function apiGetTaskTypeName(name: string, accessToken: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/tasks/types/name`, { valueString: name }, http.getRequestConfig(accessToken));
    const id = Number(ar.data);

    return id;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
