import _ from 'lodash';

export function areDifferent<T>(
  array1: T[] | undefined,
  array2: T[] | undefined,
  comparator: (a1: T, a2: T) => boolean,
) {
  array1 = array1 ?? [];
  array2 = array2 ?? [];
  if (array1.length === 0 && array2.length === 0) {
    return false;
  }
  if (array1.length !== array2.length) {
    return true;
  }

  if (_.differenceWith<T, T>(array1, array2, comparator).length > 0) {
    return true;
  }
  if (_.differenceWith<T, T>(array2, array1, comparator).length > 0) {
    return true;
  }

  return false;
}

export function rotateArrayRight<T>(count: number, arr: T[]): T[] {
  // reverse helper function
  function reverse(arr: T[], start: number, end: number) {
    while (start < end) {
      [arr[start], arr[end]] = [arr[end], arr[start]];
      start++;
      end--;
    }
  }

  count %= arr.length;

  reverse(arr, 0, arr.length - 1);
  reverse(arr, 0, count - 1);
  reverse(arr, count, arr.length - 1);

  return arr;
}

export function rotateArrayLeft<T>(count: number, arr: T[]): T[] {
  // reverse helper function
  function reverse(arr: T[], start: number, end: number) {
    while (start < end) {
      [arr[start], arr[end]] = [arr[end], arr[start]];
      start++;
      end--;
    }
  }

  count %= arr.length;
  if (count === 0) return arr;

  reverse(arr, 0, arr.length - 1);
  reverse(arr, 0, arr.length - 1 - count);
  reverse(arr, arr.length - count, arr.length - 1);

  return arr;
}

export function numberArrayToString(ar: number[]): string {
  return ar.toString();
}

export function fromStringToNumberArray(ar: string): number[] {
  try {
    const split = ar.split(',');

    return split.map((item) => +item);
  } catch {
    return [];
  }
}

export function moveItem<T>(arr: (T | undefined)[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}


//
// Grouping
//
type ObjectKey = string | number | symbol;

export const groupByKey = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
  items: TItem[],
  key: K,
): Record<ObjectKey, TItem[]> => {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {} as Record<ObjectKey, TItem[]>,
  );
};

/**
 * Sums object value(s) in an array of objects, grouping by arbitrary object keys.
 *
 * @remarks
 * This method takes and returns an array of objects.
 * The resulting array of object contains a subset of the object keys in the
 * original array.
 *
 * @param arr - The array of objects to group by and sum.
 * @param groupByKeys - An array with the keys to group by.
 * @param sumKeys - An array with the keys to sum. The keys must refer
 *    to numeric values.
 * @returns An array of objects, grouped by groupByKeys and with the values
 *    of keys in sumKeys summed up. 
 *    A count property is added to indicate how many objects with each property set are found.
 */
interface IGroupByCount {
  count: number;
}

export const groupByKeys = <T, K extends keyof T, S extends keyof T>(
  arr: T[],
  groupByKeys: K[],
  sumKeys: S[],
): (Pick<T, K | S> & IGroupByCount)[] => {
  return [
    ...arr
      .reduce((accu, curr) => {
        const keyArr = groupByKeys.map((key) => curr[key]);
        const key = keyArr.join('-');
        const groupedSum =
          accu.get(key) ||
          Object.assign(
            {count: 0},
            Object.fromEntries(groupByKeys.map((key) => [key, curr[key]])),
            Object.fromEntries(sumKeys.map((key) => [key, 0])),
          );
        for (const key of sumKeys) {
          groupedSum[key] += curr[key];
        }
        groupedSum.count++;

        return accu.set(key, groupedSum);
      }, new Map())
      .values(),
  ];
};

