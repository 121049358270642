import {
  DetailsList,
  IColumn,
  SearchBox,
  SelectionMode,
  Stack,
  Text,
  Spinner,
  SpinnerSize,
  Link,
  IDetailsColumnProps,
  Label,
  ActionButton,
  Selection,
  IObjectWithKey,
  DetailsListLayoutMode,
} from '@fluentui/react';
import InfoMessage from '../Notification/InfoMessage';
import { globalStackTokensGapSmall, newIcon } from 'globalStyles';
import React, { useContext, useEffect, useState } from 'react';
import Entity, { EntityTypes } from 'models/entity';
import { useTranslation } from 'react-i18next';
import { apiGetControl } from 'services/Api/controlService';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetTheme } from 'services/Api/themeService';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import { apiGetRisk } from 'services/Api/riskService';
import { apiGetProcess } from 'services/Api/processService';
import { apiGetObjective } from 'services/Api/objectiveService';
import { apiGetSingleTask } from 'services/Api/taskService';
import { apiGetAsset } from 'services/Api/assetService';
import { apiGetKPI } from 'services/Api/kpiService';
import { sortOnEntity } from 'utils/sorting';
import AppError from 'utils/appError';
import { getEntityIcon, onRenderDetailsHeaderGlobal } from 'globalFunctions';

interface IEntityPickerProps {
  entities: Entity[];
  isLoading?: boolean;
  addSelectedEntity: (item: Entity) => void;
  addSelectedEntities?: (items: Entity[]) => void;
  loadData?: () => void;
  isBusyAdding?: number;
  suggestionLimit?: number;
  showHeader?: boolean;
  setSearchTextToSelectedEntity?: boolean;
  selectedEntity?: Entity;
  clearSearchText?: () => void;
  isOnPanel: boolean;
  placeholder?: string;
  allowMultiAdd?: boolean;
  extraColumns?: IColumn[];
}

const EntityPicker = (props: IEntityPickerProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);
  const [filteredControls, setFilteredControls] = useState<Entity[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isLoadingSelectedEntity, setIsLoadingSelectedEntity] = useState<boolean>(false);
  const [searchHasFocus, setSearchHasFocus] = useState<boolean>(false);
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);
  const [selection] = useState<Selection<Entity>>(
    new Selection({
      selectionMode: SelectionMode.multiple,
      getKey: (item: Entity) => {
        return item.id;
      },
      onSelectionChanged: () => {
        const selectedItems = selection.getSelection();
        setSelectedEntities(selectedItems);
      },
    }),
  );

  const selectEntity = (item: Entity) => {
    if (!item) return;
    props.addSelectedEntity(item);
    if (props.setSearchTextToSelectedEntity) {
      setFilterText(item.entityName);
      setFilteredControls([]);
    }
  };

  const columns: IColumn[] = [
    {
      key: 'controls',
      name: t('translation:General.Suggestions.Header'),
      minWidth: 100,
      isMultiline: true,
      isResizable: true,
      onRenderHeader: !props.allowMultiAdd
        ? undefined
        : (
            colProps?: IDetailsColumnProps | undefined,
            defaultRender?: ((props?: IDetailsColumnProps | undefined) => JSX.Element | null) | undefined,
          ): JSX.Element | null => {
            return (
              <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
                <Label>{colProps?.column.name}</Label>
                <ActionButton
                  iconProps={newIcon}
                  text={t('translation:General.Suggestions.AddSelection')}
                  onClick={() => {
                    if (props.addSelectedEntities) {
                      props.addSelectedEntities(selectedEntities);
                    }
                  }}
                />
              </Stack>
            );
          },
      onRender: (item: Entity, index: number | undefined) => {
        return (
          <Stack
            horizontal
            tokens={globalStackTokensGapSmall}
            verticalAlign="center"
            styles={props.isBusyAdding ? {} : { root: { cursor: 'pointer' } }}
          >
            {props.isBusyAdding === item.entityId && <Spinner />}
            {props.isBusyAdding !== item.entityId && <Stack.Item>{getEntityIcon(item)}</Stack.Item>}
            <Stack.Item>
              <Text>
                <Link onClick={() => selectEntity(item)}>{item.entityName}</Link>
              </Text>
            </Stack.Item>
          </Stack>
        );
      },
    },
    //Allow the caller to add extra columns to the picker
    ...(props.extraColumns ?? []),
  ];

  const onFilter = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string): void => {
    //lazy load data when a callback is specified
    if (!isLoaded && !props.isLoading && props.loadData && props.entities.length === 0) {
      setIsLoaded(true);
      props.loadData();
    }
    setFilterText(filterText ? filterText : '');
    if (!filterText && props.clearSearchText) {
      props.clearSearchText();
    }
  };

  useEffect(() => {
    const loadEntity = async (source: Entity | undefined) => {
      if (!source || isLoadingSelectedEntity) return;
      setIsLoadingSelectedEntity(true);

      try {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        switch (props.selectedEntity?.typeOfEntity) {
          case EntityTypes.Control:
            const control = await apiGetControl(source.entityId, false, false, accessToken, appContext.globalDataCache);
            if (control) setFilterText(control.code + ' ' + control.name);
            break;
          case EntityTypes.Requirement:
            const theme = await apiGetTheme(source.entityId, false, false, accessToken, appContext.globalDataCache);
            if (theme) setFilterText(theme.code + ' ' + theme.name);
            break;
          case EntityTypes.Process:
            const process = await apiGetProcess(source.entityId, accessToken, appContext.globalDataCache);
            if (process) setFilterText(process.code + ' ' + process.name);
            break;
          case EntityTypes.Task:
            const taskCol = await apiGetSingleTask(source.entityId, true, accessToken, appContext.globalDataCache);
            if (taskCol && taskCol.tasks.length > 0) setFilterText(taskCol.tasks[0].name);
            break;
          case EntityTypes.Objective:
            const objective = await apiGetObjective(source.entityId, accessToken, appContext.globalDataCache);
            if (objective) setFilterText(objective.code + ' ' + objective.name);
            break;
          case EntityTypes.Asset:
            const asset = await apiGetAsset(source.entityId, accessToken, appContext.globalDataCache);
            if (asset) setFilterText(asset.code + ' ' + asset.name);
            break;
          case EntityTypes.Risk:
            const riskCol = await apiGetRisk(source.entityId, accessToken, appContext.globalDataCache);
            if (riskCol && riskCol.risks && riskCol.risks.length > 0) {
              const risk = riskCol.risks[0];
              setFilterText(risk.code + ' ' + risk.name);
            }
            break;
          case EntityTypes.KPI:
            const kpi = await apiGetKPI(source.entityId, accessToken, undefined);
            if (kpi) {
              setFilterText(kpi.name);
            }
            break;
          default:
            throw new AppError(`Entitytype not implemented: ${props.selectedEntity?.typeOfEntity}`);
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoadingSelectedEntity(false);
      }
    };

    if (props.selectedEntity) {
      let entity: Entity | undefined = undefined;
      if (isLoaded) {
        entity = props.entities.find((e) => e.entityId === props.selectedEntity?.entityId);
        setFilterText(entity?.entityName || '');
      } else {
        if (!isLoaded && !props.isLoading && props.loadData && props.entities.length === 0) {
          setIsLoaded(true);
          props.loadData();
        }
        loadEntity(props.selectedEntity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedEntity]);

  useEffect(() => {
    if (!filterText) {
      setFilteredControls([]);

      return;
    }
    if (props.selectedEntity && props.setSearchTextToSelectedEntity === true) {
      //hide search results when an entity is selected
      return;
    } else {
      const filterLower = filterText.toLowerCase();
      setFilteredControls(
        props.entities
          .filter((entity) => {
            return entity.entityName.toLowerCase().includes(filterLower);
          })
          .sort((a, b) => {
            return sortOnEntity(a, b);
          })
          .slice(0, props.suggestionLimit ?? (props.allowMultiAdd ? 1000 : 50)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, props.entities]);

  const getHeight = (): number => {
    let height = 37;
    if (filteredControls.length > 0 || searchHasFocus) {
      height += 213;
    }
    if (isMessageBarVisible()) {
      height += 32;
    }

    return height;
  };

  const isMessageBarVisible = (): boolean => {
    return (
      filterText.length > 0 &&
      filteredControls.length === 0 &&
     // !searchHasFocus &&
      !props.isLoading &&
      !(props.selectedEntity && props.setSearchTextToSelectedEntity === true)
    );
  };

  //
  // Main render
  //
  return (
    <Stack
      styles={{
        root: {
          overflow: 'hidden',
          height: getHeight(),
          transition: 'height 0.3s ease',
        },
      }}
    >
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <Stack.Item grow>
            <SearchBox
              value={filterText}
              underlined={false}
              placeholder={props.placeholder ?? t('translation:General.Search.Placeholder')}
              onChange={onFilter}
              onFocus={() => setSearchHasFocus(true)}
              onBlur={() => setSearchHasFocus(false)}
            />
          </Stack.Item>
          {props.isLoading && (
            <Stack.Item>
              <Spinner size={SpinnerSize.large} />
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
      {isMessageBarVisible() && (
        <Stack.Item styles={{ root: { paddingTop: 5 } }}>
          <InfoMessage message={t('translation:General.Search.NoData')} onDismiss={undefined}></InfoMessage>
        </Stack.Item>
      )}
      {(filteredControls.length > 0 || searchHasFocus) && (
        <ScrollableStackItem isOnPanel={props.isOnPanel} height={20} maxHeight={40} minHeight={215}>
          {filteredControls.length > 0 && (
            <DetailsList
              items={filteredControls}
              columns={columns}
              compact={true}
              selection={selection as Selection<IObjectWithKey>}
              selectionMode={props.allowMultiAdd ? SelectionMode.multiple : SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={false}
              isHeaderVisible={!appContext.isMobileView && props.showHeader !== false && filterText.length > 0}
              styles={{ root: { marginTop: 0, transition: 'all 0.3s ease' } }}
              onRenderDetailsHeader={props.allowMultiAdd ? onRenderDetailsHeaderGlobal : undefined}
            />
          )}
        </ScrollableStackItem>
      )}
    </Stack>
  );
};

export default EntityPicker;
