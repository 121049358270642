import React, { useContext, useState } from 'react';
import { PrimaryButton, Stack, TextField, Persona, PersonaSize, Text } from '@fluentui/react';
import { useTranslation, withTranslation , WithTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';

import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import User from 'models/user';
import UserMentionPickerCallOut from 'components/CallOuts/UserMentionPickerCallOut';

interface IAddCommentProps extends WithTranslation {
  onSend: (newComment: string, mentions: User[]) => void;
  isActionPending: boolean;
}

//
// In this component, features to mentions have been disabled  
//
const AddComment = (props: IAddCommentProps) => {
  const { t } = useTranslation(['Activity']);
  const appContext = useContext(AppContext);

  const [value, setValue] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [checkInputHasFocus, setCheckInputHasFocus] = useState<boolean>(false);
  const [isMentionCalloutVisible, setIsMentionCalloutVisible] = useState<boolean>(false);
  const [controlKeyPressed, setControlKeyPressed] = useState<boolean>(false);

  const selectUser = (user: User | undefined) => {
    if (user) {
      const mention = getMentionUnderCaret(value, 0);
      if (mention) {
        const newValue = value.replace(mention, '@' + user.name.replaceAll(' ', '_'));
        setValue(newValue);
        setIsMentionCalloutVisible(false);
        setSelectedUser(undefined);
      }
    }
  };

  const preSelectUser = (user: User | undefined) => {
    if (user && selectedUser?.id !== user.id) {
      setSelectedUser(user);
    }
  };

  const closeUserMentionCallout = () => {
    setIsMentionCalloutVisible(false);
  };

  const getMentionsFromText = (text: string): User[] => {
    //https://stackoverflow.com/questions/15265605/how-to-pull-mentions-out-of-strings-like-twitter-in-javascript
    const pattern = /\B@[a-z0-9_-]+/gi;
    const matches = text.match(pattern);
    const users: User[] = [];

    if (matches) {
      for (let i = 0; i < matches?.length; i++) {
        const match = matches[i];
        const userMatch = match.toString().toLowerCase();
        const user = appContext.globalDataCache.users.items.find(
          (u: User) => '@' + u.name.toLowerCase().replaceAll(' ', '_') === userMatch,
        );
        if (user) {
          users.push(user);
        }
      }
    }

    return users;
  };

  const getMentionUnderCaret = (text: string, pos: number): string | undefined => {
    //https://stackoverflow.com/questions/15265605/how-to-pull-mentions-out-of-strings-like-twitter-in-javascript
    const pattern = /\B@[a-z0-9_-]*/gi;
    const matches = text.match(pattern);

    if (matches && matches.length > 0) {
      const match = matches[matches.length - 1];

      return match.toString();
    }

    return undefined;
  };

  const setNewValue = (newValue: string | undefined) => {
    if (!newValue) {
      setIsMentionCalloutVisible(false);
    } else {
      // const mention = getMentionUnderCaret(newValue, 0);
      // if (mention) {
      //   setIsMentionCalloutVisible(true);
      // }
    }

    setValue(newValue ? newValue : '');
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    // if (e.key === 'Tab' || e.key === 'Enter') {
    //   selectUser(selectedUser);
    //   e.preventDefault();
    // }
    if (e.key === 'Meta' || e.key === 'Control') {
      setControlKeyPressed(true);
    }
    if (controlKeyPressed && e.key === 'Enter' && value.length > 0) {
      props.onSend(value, getMentionsFromText(value));
      setValue('');
    }
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (controlKeyPressed) setControlKeyPressed(false);
  };

  return (
    <Stack horizontal tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <Persona text={appContext.user.name} hidePersonaDetails={true} size={PersonaSize.size24} />
      </Stack.Item>
      <Stack.Item grow>
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack.Item className="calloutTargetUserMention">
            <TextField
              value={value}
              placeholder={props.t('commentTrail:Add-placeholder')}
              multiline
              resizable={true}
              rows={3}
              onFocus={() => setCheckInputHasFocus(true)}
              onBlur={() => setCheckInputHasFocus(false)}
              onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                setNewValue(newValue);
              }}
              onKeyDown={onKeyPress}
              onKeyUp={onKeyUp}
            />
          </Stack.Item>
          {isMentionCalloutVisible && (
            <UserMentionPickerCallOut
              isVisible={isMentionCalloutVisible}
              onChange={selectUser}
              onClose={closeUserMentionCallout}
              onSelect={preSelectUser}
              selectedUser={selectedUser}
              searchText={getMentionUnderCaret(value, 0)}
              targetId={'calloutTargetUserMention'}
            />
          )}
          <Stack.Item>
            <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
              <PrimaryButton
                disabled={props.isActionPending || value === ''}
                onClick={() => {
                  props.onSend(value, getMentionsFromText(value));
                  setValue('');
                }}
              >
                {props.t('commentTrail:Send')}
              </PrimaryButton>
              {checkInputHasFocus && <Text variant="small">{t('activity:KeyTipNew')}</Text>}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default withTranslation(['translation', 'commentTrail', 'activity'])(AddComment);
