import {
  Text,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  Stack,
  DefaultButton,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import GenericModal from 'components/Dialogs/GenericModal';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { globalLog } from 'services/Logging/logService';
import AppContext from 'App/AppContext';
import { useContext } from 'react';

interface IGlobalLogModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const GlobalLogModal = (props: IGlobalLogModalProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);

  const columns: IColumn[] = [
    {
      key: 'msg',
      name: t('translation:GlobalLog.Columns.Message'),
      minWidth: 100,
      isMultiline: true,
      onRender: (item?: string, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text variant="small">{item}</Text>;
      },
    },
  ];

  const copy = () => {
    try 
    {
      const msg = globalLog.messages.join('\r\n');
      navigator.clipboard.writeText(msg.toString());
    }
    catch {
      appContext.showNotification(t('translation:GlobalLog.ErrorCopy'), true)
    }
  };

  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={t('translation:GlobalLog.Title')}
      subTitle={t('translation:GlobalLog.SubText')}
      height={'80vh'}
      width={1000}
      maxWidth={'80vw'}
      optionalButtons={<DefaultButton text={t('translation:General.Button.Copy')} onClick={() => copy()} />}
    >
      <Stack verticalFill>
        <ScrollableStackItem isOnPanel={false}>
          <DetailsList
            compact
            items={globalLog.messages}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
          />
        </ScrollableStackItem>
      </Stack>
    </GenericModal>
  );
};

export default GlobalLogModal;
