import { ReactNode, useContext } from 'react';
import { ScrollablePane, ScrollbarVisibility, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalNavBarHeight } from 'globalConstants';

export interface IScrollableStackItem {
  isOnPanel?: boolean;
  height?: number; //percentage of available scene height
  minHeight?: number; //min height in px
  maxHeight?: number; //percentage of available scene height
  extraFixedMargin?: number;
  disableScroll?: boolean;
}

interface IScrollableStackItemProps extends IScrollableStackItem {
  children: ReactNode;
}

const ScrollableStackItem = (props: IScrollableStackItemProps) => {
  const appContext = useContext(AppContext);

  const setScrollableHeight = (windowHeight: number, requiredHeight: number, extraFixedMargin?: number) => {
    let fixedMargin = globalNavBarHeight; //fixed margin for top nav bar
    fixedMargin += 52; //fixed margin for panel title
    fixedMargin += 100; //fixed margin for panel footer
    fixedMargin += extraFixedMargin || 0; //context specific margin

    const height = (windowHeight - fixedMargin) * (requiredHeight / 100);

    return `${height}px`;
  };

  if (props.disableScroll === true) {
    return (
      <Stack.Item
        grow
        styles={{
          root: {
            height: props.height,
            minHeight: props.minHeight,
            maxHeight: props.maxHeight,
            overflowY: 'hidden',
            overflowX: 'hidden',
          },
        }}
      >
        {props.children}
      </Stack.Item>
    );
  } else if (props.isOnPanel === true) {
    const height = setScrollableHeight(appContext.windowHeight, props.height || 20, props.extraFixedMargin);
    const maxHeight = props.maxHeight
      ? setScrollableHeight(appContext.windowHeight, props.maxHeight, props.extraFixedMargin)
      : undefined;

    return (
      <Stack.Item
        grow
        styles={{
          root: {
            height: height,
            minHeight: props.minHeight,
            maxHeight: maxHeight,
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
        }}
      >
        {props.children}
      </Stack.Item>
    );
  } else {
    return (
      <Stack.Item grow styles={{ root: { minHeight: props.minHeight, position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{props.children}</ScrollablePane>
      </Stack.Item>
    );
  }
};

export default ScrollableStackItem;
