import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  PrimaryButton,
  Text,
  DefaultButton,
  DialogFooter,
  Modal,
  Stack,
  IconButton,
  DetailsList,
  DetailsListLayoutMode,
  ScrollablePane,
  SelectionMode,
  IColumn,
  ScrollbarVisibility,
  Spinner,
  SpinnerSize,
  FontIcon,
  CommandBar,
  ICommandBarItemProps,
  IGroup,
  SearchBox,
  IDetailsGroupRenderProps,
  Separator,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  globalStackTokensGapMedium,
  cancelIcon,
  globalStackTokensGapSmall,
  validIcon,
  errorIcon,
  getGlobalStackItemStylesPaddingSceneScrollMinHeight,
} from 'globalStyles';
import Package, { PackageCategories, PackageImport, PackageImportStates } from 'models/package';
import AppContext from 'App/AppContext';
import AppError from 'utils/appError';
import WarningMessage from 'components/Notification/WarningMessage';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetPackageImports } from 'services/Api/packageService';
import { getEntityName, onRenderDetailsHeaderGlobal } from 'globalFunctions';
import Entity, { EntityType, EntityTypes } from 'models/entity';
import { globalFilterDelay } from 'globalConstants';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { truncate } from 'utils/string';

export enum PackageInstallResultModes {
  None = 0,
  AfterInstall = 1,
  ReInstall = 2,
}

interface IPackageInstallResultModalProps {
  mode: PackageInstallResultModes;
  pack?: Package;
  importedPack?: Package;
  installErr?: AppError | undefined;
  isOpen: boolean;
  onDismiss: () => void;
  onReInstall?: () => void;
  onRemove?: () => void;
}

const PackageInstallResultModal = (props: IPackageInstallResultModalProps) => {
  const { t } = useTranslation(['store', 'translation', 'lists']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imports, setImports] = useState<PackageImport[]>([]);
  const [importsDisplay, setImportsDisplay] = useState<PackageImport[]>([]);
  const [defaultSortKey, setDefaultSortKey] = useState<string>('type');
  const [groups, setGroups] = useState<IGroup[] | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);
  const [entityTypesIdx, setEntityTypesIdx] = useState<Record<number, EntityType>>({});
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (props.isOpen) {
      loadData();
    } else {
      //cleanup
      if (searchTimer) {
        clearTimeout(searchTimer);
        setSearchTimer(undefined);
      }
      setSearchText(undefined);
      setImports([]);
      setImportsDisplay([]);
      setGroups(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const loadData = async () => {
    if (isLoading || !props.pack) return;

    try {
      let imports: PackageImport[] = [];

      if (props.importedPack) {
        imports = props.importedPack.imports;
      } else {
        setIsLoading(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        imports = await apiGetPackageImports(props.pack, accessToken);
      }

      //create the entity types and index them
      const entityTypes = getEntityTypes();
      entityTypes.sort((a: EntityType, b: EntityType) => {
        return a.name.localeCompare(b.name);
      });
      const entityTypesIndex: Record<number, EntityType> = {};
      entityTypes.forEach((element) => {
        entityTypesIndex[element.id] = element;
      });
      setEntityTypesIdx(entityTypesIndex);
      setEntityTypes(entityTypes);

      //this filters out any imports without human readable name like entity relationships
      imports = imports.filter((r) => entityTypesIndex[r.sourceEntityType] !== undefined);

      //set any links and lists to Skipped where the Id's are not set: this indicated that the content was not uploaded (manually skipped or aborted)
      imports.forEach((i) => {
        if (i.sourceEntityType === EntityTypes.Link) {
          const link = props.pack?.sharePointLinks.find((l) => l.linkId.toString() === i.sourceEntityId);
          if (link && !link.listItemId && !link.driveItemId && !link.pageId) {
            i.state = PackageImportStates.Skipped;
          }
        } else if (i.sourceEntityType === EntityTypes.List) {
          const list = props.pack?.sharePointLists.find((l) => l.listId.toString() === i.sourceEntityId);
          if (list && !list.spDriveId && !list.spSiteId && !list.spListId) {
            i.state = PackageImportStates.Skipped;
          }
        }
      });

      //sort the imports
      imports.sort((a: PackageImport, b: PackageImport) => {
        let result: number = 0;
        if (a.sourceEntityType !== b.sourceEntityType) {
          const ea = entityTypesIndex[a.sourceEntityType];
          const eb = entityTypesIndex[b.sourceEntityType];

          result = ea.name.localeCompare(eb.name);
        } else {
          if (a.name && b.name) {
            result = a.name.localeCompare(b.name);
          }
        }

        return result;
      });

      setImports(imports);
      setImportsDisplay(imports);
      calcGroups(imports, defaultSortKey, undefined, entityTypes);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getEntityTypes = (): EntityType[] => {
    let entityTypeIdx: EntityType[] = [];
    const values = Object.values(EntityTypes).filter((v) => !isNaN(v as EntityTypes));

    values.forEach((v, index) => {
      const name = getEntityName(new Entity(index, v as EntityTypes), t as TFunction<string[]>);
      if (name) {
        entityTypeIdx.push(new EntityType(v as EntityTypes, name));
      }
    });

    //filter types not to show
    const typesNotToShow = [EntityTypes.Widget];
    entityTypeIdx = entityTypeIdx.filter((e) => !typesNotToShow.includes(e.id));

    return entityTypeIdx;
  };

  const getInfo = (): string => {
    if (!props.pack || props.pack.tenants.length === 0) return '';
    const packageTenant = props.pack.tenants[0];

    if (props.mode === PackageInstallResultModes.ReInstall) {
      return t('store:TabStore.Install.Dialogs.InstallResult.InfoReInstall', {
        activationDate: toLocaleDateTimeMedium(packageTenant.activateDate),
      });
    } else {
      if (props.installErr) {
        return t('store:TabStore.Install.Dialogs.InstallResult.InfoAfterInstallError');
      } else {
        return t('store:TabStore.Install.Dialogs.InstallResult.InfoAfterInstallSuccess');
      }
    }
  };

  const getWarning = (): string => {
    if (props.mode === PackageInstallResultModes.ReInstall) {
      if (props.installErr) {
        return (
          props.installErr.message +
          ' ' +
          (props.installErr.debug ? truncate(JSON.stringify(props.installErr.debug), 1000) : '')
        );
      } else {
        return t('store:TabStore.Install.Dialogs.InstallResult.WarningReInstall');
      }
    } else {
      if (props.installErr) {
        return (
          props.installErr.message +
          ' ' +
          (props.installErr.debug ? truncate(JSON.stringify(props.installErr.debug), 1000) : '')
        );
      } else if (hasNotActivatedContent() && props.importedPack?.categoryId !== PackageCategories.AddOn) {
        return t('store:TabStore.Install.Dialogs.InstallResult.InfoAfterInstallSuccesWarning');
      } else {
        return '';
      }
    }
  };

  const onChangeText = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string): void => {
    if (!filterText) {
      setImportsDisplay(imports);
      setSearchText(undefined);
      calcGroups(imports, defaultSortKey, undefined, entityTypes);
    } else {
      const lcFilterText = filterText.toLowerCase();
      const filteredImports = imports.filter((item) => {
        if (!item.name) return false;

        return item.name.toLowerCase().indexOf(lcFilterText) >= 0;
      });
      setImportsDisplay(filteredImports);
      setSearchText(filterText);
      calcGroups(filteredImports, defaultSortKey, filterText, entityTypes);
    }
  };

  const hasNotActivatedContent = (): boolean => {
    return imports.some((i) => i.state === PackageImportStates.Skipped);
  };

  const getImportTypeName = (item: PackageImport): string => {
    const entity = new Entity(Number.parseInt(item.sourceEntityId), item.sourceEntityType, item.name);

    return getEntityName(entity, t as TFunction<string[]>);
  };

  const getImportStateName = (item: PackageImport): string => {
    switch (item.state) {
      case PackageImportStates.Created:
        return t('store:TabStore.Install.Dialogs.InstallResult.States.Created');
      case PackageImportStates.Skipped:
        return t('store:TabStore.Install.Dialogs.InstallResult.States.Skipped');
      case PackageImportStates.Updated:
        return t('store:TabStore.Install.Dialogs.InstallResult.States.Updated');
      default:
        return 'Unknown state';
    }
  };

  const getColumns = (): IColumn[] => {
    return [
      {
        key: 'type',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        name: t('store:TabStore.Install.Dialogs.InstallResult.Columns.Type'),
        onRender: (item?: PackageImport, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{getImportTypeName(item)}</Text>;
        },
      },
      {
        key: 'name',
        minWidth: 100,
        maxWidth: 800,
        isResizable: true,
        isMultiline: true,
        isSorted: false,
        isSortedDescending: false,
        name: t('store:TabStore.Install.Dialogs.InstallResult.Columns.Name'),
        onRender: (item?: PackageImport, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{`${item.name} (${item.targetEntityId})`}</Text>;
        },
      },
      {
        key: 'state',
        minWidth: 100,
        maxWidth: 150,
        isSorted: false,
        isSortedDescending: false,
        name: t('store:TabStore.Install.Dialogs.InstallResult.Columns.State'),
        onRender: (item?: PackageImport, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{getImportStateName(item)}</Text>;
        },
      },
    ];
  };

  const [columns, setColumns] = useState<IColumn[]>(getColumns());

  const getCommandBarItems = (): ICommandBarItemProps[] => {
    const items: ICommandBarItemProps[] = [];

    items.push({
      key: 'switchView',
      text: t('store:TabStore.Install.Dialogs.InstallResult.CommandBar.View'),
      iconProps: { iconName: 'View' },
      subMenuProps: {
        items: [
          {
            key: 'groupby',
            text: t('store:TabStore.Install.Dialogs.InstallResult.CommandBar.GroupBy'),
            iconProps: { iconName: 'GroupList' },
            checked: defaultSortKey === 'type',
            canCheck: true,
            onClick: (ev, item) => {
              const newSortKey = item?.checked === false ? 'type' : 'name';
              setDefaultSortKey(newSortKey);
              sortByColumn(newSortKey, false, false);
              calcGroups(importsDisplay, newSortKey, searchText, entityTypes);
            },
          },
        ],
      },
    });

    return items;
  };

  const sortByColumn = (columnKey: string, keepDirection: boolean, setDescending?: boolean) => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn = newColumns.find((currCol) => columnKey === currCol.key);
    if (!currColumn) return;

    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === currColumn.key) {
        if (!keepDirection) {
          if (setDescending !== undefined) {
            currColumn.isSortedDescending = setDescending;
          } else {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
          }
        }
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    const imports = copyAndSort(importsDisplay, columnKey, currColumn.isSortedDescending);
    setImports(imports);
    setDefaultSortKey(columnKey);
    setColumns(newColumns);
    calcGroups(imports, columnKey, searchText, entityTypes);
  };

  const copyAndSort = (imports: PackageImport[], columnKey: string, isSortedDescending?: boolean): PackageImport[] => {
    if (columnKey === 'type') {
      //sort the groups
      entityTypes.sort((a: EntityType, b: EntityType) => {
        return a.name.localeCompare(b.name) * (isSortedDescending ? -1 : 1);
      });
      setEntityTypes([...entityTypes]);

      //sort the imports
      return imports.sort((a: PackageImport, b: PackageImport) => {
        let result: number = 0;
        if (a.sourceEntityType !== b.sourceEntityType) {
          const ea = entityTypesIdx[a.sourceEntityType];
          const eb = entityTypesIdx[b.sourceEntityType];

          result = ea.name.localeCompare(eb.name);
        } else {
          if (a.name && b.name) {
            result = a.name.localeCompare(b.name);
          }
        }

        return result * (isSortedDescending ? -1 : 1);
      });
    }

    if (columnKey === 'name') {
      return imports.sort((a: PackageImport, b: PackageImport) => {
        if (!a.name || !b.name) return 0;

        return a.name?.localeCompare(b.name) * (isSortedDescending ? -1 : 1);
      });
    }

    if (columnKey === 'state') {
      return imports.sort((a: PackageImport, b: PackageImport) => {
        return (a.state - b.state) * (isSortedDescending ? -1 : 1);
      });
    }

    return imports.slice();
  };

  const calcGroups = (
    imports: PackageImport[],
    defaultSortKey: string,
    searchText: string | undefined,
    entityTypes: EntityType[],
  ) => {
    setGroups(getGroups(imports, defaultSortKey, searchText, entityTypes));
  };

  const getGroups = (
    imports: PackageImport[],
    defaultSortKey: string,
    searchText: string | undefined,
    entityTypes: EntityType[],
  ): IGroup[] | undefined => {
    let start: number = 0;

    if (defaultSortKey !== 'type') return undefined;

    const groups = entityTypes.map((entityType) => {
      const count = imports.filter((l) => l.sourceEntityType === entityType.id).length;
      const group: IGroup = {
        key: entityType.id.toString(),
        name: entityType.name,
        startIndex: start,
        count: count,
        level: 0,
        isCollapsed: searchText && count > 0 ? false : true,
      };
      start += count;

      return group;
    });

    return groups.filter((g) => g.count > 0);
  };

  const onColumnClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
    if (!column) return;
    sortByColumn(column.key, false);
  };

  const getGroupProps = (): IDetailsGroupRenderProps | undefined => {
    return {
      showEmptyGroups: false,
      isAllGroupsCollapsed: true,
    };
  };

  const getDetails = (): JSX.Element => {
    return (
      <Fragment>
        <Stack.Item>
          <Stack horizontal wrap tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <SearchBox
                placeholder={t('translation:General.Filter.Placeholder')}
                underlined
                id="search"
                onChange={(ev, newValue) => {
                  if (searchTimer) {
                    clearTimeout(searchTimer);
                  }
                  setSearchTimer(setTimeout(() => onChangeText(ev, newValue), globalFilterDelay));
                }}
                styles={{ root: { width: 300 } }}
              />
            </Stack.Item>
            <Stack.Item grow>
              <CommandBar styles={{ root: { padding: 0 } }} items={[]} farItems={getCommandBarItems()} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item grow styles={getGlobalStackItemStylesPaddingSceneScrollMinHeight(200)}>
          {isLoading && (
            <Stack verticalFill horizontalAlign="center" verticalAlign="center">
              <Spinner size={SpinnerSize.large} />
            </Stack>
          )}
          {!isLoading && (
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                compact
                items={importsDisplay}
                columns={columns}
                groups={groups}
                selectionMode={SelectionMode.none}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
                groupProps={getGroupProps()}
                onColumnHeaderClick={onColumnClick}
              />
            </ScrollablePane>
          )}
        </Stack.Item>
      </Fragment>
    );
  };

  //
  // Main render
  //
  const showDetails: boolean = props.installErr !== undefined || getWarning() !== '';

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Stack
        verticalFill
        styles={{
          root: { height: showDetails ? '90vh' : 300, width: 1000, minWidth: 320, maxWidth: '90vw', padding: 20 },
        }}
        tokens={globalStackTokensGapMedium}
      >
        <Stack horizontal horizontalAlign={'space-between'}>
          <Text variant="xxLarge">{props.pack?.name}</Text>
          <IconButton iconProps={cancelIcon} onClick={props.onDismiss} />
        </Stack>
        <Stack.Item grow={showDetails ? undefined : true}>
          <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
            {!props.installErr && <FontIcon {...validIcon} style={{ color: 'green', fontSize: 36 }} />}
            {props.installErr && <FontIcon {...errorIcon} style={{ color: 'red', fontSize: 36 }} />}
            <Text>{getInfo()}</Text>
          </Stack>
        </Stack.Item>
        {getWarning() && (
          <Stack.Item>
            <WarningMessage message={getWarning()} />
          </Stack.Item>
        )}
        {showDetails && getDetails()}
        <Separator />
        <DialogFooter>
          {props.mode === PackageInstallResultModes.ReInstall && (
            <PrimaryButton
              onClick={() => {
                if (props.onReInstall) props.onReInstall();
              }}
              text={t('store:TabStore.Install.Dialogs.InstallResult.ReActivateButton')}
            />
          )}
          {props.mode === PackageInstallResultModes.ReInstall && props.pack?.isPreview && (
            <PrimaryButton
              onClick={() => {
                if (props.onRemove) props.onRemove();
              }}
              text={t('store:TabStore.Install.Dialogs.InstallResult.RemoveButton')}
            />
          )}
          <DefaultButton
            disabled={false}
            onClick={() => {
              props.onDismiss();
            }}
            text={t('translation:General.Button.Close')}
          ></DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default PackageInstallResultModal;
