export enum ApiErrorLevel {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export class ApiErrorInfo {
  level: ApiErrorLevel;

  code: string | undefined;

  message: string;

  details: string | undefined;

  constructor() {
    this.level = ApiErrorLevel.Error;
    this.message = '';
  }
}
