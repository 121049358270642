import Theme_TranslationDTO from './theme_TranslationDTO';
import IdListDTO from './IdListDTO';
import TaskDTO from 'models/dto/tasks/taskDTO';
import { EntityStatus } from 'models/entityStatus';
import EntityDTO from './entityDTO';
import ISOControlDTO from './isoControlDTO';
import { newGuidNil } from 'utils/guid';

export default class ThemeDTO {
  tenantId: string;

  themeId: number;

  code: string;

  parentThemeId?: number;

  recurringPattern?: string;

  state: EntityStatus;

  groupId?: string;

  ownerId?: string;

  ownerRoleId?: string;

  commentTrailId: number;

  auditTrailId: number;

  normIds?: IdListDTO;

  isoControlIds?: IdListDTO;

  inheritTenantId?: string;

  inheritThemeId?: number;

  trans?: Theme_TranslationDTO[];

  tasks?: TaskDTO[];

  monitoringParent?: EntityDTO;

  tagIds?: IdListDTO;

  isoControls?: ISOControlDTO[];

  constructor() {
    this.tenantId = newGuidNil();
    this.themeId = 0;
    this.code = '';
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.state = EntityStatus.Todo;
  }
}

export class ControlTaskStatsDTO {
  themeId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.themeId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}

export class ThemeTaskStatsDTO {
  themeId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.themeId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}
