import React, { FunctionComponent, useContext, useState } from 'react';
import { Stack, Spinner, SpinnerSize, Text, Label, ComboBox, IComboBoxOption, Checkbox } from '@fluentui/react';

import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import CustomNormCoverageChart from 'components/Charts/CustomNormCoverageChart';
import { apiGetWidgetDataCustomNormCoverage } from 'services/Api/dashboardService';
import { NormCoverage } from 'models/dashboardScene';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import Norm from 'models/norm';
import Tag from 'models/tag';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import { IWidgetRendererProps } from '../WidgetRenderer';

export class WidgetCustomNormCoverageConfig {
  customNormId: number;

  tagIds: number[];

  showTags: boolean;

  tags: Tag[];

  constructor() {
    this.customNormId = -1;
    this.tagIds = [];
    this.showTags = false;
    this.tags = [];
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.customNormId = newRawConfig.customNormId ?? -1;
        this.tagIds = newRawConfig.tagIds ?? [];
        this.showTags = newRawConfig.showTags ?? false;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetCustomNormCoverageConfig {
    const newConfig = new WidgetCustomNormCoverageConfig();
    newConfig.customNormId = this.customNormId;
    newConfig.tagIds = [...this.tagIds];
    newConfig.tags = [...this.tags];
    newConfig.showTags = this.showTags;

    return newConfig;
  }
}

interface IWidgetCustomNormCoverageProps extends IWidgetRendererProps {}

const WidgetCustomNormCoverage: FunctionComponent<IWidgetCustomNormCoverageProps> = (
  props: IWidgetCustomNormCoverageProps,
) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const appContext = React.useContext(AppContext);
  const [data, setData] = React.useState<NormCoverage | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<WidgetCustomNormCoverageConfig | undefined>(undefined);

  React.useEffect(() => {
    const loadConfig = (): WidgetCustomNormCoverageConfig => {
      const newConfig = new WidgetCustomNormCoverageConfig();
      newConfig.load(props.widget.widgetConfig);
      newConfig.tags = appContext.globalDataCache.tags.getItemsForId(newConfig.tagIds).filter((t) => t.tagId !== 0);

      return newConfig;
    };

    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        await appContext.globalDataCache.tags.getItems();
        const config = loadConfig();
        setConfig(config);

        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const data = await apiGetWidgetDataCustomNormCoverage(config.customNormId, config.tags, accessToken);
        setData(data);
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  if (!data || isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!data.normId) {
    //config is invalid
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:CustomNormCoverage.Config.Invalid')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill horizontalAlign="center" tokens={globalStackTokensGapSmall}>
      {config && config.tags.length > 0 && config.showTags && (
        <Stack.Item styles={{ root: { maxHeight: 44, overflowY: 'hidden' } }}>
          <KeyValueTags tags={config.tags} compact tagMaxWidth={200} />
        </Stack.Item>
      )}
      <Stack.Item>
        <Text>{data.normName}</Text>
      </Stack.Item>
      <Stack.Item grow>
        <CustomNormCoverageChart
          CustomNormCoverageData={data}
          tags={config?.tags}
          height={config && config.tags.length > 0 && config.showTags ? 160 : 220}
          width={280}
        ></CustomNormCoverageChart>
      </Stack.Item>
    </Stack>
  );
};

export default WidgetCustomNormCoverage;

//
// Config
//

interface IWidgetConfigCustomNormCoverageChartProps extends IWidgetConfigRendererProps {}

export const WidgetConfigCustomNormCoverage: FunctionComponent<IWidgetConfigCustomNormCoverageChartProps> = (
  props: IWidgetConfigCustomNormCoverageChartProps,
) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customNorms, setCustomNorms] = useState<Norm[]>([]);
  const [selectedCustomNorm, setSelectedCustomNorm] = useState<Norm | undefined>(undefined);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [currentWidgetId, setCurrentWidgetId] = useState<number>(0);

  React.useEffect(() => {
    if (currentWidgetId === 0 || currentWidgetId !== props.widget.widgetId) {
      setCurrentWidgetId(props.widget.widgetId);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetCustomNormCoverageConfig => {
    let newRawConfig = new WidgetCustomNormCoverageConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
      newRawConfig.tags = appContext.globalDataCache.tags
        .getItemsForId(newRawConfig.tagIds)
        .filter((t) => t.tagId !== 0);
    }

    return newRawConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await appContext.globalDataCache.tags.getItems();
      const config = loadConfig();

      const customNorms = appContext.globalDataCache.norms.getItemsCustom();
      setCustomNorms(customNorms.sort((a, b) => a.name.localeCompare(b.name)));

      if (config.customNormId) {
        const norm = customNorms.find((e) => e.normId === config.customNormId);
        setSelectedCustomNorm(norm);
      }

      if (config.tags) {
        setSelectedTags(config.tags);
      }

      setShowTags(config.showTags);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfig = (
    customNormId: number | undefined,
    tags: Tag[],
    showTags: boolean,
  ): WidgetCustomNormCoverageConfig => {
    const config = new WidgetCustomNormCoverageConfig();
    config.customNormId = customNormId ?? 0;
    config.tagIds = tags.map((t) => t.tagId);
    config.showTags = showTags;

    return config;
  };

  const onSelectCustomNorm = (customNormId: number) => {
    setSelectedCustomNorm(customNorms.find((i) => i.normId === customNormId));
    const config = getConfig(customNormId, selectedTags, showTags);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const getOptions = (): IComboBoxOption[] => {
    return customNorms.map((n) => {
      return {
        key: n.normId as number,
        text: n.name,
      };
    });
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  const onAddTag = (tag: Tag) => {
    const newTags = [...selectedTags, tag];
    setSelectedTags(newTags);
    const config = getConfig(selectedCustomNorm?.normId, newTags, showTags);
    props.onUpdateConfig(JSON.stringify(config), selectedCustomNorm?.isoNormId !== undefined);
  };

  const onRemoveTag = (tag: Tag) => {
    const newTags = selectedTags.filter((t) => t.tagId !== tag.tagId);
    setSelectedTags(newTags);
    const config = getConfig(selectedCustomNorm?.normId, newTags, showTags);
    props.onUpdateConfig(JSON.stringify(config), selectedCustomNorm?.isoNormId !== undefined);
  };

  const onSetShowTags = (checked: boolean) => {
    setShowTags(checked);
    const config = getConfig(selectedCustomNorm?.normId, selectedTags, checked);
    props.onUpdateConfig(JSON.stringify(config), selectedCustomNorm?.isoNormId !== undefined);
  };

  if (getOptions().length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Label>{t('widgets:CustomNormCoverage.Config.NoNorms')}</Label>
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <Label>{t('widgets:CustomNormCoverage.Config.Label')}</Label>
        <ComboBox
          selectedKey={selectedCustomNorm?.normId}
          onChange={(ev, option) => onSelectCustomNorm(option?.key as number)}
          options={getOptions()}
          useComboBoxAsMenuWidth
        ></ComboBox>
      </Stack.Item>
      <Stack.Item>
        <Label>{t('widgets:CustomNormCoverage.Config.LabelTags')}</Label>
        <KeyValueTagPicker
          itemLimit={5}
          selectedTags={selectedTags}
          onAdd={onAddTag}
          onRemove={onRemoveTag}
          isLoading={isLoading}
          allowCreate={false}
        ></KeyValueTagPicker>
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={showTags}
          label={t('widgets:CustomNormCoverage.Config.LabelShowTags')}
          onChange={(ev, checked) => {
            onSetShowTags(checked ?? false);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
