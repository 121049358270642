//
// Each filter group has a defined key.
// The actual filter values are prefixed with this key
// The key must end with a $ sign
// The key must not contain a @ or # character because these are used in the storageKey format
// Storage format: {field}#{count1}@{field}#{count2}@{...}
// -> Field format: {group}${value}
//
// This means that values to filter on cannot contain $, @ or #
// Normally this will be oke for database Id's (numbers) or Guid's
//
export enum FilterAssetGroupKeys {
  state = 'state$',
  owner = 'owner$',
  standard = 'norm$',
  tag = 'tag$',
  classification = 'classfication$',
  type = 'type$',
  shared = 'shared$',
}
