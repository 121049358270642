import http from './httpService';
import AppError from 'utils/appError';
import ThemeDTO from 'models/dto/themeDTO';
import Theme from 'models/theme';
import ThemeSceneDTO from 'models/dto/themeSceneDTO';
import ThemeScene from 'models/themeScene';
import { mapFromThemes, mapFromTheme, mapToTheme, mapFromThemeScene, mapFromThemeContext } from 'mappings/dataMapping';
import NormThemeDTO from 'models/dto/normThemeDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import EntityRelationDTO from 'models/dto/entityRelationDTO';
import ThemeContext from 'models/themeContext';
import ThemeContextDTO from 'models/dto/themeContextDTO';
import { ObjectiveThemeDTO } from 'models/dto/Objective/objectiveThemeDTO';
import { ProcessThemeDTO } from 'models/dto/Process/processThemeDTO';
import { ControlThemeInheritInfoDTO } from 'models/dto/controlThemeInheritInfoDTO';

export async function apiGetThemes(accessToken: string, globalDataCache: GlobalDataCache): Promise<Theme[]> {
  try {
    const ar = await http.get<ThemeDTO[]>(`/themes`, http.getRequestConfig(accessToken));

    return mapFromThemes(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetThemesForTask(
  taskId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Theme[]> {
  try {
    const res = await http.get<ThemeDTO[]>(`/themes/task/${taskId}`, http.getRequestConfig(accessToken));

    return mapFromThemes(res.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetThemeChildren(
  themeId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Theme[]> {
  try {
    const ar = await http.get<ThemeDTO[]>(`/themes/${themeId}/children`, http.getRequestConfig(accessToken));

    return mapFromThemes(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTheme(
  themeId: number,
  includeISOControls: boolean,
  includePackageInstructions: boolean,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Theme | undefined> {
  try {
    if (isNaN(themeId)) return undefined;
    const ar = await http.get<ThemeDTO>(
      `/themes/${themeId}?includeISOControls=${includeISOControls}&includePackageInstructions=${includePackageInstructions}`,
      http.getRequestConfig(accessToken),
    );
    if (!ar.data) return undefined;

    return mapFromTheme(ar.data, globalDataCache) as Theme;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetThemeCode(accessToken: string, code: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/themes/code`, { valueString: code }, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTheme(theme: Theme, accessToken: string, globalDataCache: GlobalDataCache): Promise<Theme> {
  try {
    const themeDTO = mapToTheme(theme);
    const ar = await http.post<ThemeDTO>(`/themes`, themeDTO, http.getRequestConfig(accessToken));

    return mapFromTheme(ar.data, globalDataCache) as Theme;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTheme(
  theme: Theme,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Theme> {
  try {
    const themeDTO: ThemeDTO = mapToTheme(theme) as ThemeDTO;
    const ar = await http.put<ThemeDTO>('/themes', themeDTO, http.getRequestConfig(accessToken));

    return mapFromTheme(ar.data, globalDataCache) as Theme;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTheme(theme: Theme, accessToken: string): Promise<boolean> {
  try {
    await http.post(`/themes/delete/${theme.themeId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetThemeScene(accessToken: string, globalDataCache: GlobalDataCache): Promise<ThemeScene> {
  try {
    const ar = await http.get<ThemeSceneDTO>(`/themes/scene`, http.getRequestConfig(accessToken));

    return mapFromThemeScene(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetCustomNorm(
  normId: number,
  themeId: number,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const normThemeDTO: NormThemeDTO = new NormThemeDTO(normId, themeId);
    await http.post(`/themes/norms/?add=${add}`, normThemeDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateSchema(auditYear: number, accessToken: string): Promise<void> {
  try {
    await http.post(`/themes/audit/createtasks?year=${auditYear}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveThemeFromTheme(
  themeIdFrom: number,
  themeIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new EntityRelationDTO();
    body.idFrom = themeIdFrom;
    body.idTo = themeIdTo;
    await http.post<EntityRelationDTO>(`/themes/theme?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddThemeToTheme(
  themeIdFrom: number,
  themeIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new EntityRelationDTO();
    body.idFrom = themeIdFrom;
    body.idTo = themeIdTo;
    await http.post<EntityRelationDTO>(`/themes/theme?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetThemeContext(
  themeId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ThemeContext> {
  try {
    const ar = await http.get<ThemeContextDTO>(`/themes/context/${themeId}`, http.getRequestConfig(accessToken));

    return mapFromThemeContext(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Control
export async function apiRemoveControlFromTheme(
  controlIdFrom: number,
  themeId: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new EntityRelationDTO();
    body.idFrom = controlIdFrom;
    body.idTo = themeId;
    await http.post<EntityRelationDTO>(`/themes/control?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddControlToTheme(
  controlIdFrom: number,
  themeId: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new EntityRelationDTO();
    body.idFrom = controlIdFrom;
    body.idTo = themeId;
    await http.post<EntityRelationDTO>(`/themes/control?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Risk

export async function apiGetThemesForRisk(
  riskId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Theme[]> {
  try {
    const res = await http.get<ThemeDTO[]>(`/themes/risk/${riskId}`, http.getRequestConfig(accessToken));

    return mapFromThemes(res.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Objective
export async function apiAddObjectiveToTheme(
  objectiveIdFrom: number,
  themeId: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ObjectiveThemeDTO(themeId, objectiveIdFrom);
    await http.post<EntityRelationDTO>(`/objectives/theme?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveObjectiveFromTheme(
  objectiveIdFrom: number,
  themeId: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ObjectiveThemeDTO(themeId, objectiveIdFrom);
    await http.post<ObjectiveThemeDTO>(`/objectives/theme?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//process
export async function apiAddProcessToTheme(
  processIdFrom: number,
  themeId: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ProcessThemeDTO(processIdFrom, themeId);
    await http.post<ProcessThemeDTO>(`/processes/theme?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveProcessFromTheme(
  processIdFrom: number,
  themeId: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ProcessThemeDTO(processIdFrom, themeId);
    await http.post<ProcessThemeDTO>(`/processes/theme?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetNextTheme(
  themeId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Theme | undefined> {
  try {
    const ar = await http.get<ThemeDTO>(`/themes/${themeId}/next`, http.getRequestConfig(accessToken));

    return mapFromTheme(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPrevTheme(
  themeId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Theme | undefined> {
  try {
    const ar = await http.get<ThemeDTO>(`/themes/${themeId}/prev`, http.getRequestConfig(accessToken));

    return mapFromTheme(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetThemeInheritOptions(
  themeId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Theme[]> {
  try {
    const ar = await http.get<ThemeDTO[]>(`/themes/${themeId}/inherit`, http.getRequestConfig(accessToken));

    return mapFromThemes(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateThemeInheritOptions(
  sourceThemeId: number,
  orgUnitId: string,
  themeId: number,
  copyImpl: boolean,
  overwriteImpl: boolean,
  copyStat: boolean,
  copyAppl: boolean,
  copyCust: boolean,
  copyLib: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Theme | undefined> {
  try {
    const dto = new ControlThemeInheritInfoDTO();
    dto.orgUnitId = orgUnitId;
    dto.id = themeId;
    dto.copyImplementation = copyImpl;
    dto.overwriteImplementation = overwriteImpl;
    dto.copyStatus = copyStat;
    dto.copyApplicability = copyAppl;
    dto.copyCustomStandards = copyCust;
    dto.copyLibraryItems = copyLib;

    const ar = await http.post<ThemeDTO>(`/themes/${sourceThemeId}/inherit`, dto, http.getRequestConfig(accessToken));

    return mapFromTheme(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
