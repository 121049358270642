import React, { FunctionComponent, useContext, useState } from 'react';
import { Text, Stack, Spinner, SpinnerSize, SharedColors, ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { useTranslation } from 'react-i18next';
import { IPieChartDataPoint, FluentUIPieChart } from 'components/Charts/PieChart';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { apiGetWidgetDataAzureDefenderSecureScore } from 'services/Api/dashboardService';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import { AzureSubscription } from 'models/azure/azureSubscription';
import { apiGetAzureSubscriptions } from 'services/Api/azureService';
import { newGuidNil } from 'utils/guid';
import { IWidgetRendererProps } from '../WidgetRenderer';

export class WidgetAzureDefenderSecureScoreConfig {
  id: string;

  name: string;

  constructor() {
    this.id = '';
    this.name = '';
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.id = newRawConfig.id ?? '';
        this.name = newRawConfig.name ?? '';
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetAzureDefenderSecureScoreConfig {
    const newConfig = new WidgetAzureDefenderSecureScoreConfig();
    newConfig.id = this.id;
    newConfig.name = this.name;

    return newConfig;
  }
}

interface IWidgetAzureDefenderSecureScoreProps extends IWidgetRendererProps {}

const WidgetAzureDefenderSecureScore: FunctionComponent<IWidgetAzureDefenderSecureScoreProps> = (
  props: IWidgetAzureDefenderSecureScoreProps,
) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const appContext = React.useContext(AppContext);
  const [data, setData] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const loadConfig = (): WidgetAzureDefenderSecureScoreConfig => {
      const newConfig = new WidgetAzureDefenderSecureScoreConfig();
      newConfig.load(props.widget.widgetConfig);

      return newConfig;
    };

    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        const config = loadConfig();

        if (config && config.id) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const data = await apiGetWidgetDataAzureDefenderSecureScore(accessToken, config.id);
          setData(data);
        }
      } catch {
        //ignore errors, user has no auth probably
        setData(0);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const getData = (): IPieChartDataPoint[] => {
    if (data === 0) return [];

    return [
      {
        name: 'Secure Score',
        value: data,
        color: SharedColors.green10,
      },
      {
        name: '',
        value: 100 - data,
        color: SharedColors.gray10,
      },
    ];
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (data === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:AzureDefenderSecureScore.NoData')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill horizontalAlign="center" tokens={globalStackTokensGapSmall}>
      <Stack.Item grow>
        <FluentUIPieChart data={getData()} height={250} width={280} label={`${data}%`}></FluentUIPieChart>
      </Stack.Item>
    </Stack>
  );
};

export default WidgetAzureDefenderSecureScore;

//
// Config
//

interface IWidgetConfigAzureDefenderSecureScoreProps extends IWidgetConfigRendererProps {}

export const WidgetConfigAzureDefenderSecureScore: FunctionComponent<IWidgetConfigAzureDefenderSecureScoreProps> = (
  props: IWidgetConfigAzureDefenderSecureScoreProps,
) => {
  const { t } = useTranslation(['widgets', 'translation']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [azureSubscriptions, setAzureSubscriptions] = useState<AzureSubscription[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<AzureSubscription | undefined>(undefined);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetAzureDefenderSecureScoreConfig => {
    let newRawConfig: WidgetAzureDefenderSecureScoreConfig = new WidgetAzureDefenderSecureScoreConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
    }

    return newRawConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const config = loadConfig();

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const azureSubscriptions = await apiGetAzureSubscriptions(accessToken);

      setAzureSubscriptions(azureSubscriptions);

      if (config.id) {
        const sub = azureSubscriptions.find((e) => e.id === config.id);
        setSelectedSubscription(sub);
      }
    } catch {
      //ignore errors
    } finally {
      setIsLoading(false);
    }
  };

  const getConfig = (id: string, name: string): WidgetAzureDefenderSecureScoreConfig => {
    const config = new WidgetAzureDefenderSecureScoreConfig();
    config.id = id;
    config.name = name;

    return config;
  };

  const updateConfig = (id: string) => {
    const name = azureSubscriptions.find((a) => a.id === id)?.displayName;
    if (!name) return;
    const config = getConfig(id, name);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const getOptions = (): IComboBoxOption[] => {
    let items: IComboBoxOption[] = [];

    items = azureSubscriptions.map((sub) => {
      return {
        key: sub.id,
        text: sub.displayName,
      };
    });

    if (items.length === 0) {
      items.push({
        key: newGuidNil(),
        text: t('widgets:AzureDefenderSecureScore.Config.Subscription.NoneFound'),
      });
    }

    return items;
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <ComboBox
          selectedKey={selectedSubscription?.id}
          options={getOptions()}
          label={t('widgets:AzureDefenderSecureScore.Config.Subscription.Label')}
          useComboBoxAsMenuWidth
          onChange={(
            event: React.FormEvent<IComboBox>,
            option?: IComboBoxOption | undefined,
            index?: number | undefined,
            value?: string | undefined,
          ) => {
            if (option) {
              updateConfig(option.key as string);
            }
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
