import { FontIcon, Label, Stack, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import { globalStackTokensGapExtraSmall, globalStackTokensGapMedium } from 'globalStyles';
import Task, { TaskTypes } from 'models/tasks/task';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import RecurrenceModal from 'components/RecurringPattern/RecurrenceModal';
import { RecurringPatternSummaryStyle, RecurringSummary } from 'components/RecurringPattern/RecurringSummary';
import RecurringPattern from 'models/recurringPattern';
import { LegacyOutlookSeriesEnabled } from 'models/setting';

interface ITaskDetailRecurrenceProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  onUpdate: (newTask: Task) => void;
}

export const TaskDetailRecurrence = (props: ITaskDetailRecurrenceProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation', 'dateTimeComponent', 'control']);
  const [recurringModalOpen, setRecurringModalOpen] = useState<boolean>(false);
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Recurrence, appContext);
  const [legacyOutlookSeriesEnabled] = useState<boolean>(
    appContext.globalDataCache.settings.get(LegacyOutlookSeriesEnabled) as boolean,
  );

  const toggleRecurringPattern = () => {
    setRecurringModalOpen(!recurringModalOpen);
  };

  const recurringChangeHandler = (recurring: RecurringPattern) => {
    const copyTask = props.task.clone();
    copyTask.recurringPattern = recurring;
    if (recurring.isActive) {
      copyTask.setNewStartDate(recurring.startDate);
    }
    props.onUpdate(copyTask);
  };

  //
  // Main render
  //
  if (props.orgTask.taskType === TaskTypes.Normal && !legacyOutlookSeriesEnabled) {
    return null;
  }

  return (
    <Stack
      horizontal
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
      verticalAlign="start"
      styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace } } : undefined}
    >
      <Stack.Item>{!appContext.isMobileView && <FontIcon iconName="RepeatAll" style={iconFontSize} />}</Stack.Item>
      {isReadonly && (
        <Stack.Item grow>
          <Label>{t('task:Repeat.Title')}</Label>
          {props.task.recurringPattern.isActive && (
            <RecurringSummary
              pattern={props.task.recurringPattern}
              summaryStyle={RecurringPatternSummaryStyle.text}
              label={t('task:Repeat.Title')}
            />
          )}
          {!props.task.recurringPattern.isActive && <Text>{'-'}</Text>}
        </Stack.Item>
      )}
      {!isReadonly && (
        <Stack.Item grow>
          <RecurringSummary
            pattern={props.task.recurringPattern}
            summaryStyle={RecurringPatternSummaryStyle.edit}
            label={t('task:Repeat.Title')}
            placeholder={t('task:Repeat.Placeholder')}
            onClick={(e) => {
              if (!isReadonly) {
                const copyTask = props.task.clone();
                copyTask.recurringPattern.startDate = copyTask.startDateTime;
                props.onUpdate(copyTask);
                toggleRecurringPattern();
                e.preventDefault();
              }
            }}
          />
          <RecurrenceModal
            isOpen={recurringModalOpen}
            toggleModal={toggleRecurringPattern}
            recurring={props.task.recurringPattern}
            changeRecurring={recurringChangeHandler}
            allowRemove={false}
            initToAuditYear={false}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};
