import React, { useContext, useEffect, useState } from 'react';
import {
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  IColumn,
  SearchBox,
  SelectionMode,
  Stack,
  Selection,
  Text,
  ScrollablePane,
  ScrollbarVisibility,
  IObjectWithKey,
  TooltipHost,
  Link,
  DirectionalHint,
  IDetailsRowProps,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  globalSearchBoxStyles,
  globalStackItemStylesPaddingSceneScroll,
  globalStackTokensGapSmall,
} from 'globalStyles';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { globalFilterDelay } from 'globalConstants';
import AppContext from 'App/AppContext';
import KPI from 'models/kpi/kpi';
import { TFunction } from 'i18next';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import { useHistory } from 'react-router-dom';
import { copyAndSortKPIs } from './KPIListSorting';

interface IKPIListProps {
  kpis: KPI[];
  isLoading?: boolean;
  showSearch?: boolean;
  onSelectKPI?: (item: KPI) => void;
  onRemove?: (item: KPI) => void;
  onDoubleClickKPI?: (item: KPI) => void;
  onAddNewKPI?: () => void;
  existingKPIs?: number[];
  allowKPINavigation?: boolean;
}

const KPIList = (props: IKPIListProps) => {
  const { t } = useTranslation(['translation', 'kpis']);
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [kpisDisplay, setKPIsDisplay] = useState<KPI[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [selection] = useState<Selection<KPI>>(
    new Selection({
      selectionMode: props.onSelectKPI ? SelectionMode.single : SelectionMode.none,
      canSelectItem: (item) => {
        return !props.existingKPIs?.includes(item.kpiId);
      },
      getKey: (item: KPI) => {
        return item.kpiId;
      },
      onSelectionChanged: () => {
        const sel = selection.getSelection()[0] as KPI;
        if (props.onSelectKPI) {
          props.onSelectKPI(sel);
        }
      },
    }),
  );

  //
  // Effects
  //
  useEffect(() => {
    setKPIsDisplay(props.kpis);

    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchQuery) {
      setKPIsDisplay(props.kpis);
    } else {
      const queryLower = searchQuery.toLowerCase();
      const filtered = props.kpis.filter((entry) => {
        return Object.values(entry).some((val) => {
          if (typeof val === 'string') {
            if (entry.name && entry.name.toLowerCase().includes(queryLower)) {
              return true;
            }
            if (entry.name && entry.name.toLowerCase().includes(queryLower)) {
              return true;
            }
            if (entry.description && entry.description.toLowerCase().includes(queryLower)) {
              return true;
            }
          }

          return false;
        });
      });

      if (filtered) {
        setKPIsDisplay(filtered);
      }
    }
     
  }, [props.kpis, searchQuery]);

  const navigateToKPI = (kpi: KPI) => {
    const url = `/kpi/${kpi.kpiId}/details`;
    history.push(url);
  };

  //
  // Columns list view
  //
  const getColumnsList = (): IColumn[] => {
    const allColumns = [
      {
        key: 'name',
        name: t('kpis:Fields.Name'),
        minWidth: 150,
        maxWidth: 400,
        isRowHeader: true,
        isResizable: true,
        isMultiline: true,
        onRender: (item?: KPI, index?: number, column?: IColumn) => {
          if (!item) return;

          if (props.allowKPINavigation) {
            return (
              <Text>
                <Link
                  underline
                  onClick={() => {
                    navigateToKPI(item);
                  }}
                >
                  {item.name}
                </Link>
              </Text>
            );
          } else {
            return <Text>{item.name}</Text>;
          }
        },
      },
      {
        key: 'description',
        name: t('kpis:Fields.Description'),
        minWidth: 150,
        maxWidth: 400,
        isRowHeader: true,
        isResizable: true,
        isMultiline: true,
        onRender: (item?: KPI, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{item.description}</Text>;
        },
      },
      {
        key: 'type',
        name: t('kpis:Fields.Type'),
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        onRender: (item?: KPI, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text nowrap>{item.getTypeText(t as TFunction<string[]>)}</Text>;
        },
      },
      {
        key: 'tags',
        name: t('kpis:Fields.Tags'),
        minWidth: 200,
        maxWidth: 400,
        isResizable: true,
        onRender: (item?: KPI, index?: number, column?: IColumn) => {
          return <KeyValueTags tags={appContext.globalDataCache.tags.getItemsForId(item?.tagIds)} />;
        },
      },
    ];

    return allColumns;
  };

  const [columnsList, setColumnsList] = useState<IColumn[]>(getColumnsList());

  const onChangeText = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string): void => {
    if (filterText) {
      setSearchQuery(filterText.toLowerCase());
    } else {
      setSearchQuery('');
    }
  };

  const onRenderRow = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (!defaultRender || !rowProps?.item) return null;

    if (rowProps?.item && !selection.canSelectItem(rowProps.item)) {
      rowProps.disabled = true;

      return (
        <TooltipHost content={t('kpis:Picker.ItemExists')} directionalHint={DirectionalHint.topLeftEdge}>
          {defaultRender(rowProps)}
        </TooltipHost>
      );
    }

    return (
      <span
        onDoubleClick={() => {
          if (props.onDoubleClickKPI) props.onDoubleClickKPI(rowProps.item);
        }}
      >
        {defaultRender(rowProps)}
      </span>
    );
  };

  const onColumnClick = (
    ev?: React.MouseEvent<HTMLElement>,
    column?: IColumn,
    isForceDescendingEnabled?: boolean,
  ): void => {
    if (!column) return;

    const newColumns: IColumn[] = columnsList.slice(); //new columns
    const currColumn: IColumn = newColumns.filter((currCol) => column?.key === currCol.key)[0];
    if (isForceDescendingEnabled !== undefined) {
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = isForceDescendingEnabled;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
    } else {
      for (const newCol of newColumns) {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = false;
        }
      }
    }

    const newItems = copyAndSortKPIs(appContext, props.kpis, currColumn, currColumn.isSortedDescending);

    setColumnsList(newColumns);
    setKPIsDisplay(newItems);
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill>
      {props.showSearch && (
        <Stack.Item>
          <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <SearchBox
                autoFocus={!appContext.isMobileView}
                underlined
                placeholder={t('translation:General.Filter.Placeholder')}
                id="search"
                onChange={(ev, newValue) => {
                  if (searchTimer) {
                    clearTimeout(searchTimer);
                  }
                  setSearchTimer(setTimeout(() => onChangeText(ev, newValue), globalFilterDelay));
                }}
                styles={globalSearchBoxStyles}
              />
            </Stack.Item>
            {props.onAddNewKPI && (
              <Stack.Item>
                <Link
                  onClick={() => {
                    if (props.onAddNewKPI) props.onAddNewKPI();
                  }}
                >
                  {t('kpis:Picker.AddNewLink')}
                </Link>
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      )}
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            compact
            enableShimmer={props.isLoading}
            selection={selection as Selection<IObjectWithKey>}
            items={kpisDisplay}
            isHeaderVisible={true}
            columns={columnsList}
            setKey="set"
            selectionMode={SelectionMode.single}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            onRenderRow={onRenderRow}
            onColumnHeaderClick={onColumnClick}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default KPIList;
