import { appRoles, appRoleSets, appRoleSetTypes } from 'services/Auth/appRoles';
import { Role } from './auth/role';

export default class User {
  id: string;

  name: string;

  email: string;

  created?: Date;

  lastLogin?: Date;

  hasLicense: boolean = false;

  roles?: Role[];

  groups?: string[];

  userTimeZoneCode?: string;

  systemRoleIds?: number[];

  constructor(id: string, email: string, name: string) {
    this.id = id;
    this.name = name;
    this.email = email;
  }

  clone(): User {
    const newUser = new User(this.id, this.email, this.name);
    newUser.created = this.created;
    newUser.lastLogin = this.lastLogin;
    newUser.hasLicense = this.hasLicense;
    newUser.userTimeZoneCode = this.userTimeZoneCode;
    newUser.groups = this.groups ? [...this.groups] : [];
    newUser.roles = this.roles?.map((r) => r.clone());
    newUser.systemRoleIds = this.systemRoleIds ? [...this.systemRoleIds] : [];

    return newUser;
  }

  static getEmptyUser(id?: string) {
    const newUser = new User('', '', '');
    if (id) newUser.id = id;

    return newUser;
  }

  get systemRoles(): number[] {
    return this.roles?.filter((r) => r.systemRoleId !== undefined).map((r) => r.systemRoleId as number) ?? [];
  }

  hasPaidLicense = (): boolean => {
    const sr = this.systemRoleIds ?? [];

    for (let idx = 0; idx < sr.length; idx++) {
      if (appRoleSets[appRoleSetTypes.Licensed].includes(sr[idx])) {
        return true;
      }
    }

    return false;
  };

  isAdmin = (): boolean => {
    const sr = this.systemRoleIds ?? [];

    return sr.includes(appRoles.Admin);
  }
}
