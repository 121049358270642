import { IAppContext } from 'App/AppContext';
import { FilterAssetGroupKeys } from 'components/Asset/FilterAssetGroupKeys';
import { FilterRiskGroupKeys } from 'components/Risk/FilterRiskGroupKeys';
import { getLocalStorageData, LocalStorageKeys } from 'utils/localstorage';
import { GlobalFilterGroupKeys } from './GlobalFilterGroupKeys';
import { FilterThemeGroupKeys } from 'components/Theme/FilterThemeGroupKeys';
import { FilterControlGroupKeys } from 'components/Control/FilterControlGroupKeys';
import { FilterTaskGroupKeys } from 'components/Tasks/Filter/FilterTaskGroupKeys';
import { FilterLibraryGroupKeys } from 'components/Approval/FilterApprovalTaskGroupKeys';
import { FilterStandardsGroupKeys } from 'components/Norm/FilterNormGroupKeys';
import { isoNormSearchPrepend, normSearchPrepend } from 'components/Norm/FilterNorm';

export type FilterKeys =
  | typeof FilterAssetGroupKeys
  | typeof FilterRiskGroupKeys
  | typeof FilterThemeGroupKeys
  | typeof FilterControlGroupKeys
  | typeof FilterTaskGroupKeys
  | typeof FilterLibraryGroupKeys
  | typeof FilterStandardsGroupKeys;

export const applyGlobalFilter = (
  appContext: IAppContext,
  filter: string[] | undefined,
  keys: FilterKeys,
  includePrepend: boolean = false,
  onlyPrepend: string | undefined = undefined,
): string[] => {
  if (!filter) filter = [];

  if (appContext.globalFilter.length === 0) {
    return filter;
  }

  //add standards
  const newFilter = filter.filter((f) => !f.startsWith(keys.standard));
  appContext.globalFilter
    .filter((f) => f.startsWith(GlobalFilterGroupKeys.standard))
    .forEach((f) => {
      const filterValue = f.split('$')[1];
      if (filterValue) {
        if (includePrepend) {
          const norm = appContext.globalDataCache.norms.get(Number(filterValue));
          if (norm.normId) {
            const prepend = norm.isoNormId ? isoNormSearchPrepend : normSearchPrepend;
            const id = norm.isoNormId ? norm.isoNormId : norm.normId;
            if (!onlyPrepend || onlyPrepend === prepend) {
              newFilter.push(keys.standard + prepend + id);
            }
          }
        } else {
          newFilter.push(keys.standard + filterValue);
        }
      }
    });

  return newFilter;
};

export const removeGlobalFilter = (
  appContext: IAppContext,
  filter: string[] | undefined,
  keys: FilterKeys,
  localStorageKey: LocalStorageKeys,
): string[] => {
  if (!filter) filter = [];

  if (filter.length === 0 || appContext.globalFilter.length === 0) {
    return filter;
  }

  //remove standards
  const newFilter = filter.filter((f) => !f.startsWith(keys.standard));
  const orgFilter = getLocalStorageData(appContext, localStorageKey)?.split(',');
  const orgFilterKeys = orgFilter?.filter((f) => f.startsWith(keys.standard)) || [];
  newFilter.push(...orgFilterKeys);

  return newFilter;
};

export const clearFilterKeepGlobalFilter = (
  appContext: IAppContext,
  filter: string[] | undefined,
  keys: FilterKeys,
): string[] => {
  if (!filter) filter = [];

  if (filter.length === 0 || appContext.globalFilter.length === 0) {
    return [];
  }

  //remove standards
  const newFilter = filter.filter((f) => f.startsWith(keys.standard));

  return newFilter;
};

export const getGlobalFilters = (appContext: IAppContext, key: GlobalFilterGroupKeys): string[] => {
  if (appContext.globalFilter.length === 0) {
    return [];
  }

  //get standards
  const standards = appContext.globalFilter.filter((f) => f.startsWith(key)).map(f => f.split('$')[1]);

  return [...standards];
};

export const countGlobalFilters = (appContext: IAppContext, filter: string[] | undefined, keys: FilterKeys): number => {
  if (!filter) return 0;

  if (filter.length === 0 || appContext.globalFilter.length === 0) {
    return 0;
  }

  //count standards
  const standardCount = filter.filter((f) => f.startsWith(keys.standard)).length;

  return standardCount;
};

export const globalFilterCount = (appContext: IAppContext, key: GlobalFilterGroupKeys): number => {
  return appContext.globalFilter.filter((f) => f.startsWith(key)).length;
};
