import { mapFromObjectives } from './objectiveMapping';
import Classification from 'models/classification/classification';
import { Classification_Translation } from 'models/classification/classification_Translation';
import ClassificationDTO from 'models/dto/classification/classificationDTO';
import { Classification_TranslationDTO } from 'models/dto/classification/classification_TranslationDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import Language from 'models/language';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import { mapFromStringValue, mapToStringValue } from './stringValueMapping';

export function mapFromClassifications(
  classificationDTOs: ClassificationDTO[] | undefined,
  cache: GlobalDataCache,
): Classification[] {
  if (!classificationDTOs) return [];

  const output = classificationDTOs.map((item) => {
    return mapFromClassification(item, cache);
  });

  return output;
}

export function mapFromClassification(classificationDTO: ClassificationDTO, cache: GlobalDataCache): Classification {
  const output = new Classification();

  output.classificationId = classificationDTO.classificationId;
  output.value = classificationDTO.value;
  output.color = classificationDTO.color;
  output.inherited = classificationDTO.inherited;
  output.objectives = mapFromObjectives(classificationDTO.objectives, cache);
  output.group = mapFromStringValue(classificationDTO.group);
  output.trans = mapFromClassification_Translation(classificationDTO.trans);

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
    output.examples = output.trans[0].examples;
  }

  return output;
}

export const mapFromClassification_Translation = (
  transDTOs: Classification_TranslationDTO[],
): Classification_Translation[] => {
  const output: Classification_Translation[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new Classification_Translation();

    newObject_Translation.classificationId = transDTO.classificationId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.examples = transDTO.examples;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export function mapToClassifications(classifications: Classification[] | undefined): ClassificationDTO[] | undefined {
  if (!classifications) return undefined;

  const output = classifications.map((item) => {
    return mapToClassification(item);
  });

  return output;
}

export function mapToClassification(classification: Classification): ClassificationDTO {
  const output = new ClassificationDTO();

  output.classificationId = classification.classificationId;
  output.value = classification.value;
  output.color = classification.color;
  output.inherited = classification.inherited;
  output.group = mapToStringValue(classification.group);
  output.trans = mapToClassification_Translation(classification.trans);

  if (classification.trans && classification.transIdx >= 0) {
    output.trans[classification.transIdx].name = classification.name;
    output.trans[classification.transIdx].description = classification.description;
    output.trans[classification.transIdx].examples = classification.examples;
  }

  return output;
}

export const mapToClassification_Translation = (
  transDTOs: Classification_Translation[],
): Classification_TranslationDTO[] => {
  const output: Classification_TranslationDTO[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new Classification_TranslationDTO();

    newObject_Translation.classificationId = transDTO.classificationId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.examples = transDTO.examples;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapToLanguage(transDTO.lang);

    output.push(newObject_Translation);
  }

  return output;
};
