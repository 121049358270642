import { useContext, useEffect, useState } from 'react';
import { Checkbox, ScrollablePane, ScrollbarVisibility, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import {
  globalStackItemStylesPaddingSceneScroll,
  globalStackStylesHeight100,
  globalStackTokensGapSmall,
} from 'globalStyles';
import { apiGetLinksForId, apiGetLinksForTask } from 'services/Api/linkService';
import { apiRequest } from 'services/Auth/authConfig';
import ResourceLink from 'models/resourceLink';
import LinkList from 'components/Links/LinkList';
import AddNewLink from 'components/Links/AddNewLink';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalToastDelay } from 'globalConstants';
import { onRenderTextWithInfo } from 'globalFunctions';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import Task from 'models/tasks/task';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';
import { hasUserDataPermission } from 'services/Auth/featurePermissions';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';

interface ITaskLinkList {
  task: Task;
  taskLinks: ResourceLink[];
  hideWholeList?: boolean;
  navigateExternal?: boolean;
  addLinks: (links: ResourceLink[], validateForm: boolean) => void;
  setLinks: (links: ResourceLink[], validateForm: boolean) => void;
  removeLink: (link: ResourceLink, validateForm: boolean) => void;
  onPin?: (item: ResourceLink, pinned: boolean) => void;
}

const TaskLinkList = (props: ITaskLinkList) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'library']);
  const [extraLinks, setExtraLinks] = useState<ResourceLink[]>([]);
  const [taskId, setTaskId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasPermission] = useState<boolean>(
    hasUserDataPermission(appContext, [props.task.authSchemaId], AuthSchemaLineOperation.Update),
  );
  const [readonly] = useState<boolean>(
    !canUpdateTaskField(props.task, hasPermission, TaskFieldTypes.Library, appContext),
  );

  const getShowAllStateFromStorage = () => {
    const showAll: boolean = getLocalStorageData(appContext, LocalStorageKeys.ShowAllLinks) === 'true';

    return showAll;
  };

  const [showAll, setShowAll] = useState<boolean>(getShowAllStateFromStorage());

  useEffect(() => {
    if (props.task.taskId !== taskId) {
      setTaskId(props.task.taskId);
      loadData(showAll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  const loadData = async (showAll: boolean) => {
    try {
      if (isLoading) return;
      if (!props.task.resourceLinkIds || props.task.resourceLinkIds.length === 0) return;
      setIsLoading(true);

      //load the details of the task links for:
      //- links that are not already loaded into props.tasklinks
      //- links that are not new (id <= 0)
      const linksToGet = props.task.resourceLinkIds.filter(
        (id) => id > 0 && !props.taskLinks.some((t) => t.linkId === id),
      );
      if (linksToGet.length > 0) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const _links = await apiGetLinksForId(accessToken, linksToGet, appContext.globalDataCache);
        if (_links.length > 0) {
          //add links to the state of SingleTask
          props.addLinks(_links, true);
        }
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadExtraLinks = async (showAll: boolean) => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      if (showAll) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const extraLinks = await apiGetLinksForTask(
          props.task.taskId,
          showAll,
          accessToken,
          appContext.globalDataCache,
        );
        //get the links from other entities
        setExtraLinks(extraLinks.filter((a) => !props.task.resourceLinkIds?.includes(a.linkId)));
      } else {
        setExtraLinks([]);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  //
  // Main render
  //
  if (!props.task.taskId) {
    return null;
  }

  return (
    <Stack verticalFill styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="" />
      </Stack.Item>
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        {!readonly && (
          <Stack.Item>
            <AddNewLink
              addLinks={(links) => props.addLinks(links, true)}
              existingLinks={props.taskLinks}
              allowCustomLists={true}
              allowDocumentLibraries={true}
              allowWebURLs={true}
              allowSitePages={true}
              disabled={isLoading}
              entityContext={true}
              hideWholeList={props.hideWholeList}
            />
          </Stack.Item>
        )}
        {props.task.taskId !== -1 && (
          <Stack.Item>
            <Checkbox
              checked={showAll}
              onRenderLabel={() =>
                onRenderTextWithInfo(
                  t('library:LinkComponents.ShowAllLinks'),
                  t('library:LinkComponents.ShowAllLinksInfoTask'),
                )
              }
              onChange={(ev, checked) => {
                const showAll = checked || false;
                setLocalStorageData(appContext, LocalStorageKeys.ShowAllLinks, showAll.toString());
                setShowAll(showAll);
                loadExtraLinks(showAll);
              }}
            />
          </Stack.Item>
        )}
      </Stack>
      {isLoading && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
      {!isLoading && (
        <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <LinkList
              items={[...props.taskLinks, ...extraLinks]}
              onDelete={(link) => props.removeLink(link, true)}
              onPin={props.onPin}
              navigateExternal={props.navigateExternal}
              readonly={readonly}
            />
          </ScrollablePane>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default TaskLinkList;
