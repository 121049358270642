import { useContext } from 'react';
import {
  Callout,
  Stack,
  DirectionalHint,
  Text,
  Link,
  Checkbox,
  CommandBarButton,
  IconButton,
  Separator,
  ScrollablePane,
  ScrollbarVisibility,
} from '@fluentui/react';

import { useTranslation } from 'react-i18next';
import Accordion from 'components/Accordion/Accordion';
import {
  globalStackStylesHeight100PaddingSmall,
  globalStackTokensGapSmall,
  filterICon,
  cancelIcon,
  globalSceneBarItemStyles,
} from 'globalStyles';
import Norm from 'models/norm';
import { FilterStandardsGroupKeys } from 'components/Norm/FilterNormGroupKeys';
import Control from 'models/control';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import Tag from 'models/tag';
import AppContext from 'App/AppContext';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { AuthSchema } from 'models/auth/authSchema';
import { OwnerPicker } from 'components/Pickers/OwnerPicker';
import User from 'models/user';
import { Role } from 'models/auth/role';
import {
  clearFilterKeepGlobalFilter,
  countGlobalFilters,
  globalFilterCount,
} from 'components/GlobalFilter/GlobalFilterHelper';
import { GlobalFilterGroupKeys } from 'components/GlobalFilter/GlobalFilterGroupKeys';
import { getEntityStatusTextList } from 'globalFunctions';

export const isoNormSearchPrepend = 'SearchByISONorm_';
export const normSearchPrepend = 'SearchByNorm_';

interface IFilterControl {
  toggleDialog: () => void;
  isOpen: boolean;
  updateSelectedFilter: Function;
  selectedFilter: string[];
}

interface ICheckbox {
  key: string;
  value: string;
  label: string;
}

const FilterNorm = (props: IFilterControl) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'norms']);

  const applicabilityFilterList: ICheckbox[] = [
    ...Control.getApplicabilityReasonList(t).map((r) => {
      return {
        key: r.key as string,
        value: r.key as string,
        label: r.text,
      };
    }),
  ];

  const statuses: ICheckbox[] = [
    ...getEntityStatusTextList(t).map((item) => {
      return {
        key: item.key as string,
        value: item.key as string,
        label: item.text,
      };
    }),
  ];

  const getCoveredFilterList = (): ICheckbox[] => {
    const items: ICheckbox[] = [
      {
        key: 'noControl',
        value: 'noControl',
        label: t('norms:TreeList.Filter.NoOrganizationControl'),
      },
      {
        key: 'covered',
        value: 'covered',
        label: t('norms:TreeList.Filter.Covered'),
      },
    ];

    if (hasUserFeature(appContext, FeatureTypes.OrgUnits)) {
      items.push({
        key: 'linked',
        value: 'linked',
        label: t('norms:TreeList.Filter.Linked'),
      });
    }

    return items;
  };

  const handleFilterClear = () => {
    clearFilter();
    props.toggleDialog();
  };

  const clearFilter = () => {
    const newFilter = clearFilterKeepGlobalFilter(appContext, props.selectedFilter, FilterStandardsGroupKeys);
    props.updateSelectedFilter(newFilter);
  };

  const handleSelectChange = (key: string) => {
    const items: string[] = [...props.selectedFilter];
    if (!props.selectedFilter.includes(key)) {
      items.push(key);
      props.updateSelectedFilter(items);
    } else {
      props.updateSelectedFilter(items.filter((i: string) => i !== key));
    }
  };

  const getSelectedFilterCount = (type: FilterStandardsGroupKeys) => {
    if (props.selectedFilter.length > 0) {
      return props.selectedFilter.filter((f) => f.startsWith(type))?.length;
    } else {
      return 0;
    }
  };

  const getTagsFromFilter = (filter: string[]): Tag[] => {
    const tags: Tag[] = [];
    filter.forEach((f) => {
      if (f.startsWith(FilterStandardsGroupKeys.Tag)) {
        const tagId = f.substring(4);
        const tag = appContext.globalDataCache.tags.get(Number.parseInt(tagId));
        tags.push(tag);
      }
    });

    return tags;
  };

  const getUserFromFilter = (filter: string[]): string | undefined => {
    const user = filter.find((f) => f.startsWith(FilterStandardsGroupKeys.Owner));

    return user?.split('$')[1];
  };

  const getFilterCount = (filter: string[]): number => {
    const count = filter.length;

    return count - countGlobalFilters(appContext, filter, FilterStandardsGroupKeys);
  };

  //
  // Main render
  //
  const filterCount = getFilterCount(props.selectedFilter);

  return (
    <Stack horizontal verticalAlign="center">
      <CommandBarButton
        iconProps={filterICon}
        className="calloutTargetFilterButton"
        text={t('norms:TreeList.Filter.Button', { count: filterCount })}
        onClick={props.toggleDialog}
        styles={globalSceneBarItemStyles}
      />
      {filterCount > 0 && (
        <IconButton styles={globalSceneBarItemStyles} onClick={() => clearFilter()} iconProps={cancelIcon} />
      )}
      <Callout
        alignTargetEdge={true}
        gapSpace={0}
        target=".calloutTargetFilterButton"
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomLeftEdge}
        hidden={!props.isOpen}
        onDismiss={props.toggleDialog}
      >
        <Stack styles={globalStackStylesHeight100PaddingSmall} tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Stack horizontal horizontalAlign={'space-between'} styles={globalStackStylesHeight100PaddingSmall}>
              <Text variant={'mediumPlus'}>{t('norms:TreeList.Filter.Header')}</Text>
              <Link onClick={handleFilterClear} underline>
                {t('norms:TreeList.Filter.Clear')}
              </Link>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { height: 400, width: 300, position: 'relative' } }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <Accordion
                disabled={globalFilterCount(appContext, GlobalFilterGroupKeys.standard) > 0}
                title={t('norms:TreeList.Filter.ISONorms', {
                  count: getSelectedFilterCount(FilterStandardsGroupKeys.standard),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {appContext.globalDataCache.norms.getItemsISO().map((norm: Norm) => (
                    <Checkbox
                      key={norm.isoNormId}
                      label={norm.name}
                      checked={props.selectedFilter.includes(
                        `${FilterStandardsGroupKeys.standard}${isoNormSearchPrepend}${norm.isoNormId}`,
                      )}
                      onChange={() =>
                        handleSelectChange(
                          `${FilterStandardsGroupKeys.standard}${isoNormSearchPrepend}${norm.isoNormId}`,
                        )
                      }
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion
                title={t('norms:TreeList.Filter.Owners', {
                  count: getSelectedFilterCount(FilterStandardsGroupKeys.Owner),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  <OwnerPicker
                    hideLabel={true}
                    selectedItemId={getUserFromFilter(props.selectedFilter)}
                    onSelect={(item) => {
                      let id: string | undefined = undefined;
                      if (item instanceof User) {
                        id = item.id;
                      } else if (item instanceof Role) {
                        id = item.roleId;
                      } else if (!item) {
                        return handleSelectChange(
                          FilterStandardsGroupKeys.Owner + getUserFromFilter(props.selectedFilter),
                        );
                      }

                      handleSelectChange(FilterStandardsGroupKeys.Owner + id);
                    }}
                  />
                </Stack>
              </Accordion>
              {hasUserFeature(appContext, FeatureTypes.RBAC) && (
                <Accordion
                  title={t('adminAuth:AuthSchemaPicker.Filter.Shared', {
                    count: getSelectedFilterCount(FilterStandardsGroupKeys.Shared),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                    <Checkbox
                      key={'everyone'}
                      label={t('adminAuth:AuthSchemaPicker.Placeholder')}
                      checked={props.selectedFilter.includes(FilterStandardsGroupKeys.Shared + 'everyone')}
                      onChange={() => {
                        handleSelectChange(FilterStandardsGroupKeys.Shared + 'everyone');
                      }}
                    />
                    <Separator styles={{ root: { height: 1 } }} />
                    {appContext.globalDataCache.authSchemas
                      .getItemsForMember(appContext.user.id)
                      .map((schema: AuthSchema) => (
                        <Checkbox
                          key={schema.authSchemaId}
                          label={schema.name}
                          checked={props.selectedFilter.includes(FilterStandardsGroupKeys.Shared + schema.authSchemaId)}
                          onChange={() => handleSelectChange(FilterStandardsGroupKeys.Shared + schema.authSchemaId)}
                        />
                      ))}
                  </Stack>
                </Accordion>
              )}
              <Accordion
                title={t('norms:TreeList.Filter.Scope', {
                  count: getSelectedFilterCount(FilterStandardsGroupKeys.Scope),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {getCoveredFilterList().map((item: ICheckbox) => (
                    <Checkbox
                      key={item.key}
                      label={item.label}
                      checked={props.selectedFilter.includes(FilterStandardsGroupKeys.Scope + item.value)}
                      onChange={() => handleSelectChange(FilterStandardsGroupKeys.Scope + item.value)}
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion
                title={t('norms:TreeList.Filter.Reasons', {
                  count: getSelectedFilterCount(FilterStandardsGroupKeys.Applicability),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {applicabilityFilterList.map((item: ICheckbox) => (
                    <Checkbox
                      key={item.key}
                      label={item.label}
                      checked={props.selectedFilter.includes(FilterStandardsGroupKeys.Applicability + item.value)}
                      onChange={() => handleSelectChange(FilterStandardsGroupKeys.Applicability + item.value)}
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion
                title={t('norms:TreeList.Filter.ImplementationStates', {
                  count: getSelectedFilterCount(FilterStandardsGroupKeys.EntityStatus),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {statuses.map((item: ICheckbox) => (
                    <Checkbox
                      key={item.key}
                      label={item.label}
                      onChange={() => {
                        handleSelectChange(FilterStandardsGroupKeys.EntityStatus + item.key);
                      }}
                      checked={props.selectedFilter.includes(FilterStandardsGroupKeys.EntityStatus + item.key)}
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion
                title={t('norms:TreeList.Filter.Tags', {
                  count: getSelectedFilterCount(FilterStandardsGroupKeys.Tag),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  <KeyValueTagPicker
                    maxTagWidth={240}
                    styles={{ root: { height: 200 } }}
                    selectedTags={getTagsFromFilter(props.selectedFilter)}
                    onAdd={(item: Tag) => handleSelectChange(FilterStandardsGroupKeys.Tag + item.tagId.toString())}
                    onRemove={(item: Tag) => handleSelectChange(FilterStandardsGroupKeys.Tag + item.tagId.toString())}
                  />
                </Stack>
              </Accordion>
            </ScrollablePane>
          </Stack.Item>
        </Stack>
      </Callout>
    </Stack>
  );
};

export default FilterNorm;
