import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';
import { Role } from 'models/auth/role';
import { apiGetRolesForCache } from 'services/Api/authService';
import { appRoles } from 'services/Auth/appRoles';
import { UserCache } from './userCache';

export class RoleCache extends EntityCache<Role> implements ICache<Role, RoleCache> {
  private isLoading: boolean = false;

  private rolesPromise: Promise<Role[]> | undefined;

  public userCache: UserCache | undefined;
  
  constructor() {
    super();
    this.getId = (role) => role.roleId;
    this.getEmptyItem = (id) => Role.getEmptyRole(id as string);
  }

  getForSystemId(systemRoleId: appRoles): Role | undefined {
    let role: Role | undefined;
    if (this.items) {
      role = this.items.find((i) => i.systemRoleId === systemRoleId);
    }

    return role;
  }

  async getItems(refresh: boolean = false): Promise<Role[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.rolesPromise = apiGetRolesForCache(accessToken, undefined);
          this.items = await this.rolesPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.rolesPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.rolesPromise) {
      return await this.rolesPromise;
    } else {
      return this.items;
    }
  }

  clone(): RoleCache {
    const roleCache: RoleCache = new RoleCache();
    roleCache.items = this.items?.map((g) => g.clone());
    roleCache.userCache = this.userCache;

    return roleCache;
  }
}
