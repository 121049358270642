import Tag from 'models/tag';
import { apiGetTags } from 'services/Api/tagService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';

export class TagCache extends EntityCache<Tag> implements ICache<Tag, TagCache> {
  private isLoading: boolean = false;

  private tagsPromise: Promise<Tag[]> | undefined;

  constructor() {
    super();
    this.getId = (tag) => tag.tagId;
    this.getEmptyItem = () => Tag.getEmptyTag();
  }

  getForValue(group: string, value: string): Tag | undefined {
    const groupLower = group.toLowerCase();
    const valueLower = value.toLowerCase();
    const tag = this.items?.find(
      (i) => i.tagName.toLowerCase() === groupLower && i.tagValue.toLowerCase() === valueLower,
    );

    return tag;
  }

  async getItems(refresh: boolean = false): Promise<Tag[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.tagsPromise = apiGetTags(accessToken);
          this.isLoading = true;
          this.items = await this.tagsPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.tagsPromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.tagsPromise) {
      return await this.tagsPromise;
    } else {
      return this.items;
    }
  }

  clone(): TagCache {
    const tagCache: TagCache = new TagCache();
    tagCache.items = this.items?.map((u) => u.clone());

    return tagCache;
  }
}
