import { SearchResultDTO } from 'models/dto/search/SearchResultDTO';
import { SearchResult } from 'models/search/SearchResult';
import { fromApiDateOptional } from 'utils/datetime';

export function mapFromSearchResults(results: SearchResultDTO[]): SearchResult[] {
  return results.map((result) => {
    return mapFromSearchResult(result);
  });
}

export function mapFromSearchResult(result: SearchResultDTO): SearchResult {
  const output = new SearchResult();
  output.id = result.id;
  output.rank = result.rank;
  output.code = result.code;
  output.name = result.name;
  output.entityType = result.entityType;
  output.entityId = result.entityId;
  output.snippet = result.snippet;
  output.modified = fromApiDateOptional(result.modified);
  output.spName = result.spName;
  output.spWebUrl = result.spWebUrl;
  output.spSnippet = result.spSnippet;
  output.spLinkId = result.spLinkId;
  output.spSiteId = result.spSiteId;
  output.spListId = result.spListId;
  output.spOdataType = result.spOdataType;
  output.tagIds = [...result.tagIds];
  output.normIds = [...result.normIds];
  output.searchResultType = result.searchResultType;
  output.ownerId = result.ownerId;
  output.ownerRoleId = result.ownerRoleId;
  output.assignmentId = result.assignmentId;
  
  return output;
}
