import { ApiErrorInfo } from 'models/apiErrorInfo';
import Task from './task';

export default class Tasks {
  tasks: Task[];

  errorInfo: { [id: number]: ApiErrorInfo };

  constructor() {
    this.tasks = [];
    this.errorInfo = {};
  }
}
