import { useContext } from 'react';
import {
  DetailsList,
  IColumn,
  Link,
  Persona,
  PersonaSize,
  SelectionMode,
  Separator,
  Stack,
  Text,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import { globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import AuditTrailEntry from 'models/auditTrailEntry';
import { useTranslation } from 'react-i18next';
import { toLocaleDateTime } from 'utils/datetime';
import { AuditTrailActions } from 'utils/auditTrailActions';
import AppContext from 'App/AppContext';
import CheckList from 'components/Checklist/Checklist';
import { getHtmlJSX } from 'utils/html';
import { TaskCheckList } from 'models/tasks/taskHelperClasses';
import { sortOnNumber } from 'utils/sorting';
import { getPermissionLabel } from 'components/Auth/AuthHelpers';

interface ISingleAuditProps {
  audit: AuditTrailEntry;
}

export interface AuditViewItem {
  name: string;
  value: string;
  auditTrailActionId: AuditTrailActions;
  property: string;
  isNewValue: boolean | undefined;
}

const SingleAudit = (props: ISingleAuditProps) => {
  const { t } = useTranslation(['translation', 'auditTrail', 'risk', 'control', 'library', 'adminAuth']);
  const appContext = useContext(AppContext);

  const onRenderValue = (item: AuditViewItem): JSX.Element => {
    if (!item || !item.value) return <p />;
    let output: JSX.Element = <p />;
    let isHTML: boolean = false;
    let isBig: boolean = false;

    try {
      switch (item.auditTrailActionId) {
        case AuditTrailActions.Risk_analysis_updated:
        case AuditTrailActions.Risk_description_updated:
        case AuditTrailActions.Risk_strategy_updated:
        case AuditTrailActions.Risk_asset_updated:
        case AuditTrailActions.Control_description_updated:
        case AuditTrailActions.Control_background_updated:
        case AuditTrailActions.Control_implementation_updated:
        case AuditTrailActions.Asset_description_updated:
        case AuditTrailActions.Process_description_updated:
        case AuditTrailActions.Objective_description_updated:
          output = <div style={{ borderWidth: '2px' }}>{getHtmlJSX(item.value)}</div>;
          isHTML = true;
          break;
        case AuditTrailActions.RolePermission_updated:
          output = (
            <div>
              <b>{appContext.globalDataCache.roles.get(item.property).name}</b>
              <ul>
                {item.value
                  .split(',')
                  .map((a) => Number(a))
                  .sort((a, b) => sortOnNumber(a, b))
                  .map((a) => (
                    <li key={a}>{getPermissionLabel(a, t)}</li>
                  ))}
              </ul>
            </div>
          );
          isBig = true;
          break;
        case AuditTrailActions.Task_checklist_updated:
          const checklist = new TaskCheckList();
          checklist.fromJSON(item.value);
          if (checklist.items.length > 0) {
            output = (
              <Stack styles={{ root: { height: 300, width: 300 } }}>
                <CheckList checkList={checklist} allowEdit={false} allowFill={false} updateCheckList={() => {}} />
              </Stack>
            );
            isBig = true;
          }
          break;
        default:
          output = (
            <Text>
              {AuditTrailEntry.translateValue(item.auditTrailActionId, item.value, t, appContext, item.property, item.isNewValue)}
            </Text>
          );
      }
    } catch {
      output = <Text>{item.value}</Text>;
    }

    const toolTipComponent = (
      <div
        style={{
          padding: '5px',
          borderRadius: '3px',
        }}
      >
        {output}
      </div>
    );

    return (
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          height: isHTML ? 40 : 20,
        }}
      >
        <TooltipHost content={toolTipComponent} delay={TooltipDelay.zero}>
          {isBig ? <Link>{t('auditTrail:SingleAudit.HoverToSeeMore')}</Link> : output}
        </TooltipHost>
      </div>
    );
  };

  const columns: IColumn[] = [
    {
      key: 'leftColumn',
      minWidth: 80,
      maxWidth: 80,
      name: '',
      onRender: (item?: AuditViewItem, index?: number, column?: IColumn) => {
        if (!item) return <p />;

        return (
          <Text variant="small" styles={globalTextStylesBold}>
            {item.name}
          </Text>
        );
      },
    },
    {
      key: 'rightColumn',
      minWidth: 120,
      maxWidth: 400,
      name: '',
      onRender: (item?: AuditViewItem, index?: number, column?: IColumn) => {
        if (item && index && index > 0) {
          return <TooltipHost content={item.property}>{onRenderValue(item)}</TooltipHost>;
        } else {
          return (
            <TooltipHost content={item?.property}>
              <Text>{item?.value}</Text>
            </TooltipHost>
          );
        }
      },
    },
  ];

  const items: AuditViewItem[] = [
    {
      name: t('auditTrail:SingleAudit.change'),
      value: props.audit.auditTrailAction?.name || '',
      auditTrailActionId: props.audit.auditTrailActionId,
      property: props.audit.property,
      isNewValue: undefined,
    },
    {
      name: t('auditTrail:SingleAudit.oldValue'),
      value: props.audit.oldValue,
      auditTrailActionId: props.audit.auditTrailActionId,
      property: props.audit.property,
      isNewValue: false,
    },
    {
      name: t('auditTrail:SingleAudit.newValue'),
      value: props.audit.newValue,
      auditTrailActionId: props.audit.auditTrailActionId,
      property: props.audit.property,
      isNewValue: true,
    },
  ];

  return (
    <Stack>
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <Stack.Item styles={{ root: { paddingLeft: 10 } }}>
          {props.audit.createdBy && (
            <Persona text={props.audit.createdBy.name} hidePersonaDetails={true} size={PersonaSize.size24} />
          )}
        </Stack.Item>
        <Stack.Item grow>
          <Stack>
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <Stack.Item>
                {props.audit.createdBy && (
                  <Text variant="small">
                    <strong>{props.audit.createdBy.name}</strong>
                  </Text>
                )}
              </Stack.Item>
              <Stack.Item>
                <Text variant="small">{toLocaleDateTime(props.audit.created)}</Text>
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
      <DetailsList compact items={items} isHeaderVisible={false} columns={columns} selectionMode={SelectionMode.none} />
      <Separator />
    </Stack>
  );
};

export default SingleAudit;
