import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
  Stack,
  ScrollablePane,
  ShimmeredDetailsList,
  ScrollbarVisibility,
  IColumn,
  SelectionMode,
  Text,
  Link,
  DetailsListLayoutMode,
  ConstrainMode,
  Persona,
  PersonaSize,
  TooltipHost,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Selection,
  IObjectWithKey,
} from '@fluentui/react';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import { getAuditStartForSetting } from 'utils/datetime';
import { rotateArrayLeft } from 'utils/array';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import AppContext from 'App/AppContext';
import { AuditYearStart } from 'models/setting';
import { TaskViewScheduleLevel } from './TasksView';
import Entity from 'models/entity';
import { useHistory } from 'react-router-dom';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import { RecurringPatternSummaryStyle, RecurringSummary } from 'components/RecurringPattern/RecurringSummary';
import { getEntityUrl } from 'utils/url';
import { TaskDateLinkWithHoverCard } from './TaskDateLinkWithHoverCard';
import Tag from 'models/tag';
import { sortOnDate, sortOnNumber, sortOnString, sortOnTaskTags } from 'utils/sorting';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import { TaskOverflowLinkWithHoverCard } from './TaskOverflowLinkWithHoverCard';
import { onRenderTaskContext } from './TaskRenderHelpers';
import { FilterTaskGroupKeys } from '../Filter/FilterTaskGroupKeys';

interface ITaskScheduleDetailsList {
  level: TaskViewScheduleLevel;
  year: number;
  tasks: Task[];
  tasksDisplay: Task[];
  isUpdating: boolean;
  isLoading: boolean;
  hideContext?: boolean;
  filter: string[];
  selectionMode?: SelectionMode;
  allowEditSeries?: boolean;
  onChangeSelection?: (selectedTasks: Task[]) => void;
  onTaskClick: (task: Task) => void;
  onTaskEditRecurringPattern?: (task: Task) => void;
  onTaskRecurringPatternUpdated?: (oldTask: Task, newTask: Task) => void;
  updateFilter?: (filter: string[]) => void;
  onUpdateTaskSeries?: (series: Task[]) => void;
  cacheKeySort: LocalStorageKeys;
}

export const TaskScheduleDetailsList = (props: ITaskScheduleDetailsList) => {
  //load common translation files upfront for context
  const { t } = useTranslation([
    'tasks',
    'control',
    'controls',
    'dateTimeComponent',
    'task',
    'asset',
    'risk',
    'control',
    'theme',
    'objective',
    'process',
  ]);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [seriesTasks, setSeriesTasks] = useState<Task[]>([]);
  const [instanceTasks, setInstanceTasks] = useState<Task[]>([]);
  const auditDateSetting: string = appContext.globalDataCache.settings.get(AuditYearStart);
  const startMonth = getAuditStartForSetting(props.year, auditDateSetting).getMonth();
  const [sortKey, setSortKey] = useState<string>('level');
  const [sortDesc, setSortDesc] = useState<boolean>(false);

  const selection = new Selection<Task>({
    canSelectItem: (item) => !item.completed,
    getKey: (item) => item.taskId.toString(),
    selectionMode: props.selectionMode,
    onSelectionChanged: () => {
      if (props.onChangeSelection) {
        const items = selection.getSelection();
        props.onChangeSelection(items);
      }
    },
  });

  //
  // Effects
  //
  useEffect(() => {
    //load sorting from local storage and save to state
    const newSort = loadSortFromLocalStorage();
    const newSortKey = newSort[0];
    const newSortDesc = newSort[1] === '1';
    setSortKey(newSortKey);
    setSortDesc(newSortDesc);

    //create columns based on the task instances
    const instanceTasks = getTaskForColumns(props.tasks);
    const newColumns = getColumns(
      props.tasks,
      instanceTasks,
      props.filter,
      props.year,
      props.level,
      props.isLoading,
      newSortKey,
      newSortDesc,
      props.hideContext,
    );

    //for performance, just return when no level columns are generated yet
    if (newColumns.length <= 4) return;

    //get main tasks for the list and sort them
    const seriesTasks = getTaskForRows(props.tasksDisplay);
    const sortedSeriesTasks = copyAndSort(seriesTasks, instanceTasks, newSortKey, newSortDesc);

    setColumns(newColumns);
    setInstanceTasks(instanceTasks);
    setSeriesTasks(sortedSeriesTasks);
    if (props.onUpdateTaskSeries) props.onUpdateTaskSeries(sortedSeriesTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tasksDisplay, props.filter, props.year, props.level, props.hideContext, props.isLoading, props.isUpdating]);

  //
  // Planning
  //
  const getPlanningRotation = (startMonth: number, level: TaskViewScheduleLevel): number => {
    if (level === TaskViewScheduleLevel.Month) {
      return startMonth;
    } else if (level === TaskViewScheduleLevel.Quarter) {
      return Math.floor(startMonth / level);
    } else {
      return 0;
    }
  };

  const planningRotation = getPlanningRotation(startMonth, props.level);

  //
  // Tasks
  //
  const getTaskForRows = (tasks: Task[]): Task[] => {
    //return the series tasks and non-recurring tasks
    return tasks.filter((t) => t.isNonRecurring() || t.isSeries());
  };

  const getTaskForColumns = (tasks: Task[]): Task[] => {
    //return the instance tasks and non-recurring tasks
    return tasks.filter((t) => t.isNonRecurring() || t.isInstance());
  };

  //
  // Helpers
  //
  const onEntityClick = (item?: Entity) => {
    if (!item) {
      return;
    }

    const url = getEntityUrl(item);
    history.push(url);
  };

  const onTaskEditRecurringPattern = (item: Task) => {
    if (props.onTaskEditRecurringPattern) {
      props.onTaskEditRecurringPattern(item);
    }
  };

  const setRecurringPatternYears = (task: Task, year: number, checked: boolean) => {
    const newTask = task.clone();
    newTask.recurringPattern.setYearSelect(year, checked);
    if (props.onTaskRecurringPatternUpdated) props.onTaskRecurringPatternUpdated(task, newTask);
  };

  const setTagFilter = (tag: Tag, selectedFilter: string[]) => {
    let newFilter = [...selectedFilter];
    const filterId = FilterTaskGroupKeys.tag + tag.tagId;
    if (newFilter.indexOf(filterId) >= 0) {
      newFilter = newFilter.filter((f) => f !== filterId);
    } else {
      newFilter.push(filterId);
    }

    if (props.updateFilter) {
      props.updateFilter(newFilter);
    }
  };

  //
  // Columns
  //
  const getColumns = (
    tasks: Task[],
    instanceTasks: Task[],
    filter: string[],
    year: number,
    level: TaskViewScheduleLevel,
    isLoading: boolean,
    sortKey: string,
    sortDesc: boolean,
    hideContext?: boolean,
  ): IColumn[] => {
    const allColumns: IColumn[] = [
      {
        key: 'assignee',
        name: '@',
        minWidth: 28,
        maxWidth: 28,
        isSorted: sortKey === 'assignee',
        isSortedDescending: sortKey === 'assignee' ? sortDesc : false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return;

          return (
            <TooltipHost content={item.user?.name}>
              <Persona text={item.user?.name} size={PersonaSize.size24} hidePersonaDetails={true} />
            </TooltipHost>
          );
        },
      },
      {
        key: 'name',
        name: t('tasks:TaskView.Schedule.Columns.TaskName'),
        minWidth: 100,
        maxWidth: 300,
        isMultiline: true,
        isResizable: true,
        isSorted: sortKey === 'name',
        isSortedDescending: sortKey === 'name' ? sortDesc : false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return;
          let seriesElement: JSX.Element;

          if (props.allowEditSeries && !item.completed) {
            seriesElement = (
              <Text>
                <Link
                  underline
                  onClick={() => {
                    props.onTaskClick(item);
                  }}
                >
                  {item.name}
                </Link>
              </Text>
            );
          } else if (item.completed) {
            let name = item.name;
            if (item.completed) {
              name = name + ' (' + t('tasks:TaskView.Schedule.InactiveState') + ')';
            }
            seriesElement = <Text>{name}</Text>;
          } else {
            seriesElement = <Text>{item.name}</Text>;
          }

          return seriesElement;
        },
      },
      {
        key: 'tags',
        name: t('tasks:TaskView.Schedule.Columns.Tags'),
        minWidth: 80,
        maxWidth: 200,
        isResizable: true,
        isSorted: sortKey === 'tags',
        isSortedDescending: sortKey === 'tags' ? sortDesc : false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return;

          return (
            <KeyValueTags
              compact
              tags={appContext.globalDataCache.tags.getItemsForId(item.tagIds)}
              onClick={(tag) => setTagFilter(tag, filter)}
            />
          );
        },
      },
      {
        key: 'recurringPattern',
        name: t('tasks:TaskView.Schedule.Columns.RecurringPattern'),
        minWidth: 170,
        maxWidth: 170,
        isMultiline: true,
        isResizable: true,
        
        isSorted: false,
        isSortedDescending: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return;

          return (
            <Fragment>
              {(!props.onTaskEditRecurringPattern || item.completed) && (
                <RecurringSummary
                  summaryStyle={RecurringPatternSummaryStyle.text}
                  pattern={item.recurringPattern}
                  variant="small"
                />
              )}
              {props.onTaskEditRecurringPattern && !item.completed && (
                <RecurringSummary
                  summaryStyle={RecurringPatternSummaryStyle.button}
                  pattern={item.recurringPattern}
                  onClick={() => onTaskEditRecurringPattern(item)}
                  onChange={(year, number) => setRecurringPatternYears(item, year, number)}
                  buttonStyles={{ root: { width: 130 } }}
                />
              )}
            </Fragment>
          );
        },
      },
      {
        key: 'context',
        name: t('tasks:TaskView.Schedule.Columns.Context'),
        minWidth: hideContext ? 1 : 100,
        maxWidth: hideContext ? 1 : 100,
        isMultiline: true,
        isResizable: true,
        isCollapsible: false,
        isSorted: false,
        isSortedDescending: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item || hideContext) return;
          let entityCount: number;
          const entity: Entity | undefined = undefined;

          entityCount = item.riskIds.length;
          entityCount += item.controlIds.length;
          entityCount += item.processIds.length;
          entityCount += item.objectiveIds.length;
          entityCount += item.assetIds.length;

          if (entityCount > 0) {
            const plainCardProps: IPlainCardProps = {
              onRenderPlainCard: onRenderTaskContext,
              renderData: item,
            };

            return (
              <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps} instantOpenOnClick={true}>
                <Text variant="small">
                  <Link
                    underline
                    onClick={() => {
                      onEntityClick(entity);
                    }}
                  >
                    {t('translation:General.List.ItemCount', { count: entityCount })}
                  </Link>
                </Text>
              </HoverCard>
            );
          } else {
            return null;
          }
        },
      },
    ];

    if (!isLoading) {
      //render all periode columns
      const periodColumns: IColumn[] = getPeriodColumns(level, year);
      allColumns.push(...periodColumns);
    }

    return allColumns;
  };

  //
  // Period columns render
  // within callback with all dependend helper functions
  //
  const getPeriodColumns = useCallback(
    (level: number, year: number): IColumn[] => {
      const isInPeriod = (
        year: number,
        yearOrQuarterOrMonthNumber: number,
        level: TaskViewScheduleLevel,
        date: Date,
      ): boolean => {
        const lowerLevelMonth = (yearOrQuarterOrMonthNumber - 1) * level;
        const upperLevelMonth = yearOrQuarterOrMonthNumber * level;

        if (lowerLevelMonth === planningRotation * level) {
          return date.getFullYear() === year && date.getMonth() >= lowerLevelMonth && date.getMonth() < upperLevelMonth;
        } else {
          return date.getMonth() >= lowerLevelMonth && date.getMonth() < upperLevelMonth;
        }
      };

      const getTasksForPeriod = (
        year: number,
        yearOrQuarterOrMonthNumber: number,
        level: number,
        task: Task,
      ): Task[] => {
        //when the input task is non-recurring, return that when it's in the period
        if (task.isNonRecurring() && isInPeriod(year, yearOrQuarterOrMonthNumber, level, task.startDateTime)) {
          return [task];
        }

        //get the serie instances in the period
        let instances = props.tasksDisplay.filter((_task) => {
          return _task.taskMasterId === task.taskId;
        });

        instances = instances.filter((_task) => {
          return isInPeriod(year, yearOrQuarterOrMonthNumber, level, _task.startDateTime);
        });

        return instances.sort((a, b) => {
          const dateA: Date = a.startDateTime;
          const dateB: Date = b.startDateTime;

          return dateA > dateB ? 1 : -1;
        });
      };

      const monthsNames = [
        t('dateTimeComponent:MonthsShort.Jan'),
        t('dateTimeComponent:MonthsShort.Feb'),
        t('dateTimeComponent:MonthsShort.Mar'),
        t('dateTimeComponent:MonthsShort.Apr'),
        t('dateTimeComponent:MonthsShort.May'),
        t('dateTimeComponent:MonthsShort.Jun'),
        t('dateTimeComponent:MonthsShort.Jul'),
        t('dateTimeComponent:MonthsShort.Aug'),
        t('dateTimeComponent:MonthsShort.Sep'),
        t('dateTimeComponent:MonthsShort.Oct'),
        t('dateTimeComponent:MonthsShort.Nov'),
        t('dateTimeComponent:MonthsShort.Dec'),
      ];

      const getPeriodColumnName = (year: number, level: TaskViewScheduleLevel, rotation: number): string => {
        switch (level) {
          case TaskViewScheduleLevel.Month:
            return monthsNames[rotation];
          case TaskViewScheduleLevel.Quarter:
            return t('tasks:TaskView.Schedule.Columns.Quarter', { quarter: rotation + 1 });
          case TaskViewScheduleLevel.Year:
            return t('tasks:TaskView.Schedule.Columns.Year', { year: year });
        }
      };

      const renderPeriodColumn = (year: number, level: TaskViewScheduleLevel, i: number, item?: Task) => {
        if (!item) return;
        const tasks = getTasksForPeriod(year, i + 1, level, item);
        if (tasks.length === 0) return;
        const hasOverflow = tasks.length > 4;

        return (
          <Stack tokens={globalStackTokensGapExtraSmall}>
            {tasks.slice(0, 4 - (hasOverflow ? 1 : 0)).map((task: Task) => {
              return (
                <TaskDateLinkWithHoverCard
                  key={'TaskDateLinkWithHoverCard' + task.taskId.toString()}
                  task={task}
                  level={level}
                  onTaskClick={props.onTaskClick}
                />
              );
            })}
            {hasOverflow && <TaskOverflowLinkWithHoverCard tasks={tasks.slice(3)} onTaskClick={props.onTaskClick} />}
          </Stack>
        );
      };

      let periodColumns: IColumn[] = [];

      periodColumns = rotateArrayLeft<IColumn>(
        planningRotation,
        Array.from({ length: Math.floor(12 / level) }, (_, i) => {
          const output: IColumn = {
            key: `level ${i + 1}`,
            name: getPeriodColumnName(year, level, i),
            minWidth: level === 1 ? 35 : 70,
            maxWidth: level === 1 ? 35 : 70,
            isMultiline: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item?: Task, index?: number, column?: IColumn) => {
              return renderPeriodColumn(year, level, i, item);
            },
          };

          return output;
        }),
      );

      //set a fixed key on the first column for sorting identification
      periodColumns[0].key = 'level';
      periodColumns[0].isSorted = sortKey === 'level';
      periodColumns[0].isSortedDescending = sortKey === 'level' ? sortDesc : false;

      //render extra column for the first period in the next year
      periodColumns.push({
        key: 'level 0',
        name: getPeriodColumnName(year + 1, level, planningRotation),
        minWidth: level === 1 ? 60 : 100,
        maxWidth: level === 1 ? 60 : 100,
        isMultiline: true,
        isSorted: false,
        isSortedDescending: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          return renderPeriodColumn(year + 1, level, planningRotation, item);
        },
      });

      return periodColumns;
    },
    [planningRotation, props, sortDesc, sortKey, t],
  );

  const [columns, setColumns] = useState<IColumn[]>(
    getColumns(
      props.tasks,
      instanceTasks,
      props.filter,
      props.year,
      props.level,
      props.isLoading,
      sortKey,
      sortDesc,
      props.hideContext,
    ),
  );

  //
  // Sorting
  //
  const loadSortFromLocalStorage = (): string[] => {
    let sort: string[] = ['level', '1'];

    if (props.cacheKeySort) {
      const storedSort = getLocalStorageData(appContext, props.cacheKeySort);
      if (storedSort) {
        try {
          sort = storedSort.split(',');
        } catch {
          //ignore
        }
      }
    }

    return sort;
  };

  const saveSortToLocalStorage = (key: string, descending: boolean) => {
    if (props.cacheKeySort) {
      const values = [key, descending ? '1' : '0'];
      setLocalStorageData(appContext, props.cacheKeySort, values.join(','));
    }
  };

  const onColumnClick = (ev: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column?.key === currCol.key)[0];
    let desc: boolean = false;

    if (currColumn.key.startsWith('level')) {
      desc = !sortDesc;
      let firstCol: string = '';

      for (const newCol of newColumns) {
        if (!firstCol && newCol.key.startsWith('level')) {
          newCol.isSortedDescending = desc;
          newCol.isSorted = true;
          firstCol = newCol.key;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = false;
        }
      }
      setSortKey(firstCol);
    } else {
      for (const newCol of newColumns) {
        if (newCol === currColumn) {
          newCol.isSortedDescending = !newCol.isSortedDescending;
          newCol.isSorted = true;
          desc = newCol.isSortedDescending;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = false;
        }
      }
      setSortKey(currColumn.key);
    }

    const newItems = copyAndSort(seriesTasks, instanceTasks, currColumn.key, desc);

    setSortDesc(desc);
    setColumns(newColumns);
    saveSortToLocalStorage(currColumn.key, desc);

    setSeriesTasks(newItems);
    if (props.onUpdateTaskSeries) props.onUpdateTaskSeries(newItems);
  };

  const copyAndSort = (
    seriesTasks: Task[],
    instanceTasks: Task[],
    columnKey: string,
    isSortedDescending?: boolean,
  ): Task[] => {
    if (columnKey === 'name') {
      return seriesTasks.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnString(a.name, b.name);
      });
    } else if (columnKey === 'assignee') {
      return seriesTasks.slice(0).sort((a: Task, b: Task) => {
        const u1 = appContext.globalDataCache.users.get(a.userId);
        const u2 = appContext.globalDataCache.users.get(b.userId);

        return (isSortedDescending ? -1 : 1) * sortOnString(u1.name, u2.name);
      });
    } else if (columnKey === 'tags') {
      return seriesTasks.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnTaskTags(a, b);
      });
    } else if (columnKey.startsWith('level')) {
      return seriesTasks.slice(0).sort((a: Task, b: Task) => {
        const instancesA = instanceTasks
          .filter((t) => t.taskMasterId === a.taskId && !t.completed)
          .sort((a, b) => (isSortedDescending ? -1 : 1) * sortOnDate(a.startDateTime, b.startDateTime));
        const instancesB = instanceTasks
          .filter((t) => t.taskMasterId === b.taskId && !t.completed)
          .sort((a, b) => (isSortedDescending ? -1 : 1) * sortOnDate(a.startDateTime, b.startDateTime));

        const taskA: Task | undefined = instancesA[0];
        const taskB: Task | undefined = instancesB[0];

        if (taskA && taskB) {
          return (isSortedDescending ? -1 : 1) * sortOnDate(taskA.startDateTime, taskB.startDateTime);
        } else if (!taskA) {
          return 1;
        } else {
          return -1;
        }
      });
    } else if (columnKey.startsWith('context')) {
      return seriesTasks.slice(0).sort((a: Task, b: Task) => {
        let entityCount1 = a.riskIds.length;
        entityCount1 += a.controlIds.length;
        entityCount1 += a.processIds.length;
        entityCount1 += a.objectiveIds.length;
        entityCount1 += a.assetIds.length;

        let entityCount2 = b.riskIds.length;
        entityCount2 += b.controlIds.length;
        entityCount2 += b.processIds.length;
        entityCount2 += b.objectiveIds.length;
        entityCount2 += b.assetIds.length;

        return (isSortedDescending ? -1 : 1) * sortOnNumber(entityCount1, entityCount2);
      });
    }

    return seriesTasks;
  };

  //
  // Main render
  //
  if (!props.isLoading && seriesTasks?.length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('translation:General.Notifications.NoItemsFilter')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <Stack.Item grow styles={{ root: { position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            enableShimmer={props.isLoading}
            items={seriesTasks}
            columns={columns}
            selectionMode={props.selectionMode || SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            selection={selection as Selection<IObjectWithKey>}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            onColumnHeaderClick={onColumnClick}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
