import { ReactNode, useContext } from 'react';
import { Text, Image, IImageProps, ImageFit, TooltipHost, IconButton , Stack } from '@fluentui/react';

import Config from 'services/Config/configService';
import ScenePivot, { IScenePivotItemProps } from 'components/SceneBar/ScenePivot';
import {
  globalStackItemStylesPaddingScene,
  globalStackTokensGapSmall,
  globalPaddingScene,
  backIcon,
  globalTextStylesBold,
} from 'globalStyles';
import PinnedLinkTabs from 'components/Links/PinnedLinkTabs';
import ResourceLink from 'models/resourceLink';
import CopyLink from 'components/Utils/CopyLink';
import { globalNavBarHeight } from 'globalConstants';
import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface ISceneBarProps {
  title: string;
  subtitle: string;
  image: string;
  pivotItems: IScenePivotItemProps[] | undefined;
  selectedPivotKey: string | undefined;
  pinnedLinks?: ResourceLink[];
  selectedPinnedLink?: ResourceLink;
  onSelectPivot?: (item: IScenePivotItemProps) => void;
  onSelectPinnedLink?: (link: ResourceLink) => void;
  linkUrl?: string;
  showBackButton?: boolean;
  backButtonUrl?: string;
  children?: ReactNode;
}

const SceneBar = (props: ISceneBarProps) => {
  const { t } = useTranslation(['translation', 'norms']);
  const appContext = useContext(AppContext);
  const history = useHistory();

  const imagePropsSceneTitle: IImageProps = {
    src: `${Config.getImageURL()}/${props.image}`,
    imageFit: ImageFit.centerContain,
    width: globalNavBarHeight,
    height: globalNavBarHeight,
  };

  return (
    <Stack horizontal wrap verticalAlign="center" styles={{ root: { minHeight: 64 } }}>
      <Stack.Item>
        <Stack
          horizontal
          verticalAlign="center"
          styles={globalStackItemStylesPaddingScene}
          tokens={globalStackTokensGapSmall}
        >
          <Stack.Item>
            <Image {...imagePropsSceneTitle}></Image>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal verticalAlign="center">
              {props.showBackButton && (
                <Stack.Item>
                  <TooltipHost content={t('translation:NavMain.GoBack')}>
                    <IconButton
                      iconProps={backIcon}
                      onClick={() => {
                        if (props.backButtonUrl) {
                          history.push(props.backButtonUrl);
                        }
                      }}
                    />
                  </TooltipHost>
                </Stack.Item>
              )}
              <Text styles={globalTextStylesBold} block nowrap variant="large">
                {props.title}
              </Text>
            </Stack>
            {!appContext.isMobileView && (
              <Stack
                horizontal
                tokens={globalStackTokensGapSmall}
                styles={{ root: { paddingLeft: props.showBackButton ? 8 : undefined } }}
              >
                <Stack.Item>
                  <Text block variant="small">
                    {props.subtitle}
                  </Text>
                </Stack.Item>
                {props.linkUrl && (
                  <Stack.Item>
                    <CopyLink url={props.linkUrl}></CopyLink>
                  </Stack.Item>
                )}
              </Stack>
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {props.pivotItems && (
        <Stack.Item grow={1}>
          <ScenePivot
            items={props.pivotItems}
            selectedKey={props.selectedPivotKey}
            onSelectPivot={props.onSelectPivot}
          />
        </Stack.Item>
      )}
      {props.pinnedLinks && props.onSelectPinnedLink && (
        <Stack.Item grow>
          <PinnedLinkTabs
            links={props.pinnedLinks}
            selectedLink={props.selectedPinnedLink}
            onClick={props.onSelectPinnedLink}
          ></PinnedLinkTabs>
        </Stack.Item>
      )}
      {props.children && (
        <Stack.Item grow={5}>
          <Stack horizontal tokens={globalStackTokensGapSmall} styles={{ root: { paddingLeft: globalPaddingScene } }}>
            {props.children}
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default SceneBar;
