import { useContext, useEffect, useState } from 'react';
import { CommandBar, ICommandBarItemProps, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { cancelIcon, globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { ResourceListType } from 'models/resourceList';
import { IListItem, ISitePage, ITextWebPart } from 'services/Graph/SharepointInterfaces';
import { graphGetCanvasValidWebParts } from 'services/Graph/graphServicePage';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import parse, { domToReact, HTMLReactParserOptions, Element } from 'html-react-parser';
import 'components/SharePoint/SharePointPageRender.css';
import { LibraryOpenInModal } from 'models/setting';
import logger from 'services/Logging/logService';
import { ILinkPreviewProps, getPageHeaderDoc, getPageHeaderPage } from './LinkPreview';
import {
  GetContentFileInfo,
  GetContentListColumnDef,
  GetContentListItem,
  GetContentListItems,
  GetContentPage,
} from 'services/Wrappers/contentService';
import { apiRequest } from 'services/Auth/authConfig';
import MyListItems from 'components/SharePoint/MyListItems';
import { ColumnDefinition } from 'microsoft-graph';

export const LinkPreviewVirtual = (props: ILinkPreviewProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['library']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [webUrl, setWebUrl] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<ISitePage | undefined>(undefined);
  const [pageContent, setPageContent] = useState<JSX.Element[] | undefined>(undefined);
  const [columns, setColumns] = useState<ColumnDefinition[]>([]);
  const [items, setItems] = useState<IListItem[]>([]);

  useEffect(() => {
    if (props.link) {
      getData();
    } else {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.link]);

  useEffect(() => {
    //load the page content when the page changes
    try {
      if (page) {
        const pageContent = getPageContent(page);
        setPageContent(pageContent);
      } else {
        setPageContent(undefined);
      }
    } catch (err) {
      appContext.setError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getCommandBarItems = (): ICommandBarItemProps[] => {
    const items: ICommandBarItemProps[] = [];

    const libraryOpenInModal = appContext.globalDataCache.settings.get(LibraryOpenInModal) as boolean;
    if (libraryOpenInModal) return [];

    if (props.onClose) {
      items.push({
        key: 'close',
        disabled: false,
        text: t('library:PinnedLinkTabs.Commands.Close'),
        iconProps: cancelIcon,
        onClick: () => {
          if (props.onClose) props.onClose();
        },
      });
    }

    if (props.extraCommandBarButtons) {
      items.push(...props.extraCommandBarButtons);
    }

    return items;
  };

  const clear = () => {
    setPage(undefined);
    setPageContent(undefined);
  };

  const needViewer = (mimeType: string): boolean => {
    if (mimeType.includes('ms-powerpoint')) {
      return true;
    } else if (mimeType.includes('ms-excel')) {
      return true;
    } else if (mimeType.includes('ms-word')) {
      return true;
    } else if (mimeType.includes('openxmlformats-officedocument')) {
      return true;
    }

    return false;
  };

  const getData = async () => {
    try {
      if (!props.link) return;
      setIsLoading(true);
      clear();

      logger.debug('Getting link preview data for ' + props.link.linkName);

      if (props.link.list.listType === ResourceListType.SitePageLibrary) {
        const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
        const page = await GetContentPage(props.link, accessToken);
        setPage(page);
      } else if (props.link.list.listType === ResourceListType.DocumentLibrary) {
        const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
        const fileInfo = await GetContentFileInfo(props.link, accessToken);
        if (fileInfo) {
          if (needViewer(fileInfo.mimeType)) {
            const embedUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileInfo.url)}`;
            setWebUrl(embedUrl);
          } else {
            setWebUrl(fileInfo.url);
          }
        }
      } else if (props.link.list.listType === ResourceListType.CustomList) {
        const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
        const columns = await GetContentListColumnDef(props.link.list, accessToken);
        setColumns(columns);
        if (props.link.isWholeList()) {
          const items = await GetContentListItems(props.link.list, accessToken);
          setItems([...items]);
        } else {
          const item = await GetContentListItem(props.link, accessToken);
          setItems([item]);
        }
      } else if (props.link.list.listType === ResourceListType.WebURL) {
        const webUrl: string = props.link.list.webURL || '';
        if (webUrl) setWebUrl(webUrl);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getPageContent = (page: ISitePage | undefined): JSX.Element[] | undefined => {
    if (!page) return undefined;
    const content: JSX.Element[] = [];

    content.push(getPageHeaderPage('header', page.title ?? page.name, undefined, appContext.useDarkMode));

    const webparts = graphGetCanvasValidWebParts(page);
    webparts.forEach((item: ITextWebPart, index: number) => {
      const contentItem = parseWebpart(item);
      if (contentItem) {
        content.push(
          <Stack.Item key={index} styles={{ root: { fontSize: 16, paddingLeft: 20 } }}>
            {contentItem}
          </Stack.Item>,
        );
      } else {
        //ignore invalid web parts
      }
    });

    return content;
  };

  const parseWebpart = (item: ITextWebPart): string | JSX.Element | JSX.Element[] | null => {
    let output: string | JSX.Element | JSX.Element[] | null;

    try {
      const htmlString = item.innerHtml;
      output = parse(htmlString, options);
    } catch {
      output = null;
    }

    return output;
  };

  //Replace all links <a> with <span> when they point to SharePoint or are invalid
  //Also remove any url query params to prevent logging in the wrong tenant/OU
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      const node = domNode as Element;
      if (node.name === 'a') {
        let url = node.attribs['data-cke-saved-href'] || node.attribs.href;
        const partRef = 'sharepoint.com';
        //disable all invalid URL's and URL's to SharePoint
        if (url && url.startsWith('https:') && !url.includes(partRef)) {
          //remove any params
          const urlParamIdx = url.indexOf('?');
          if (urlParamIdx >= 0) {
            url = url.substring(0, urlParamIdx);
          }

          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {domToReact(node.children, options)}
            </a>
          );
        } else {
          return <span>{domToReact(node.children, options)}</span>;
        }
      }
    },
  };

  const getCommandBar = () => {
    if (props.hideCommandbar) return null;
    const items = getCommandBarItems();
    if (items.length === 0) return null;

    return (
      <Stack.Item>
        <CommandBar items={getCommandBarItems()}></CommandBar>
      </Stack.Item>
    );
  };

  //
  // Main render
  //
  if (!props.link) return null;

  //
  // For links of type 'whole list' render a file picker or list item picker in view-mode
  //
  if (props.link.isWholeList()) {
    switch (props.link.list.listType) {
      case ResourceListType.CustomList:
        return (
          <Stack verticalFill tokens={globalStackTokensGapMedium}>
            {getCommandBar()}
            <MyListItems
              items={items}
              columns={columns}
              loading={isLoading}
              onSelect={(_items: IListItem[]) => {}}
              viewMode={true}
              existingLinks={[]}
              linkToEdit={props.link}
            />
          </Stack>
        );
      default:
        //cannot render preview for other types
        return null;
    }
  }

  //
  // For custom list items, render the list with item filter
  //
  if (props.link.list.listType === ResourceListType.CustomList) {
    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        {getCommandBar()}
        <MyListItems
          items={items}
          columns={columns}
          loading={isLoading}
          onSelect={(_items: IListItem[]) => {}}
          viewMode={true}
          existingLinks={[]}
          linkToEdit={props.link}
        />
      </Stack>
    );
  }

  //
  // For other individual items, render the Embed-url in an iframe
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      {getCommandBar()}
      {isLoading && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
      {!isLoading && !pageContent && !webUrl && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Text>{t('translation:General.Notifications.NotAvailable')}</Text>
        </Stack>
      )}
      {!isLoading && pageContent && (
        <Stack verticalFill tokens={globalStackTokensGapSmall}>
          <ScrollableStackItem>{pageContent}</ScrollableStackItem>
        </Stack>
      )}
      {!isLoading && !pageContent && webUrl && (
        <Stack verticalFill>
          {props.link.list.listType === ResourceListType.DocumentLibrary &&
            getPageHeaderDoc(props.link.linkId.toString(), props.link.linkName, undefined, appContext.useDarkMode)}
          <Stack.Item grow>
            <iframe
              title={props.link.linkName}
              width="100%"
              height="95%"
              allowFullScreen={true}
              referrerPolicy="no-referrer"
              style={{ border: 'none' }}
              src={webUrl}
            ></iframe>
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};
