import { useContext, useEffect, useState } from 'react';
import {
  CommandBar,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  IconButton,
  Separator,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { graphSharepointLibraryRequest } from 'services/Auth/authConfig';
import {
  globalStackStylesHeight100,
  globalStackTokensGapSmall,
  newIcon,
  refreshIcon,
  sharepointIcon,
} from 'globalStyles';
import { useTranslation } from 'react-i18next';
import {
  graphCreateNewFolderInDrive,
  graphGetChildrenForDriveItem,
  graphGetDrive,
  graphGetDriveItem,
  graphGetDriveRoot,
  graphGetDrivesForSite,
} from 'services/Graph/graphService';
import MyDrives from './MyDrives';
import MyDriveItems from './MyDriveItems';
import DirectoryParents from './DirectoryParents';
import MySites, { MySiteAuthTypes } from 'components/SharePoint/MySites';
import ModifyTextDialog from 'components/Dialogs/ModifyTextDialog';
import { IDrive, IDriveItem, ISite } from 'services/Graph/SharepointInterfaces';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import AppError from 'utils/appError';
import WarningMessage from 'components/Notification/WarningMessage';
import { navigateToExternalUrl } from 'utils/url';
import ResourceLink from 'models/resourceLink';

export interface ParentFolderInformation {
  tenantId?: string;
  driveId: string;
  driveItemId: string;
}

interface IDirectoryExplorer {
  onSelectItems: (items?: IDriveItem[]) => void;
  invalidAuthToken: () => void;
  parentFolder?: ParentFolderInformation;
  viewMode?: boolean;
  existingLinks?: ResourceLink[];
  linkToEdit?: ResourceLink;
  singleSelect?: boolean;
}

const DirectoryExplorer = (props: IDirectoryExplorer) => {
  const { t } = useTranslation(['translation', 'sharepoint']);
  const appContext = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [spError, setSPError] = useState<string | undefined>(undefined);
  const [newFolderName, setNewFolderName] = useState<string | undefined>(undefined);
  const [showNewFolderDialog, setShowNewFolderDialog] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<ISite | undefined>(undefined);
  const [drives, setDrives] = useState<IDriveItem[]>([]);
  const [selectedDrive, setSelectedDrive] = useState<IDrive | undefined>(undefined);
  const [selectedDriveItem, setSelectedDriveItem] = useState<IDriveItem | undefined>(undefined);
  const [driveItems, setDriveItems] = useState<IDriveItem[]>([]);
  const [parents, setParents] = useState<IDriveItem[]>([]);
  const [parentInfo, setParentInfo] = useState<ParentFolderInformation | undefined>(undefined);

  //
  // Effects
  //

  useEffect(() => {
    if (props.parentFolder) {
      //changing the parentFolder prop triggers a reload: check whether this is not only a new object but has also different content
      if (
        props.parentFolder.driveId !== parentInfo?.driveId ||
        props.parentFolder.driveItemId !== parentInfo?.driveItemId ||
        props.parentFolder.tenantId !== parentInfo?.tenantId
      ) {
        setParentInfo(props.parentFolder);
        loadParentInformation(props.parentFolder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentFolder]);

  useEffect(() => {
    fetchDrives(selectedSite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite]);

  useEffect(() => {
    if (selectedDrive) {
      if (!selectedDriveItem) {
        fetchDriveItemsForDrive(selectedDrive);
      } else {
        fetchDriveItemsForDriveItem(selectedDrive, selectedDriveItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrive, selectedDriveItem]);

  //
  // Helpers
  //
  const checkToken = (token: string): boolean => {
    if (!token) {
      props.invalidAuthToken();
      appContext.showNotification(t('translation:General.Notifications.TryAgainAfterConsent'));

      return false;
    }

    return true;
  };

  const loadParentInformation = async (parentInfo: ParentFolderInformation) => {
    if (!parentInfo) return;

    try {
      setIsLoading(true);
      setSPError(undefined);
      clear();

      const graphInterface = await appContext.getGraphInterface(
        graphSharepointLibraryRequest.scopes,
        parentInfo.tenantId,
      );

      if (!checkToken(graphInterface.accessToken)) return;
      const parentDrive = await graphGetDrive(graphInterface.client, parentInfo.driveId);

      const parentDriveItem: IDriveItem | undefined = await graphGetDriveItem(
        graphInterface.client,
        parentInfo.driveId,
        parentInfo.driveItemId,
      );

      if (parentDriveItem) {
        parentDriveItem.tenantId = parentInfo?.tenantId;
        setParents([parentDriveItem]);
      } else {
        setParents([]);
      }

      setSelectedDriveItem(parentDriveItem);
      setSelectedDrive(parentDrive);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDrives = async (site: ISite | undefined) => {
    if (site === undefined || !site.id) return;
    try {
      setIsLoading(true);
      setSPError(undefined);
      clear();

      const graphInterface = await appContext.getGraphInterface(graphSharepointLibraryRequest.scopes, site.tenantId);
      if (!checkToken(graphInterface.accessToken)) return;
      const drives: IDriveItem[] = await graphGetDrivesForSite(graphInterface.client, site.id);
      if (drives) {
        drives.forEach((d) => (d.tenantId = site.tenantId));
      }

      setDrives(drives);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDriveItemsForDrive = async (drive: IDrive | undefined) => {
    if (drive === undefined || drive.id === undefined) return;
    //not a drive and has no parent reference

    try {
      setIsLoading(true);
      setSPError(undefined);

      const graphInterface = await appContext.getGraphInterface(graphSharepointLibraryRequest.scopes, drive.tenantId);
      if (!checkToken(graphInterface.accessToken)) return;

      //get the root of the drive
      const _driveRoot: IDriveItem = await graphGetDriveRoot(graphInterface.client, drive.id);
      if (_driveRoot) {
        _driveRoot.tenantId = drive.tenantId;
      }

      //this triggers fetchDriveItemsForDriveItem
      setSelectedDriveItem(_driveRoot);

      //select the root of the folder
      _driveRoot.name = drive.name;
      props.onSelectItems([_driveRoot]);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDriveItemsForDriveItem = async (drive: IDrive | undefined, driveItem: IDriveItem | undefined) => {
    if (drive === undefined || drive.id === undefined || driveItem === undefined || driveItem.id === undefined) {
      return;
    }

    try {
      setIsLoading(true);
      setDriveItems([]);
      setSPError(undefined);

      const graphInterface = await appContext.getGraphInterface(
        graphSharepointLibraryRequest.scopes,
        driveItem.tenantId,
      );

      if (!checkToken(graphInterface.accessToken)) return;

      let _drivesItems: IDriveItem[] = await graphGetChildrenForDriveItem(
        graphInterface.client,
        drive.id,
        driveItem.id,
      );

      if (!_drivesItems) _drivesItems = [];
      _drivesItems.forEach((d) => (d.tenantId = driveItem.tenantId));
      setDriveItems(_drivesItems);

      //select the root of the folder
      if (driveItem && driveItem.name === 'root') {
        driveItem.name = drive.name;
      }

      props.onSelectItems([driveItem]);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectDriveFolder = (driveItem: IDriveItem, isChild: boolean) => {
    setSelectedDriveItem(driveItem);

    if (isChild) {
      setParents([...parents, driveItem]);
    } else {
      let newParents: IDriveItem[] = [];
      const existingParentIndex = parents.findIndex((existingItem: IDriveItem) => driveItem.id === existingItem.id);

      if (existingParentIndex !== -1) {
        newParents = parents.slice(0, existingParentIndex + 1);
      }
      setParents(newParents);
    }
  };

  const onOpenFile = (driveItem: IDriveItem) => {
    if (driveItem.webUrl) {
      navigateToExternalUrl(driveItem.webUrl, '', '');
    }
  };

  const onSelectDrive = (drive: IDrive) => {
    setSelectedDriveItem(undefined);
    setParents([]);
    setSelectedDrive(drive);
  };

  const updateSiteSelection = (site?: ISite) => {
    clear();
    setSelectedSite(site);
  };
  
  const clear = () => {
    setSelectedDrive(undefined);
    setSelectedDriveItem(undefined);
    setDrives([]);
    setDriveItems([]);
    props.onSelectItems([]);
  };

  const onDirectoryBack = () => {
    if (parents.length > 1) {
      // Refresh DriveItems for a IDriveItem
      onSelectDriveFolder(parents[parents.length - 2], false);
    } else if (parents.length === 1 && selectedDrive !== undefined) {
      if (props.parentFolder) return;

      //Refresh DriveItems for IDrive
      onSelectDrive(selectedDrive);
    } else if (parents.length === 0 && selectedDrive !== undefined && selectedSite !== undefined) {
      //Refresh Drives for Group
      updateSiteSelection(selectedSite);
    }
  };

  const onRefresh = () => {
    if (selectedDrive && selectedDriveItem) {
      // Refresh DriveItems
      fetchDriveItemsForDriveItem(selectedDrive, selectedDriveItem);
    } else if (selectedDrive) {
      //Refresh DriveItems for IDrive
      fetchDriveItemsForDrive(selectedDrive);
    } else {
      //Refresh Drives for Group
      fetchDrives(selectedSite);
    }
  };

  const onSelectDriveItems = (items: IDriveItem[] | undefined) => {
    let driveItems: IDriveItem[] = items ? [...items] : [];

    if (!driveItems || driveItems.length === 0) {
      if (selectedDriveItem) {
        //when the selection is empty, select the parent (containing item or root)
        driveItems = [selectedDriveItem];
      } else if (selectedDrive) {
        //if that is also empty, selet the drive
        driveItems = [selectedDrive] as IDriveItem[];
      }
    }

    props.onSelectItems(driveItems);
  };

  const createNewFolder = async () => {
    if (isLoading || newFolderName === undefined) return;
    if (
      selectedDrive === undefined ||
      selectedDrive.id === undefined ||
      selectedDriveItem === undefined ||
      selectedDriveItem.id === undefined
    ) {
      return;
    }

    try {
      setIsLoading(true);
      // const accessToken: string = await appContext.getAccessToken(graphSharepointLibraryRequest.scopes);
      const graphInterface = await appContext.getGraphInterface(
        graphSharepointLibraryRequest.scopes,
        selectedDriveItem.tenantId,
      );
      if (!checkToken(graphInterface.accessToken)) return;
      await graphCreateNewFolderInDrive(graphInterface.client, selectedDrive.id, selectedDriveItem.id, newFolderName);
      onRefresh();
    } catch (err) {
      const graphErr = AppError.fromGraphError(err);
      const spErr = getGraphSharepointErrorMsg(graphErr, t);
      if (spErr) {
        appContext.showNotification(spErr, true);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const commandButtons: ICommandBarItemProps[] = [
    {
      key: 'new-folder',
      text: t('sharepoint:CommandBar.NewFolder.Button'),
      iconProps: newIcon,
      disabled: selectedDriveItem === undefined || selectedDrive === undefined,
      onClick: () => setShowNewFolderDialog(true),
    },
  ];

  const validateFolderName = (newName: string): string | undefined => {
    const invalidSharepointChars = '"*:<>?/\\|';
    for (let i = 0; i < newName.length; i++) {
      if (invalidSharepointChars.indexOf(newName.charAt(i)) !== -1) {
        return t('sharepoint:CommandBar.NewFolder.ErrorInvalid', { chars: invalidSharepointChars });
      }
    }

    return undefined;
  };

  const onOpenSharePoint = () => {
    if (selectedDrive?.webUrl) {
      navigateToExternalUrl(selectedDrive.webUrl, '', '');
    } else if (selectedSite?.webUrl) {
      navigateToExternalUrl(selectedSite.webUrl + '/_layouts/15/viewlsts.aspx', '', '');
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill>
      {/* Body Left and Right*/}
      <Stack.Item grow>
        <Stack horizontal styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
          {/* Left Sites Part*/}
          {!props.parentFolder && (
            <Stack.Item styles={{ root: { width: '30%' } }}>
              <MySites
                onSelect={(item) => updateSiteSelection(item)}
                selectedSite={selectedSite}
                checkToken={checkToken}
                authType={MySiteAuthTypes.Library}
              />
              <Separator vertical />
            </Stack.Item>
          )}
          {/* Right Part*/}
          <Stack.Item grow styles={{ root: { width: !props.parentFolder ? '70%' : '100%' } }}>
            {/* Directory Parent Stucture and refresh button*/}
            <Stack verticalFill>
              {/* Sharepoint functions*/}
              {!props.viewMode && (
                <Stack.Item>
                  <CommandBar styles={{ root: { padding: 0, marginBottom: 10 } }} items={commandButtons}></CommandBar>
                  <ModifyTextDialog
                    isOpen={showNewFolderDialog}
                    value={newFolderName}
                    required={true}
                    title={t('sharepoint:CommandBar.NewFolder.DialogTitle')}
                    placeholder={t('sharepoint:CommandBar.NewFolder.DialogPlaceholder')}
                    maxLength={400}
                    onClose={() => {
                      setNewFolderName(undefined);
                      setShowNewFolderDialog(false);
                    }}
                    onSave={createNewFolder}
                    onUpdate={(value: string) => setNewFolderName(value)}
                    onGetErrorMessage={validateFolderName}
                  />
                </Stack.Item>
              )}
              <Stack.Item>
                {(selectedSite || props.parentFolder) && (
                  <Stack horizontal wrap horizontalAlign="space-between">
                    <Stack.Item>
                      <DirectoryParents
                        drive={selectedDrive}
                        parents={parents}
                        site={selectedSite}
                        onSelectDrive={onSelectDrive}
                        onSelectDriveFolder={onSelectDriveFolder}
                        onBack={onDirectoryBack}
                        onSelectSite={updateSiteSelection}
                        disableBackBeforeParentInfo={props.parentFolder !== undefined}
                        parentInfo={props.parentFolder}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      {(selectedSite || selectedDrive) && (
                        <TooltipHost content={t('sharepoint:CommandBar.OpenSharePoint')}>
                          <IconButton iconProps={sharepointIcon} onClick={onOpenSharePoint} />
                        </TooltipHost>
                      )}
                      <TooltipHost content={t('sharepoint:CommandBar.Refresh')}>
                        <IconButton iconProps={refreshIcon} onClick={onRefresh} disabled={isLoading} />
                      </TooltipHost>
                    </Stack.Item>
                  </Stack>
                )}
              </Stack.Item>
              {spError && !isLoading && (
                <Stack verticalFill>
                  <WarningMessage message={spError} onDismiss={undefined}></WarningMessage>
                </Stack>
              )}
              {/* Show drives when no drive has been selected*/}
              {!spError && !selectedDrive && (
                <Stack.Item grow styles={{ root: { minHeight: 150 } }}>
                  <MyDrives drives={drives} onSelect={onSelectDrive} isDriveLoading={isLoading} />
                </Stack.Item>
              )}
              {/* Show drive content*/}
              {!spError && selectedDrive && (
                <Stack.Item grow styles={{ root: { minHeight: 150 } }}>
                  <MyDriveItems
                    isLoading={isLoading}
                    driveItems={driveItems}
                    onSelectFolder={onSelectDriveFolder}
                    onSelectItems={onSelectDriveItems}
                    layoutMode={
                      props.parentFolder ? DetailsListLayoutMode.justified : DetailsListLayoutMode.fixedColumns
                    }
                    viewMode={props.viewMode}
                    onOpenFile={onOpenFile}
                    existingLinks={props.existingLinks}
                    linkToEdit={props.linkToEdit}
                    singleSelect={props.singleSelect}
                  />
                </Stack.Item>
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default DirectoryExplorer;
