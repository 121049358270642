import _ from 'lodash';

export function isObjectEqual<T>(a: T, b: T): boolean {
  return _.isEqual(a, b);
}

export function getUniqueString(name?: string): string {
  return _.uniqueId(name + '__');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function nullsToUndefined(obj: any): any {
  if (obj === null || obj === undefined) {
    return undefined;
  }

  if (obj.constructor.name === "Object" || obj.constructor.name === "Array") {
    for (const key in obj) {
      obj[key] = nullsToUndefined(obj[key]);
    }
  }

  return obj;
}