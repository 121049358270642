import http from './httpService';
import AppError from 'utils/appError';
import Asset from 'models/asset/asset';
import { mapFromAsset, mapFromAssetContext, mapFromAssets, mapToAsset } from 'mappings/assetMapping';
import AssetDTO, { AssetProcessDTO } from 'models/dto/asset/assetDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import IdListDTO from 'models/dto/IdListDTO';
import { AssetContext } from 'models/asset/assetContext';
import { AssetContextDTO } from 'models/dto/asset/assetContextDTO';

export async function apiGetAsset(id: number, accessToken: string, cache: GlobalDataCache): Promise<Asset | undefined> {
  try {
    const ar = await http.get<AssetDTO>(`/assets/${id}`, http.getRequestConfig(accessToken));
    if (!ar.data) return undefined;

    return mapFromAsset(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAssets(accessToken: string, cache: GlobalDataCache): Promise<Asset[]> {
  try {
    const ar = await http.get<AssetDTO[]>(`/assets`, http.getRequestConfig(accessToken));

    return mapFromAssets(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAssetChildren(id: number, accessToken: string): Promise<number[]> {
  try {
    const ar = await http.get<IdListDTO>(`/assets/${id}/children`, http.getRequestConfig(accessToken));
    if (!ar.data.idList) return [];

    return ar.data.idList.map((a) => Number.parseInt(a));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAssetCode(accessToken: string, code: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/assets/code`, { valueString: code }, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddAsset(
  newAsset: Asset,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Asset> {
  try {
    const assetDTO: AssetDTO = mapToAsset(newAsset);

    const ar = await http.post<AssetDTO>(`/assets`, assetDTO, http.getRequestConfig(accessToken));

    return mapFromAsset(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateAsset(
  editAsset: Asset,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Asset> {
  try {
    const assetDTO: AssetDTO = mapToAsset(editAsset);

    const ar = await http.put<AssetDTO>(`/assets`, assetDTO, http.getRequestConfig(accessToken));

    return mapFromAsset(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveAsset(asset: Asset, accessToken: string): Promise<boolean> {
  try {
    await http.post(`/assets/delete/${asset.assetId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export const apiGetAssetContext = async (
  assetId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<AssetContext> => {
  try {
    const ar = await http.get<AssetContextDTO>(`/assets/context/${assetId}`, http.getRequestConfig(accessToken));

    return mapFromAssetContext(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

//Process
export async function apiAddProcessToAsset(
  assetId: number,
  processIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new AssetProcessDTO(assetId, processIdTo);
    await http.post<AssetProcessDTO>(`/assets/process?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveProcessFromAsset(
  assetId: number,
  processIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new AssetProcessDTO(assetId, processIdTo);
    await http.post<AssetProcessDTO>(`/assets/process?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAssetsForRisk(
  riskId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Asset[]> {
  try {
    const ar = await http.get<AssetDTO[]>(`/assets/risk/${riskId}`, http.getRequestConfig(accessToken));

    return mapFromAssets(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
