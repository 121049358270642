import ISOControlDTO, { ISOControlTransMappingDTO , ISOControlISOControlDTO, ISONormUpgradeCheckMapDTO } from 'models/dto/isoControlDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import http from './httpService';
import AppError from 'utils/appError';

import ISOControl, { ISOControlISOControl, ISOControlTransMapping } from 'models/isoControl';
import {
  mapFromControl,
  mapFromISOControl,
  mapFromISOControlISOControl,
  mapFromISOControls,
  mapFromISOControlTransMapping,
  mapFromISONorm,
  mapFromISONorms,
  mapToISOControl,
  mapToISONorm,
} from 'mappings/dataMapping';
import ControlISOControlDTO from 'models/dto/controlISOControlDTO';
import { ISONorm } from 'models/norm';
import { ISONormDTO } from 'models/dto/normDTO';
import Control from 'models/control';
import ControlDTO from 'models/dto/controlDTO';

export async function apiGetISOControls(accessToken: string, globalDataCache: GlobalDataCache): Promise<ISOControl[]> {
  try {
    const ar = await http.get<ISOControlDTO[]>(`iso/controls`, http.getRequestConfig(accessToken));

    return mapFromISOControls(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAllISOControls(
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ISOControl[]> {
  try {
    const ar = await http.get<ISOControlDTO[]>(`iso/controls/all`, http.getRequestConfig(accessToken));

    return mapFromISOControls(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetISOControl(
  isoControlId: number,
  controlId: number,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const controlISOControlDTO: ControlISOControlDTO = new ControlISOControlDTO(isoControlId, controlId);
    await http.post(`/controls/iso/?add=${add}`, controlISOControlDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiTransferISONorm(
  isoNormId: number,
  createControls: boolean,
  langCode: string,
  authSchemaId: number | undefined,
  accessToken: string,
): Promise<void> {
  try {
    let url = `iso/transfer/${isoNormId}/${langCode}?createControls=${createControls}`;
    if (authSchemaId) {
      url += `&authSchemaId=${authSchemaId}`;
    }

    await http.post(url, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetISONorms(includeNotPublished: boolean, accessToken: string): Promise<ISONorm[]> {
  try {
    const ar = await http.get<ISONormDTO[]>(
      `/iso/norm?includeNotPublished=${includeNotPublished}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromISONorms(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetISOControlRelations(
  isoControlId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ISOControl[]> {
  try {
    const ar = await http.get<ISOControlDTO[]>(
      `iso/controls/${isoControlId}/relations`,
      http.getRequestConfig(accessToken),
    );

    return mapFromISOControls(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddISOControlRelation(
  isoControlIdFrom: number,
  isoControlIdTo: number,
  accessToken: string,
): Promise<ISOControlISOControl | undefined> {
  try {
    const newISOControlISOControlDTO = new ISOControlISOControlDTO();
    newISOControlISOControlDTO.isoControlIdFrom = isoControlIdFrom;
    newISOControlISOControlDTO.isoControlIdTo = isoControlIdTo;

    const ar = await http.post<ISOControlISOControlDTO>(
      `iso/controls/relations`,
      newISOControlISOControlDTO,
      http.getRequestConfig(accessToken),
    );

    if (ar && ar.data) {
      return mapFromISOControlISOControl(ar.data);
    } else {
      return undefined;
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateISOControlRelation(relation: ISOControlISOControl, accessToken: string): Promise<void> {
  try {
    const newISOControlISOControlDTO = new ISOControlISOControlDTO();
    newISOControlISOControlDTO.isoControlIdFrom = relation.isoControlIdFrom;
    newISOControlISOControlDTO.isoControlIdTo = relation.isoControlIdTo;
    newISOControlISOControlDTO.status = relation.status;

    await http.put<ISOControlISOControlDTO>(
      `iso/controls/relations`,
      newISOControlISOControlDTO,
      http.getRequestConfig(accessToken),
    );

    return;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteISOControlRelation(
  isoControlIdFrom: number,
  isoControlIdTo: number,
  accessToken: string,
): Promise<void> {
  try {
    const newISOControlISOControlDTO = new ISOControlISOControlDTO();
    newISOControlISOControlDTO.isoControlIdFrom = isoControlIdFrom;
    newISOControlISOControlDTO.isoControlIdTo = isoControlIdTo;

    await http.post<ISOControlISOControlDTO>(
      `iso/controls/relations/delete`,
      newISOControlISOControlDTO,
      http.getRequestConfig(accessToken),
    );

    return;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCheckStandardUpgrade(
  sourceStandard: ISONorm,
  targetStandard: ISONorm,
  standardToCheck: ISONorm,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ISOControl[]> {
  try {
    const map: ISONormUpgradeCheckMapDTO = {
      sourceISONormId: sourceStandard.isoNormId,
      targetISONormId: targetStandard.isoNormId,
      checkISONormId: standardToCheck.isoNormId,
    };

    const ar = await http.post<ISOControlDTO[]>(`/iso/upgrade/check`, map, http.getRequestConfig(accessToken));

    return mapFromISOControls(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetISOControlTransMapping(
  sourceStandard: ISONorm,
  accessToken: string,
): Promise<ISOControlTransMapping[]> {
  try {
    const ar = await http.get<ISOControlTransMappingDTO[]>(
      `/iso/upgrade/trans/${sourceStandard.isoNormId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromISOControlTransMapping(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateISOControls(
  isoNormId: number,
  languageCode: string,
  accessToken: string,
): Promise<void> {
  try {
    await http.post(`/iso/${isoNormId}/${languageCode}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetISOControlsForLang(
  isoNormId: number,
  languageId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ISOControl[]> {
  try {
    const ar = await http.get<ISOControlDTO[]>(
      `/iso/controls/${isoNormId}/${languageId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromISOControls(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetISOControlsForStandard(
  isoNormId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ISOControl[]> {
  try {
    const ar = await http.get<ISOControlDTO[]>(`/iso/controls/${isoNormId}`, http.getRequestConfig(accessToken));

    return mapFromISOControls(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddISOControl(
  isoControl: ISOControl,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ISOControl> {
  try {
    const isoControlDTO: ISOControlDTO = mapToISOControl(isoControl);
    const ar = await http.post('/iso/controls', isoControlDTO, http.getRequestConfig(accessToken));

    return mapFromISOControl(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateISOControl(isoControl: ISOControl, accessToken: string): Promise<void> {
  try {
    const isoControlDTO: ISOControlDTO = mapToISOControl(isoControl);
    await http.put('/iso/controls', isoControlDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteISOControl(isoControl: ISOControl, accessToken: string): Promise<void> {
  try {
    const isoControlDTO: ISOControlDTO = mapToISOControl(isoControl);
    await http.post('/iso/controls/delete', isoControlDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCopyISONorm(
  isoNorm: ISONorm,
  targetLangCode: string | undefined,
  accessToken: string,
): Promise<ISONorm> {
  try {
    const isoNormDTO = mapToISONorm(isoNorm);
    let ar;

    if (targetLangCode) {
      ar = await http.post(
        `/iso/norm/copy?targetLang=${targetLangCode}`,
        isoNormDTO,
        http.getRequestConfig(accessToken),
      );
    } else {
      ar = await http.post(`/iso/norm/copy`, isoNormDTO, http.getRequestConfig(accessToken));
    }

    return mapFromISONorm(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateISONorm(isoNorm: ISONorm, accessToken: string): Promise<void> {
  try {
    const isoNormDTO = mapToISONorm(isoNorm);
    await http.put<ISONormDTO[]>(`/iso/norm`, isoNormDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteISONorm(isoNorm: ISONorm, targetLangCode: string, accessToken: string): Promise<void> {
  try {
    await http.post<ISONormDTO[]>(
      `/iso/norm/delete/${isoNorm.isoNormId}?targetLang=${targetLangCode}`,
      null,
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlForISOControl(
  isoControlId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Control | undefined> {
  try {
    const ar = await http.get<ControlDTO | undefined>(
      `/iso/controls/${isoControlId}/control`,
      http.getRequestConfig(accessToken),
    );

    return mapFromControl(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
