import Asset from 'models/asset/asset';
import Task from '../models/tasks/task';
import Risk from 'models/risk';
import { SearchResult } from 'models/search/SearchResult';

export const getUniqueNormIds = (items: Asset[] | SearchResult[] | Task[] | Risk[]): number[] => {
  let ids: number[] = [];

  for (let idx = 0; idx < items.length; idx++) {
    const item = items[idx];
    if (item.normIds) {
      ids.push(...item.normIds);
    }
  }

  ids = [...new Set(ids)];

  return ids;
};
