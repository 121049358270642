import TaskType from './taskType';

export class TaskCheckListConversion {
  taskId: number;

  taskType?: TaskType;

  trans_FormSuffix: string;

  trans_KPISuffix: string;

  constructor() {
    this.taskId = 0;
    this.trans_FormSuffix = '';
    this.trans_KPISuffix = '';
  }
}
