import { FontIcon, IIconProps } from '@fluentui/react';
import {
  listTypeCustomListIcon,
  listTypeDocLibraryIcon,
  listTypeWebURLIcon,
  listTypeSitePageLibraryIcon,
} from 'globalStyles';
import ResourceLink, { ResourceLinkPublishingState } from 'models/resourceLink';
import ResourceList, { ResourceListType } from 'models/resourceList';

export const getListIcon = (item: ResourceList): IIconProps | undefined => {
  if (item.listType === ResourceListType.CustomList) return listTypeCustomListIcon;
  if (item.listType === ResourceListType.DocumentLibrary) return listTypeDocLibraryIcon;
  if (item.listType === ResourceListType.WebURL) return listTypeWebURLIcon;
  if (item.listType === ResourceListType.SitePageLibrary) return listTypeSitePageLibraryIcon;

  return undefined;
};

export const getPublicationStateIcon = (item: ResourceLink): JSX.Element | null => {
  switch (item.publishingState) {
    case ResourceLinkPublishingState.Published:
      return <FontIcon style={{ fontSize: 14, color: 'green' }} iconName={'Checkmark'} />;
    case ResourceLinkPublishingState.Checkout:
      return <FontIcon style={{ fontSize: 14, color: 'red' }} iconName={'CheckedOutByOther12'} />;
    case ResourceLinkPublishingState.Draft:
      return <FontIcon style={{ fontSize: 14, color: 'orange' }} iconName={'WebPublish'} />;
    default:
      return null;
  }
};
