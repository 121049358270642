import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DetailsList,
  FontIcon,
  IColumn,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  SelectionMode,
  TooltipHost,
 IconButton, Label, Link, Text , Stack } from '@fluentui/react';


import { globalStackTokensGapSmall, guestIcon, homeIcon, orgIcon, refreshIcon } from 'globalStyles';
import AppContext from 'App/AppContext';
import { HomeAccount } from 'models/userSetting';
import Tenant from 'models/tenant';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { SubscriptionTypes } from 'utils/subscription';
import { appRoles } from 'services/Auth/appRoles';

interface ITenantListProps {
  tenants: Tenant[];
  switchOrg: (tenant: Tenant) => Promise<void>;
  switchOrgUnit: (tenant: Tenant) => Promise<void>;
  onRefresh: () => void;
}

enum TenantListItemType {
  GuestAccount = 1,
  OrgUnit = 2,
}

interface ITenantListItem {
  id: string;
  parentId?: string;
  azureTenantId?: string;
  type: TenantListItemType;
  name: string;
}

const TenantList = (props: ITenantListProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [tenantList, setTenantList] = useState<ITenantListItem[]>([]);
  const [tenantListDisplay, setTenantListDisplay] = useState<ITenantListItem[]>([]);
  const [filterText, setFilterText] = useState<string>('');

  useEffect(() => {
    getTenantList();
    setFilterText('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tenants]);

  const doSwitch = async (item: ITenantListItem) => {
    if (item.type === TenantListItemType.GuestAccount) {
      const azureTenant = props.tenants.find((t) => t.id === item.id);
      if (azureTenant) {
        await props.switchOrg(azureTenant);
      } else {
        appContext.showNotification('Guest account not found', true);
      }
    } else {
      const orgUnit = props.tenants.find((t) => t.id === item.id);
      if (orgUnit) {
        await props.switchOrgUnit(orgUnit);
      } else {
        appContext.showNotification('Organizational unit not found', true);
      }
    }
  };

  const columns: IColumn[] = [
    {
      key: 'name',
      name: '',
      minWidth: 100,
      isResizable: true,
      isMultiline: true,
      onRender: (item?: ITenantListItem, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item) return;

        const homeAccount = appContext.globalDataCache.userSettings.get(HomeAccount) as string;

        return (
          <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
            <Stack.Item>
              <FontIcon
                {...(item.type === TenantListItemType.OrgUnit ? orgIcon : guestIcon)}
                style={{ color: 'green' }}
              />
            </Stack.Item>
            <Text>
              <Link onClick={() => doSwitch(item)}>{item.name}</Link>
            </Text>
            {item.type === TenantListItemType.GuestAccount && item.azureTenantId === homeAccount && !item.parentId && (
              <FontIcon {...homeIcon} style={{ fontSize: 14, color: 'green' }} />
            )}
          </Stack>
        );
      },
    },
  ];

  const getListItemFromTenant = (tenant: Tenant): ITenantListItem => {
    let name = tenant.name;
    if (tenant.parentId) {
      //this is an OU
      const topLevelTenant = props.tenants.find((t) => t.id === tenant.topLevelParentTenantId);
      if (topLevelTenant) {
        name = topLevelTenant.name + ' | ' + tenant.name;
      }
    }

    return {
      id: tenant.id,
      azureTenantId: tenant.azureTenantId,
      parentId: tenant.parentId,
      name: name,
      type: isOrgUnit(tenant) ? TenantListItemType.OrgUnit : TenantListItemType.GuestAccount,
    };
  };

  const isOrgUnit = (tenant: Tenant): boolean => {
    return tenant.azureTenantId === appContext.user.tenant.azureTenantId;
  };

  const getTenantList = () => {
    try {
      let tenantList: ITenantListItem[] = [];
      let tenants = [...props.tenants];

      tenants = tenants.filter(t => t.subscriptionType !== SubscriptionTypes.Container);

      if (hasUserFeature(appContext, FeatureTypes.OrgUnits)) {
        //remove org units where the user has no account, except when the user is an org admin
        if (!appContext.user.login.userSystemRoles?.includes(appRoles.OrgAdmin)) {
          tenants = tenants.filter((t) => !isOrgUnit(t) || t.currentUserLicensed === true);
        }
      } else {
        //remove all org units
        tenants = tenants.filter((t) => !isOrgUnit(t));
      }

      tenantList = tenants
        .filter((t) => t.id !== appContext.user.tenant.id)
        .map((at) => getListItemFromTenant(at))
        .sort((a, b) => a.name.localeCompare(b.name));

      setTenantList(tenantList);
      setTenantListDisplay(tenantList);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const filterTenants = (text: string) => {
    if (!text) {
      setTenantListDisplay(tenantList);
      setFilterText('');
    } else {
      let tenants: ITenantListItem[] = [];
      tenants = tenantList.filter((t) => t.name.toLowerCase().indexOf(text.toLowerCase()) >= 0);
      setTenantListDisplay(tenants);
      setFilterText(text);
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <Label>{t('translation:NavBar.UserDialog.SwitchOrg')}</Label>
        <TooltipHost content={t('translation:General.Button.Refresh')}>
          <IconButton iconProps={refreshIcon} toggle onClick={() => props.onRefresh()}></IconButton>
        </TooltipHost>
      </Stack>
      {tenantList.length === 0 && <Text variant="small">{t('translation:NavBar.UserDialog.SwitchOrgNoItems')}</Text>}
      {tenantList.length > 0 && (
        <Fragment>
          {tenantList.length > 5 && (
            <SearchBox
              autoFocus={!appContext.isMobileView} //does not seem to work
              value={filterText}
              underlined
              placeholder={t('translation:NavBar.UserDialog.SwitchOrgSearchPlaceholder')}
              onChange={(ev, newValue) => filterTenants(newValue ?? '')}
            />
          )}
          <Stack.Item
            grow
            styles={{
              root: {
                minHeight: 100,
                height: 'calc(100vh - 450px)',
                position: 'relative',
              },
            }}
          >
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                compact
                columns={columns}
                items={tenantListDisplay}
                isHeaderVisible={false}
                selectionMode={SelectionMode.none}
              />
            </ScrollablePane>
          </Stack.Item>
        </Fragment>
      )}
    </Stack>
  );
};

export default TenantList;
