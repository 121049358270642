import { useTranslation } from 'react-i18next';
import { NormCoverage } from 'models/dashboardScene';
import { FluentUIPieChart, IPieChartDataPoint } from './PieChart';
import Tag from 'models/tag';
import { ApplicabilityReasons } from 'models/control';
import { EntityStatus } from 'models/entityStatus';

interface NormCoverageProps {
  normCoverageData: NormCoverage | undefined;
  tags?: Tag[];
  height?: number;
  width?: number;
}

const NormCoverageChart = (props: NormCoverageProps) => {
  const { t } = useTranslation(['translation', 'widgets']);

  const getLink = (series: number) => {
    if (!props.normCoverageData) return;

    const normParam = `isonorm=${props.normCoverageData.isoNormId}`;

    let seriesParams = '';
    const baseParam = '/standards/overview';
    
    switch (series) {
      case 0: {
        seriesParams = `state=${EntityStatus.Implemented}`;
        break;
      }
      case 1: {
        seriesParams = `applicability=${ApplicabilityReasons.OutOfScope}`;
        break;
      }
      case 2: {
        seriesParams = 'state=inProgress'; //EntityStatus !== Implemented
        break;
      }
      case 3: {
        seriesParams = 'scope=noControl'; //scope=notControl
        break;
      }
    }

    let tagStr = '';
    if (props.tags && props.tags.length > 0) {
      tagStr = `&tags=${props.tags.map((t) => t.tagId.toString()).join(',')}`;
    }

    const url = `${baseParam}?${normParam}&${seriesParams}${tagStr ? tagStr : ''}`;

    return url;
  };

  const getData = (): IPieChartDataPoint[] => {
    if (!props.normCoverageData) return [];

    const data: IPieChartDataPoint[] = [
      {
        name: t('widgets:NormCoverage.Covered'),
        value: props.normCoverageData.implementedCount,
        link: getLink(0),
        color: '#59ab5a',
      },
      {
        name: t('widgets:NormCoverage.OutOfScope'),
        value: props.normCoverageData.outOfScopeCount,
        link: getLink(1),
        color: '#d9ce41',
      },
      {
        name: t('widgets:NormCoverage.InProgress'),
        value: props.normCoverageData.notImplementedCount,
        link: getLink(2),
        color: '#ed9537',
      },
      {
        name: t('widgets:NormCoverage.NotCovered'),
        value: props.normCoverageData.noControlCount,
        link: getLink(3),
        color: '#db5858',
      },
    ];

    return data.filter(d => d.value > 0);
  };

  return (
    <FluentUIPieChart
      height={props.height}
      width={props.width}
      data={getData()}
      label={props.normCoverageData?.controlCount.toString()}
    />
  );
};

export default NormCoverageChart;
