import { useTranslation } from 'react-i18next';
import { NormCoverage } from 'models/dashboardScene';
import { FluentUIPieChart, IPieChartDataPoint } from './PieChart';
import Tag from 'models/tag';

interface CustomNormCoverageProps {
  CustomNormCoverageData: NormCoverage | undefined;
  tags?: Tag[];
  height?: number;
  width?: number;
}

const CustomNormCoverageChart = (props: CustomNormCoverageProps) => {
  const { t } = useTranslation(['translation', 'widgets']);

  const getLink = (series: number) => {
    if (!props.CustomNormCoverageData) return;

    return undefined;

    // const normParam = `norm=${props.CustomNormCoverageData.normId}`;

    // let seriesParams = '';
    // let baseParam = '/controls';
    // switch (series) {
    //   case 0: {
    //     seriesParams = 'covered=true';
    //     break;
    //   }
    //   case 1: {
    //     seriesParams = 'outofscope=true';
    //     break;
    //   }
    //   case 2: {
    //     seriesParams = 'EntityStatus=p&notscheduled=true';
    //     break;
    //   }
    // }

    // let tagStr = '';
    // if (props.tags && props.tags.length > 0) {
    //   tagStr = `&tags=${props.tags.map((t) => t.tagId.toString()).join(',')}`;
    // }

    // const url = `${baseParam}?${normParam}&${seriesParams}${tagStr ? tagStr : ''}`;

    // return url;
  };

  const getData = (): IPieChartDataPoint[] => {
    if (!props.CustomNormCoverageData) return [];

    const data: IPieChartDataPoint[] = [
      {
        name: t('widgets:CustomNormCoverage.Covered'),
        value: props.CustomNormCoverageData.implementedCount,
        link: getLink(0),
        color: '#59ab5a',
      },
      {
        name: t('widgets:CustomNormCoverage.OutOfScope'),
        value: props.CustomNormCoverageData.outOfScopeCount,
        link: getLink(1),
        color: '#d9ce41',
      },
      {
        name: t('widgets:CustomNormCoverage.InProgress'),
        value: props.CustomNormCoverageData.notImplementedCount,
        link: getLink(2),
        color: '#ed9537',
      },
    ];

    return data.filter(d => d.value > 0);;
  };

  return (
    <FluentUIPieChart
      height={props.height}
      width={props.width}
      data={getData()}
      label={props.CustomNormCoverageData?.controlCount.toString()}
    />
  );
};

export default CustomNormCoverageChart;
