import { useContext, useState } from 'react';
import { Stack, Link, IconButton, TooltipHost } from '@fluentui/react';
import ResourceLink from 'models/resourceLink';
import {
  globalStackTokensGapSmall,
  deleteIcon,
  infoIcon,
  listTypeSitePageLibraryIcon,
  pinIcon,
  unPinIcon,
  extLinkIcon,
  documentIcon,
  webIcon,
} from 'globalStyles';
import { navigateToExternalEntity } from 'utils/url';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import AppContext from 'App/AppContext';
import { ResourceListType } from 'models/resourceList';
import { getEntityName } from 'globalFunctions';
import Entity from 'models/entity';
import { LinkPreviewModalOrUrl } from './Preview/LinkPreviewModalOrUrl';

interface ISingleResourceLinkProps {
  type?: ResourceListType;
  link: ResourceLink;
  navigateExternal?: boolean;
  readonly: boolean;
  onDelete: (item: ResourceLink) => void;
  onPin?: (item: ResourceLink, pinned: boolean) => void;
}

const getIcon = (type: ResourceListType, navigateExternal: boolean): JSX.Element => {
  if (navigateExternal) {
    return <IconButton iconProps={extLinkIcon} />;
  } else {
    if (type === ResourceListType.CustomList) return <IconButton iconProps={documentIcon} />;
    if (type === ResourceListType.DocumentLibrary) return <IconButton iconProps={documentIcon} />;
    if (type === ResourceListType.WebURL) return <IconButton iconProps={webIcon} />;
    if (type === ResourceListType.SitePageLibrary) return <IconButton iconProps={listTypeSitePageLibraryIcon} />;

    return <IconButton iconProps={webIcon} />;
  }
};

const SingleResourceLink = (props: ISingleResourceLinkProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'library']);
  const [deleteLink, setDeleteLink] = useState<ResourceLink | undefined>(undefined);
  const [showLink, setShowLink] = useState<ResourceLink | undefined>(undefined);

  const getEntityTypeAndCode = (item: Entity): string => {
    const entityTypeName = getEntityName(item, t as TFunction<string[]>);

    return entityTypeName + ' ' + item.entityName;
  };

  //
  // Main render
  //
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      {props.type !== undefined && (
        <Stack.Item onClick={() => setShowLink(props.link)}>
          {getIcon(props.type, props.navigateExternal ?? false)}
        </Stack.Item>
      )}
      <Stack.Item grow>
        <Stack horizontal verticalAlign="center">
          <Stack.Item grow>
            <Link onClick={() => setShowLink(props.link)}>{props.link.linkName}</Link>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal>
              {props.onPin && !props.link.entity && !props.readonly && (
                //when entity is set, the link in indirect from another entity or up/down in the hierarchy so we cannot delete it
                <TooltipHost
                  content={props.link.pinned ? t('library:LinkComponents.UnPin') : t('library:LinkComponents.Pin')}
                >
                  <IconButton
                    className="redlab-usetiful-library-singleresource-pin"
                    iconProps={props.link.pinned ? unPinIcon : pinIcon}
                    onClick={() => {
                      if (props.onPin) props.onPin(props.link, !props.link.pinned);
                    }}
                  />
                </TooltipHost>
              )}
              {!props.link.entity && !props.readonly && (
                //when entity is set, the link in indirect from another entity or up/down in the hierarchy so we cannot delete it
                <TooltipHost content={t('translation:General.Button.Remove')}>
                  <IconButton
                    iconProps={deleteIcon}
                    onClick={() => {
                      setDeleteLink(props.link);
                    }}
                  />
                </TooltipHost>
              )}
              {props.link.entity && (
                <TooltipHost
                  content={
                    <Link
                      onClick={() => {
                        navigateToExternalEntity(
                          props.link.entity!,
                          appContext.user.tenant.azureTenantId,
                          appContext.user.login.tenantId,
                        );

                        return false;
                      }}
                    >
                      {getEntityTypeAndCode(props.link.entity)}
                    </Link>
                  }
                >
                  <IconButton iconProps={infoIcon} />
                </TooltipHost>
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <DialogYesNo
        hidden={deleteLink === undefined}
        title={t('library:LinkComponents.DeleteTitle')}
        subText={t('library:LinkComponents.DeleteSubText')}
        onYes={() => {
          if (deleteLink && props.onDelete) props.onDelete(deleteLink);
          setDeleteLink(undefined);
        }}
        onNo={() => {
          setDeleteLink(undefined);
        }}
      />
      <LinkPreviewModalOrUrl
        isOpen={showLink !== undefined}
        onClose={() => setShowLink(undefined)}
        link={showLink}
        confirmOpen={false}
        enableLinks={true}
      />
    </Stack>
  );
};

export default SingleResourceLink;
