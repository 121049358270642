import http from './httpService';
import AppError from 'utils/appError';
import ActivityDTO from 'models/dto/activityDTO';
import Activity, { ActivityType } from 'models/activity';
import { mapFromActivities, mapFromActivity, mapFromActivityTrail, mapToActivity } from 'mappings/activityMapping';
import ActivityTrail from 'models/ActivityTrail';
import ActivityTrailDTO from 'models/dto/activityTrailDTO';
import { EntityTypes } from 'models/entity';

export async function apiCreateActivity(
  activity: Activity,
  checkExists: boolean,
  checkExistsForUser: boolean,
  accessToken: string,
): Promise<Activity> {
  try {
    const activityDto = mapToActivity(activity);
    const ar = await http.post<ActivityDTO>(
      `/activities?checkExists=${checkExists}&checkExistsForUser=${checkExistsForUser}`,
      activityDto,
      http.getRequestConfig(accessToken),
    );

    return mapFromActivity(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateActivity(activity: Activity, accessToken: string): Promise<Activity> {
  try {
    const activityDto = mapToActivity(activity);
    const ar = await http.put<ActivityDTO>(`/activities`, activityDto, http.getRequestConfig(accessToken));

    if (ar.data) {
      return mapFromActivity(ar.data);
    } else {
      return activity;
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetActivities(
  type: ActivityType,
  entityType: EntityTypes | undefined,
  entityId: number | undefined,
  accessToken: string,
): Promise<Activity[]> {
  try {
    let url: string;
    if (entityType) {
      url = `/activities/type/${type}?entityType=${entityType}&entityId=${entityId}`;
    } else {
      url = `/activities/type/${type}`;
    }
    const ar = await http.get<ActivityDTO[]>(url, http.getRequestConfig(accessToken));

    return mapFromActivities(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetActivitiesForTypes(
  types: ActivityType[],
  count: number,
  includeDismissed: boolean,
  accessToken: string,
): Promise<Activity[]> {
  try {
    const typeStr = types.join(',');
    const url = `/activities/types?types=${typeStr}&count=${count}&includeDismissed=${includeDismissed}`;
    const ar = await http.get<ActivityDTO[]>(url, http.getRequestConfig(accessToken));

    return mapFromActivities(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetActivitiesForUser(
  accessToken: string,
  count: number,
  includeDismissed: boolean,
): Promise<Activity[]> {
  try {
    const ar = await http.get<ActivityDTO[]>(
      `/activities/user?count=${count}&includeDismissed=${includeDismissed}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromActivities(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetActivityTrail(
  auditTrailId: number,
  commentTrailId: number,
  entityType: EntityTypes,
  entityId: number,
  accessToken: string,
): Promise<ActivityTrail> {
  try {
    const activityTrailDTO: ActivityTrailDTO = new ActivityTrailDTO();
    activityTrailDTO.typeOfEntity = entityType;
    activityTrailDTO.entityId = entityId;
    activityTrailDTO.commentTrailId = commentTrailId;
    activityTrailDTO.auditTrailId = auditTrailId;

    const ar = await http.post<ActivityTrailDTO>(
      `activities/trail`,
      activityTrailDTO,
      http.getRequestConfig(accessToken),
    );

    return mapFromActivityTrail(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetActivityTrailForTenant(
  tenantId: string,
  auditTrailId: number,
  commentTrailId: number,
  entityType: EntityTypes,
  entityId: number,
  accessToken: string,
): Promise<ActivityTrail> {
  try {
    const activityTrailDTO: ActivityTrailDTO = new ActivityTrailDTO();
    activityTrailDTO.typeOfEntity = entityType;
    activityTrailDTO.entityId = entityId;
    activityTrailDTO.commentTrailId = commentTrailId;
    activityTrailDTO.auditTrailId = auditTrailId;

    const ar = await http.post<ActivityTrailDTO>(
      `activities/trail/${tenantId}`,
      activityTrailDTO,
      http.getRequestConfig(accessToken),
    );

    return mapFromActivityTrail(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
