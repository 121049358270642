import {
  ICommandBarItemProps,
  Link,
  IContextualMenuProps,
  IContextualMenuItem,
  ContextualMenuItemType,
  DirectionalHint,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { IAppContext } from 'App/AppContext';
import { groupIcon, templateIcon, taskIcon, eventIcon } from 'globalStyles';
import { TFunction } from 'i18next';
import Task, { TaskTypes } from 'models/tasks/task';
import { getAuditStartForSetting, toLocaleDateShort, addDateTimeYears } from 'utils/datetime';
import { sortOnString } from 'utils/sorting';
import { TaskViewScheduleLevel, TaskViewType } from './TasksView';
import { hasUserFeatureGenericManager, hasUserRolePermission } from 'services/Auth/featurePermissions';
import { PermissionTypes } from 'models/auth/rolePermission';

//
// Button planning level
//
interface ITasksViewButtonScheduleLevelProps {
  onSelectLevel: (level: number) => void;
  disabled: boolean | undefined;
}

export const getCommandBarItemsScheduleLevel = (
  level: TaskViewScheduleLevel,
  props: ITasksViewButtonScheduleLevelProps,
  t: TFunction<string[]>,
): ICommandBarItemProps => {
  const levelMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'year',
        checked: level === TaskViewScheduleLevel.Year,
        text: t('tasks:TaskView.Commands.ScheduleLevel.Year'),
        iconProps: { iconName: 'CalendarAgenda' },
        onClick: () => props.onSelectLevel(TaskViewScheduleLevel.Year),
      },
      {
        key: 'quarter',
        checked: level === TaskViewScheduleLevel.Quarter,
        text: t('tasks:TaskView.Commands.ScheduleLevel.Quarter'),
        iconProps: { iconName: 'CalendarDay' },
        onClick: () => props.onSelectLevel(TaskViewScheduleLevel.Quarter),
      },
      {
        key: 'month',
        checked: level === TaskViewScheduleLevel.Month,
        text: t('tasks:TaskView.Commands.ScheduleLevel.Month'),
        iconProps: { iconName: 'CalendarWeek' },
        onClick: () => props.onSelectLevel(TaskViewScheduleLevel.Month),
      },
    ],
  };

  const getMainText = (): string => {
    let subText: string = '';
    switch (level) {
      case TaskViewScheduleLevel.Year:
        subText = t('tasks:TaskView.Commands.ScheduleLevel.Year');
        break;
      case TaskViewScheduleLevel.Quarter:
        subText = t('tasks:TaskView.Commands.ScheduleLevel.Quarter');
        break;
      case TaskViewScheduleLevel.Month:
        subText = t('tasks:TaskView.Commands.ScheduleLevel.Month');
        break;
    }

    return t('tasks:TaskView.Commands.ScheduleLevel.Main') + ' : ' + subText;
  };

  return {
    key: 'level',
    disabled: props.disabled,
    text: getMainText(),
    iconProps: { iconName: 'CalendarSettings' },
    subMenuProps: levelMenuProps,
  };
};

//
// Button audit cycle
//
interface ITasksViewButtonAuditYearProps {
  onSelectYear: (year: number) => void;
  disabled: boolean | undefined;
  auditDateSetting: string;
}

const yearStartsInJan = (auditDateSetting?: string): boolean => {
  if (!auditDateSetting) return false;
  const month = getAuditStartForSetting(1, auditDateSetting).getMonth();

  return month === 0;
};

const getYearText = (yearIdx: number, props: ITasksViewButtonAuditYearProps, t: TFunction<string[]>) => {
  if (yearStartsInJan(props.auditDateSetting)) {
    return t('tasks:TaskView.Commands.AuditYear.ItemStartInJan', { year: yearIdx });
  } else {
    return t('tasks:TaskView.Commands.AuditYear.Item', { year: yearIdx, nextYear: yearIdx + 1 });
  }
};

const getYearItems = (
  selectedYear: number,
  props: ITasksViewButtonAuditYearProps,
  t: TFunction<string[]>,
): IContextualMenuItem[] => {
  return [
    {
      key: 'header1',
      text: t('tasks:TaskView.Commands.AuditYear.Header1'),
      itemType: ContextualMenuItemType.Header,
    },
    {
      key: 'yearMinus1' + (selectedYear - 1).toString(),
      text: getYearText(selectedYear - 1, props, t),
      canCheck: true,
      checked: false,
      onClick: () => props.onSelectYear(selectedYear - 1),
    },
    {
      key: 'yearCurrent' + selectedYear.toString(),
      text: getYearText(selectedYear, props, t),
      canCheck: true,
      checked: true,
      onClick: () => props.onSelectYear(selectedYear),
    },
    {
      key: 'yearPlus1' + (selectedYear + 1).toString(),
      text: getYearText(selectedYear + 1, props, t),
      canCheck: true,
      checked: false,
      onClick: () => props.onSelectYear(selectedYear + 1),
    },
    {
      key: 'header2',
      text: t('tasks:TaskView.Commands.AuditYear.Header2'),
      itemType: ContextualMenuItemType.Header,
    },
  ];
};

export const getCommandBarItemsAuditYear = (
  year: number,
  props: ITasksViewButtonAuditYearProps,
  t: TFunction<string[]>,
  isUpdating?: boolean,
): ICommandBarItemProps => {
  const currentYear = new Date().getFullYear();
  const yearMenuProps: IContextualMenuProps = {
    directionalHint: DirectionalHint.bottomLeftEdge,
    directionalHintFixed: true,
    items: [
      ...getYearItems(year, props, t),
      ...Array.from({ length: 21 }, (_, i) => {
        const yearIdx: number = i + currentYear - 10;

        return {
          key: 'year' + yearIdx.toString(),
          text: getYearText(yearIdx, props, t),
          canCheck: true,
          checked: yearIdx === year,
          onClick: () => props.onSelectYear(yearIdx),
        };
      }),
    ],
    calloutProps: {
      calloutMaxHeight: 300,
    },
  };

  const getMainText = (): string => {
    const auditStart = getAuditStartForSetting(year, props.auditDateSetting);
    const auditYearStartString = toLocaleDateShort(auditStart);
    const auditYearEndString = toLocaleDateShort(addDateTimeYears(auditStart, 1));

    return t('tasks:TaskView.Schedule.Columns.PeriodInfo', {
      start: auditYearStartString,
      end: auditYearEndString,
    });
  };

  return {
    key: 'audityear',
    disabled: props.disabled || isUpdating,
    text: getMainText(),
    iconProps: { iconName: 'Calendar' },
    onRenderIcon: !isUpdating
      ? undefined
      : () => {
          return <Spinner size={SpinnerSize.medium} />;
        },
    subMenuProps: yearMenuProps,
  };
};

//
// Button group by
//
interface ITasksViewButtonGroupByProps {
  onGroupBy: (field: 'state' | 'assignee') => void;
  disabled: boolean | undefined;
}

export const getCommandBarItemsGroupBy = (
  field: 'state' | 'assignee',
  props: ITasksViewButtonGroupByProps,
  t: TFunction<string[]>,
): ICommandBarItemProps => {
  const groupByMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'state',
        checked: field === 'state',
        text: t('tasks:TaskView.Commands.GroupBy.Status'),
        iconProps: { iconName: 'StatusCircleRing' },
        onClick: () => props.onGroupBy('state'),
      },
      {
        key: 'assignee',
        checked: field === 'assignee',
        text: t('tasks:TaskView.Commands.GroupBy.Assignee'),
        iconProps: groupIcon,
        onClick: () => props.onGroupBy('assignee'),
      },
    ],
  };

  const getMainText = (): string => {
    let subText: string = '';
    switch (field) {
      case 'state':
        subText = t('tasks:TaskView.Commands.GroupBy.Status');
        break;
      case 'assignee':
        subText = t('tasks:TaskView.Commands.GroupBy.Assignee');
        break;
    }

    return t('tasks:TaskView.Commands.GroupBy.Main') + ' : ' + subText;
  };

  return {
    key: 'groupBy',
    disabled: props.disabled,
    text: getMainText(),
    iconProps: { iconName: 'GroupList' },
    subMenuProps: groupByMenuProps,
  };
};

//
// Button switch view
//
interface ITasksViewButtonSwitchViewProps {
  onSwitchView: (viewType: TaskViewType) => void;
  disabled: boolean | undefined;
}

export const getCommandBarItemsSwitchView = (
  selectedViewType: TaskViewType,
  props: ITasksViewButtonSwitchViewProps,
  t: TFunction<string[]>,
  extraItems?: IContextualMenuItem[],
): ICommandBarItemProps => {
  if (extraItems && extraItems.length > 0) {
    extraItems.push({
      key: 'div1',
      text: '-',
      itemType: ContextualMenuItemType.Divider,
    });
  }

  const viewTypeMenuProps: IContextualMenuProps = {
    items: [
      ...(extraItems ?? []),
      {
        key: 'list',
        checked: selectedViewType === TaskViewType.List,
        text: t('tasks:TaskView.Commands.View.List'),
        iconProps: { iconName: 'BulletedList' },
        onClick: () => props.onSwitchView(TaskViewType.List),
      },
      {
        key: 'board',
        checked: selectedViewType === TaskViewType.Board,
        text: t('tasks:TaskView.Commands.View.Board'),
        iconProps: { iconName: 'BacklogBoard' },
        onClick: () => props.onSwitchView(TaskViewType.Board),
      },
      {
        key: 'planning',
        checked: selectedViewType === TaskViewType.Schedule,
        text: t('tasks:TaskView.Commands.View.Schedule'),
        iconProps: { iconName: 'CalendarAgenda' },
        onClick: () => props.onSwitchView(TaskViewType.Schedule),
      },
    ],
  };

  const getMainText = (): string => {
    let subText: string = '';
    switch (selectedViewType) {
      case TaskViewType.Board:
        subText = t('tasks:TaskView.Commands.View.Board');
        break;
      case TaskViewType.Schedule:
        subText = t('tasks:TaskView.Commands.View.Schedule');
        break;
      case TaskViewType.List:
        subText = t('tasks:TaskView.Commands.View.List');
        break;
    }

    return t('tasks:TaskView.Commands.View.Main') + ' : ' + subText;
  };

  return {
    key: 'switchView',
    disabled: props.disabled,
    text: getMainText(),
    iconProps: { iconName: 'View' },
    subMenuProps: viewTypeMenuProps,
  };
};

//
// Button new task/event
//
interface ITasksViewButtonAddTaskEventProps {
  onAdd: (template: Task | undefined) => void;
  onNavigateToTemplates: () => void;
  disabled?: boolean | undefined;
  className?: string;
}

const getTemplates = (
  onClick: (template: Task) => void,
  onNavigateToTemplates: () => void,
  appContext: IAppContext,
  t: TFunction<string[]>,
  navigateExternal?: boolean,
): IContextualMenuProps => {
  const output: IContextualMenuProps = {
    items: [
      {
        key: 'h1',
        itemType: ContextualMenuItemType.Header,
        text: t('tasks:TaskView.Commands.AddFromTemplate'),
        iconProps: templateIcon,
      },
    ],
    directionalHint: DirectionalHint.bottomLeftEdge,
    directionalHintFixed: true,
    calloutProps: {
      calloutMaxHeight: 400,
    },
  };

  const templates = appContext.globalDataCache.templates.items.filter((t) => !t.isCompleted() && !t.systemTaskType);
  templates.sort((a, b) => sortOnString(a.name, b.name));

  if (templates && templates.length > 0) {
    output.items.push(
      ...templates.map((_itm: Task) => {
        return {
          key: `${_itm.taskId}`,
          text: `${_itm.name}`,
          iconProps: _itm.taskType === TaskTypes.Template ? taskIcon : eventIcon,
          onClick: () => onClick(_itm),
        };
      }),
    );
  } else if (!navigateExternal && hasUserFeatureGenericManager(appContext)) {
    output.items.push({
      key: 'no-items',
      onRenderContent: () => {
        return (
          <Link styles={{ root: { padding: 5 } }} onClick={() => onNavigateToTemplates()}>
            {t('task:TopTasks.NoTemplateItems')}
          </Link>
        );
      },
    });
  } else {
    output.items.push({
      key: 'no-items',
      text: t('task:TopTasks.NoTemplateItemsUser'),
    });
  }

  return output;
};

export const getCommandBarItemsAddTaskEvent = (
  props: ITasksViewButtonAddTaskEventProps,
  appContext: IAppContext,
  t: TFunction<string[]>,
  navigateExternal?: boolean,
): ICommandBarItemProps => {
  return {
    className: props.className,
    key: 'add',
    split: true,
    disabled: props.disabled || !hasUserRolePermission(appContext, PermissionTypes.CreateTask),
    text: t('tasks:TaskView.Commands.Add'),
    iconProps: { iconName: 'Add' },
    onClick: () => props.onAdd(undefined),
    subMenuProps: getTemplates(props.onAdd, props.onNavigateToTemplates, appContext, t, navigateExternal),
  };
};
