import React, { FunctionComponent, useContext, useState } from 'react';
import { Stack, Spinner, SpinnerSize, Checkbox, Label, Text } from '@fluentui/react';

import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import UserTasksChart, { IUserChart } from 'components/Charts/UserTasksChart';
import { apiGetWidgetDataOpenTasksPerUser } from 'services/Api/dashboardService';
import { UserTaskStat } from 'models/dashboardScene';
import { useHistory } from 'react-router-dom';
import Tag from 'models/tag';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import { useTranslation } from 'react-i18next';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import { IWidgetRendererProps } from '../WidgetRenderer';

export class WidgetOpenTasksPerUserConfig {
  tagIds: number[];

  showTags: boolean;

  tags: Tag[];

  overDeadline: boolean;

  constructor() {
    this.tagIds = [];
    this.showTags = false;
    this.tags = [];
    this.overDeadline = false;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.tagIds = newRawConfig.tagIds ?? [];
        this.showTags = newRawConfig.showTags ?? false;
        this.overDeadline = newRawConfig.overDeadline ?? false;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetOpenTasksPerUserConfig {
    const newConfig = new WidgetOpenTasksPerUserConfig();
    newConfig.tagIds = [...this.tagIds];
    newConfig.tags = [...this.tags];
    newConfig.showTags = this.showTags;
    newConfig.overDeadline = this.overDeadline;

    return newConfig;
  }
}

interface IWidgetOpenTasksPerUserProps extends IWidgetRendererProps {}

const WidgetOpenTasksPerUser: FunctionComponent<IWidgetOpenTasksPerUserProps> = (
  props: IWidgetOpenTasksPerUserProps,
) => {
  const appContext = React.useContext(AppContext);
  const { t } = useTranslation(['translation', 'widgets']);
  const history = useHistory();
  const [data, setData] = React.useState<UserTaskStat[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<WidgetOpenTasksPerUserConfig | undefined>(undefined);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        await appContext.globalDataCache.tags.getItems();
        const config = loadConfig();
        setConfig(config);

        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const data = await apiGetWidgetDataOpenTasksPerUser(config.tags, config.overDeadline, accessToken);
        setData(data.sort((a, b) => b.taskCount - a.taskCount));
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetOpenTasksPerUserConfig => {
    const newConfig = new WidgetOpenTasksPerUserConfig();
    newConfig.load(props.widget.widgetConfig);
    newConfig.tags = appContext.globalDataCache.tags.getItemsForId(newConfig.tagIds).filter((t) => t.tagId !== 0);

    return newConfig;
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  const getChartData = (data: UserTaskStat[]): IUserChart[] => {
    const chartData: IUserChart[] = [];

    for (let idx = 0; idx < data.length; idx++) {
      const d = data[idx];
      const userData: IUserChart = {
        user: appContext.globalDataCache.users.get(d.userId).name,
        userId: d.userId,
        taskCount: d.taskCount,
      };
      const existingUser = chartData.find((c) => c.user === userData.user);
      if (existingUser) {
        //there can be users with the same name which is confusing in the graph and leads to React duplicate keys
        //add last 10 chars of email
        userData.user = userData.user + ' (' + appContext.globalDataCache.users.get(d.userId).email.slice(-10) + ')';
      }
      chartData.push(userData);
    }

    return chartData;
  };

  const onUserChartClick = (data: IUserChart) => {
    const baseParam = '/tasks/alltasks';

    const config = loadConfig();
    const tagStr = config.tags.length > 0 ? `&tags=${config.tags.map((t) => t.tagId.toString()).join(',')}` : '';
    const deadlineStr = config.overDeadline ? '&deadline=deadlineTooLate' : '';

    const url = `${baseParam}?user=${data.userId}&open=true${tagStr}${deadlineStr}`;
    history.push(url);
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }
  
  if (data.length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:TaskStatus.NoTasks')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      {config && config.tags.length > 0 && config.showTags && (
        <Stack.Item styles={{ root: { maxHeight: 44, overflowY: 'hidden' } }}>
          <KeyValueTags tags={config.tags} compact tagMaxWidth={200} />
        </Stack.Item>
      )}
      <Stack.Item grow>
        <UserTasksChart
          height={config && config.tags.length > 0 && config.showTags ? 160 : 220}
          width={280}
          userTasksData={getChartData(data)}
          onClick={onUserChartClick}
        ></UserTasksChart>
      </Stack.Item>
    </Stack>
  );
};

export default WidgetOpenTasksPerUser;
//
// Config
//

interface IWidgetConfigWidgetOpenTasksPerUserProps extends IWidgetConfigRendererProps {}

export const WidgetConfigOpenTasksPerUser: FunctionComponent<IWidgetConfigWidgetOpenTasksPerUserProps> = (
  props: IWidgetConfigWidgetOpenTasksPerUserProps,
) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [overDeadline, setOverDeadline] = useState<boolean>(false);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetOpenTasksPerUserConfig => {
    let newRawConfig = new WidgetOpenTasksPerUserConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
      newRawConfig.tags = appContext.globalDataCache.tags
        .getItemsForId(newRawConfig.tagIds)
        .filter((t) => t.tagId !== 0);
    }

    return newRawConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await appContext.globalDataCache.tags.getItems();
      const config = loadConfig();

      setOverDeadline(config.overDeadline);

      if (config.tags) {
        setSelectedTags(config.tags);
      }

      setShowTags(config.showTags);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfig = (tags: Tag[], showTags: boolean, overDeadline: boolean): WidgetOpenTasksPerUserConfig => {
    const config = new WidgetOpenTasksPerUserConfig();
    config.tagIds = tags.map((t) => t.tagId);
    config.showTags = showTags;
    config.overDeadline = overDeadline;

    return config;
  };

  const onAdd = (tag: Tag) => {
    const newTags = [...selectedTags, tag];
    setSelectedTags(newTags);
    const config = getConfig(newTags, showTags, overDeadline);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onRemove = (tag: Tag) => {
    const newTags = selectedTags.filter((t) => t.tagId !== tag.tagId);
    setSelectedTags(newTags);
    const config = getConfig(newTags, showTags, overDeadline);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetShowTags = (checked: boolean) => {
    setShowTags(checked);
    const config = getConfig(selectedTags, checked, overDeadline);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetOverDeadline = (checked: boolean) => {
    setOverDeadline(checked);
    const config = getConfig(selectedTags, showTags, checked);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <Checkbox
          checked={overDeadline}
          label={t('widgets:OpenTasksPerUser.Config.LabelOverDeadline')}
          onChange={(ev, checked) => {
            onSetOverDeadline(checked ?? false);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <Label>{t('widgets:OpenTasksPerUser.Config.Label')}</Label>
        <KeyValueTagPicker
          itemLimit={5}
          selectedTags={selectedTags}
          onAdd={onAdd}
          onRemove={onRemove}
          isLoading={isLoading}
          allowCreate={false}
        ></KeyValueTagPicker>
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={showTags}
          label={t('widgets:OpenTasksPerUser.Config.LabelShowTags')}
          onChange={(ev, checked) => {
            onSetShowTags(checked ?? false);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
