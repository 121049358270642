export class SearchResult {
    id: number;

    rank: number;

    searchResultType: number;

    code?: string;

    name: string;

    entityType: number;

    entityId: string;

    snippet?: string;

    modified?: Date;

    spName?: string;

    spWebUrl?: string;

    spSnippet?: string;

    spLinkId?: number;

    spSiteId?: string;

    spListId?: string;

    spOdataType?: string;

    tagIds: number[];

    normIds: number[];

    ownerId?: string;

    ownerRoleId?: string;

    assignmentId?: string;

    constructor()
    {
        this.id = 0;
        this.rank = 0;
        this.name = '';
        this.entityType = 0;
        this.entityId = '';
        this.tagIds = [];
        this.normIds = [];
        this.searchResultType = 0;
    }
}