import { useRef, useEffect, useLayoutEffect, useCallback } from 'react';

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useKeyPress = (keys: string[], callback: (event: KeyboardEvent) => void, node = null) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (key.startsWith('ctrl+')) {
          key = key.substring(5);
          if (event.key === key && event.ctrlKey === true) {
            callbackRef.current(event);
          }
        } else {
          if (event.key === key) {
            callbackRef.current(event);
          }
        }
      }
    },
    [keys],
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    if (targetNode) targetNode.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};
