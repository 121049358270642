import React, { Fragment, useContext, useState } from 'react';
import { Panel, IconButton, Toggle, Link, Text , ComboBox, IComboBox, IComboBoxOption , Stack } from '@fluentui/react';


import AppContext from 'App/AppContext';
import { navBarItemStyles } from './NavBarStyles';
import { useTranslation } from 'react-i18next';
import { navigateToExternalUrl } from 'utils/url';
import {
  globalStackTokensGapLarge,
  globalTextStylesPaddingLabelPanel,
  globalStackStylesPanel,
  globalLayerRightSideBarProps,
  settingsIcon,
} from 'globalStyles';
import UserLanguage from 'models/userLanguage';
import { apiUpdateUser } from 'services/Api/userService';
import { apiRequest } from 'services/Auth/authConfig';
import { useHistory } from 'react-router-dom';
import { HomeAccount } from 'models/userSetting';
import { apiSetUserSetting } from 'services/Api/settingService';
import TextInfo from 'components/Notification/Info';
import SettingsNotificationsModal from './SettingsNotificationsModal';

interface ISettingsNavItemProps {
  togglePanel: () => void;
  isOpen: boolean;
}

const SettingsNavItem = (props: ISettingsNavItemProps) => {
  const { t } = useTranslation(['translation']);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [showNotificationsModal, setShowNotificationsModal] = useState<boolean>(false);

  const getLanguageComboOptions = (): IComboBoxOption[] => {
    const languageComboItems: IComboBoxOption[] = [];

    const comboOption: IComboBoxOption = {
      key: 'cancel',
      text: t('translation:Settings.OverrideLanguage-Cancel'),
    };
    languageComboItems.push(comboOption);

    const lngs = UserLanguage.getSupportedLanguages();
    for (const lng of lngs) {
      const comboOption: IComboBoxOption = {
        key: lng[0],
        text: lng[1],
      };
      languageComboItems.push(comboOption);
    }

    return languageComboItems;
  };

  const getHomeAccountComboOptions = (): IComboBoxOption[] => {
    const homeAccountComboOptions: IComboBoxOption[] = [];
    const accounts = appContext.user.login.tenants.filter((t) => t.id === t.topLevelParentTenantId); //filter out OU's

    if (accounts) {
      for (const account of accounts) {
        if (account.azureTenantId) {
          const comboOption: IComboBoxOption = {
            key: account.azureTenantId,
            text: account.name,
          };
          homeAccountComboOptions.push(comboOption);
        }
      }
    }

    return homeAccountComboOptions.sort((a, b) => a.text.localeCompare(b.text));
  };

  const setHomeAccount = async (tenantId: string | undefined) => {
    if (!tenantId) return;

    try {
      appContext.showContentLoader();

      appContext.globalDataCache.userSettings.set(HomeAccount, tenantId);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiSetUserSetting(HomeAccount, tenantId, accessToken);
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const setUserLanguage = async (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined,
  ): Promise<void | undefined> => {
    if (option) {
      try {
        appContext.showContentLoader();
        let newLang = option.key as string;

        if (newLang === 'cancel') {
          // restore the language code of the Microsoft account of the user
          newLang = '';
        }

        //update the application language
        newLang = await appContext.changeUserLanguage(newLang);
        //override the default lanauge of the user
        appContext.user.login.userLanguageCode = newLang;

        //update the user preference in the back-end
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        await apiUpdateUser(appContext.user, accessToken);

        //reload the page to make sure every component is initialized again
        reloadPage();
      } catch (err) {
        appContext.setError(err);
      } finally {
        appContext.hideContentLoader();
      }
    }
  };

  const reloadPage = () => {
    history.push({ pathname: '/reload' });
    history.goBack();
  };

  return (
    <Fragment>
      <Stack.Item>
        <IconButton styles={navBarItemStyles} iconProps={settingsIcon} onClick={props.togglePanel}></IconButton>
      </Stack.Item>
      <Panel
        headerText={t('translation:Settings.Title')}
        isBlocking={false}
        isOpen={props.isOpen}
        onDismiss={props.togglePanel}
        isFooterAtBottom={true}
        layerProps={globalLayerRightSideBarProps}
      >
        <Stack styles={globalStackStylesPanel} tokens={globalStackTokensGapLarge}>
          <Stack.Item>
            <Text styles={globalTextStylesPaddingLabelPanel} block>
              {t('translation:Settings.Theme-Switch')}
            </Text>
            <Toggle
              checked={appContext.useDarkMode}
              onText={t('translation:General.Toggle.On')}
              offText={t('translation:General.Toggle.Off')}
              onChange={() => {
                appContext.setUseDarkMode(!appContext.useDarkMode);
                reloadPage();
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Text styles={globalTextStylesPaddingLabelPanel} block>
              {t('translation:Settings.ChangeLanguage-Label')}
            </Text>
            <Link onClick={() => navigateToExternalUrl(appContext.user.getMyAccountLanguageURL(), '', '')}>
              {t('translation:Settings.ChangeLanguage-Link')}
            </Link>
          </Stack.Item>
          <Stack.Item>
            <Text styles={globalTextStylesPaddingLabelPanel} block>
              {t('translation:Settings.OverrideLanguage-Text')}
            </Text>
            <ComboBox
              allowFreeform={false}
              autoComplete="on"
              useComboBoxAsMenuWidth
              options={getLanguageComboOptions()}
              onChange={setUserLanguage}
              defaultSelectedKey={appContext.user.login.userLanguageCode}
            />
          </Stack.Item>
          <Stack.Item>
            <Text styles={globalTextStylesPaddingLabelPanel} block>
              {t('translation:Settings.Notifications-Label')}
            </Text>
            <Link onClick={() => setShowNotificationsModal(true)}>{t('translation:Settings.Notifications-Link')}</Link>
          </Stack.Item>
          <Stack.Item>
            <TextInfo message={t('translation:Settings.DefaultOrgInfo')}>
              <Text styles={globalTextStylesPaddingLabelPanel}>{t('translation:Settings.DefaultOrg')}</Text>
            </TextInfo>
            <ComboBox
              allowFreeform={false}
              autoComplete="on"
              useComboBoxAsMenuWidth
              options={getHomeAccountComboOptions()}
              onChange={(event, option, index, value) => setHomeAccount(option?.key as string)}
              selectedKey={appContext.globalDataCache.userSettings.get(HomeAccount) as string}
            />
          </Stack.Item>
        </Stack>
      </Panel>
      <SettingsNotificationsModal isOpen={showNotificationsModal} onClose={() => setShowNotificationsModal(false)} />
    </Fragment>
  );
};

export default SettingsNavItem;
