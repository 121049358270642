export enum SessionStorageKeys {
  NotSet = '',
  StartQuery = 'REDLAB-Start-Query'
}

export function setSessionStorageData(name: SessionStorageKeys, value: string) {
  try {
    if (name === SessionStorageKeys.NotSet) return;
    window.sessionStorage.setItem(name, value);
  } catch {
    // no-op
  }
}

export function getSessionStorageData(name: SessionStorageKeys): string | undefined {
  try {
    if (name === SessionStorageKeys.NotSet) return;
    const val = window.sessionStorage.getItem(name);
    if (!val) return undefined;

    return val;
  } catch {
    return undefined;
  }
}

export function removeSessionStorageData(name: SessionStorageKeys) {
  try {
    if (name === SessionStorageKeys.NotSet) return;
    window.sessionStorage.removeItem(name);
  } catch {
    // no-op
  }
}

export function removeAllSessionStorageData() {
  try {
    window.sessionStorage.clear();
  } catch{
    // no-op
  }
}
