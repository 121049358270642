import React, { useContext, useEffect, useState } from 'react';
import {
  Panel,
  PanelType,
  PrimaryButton,
  DefaultButton,
  IGroup,
  DetailsListLayoutMode,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  IDetailsGroupRenderProps,
  Selection,
  IColumn,
  Text,
  IObjectWithKey,
  IconButton,
  Separator,
  TextField,
  Link,
 Stack } from '@fluentui/react';

import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';
import {
  globalLayerRightSideBarProps,
  globalStackTokensGapSmall,
  globalStackStylesPanel,
  globalStackTokensGapMedium,
  backIcon,
  globalTextStylesBold,
} from 'globalStyles';
import {
  onRenderDetailsHeaderGlobal,
  freshdeskWidgetOpenTicket,
  freshdeskWidgetSetSubject,
  freshdeskWidgetSetContactInfo,
  freshdeskWidgetSetTranslations,
} from 'globalFunctions';
import { globalFilterDelay } from 'globalConstants';
import Widget, {
  getWidgetCategories,
  getWidgetTypes,
  IWidgetCategory,
  IWidgetType,
  Widget_Translation,
} from 'models/widget';
import WidgetConfigRenderer from './WidgetConfigRenderer';
import Language from 'models/language';
import Dashboard from 'models/dashboard';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';

interface IAddWidgetPanelProps {
  dashboard: Dashboard;
  isOpen: boolean;
  allowSharedDataWidgets: boolean;
  onAdd: (widget: Widget) => void;
  onClose: () => void;
}

const AddWidgetPanel = (props: IAddWidgetPanelProps) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [categories, setCategories] = useState<IWidgetCategory[]>([]);
  const [widgets, setWidgets] = useState<IWidgetType[]>([]);
  const [widgetsDisplay, setWidgetsDisplay] = useState<IWidgetType[]>([]);
  const [selectedWidget, setSelectedWidget] = useState<IWidgetType | undefined>(undefined);
  const [configuredWidget, setConfiguredWidget] = useState<Widget | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [groups, setGroups] = useState<IGroup[] | undefined>(undefined);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [nameError, setNameError] = useState<boolean>(false);
  const [configError, setConfigError] = useState<boolean>(false);

  useEffect(() => {
    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSelection = () => {
    return new Selection({
      getKey: (item: IWidgetType) => {
        return item.id;
      },
      onSelectionChanged: () => {
        const item = selection.getSelection()[0] as IWidgetType;
        setSelectedWidget(item);
      },
    });
  };

  const [selection] = React.useState(createSelection());

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      const categories = getWidgetCategories(appContext, t);
      categories.sort((a, b) => a.name.localeCompare(b.name));
      setCategories(categories);

      let widgets = getWidgetTypes(t);
      if (!props.allowSharedDataWidgets) {
        widgets = widgets.filter((w) => !Widget.needSharedData(w.type));
      }

      widgets.sort((a, b) => {
        if (a.category === b.category) {
          return a.name.localeCompare(b.name);
        } else {
          const catA = categories.find((c) => c.id === a.category);
          const catB = categories.find((c) => c.id === b.category);
          if (!catA || !catB) return 0;

          return catA.name.localeCompare(catB.name);
        }
      });

      setWidgets(widgets);
      setWidgetsDisplay(widgets);

      calcGroups(categories, widgets, searchText);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getGroups = (
    categories: IWidgetCategory[],
    widgets: IWidgetType[],
    searchText: string | undefined,
  ): IGroup[] | undefined => {
    let start: number = 0;

    const groups = categories.map((category) => {
      const count = widgets.filter((l) => l.category === category.id).length;
      const group: IGroup = {
        key: category.name,
        name: category.name,
        startIndex: start,
        count: count,
        level: 0,
        isCollapsed: searchText && count > 0 ? false : true,
      };
      start += count;

      return group;
    });

    return groups.filter((g) => g.count > 0);
  };

  const onUpdateConfig = (config: string, isValid: boolean) => {
    if (!configuredWidget) return;

    if (isValid) {
      const newWidget = configuredWidget.clone();
      newWidget.widgetConfig = config;
      setConfiguredWidget(newWidget);
    }

    setConfigError(!isValid);
  };

  const onSelect = () => {
    if (!selectedWidget) return;
    const newWidget = getDefConfigWidgetFromType(selectedWidget);
    setConfiguredWidget(newWidget);
  };

  const getDefConfigWidgetFromType = (widgetType: IWidgetType): Widget => {
    const newWidget = new Widget();
    newWidget.dashboard = props.dashboard;
    newWidget.dashboardId = props.dashboard.dashboardId;
    newWidget.name = widgetType.name;
    newWidget.widgetType = widgetType.type;
    newWidget.trans = [new Widget_Translation()];
    newWidget.trans[0].name = newWidget.name;
    newWidget.trans[0].lang = new Language();
    newWidget.trans[0].lang.code = appContext.user.language.code;
    newWidget.transIdx = 0;

    return newWidget;
  };

  const onAddWidget = () => {
    if (!configuredWidget) return;
    props.onAdd(configuredWidget);
  };

  const calcGroups = (categories: IWidgetCategory[], widgets: IWidgetType[], searchText: string | undefined) => {
    setGroups(getGroups(categories, widgets, searchText));
  };

  const onChangeText = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string): void => {
    if (filterText) {
      const filteredWidgets = widgets.filter(
        (widget) =>
          widget.name.toLowerCase().indexOf(filterText) >= 0 ||
          widget.description.toLowerCase().indexOf(filterText) >= 0,
      );
      setSearchText(filterText.toLowerCase());
      setWidgetsDisplay(filteredWidgets);
      calcGroups(categories, filteredWidgets, filterText);
    } else {
      setWidgetsDisplay(widgets);
      calcGroups(categories, widgets, undefined);
      setSearchText('');
    }
  };

  const onGroupClick = (group: IGroup) => {
    const listId = Number.parseInt(group.key);
    const category = categories.find((l) => l.id === listId);
    if (category) {
      const keysToSelect = widgetsDisplay.filter((l) => l.category === category.id).map((l) => l.id);
      keysToSelect.forEach((k) => selection.setKeySelected(k.toString(), true, false));
    }
  };

  const getGroupProps = (): IDetailsGroupRenderProps | undefined => {
    return {
      showEmptyGroups: false,
      isAllGroupsCollapsed: true,
      headerProps: {
        onGroupHeaderClick: onGroupClick,
      },
    };
  };

  const onColumnClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
    if (!column) return;
    sortByColumn(column.key, false);
  };

  const sortByColumn = (columnKey: string, keepDirection: boolean, setDescending?: boolean) => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn = newColumns.find((currCol) => columnKey === currCol.key);
    if (!currColumn) return;

    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === currColumn.key) {
        if (!keepDirection) {
          if (setDescending !== undefined) {
            currColumn.isSortedDescending = setDescending;
          } else {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
          }
        }
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    const widgets = copyAndSort(widgetsDisplay, columnKey, currColumn.isSortedDescending);
    setColumns(newColumns);
    setWidgets(widgets);

    calcGroups(categories, widgets, searchText);
  };

  const copyAndSort = (widgets: IWidgetType[], columnKey: string, isSortedDescending?: boolean): IWidgetType[] => {
    if (columnKey === 'name') {
      widgets.sort((a, b) => {
        if (a.category === b.category) {
          return a.name.localeCompare(b.name);
        } else {
          const catA = categories.find((c) => c.id === a.category);
          const catB = categories.find((c) => c.id === b.category);
          if (!catA || !catB) return 0;

          return catA.name.localeCompare(catB.name);
        }
      });
    }

    return widgets.slice();
  };

  const onSetWidgetName = (value: string | undefined) => {
    if (!configuredWidget) return;
    const widgetClone = configuredWidget.clone();
    widgetClone.name = value?.trimStart() ?? '';
    setConfiguredWidget(widgetClone);
  };

  const hasError = (): boolean => {
    return nameError || configError;
  };

  const onGetWidgetNameError = (value: string | undefined): string | undefined => {
    if (value) {
      setNameError(false);

      return undefined;
    } else {
      setNameError(true);

      return t('dashboard:AddWidgetPanel.NameRequired');
    }
  };

  const openTicket = () => {
    freshdeskWidgetOpenTicket();
    freshdeskWidgetSetContactInfo(appContext.user.name, appContext.user.email);
    freshdeskWidgetSetTranslations();
    freshdeskWidgetSetSubject(t('dashboard:AddWidgetPanel.RequestWidgetTicketSubject'));
  };

  //
  // Render
  //

  const getColumns = (): IColumn[] => {
    return [
      {
        isMultiline: true,
        key: 'name',
        name: t('dashboard:AddWidgetPanel.Columns.Name'),
        minWidth: 100,
        maxWidth: 200,
        isSorted: true,
        isSortedDescending: false,
        isResizable: true,
        onRender: (item?: IWidgetType, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{item.name}</Text>;
        },
      },
      {
        isMultiline: true,
        key: 'description',
        name: t('dashboard:AddWidgetPanel.Columns.Description'),
        minWidth: 100,
        maxWidth: 200,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,
        isCollapsible: true,
        onRender: (item?: IWidgetType, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{item.description}</Text>;
        },
      },
    ];
  };

  const [columns, setColumns] = React.useState<IColumn[]>(getColumns());

  const onRenderFooterContent = () => {
    return (
      <Stack horizontal styles={globalStackStylesPanel} tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <PrimaryButton onClick={() => onSelect()} disabled={selectedWidget === undefined}>
            {t('translation:General.Button.Select')}
          </PrimaryButton>
        </Stack.Item>
        <Stack.Item>
          <DefaultButton onClick={props.onClose} disabled={isLoading}>
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack.Item>
      </Stack>
    );
  };

  const onRenderFooterContentConfiguredWidget = () => {
    return (
      <Stack horizontal styles={globalStackStylesPanel} tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <PrimaryButton onClick={() => onAddWidget()} disabled={selectedWidget === undefined || hasError()}>
            {t('translation:General.Button.Add')}
          </PrimaryButton>
        </Stack.Item>
        <Stack.Item>
          <DefaultButton onClick={props.onClose} disabled={isLoading}>
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack.Item>
      </Stack>
    );
  };

  return (
    <Panel
      headerText={t('dashboard:AddWidgetPanel.Title')}
      type={PanelType.medium}
      isBlocking={true}
      isHiddenOnDismiss={false}
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      isFooterAtBottom={true}
      layerProps={globalLayerRightSideBarProps}
      onRenderFooterContent={configuredWidget ? onRenderFooterContentConfiguredWidget : onRenderFooterContent}
    >
      {configuredWidget && (
        <Stack verticalFill styles={globalStackStylesPanel} tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Stack horizontal verticalAlign="start">
              <Stack.Item>
                <IconButton
                  iconProps={backIcon}
                  onClick={() => {
                    setConfiguredWidget(undefined);
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                <Stack>
                  <Text variant="large" styles={globalTextStylesBold}>
                    {selectedWidget?.name}
                  </Text>
                  {!appContext.isMobileView && <Text variant="medium"> {selectedWidget?.description}</Text>}
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t('dashboard:AddWidgetPanel.NameLabel')}
              autoFocus={!appContext.isMobileView}
              required
              value={configuredWidget.name}
              onChange={(ev, newValue) => onSetWidgetName(newValue)}
              onGetErrorMessage={onGetWidgetNameError}
              maxLength={128}
            />
            <Separator />
          </Stack.Item>
          <Stack.Item grow>
            <WidgetConfigRenderer widget={configuredWidget} onUpdateConfig={onUpdateConfig} editmode={false} />
          </Stack.Item>
        </Stack>
      )}
      {!configuredWidget && (
        <Stack verticalFill styles={globalStackStylesPanel} tokens={globalStackTokensGapMedium}>
          <Stack.Item>
            <Text block>{t('dashboard:AddWidgetPanel.Info')}</Text>
            <Link onClick={() => openTicket()}>{t('dashboard:AddWidgetPanel.MoreLink')}</Link>
          </Stack.Item>
          <Stack.Item>
            <SearchBox
              placeholder={t('translation:General.Filter.Placeholder')}
              underlined
              id="search"
              onChange={(ev, newValue) => {
                if (searchTimer) {
                  clearTimeout(searchTimer);
                }
                setSearchTimer(setTimeout(() => onChangeText(ev, newValue), globalFilterDelay));
              }}
              styles={{ root: { width: 300 } }}
            />
          </Stack.Item>
          <ScrollableStackItem isOnPanel={true}>
            <ShimmeredDetailsList
              onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
              compact
              shimmerLines={5}
              enableShimmer={isLoading}
              layoutMode={DetailsListLayoutMode.justified}
              items={widgetsDisplay}
              columns={columns}
              groups={groups}
              groupProps={getGroupProps()}
              onColumnHeaderClick={onColumnClick}
              selectionMode={SelectionMode.single}
              selection={selection as Selection<IObjectWithKey>}
            />
          </ScrollableStackItem>
        </Stack>
      )}
    </Panel>
  );
};

export default AddWidgetPanel;
