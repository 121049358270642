import React, { useContext, useState } from 'react';
import { IconButton, Modal, ProgressIndicator, Separator, Stack, Text } from '@fluentui/react';
import ResourceList from 'models/resourceList';
import { cancelIcon, globalStackTokensGapMedium, globalTextStylesCardRed } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';
import { graphSharepointLibraryRequest } from 'services/Auth/authConfig';
import SharepointFilePicker from 'components/SharePoint/SharepointFilePicker';
import ButtonDialogFooter from 'components/Footer/ButtonDialogFooter';
import FilePicker from 'components/Pickers/FilePicker';
import { cancelGraphAsyncProces, graphUploadFileToFolder } from 'services/Graph/graphService';
import { allowedMimeTypesForUpload, globalAppName } from 'globalConstants';
import { matchWildcard, stripFileExtension } from 'utils/string';
import { IDriveItem } from 'services/Graph/SharepointInterfaces';
import { ParentFolderInformation } from 'components/SharePoint/DirectoryExplorer';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import AppError from 'utils/appError';

interface ILinkCreatorDocumentLibraryProps {
  isOpen: boolean;
  list: ResourceList;
  dismiss: () => void;
  addLinks: (links: ResourceLink[]) => void;
  listLinks: ResourceLink[];
  existingLinks: ResourceLink[];
  listLoading: boolean;
  linkToEdit?: ResourceLink;
  hideWholeList?: boolean;
  singleSelect?: boolean;
}
const LinkCreatorDocumentLibrary = (props: ILinkCreatorDocumentLibraryProps) => {
  const { t } = useTranslation(['library', 'translation']);
  const appContext = useContext(AppContext);
  const [selectedFileName, setSelectedFileName] = useState<string | undefined>(undefined);
  const [selectedDriveItems, setSelectedDriveItems] = useState<IDriveItem[]>([]);
  const [selectedDriveFolder, setSelectedDriveFolder] = useState<IDriveItem | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [fileTypeError, setFileTypeError] = useState<boolean>(false);
  const [fileNameError, setFileNameError] = useState<string | undefined>(undefined);
  const [linkPickError, setLinkPickError] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSize, setUploadSize] = useState<number>(0);
  const [uploadSend, setUploadSend] = useState<number>(0);

  const fileUploadToFolder = async (
    file: File,
    name: string,
    folder: IDriveItem | undefined,
  ): Promise<IDriveItem | undefined> => {
    if (!props.list.spDriveId || !props.list.spDriveItemId || !selectedFile || !selectedFileName) return undefined;

    const graphInterface = await appContext.getGraphInterface(
      graphSharepointLibraryRequest.scopes,
      props.list.altTenantId,
    );

    const uploadedItem = await graphUploadFileToFolder(
      graphInterface.client,
      file,
      props.list.spDriveId,
      folder?.id || props.list.spDriveItemId, //upload to currently selected folder or root folder of list if none selected
      name,
      t('library:Picker.UploadDescription', { appName: globalAppName }),
      onUploadProgress,
    );

    return uploadedItem;
  };

  const getLinkForDriveItem = (item: IDriveItem): ResourceLink => {
    let output: ResourceLink;
    if (props.linkToEdit) {
      output = props.linkToEdit.clone();
    } else {
      output = new ResourceLink();
    }

    output.listId = props.list.listId;
    output.list = props.list;
    output.linkName = item.name || 'Unknown';
    output.linkURL = item.webUrl || 'Unknown';
    output.driveItemId = item.id;

    return output;
  };

  const onUploadProgress = (totalBytes: number, sendBytes: number) => {
    setUploadSize(totalBytes);
    setUploadSend(sendBytes);
  };

  const onSubmitWholeListItem = () => {
    const existingLink = props.listLinks.find((l) => l.isWholeList());
    let newLink: ResourceLink;
    if (existingLink) {
      newLink = existingLink;
    } else {
      newLink = new ResourceLink();
      newLink.setWholeList(props.list);
    }
    props.addLinks([newLink]);
    props.dismiss();
  };

  const getExistingLink = (links: ResourceLink[], newLink: ResourceLink): ResourceLink | undefined => {
    const linkWithId = links.find((link) => link.driveItemId === newLink.driveItemId);
    const linkWithName = links.find((link) => link.linkName.toLowerCase() === newLink.linkName.toLowerCase());

    if (linkWithId) {
      return linkWithId;
    } else if (linkWithName) {
      const linkCopies = links.filter((link) =>
        link.linkName.toLowerCase().startsWith(newLink.linkName.toLowerCase() + ' ('),
      );
      newLink.linkName += ` (${linkCopies.length + 2})`;

      return newLink;
    }

    return undefined;
  };

  const onSubmit = async () => {
    if (disableAdd()) return;
    try {
      if (selectedFile && selectedFileName) {
        //user selected a file (paste, drop, file explorer)
        setIsUploading(true);
        const newDriveItem = await fileUploadToFolder(selectedFile, selectedFileName, selectedDriveFolder);
        if (newDriveItem) {
          const newLink = getLinkForDriveItem(newDriveItem);
          if (newLink) {
            props.addLinks([newLink]);
          }
        } else {
          appContext.showNotification(
            t('library:Picker.FileErrorUpload', { info: t('library:Picker.FileNull') }),
            true,
          );

          return;
        }
      } else if (
        selectedDriveItems.length > 0 &&
        !selectedDriveItems.find((selectedDriveItem) => selectedDriveItem.folder !== undefined)
      ) {
        //user selected file(s) from Sharepoint
        const newLinks: ResourceLink[] = [];
        for (let idx = 0; idx < selectedDriveItems.length; idx++) {
          const item = selectedDriveItems[idx];
          const newLink = getLinkForDriveItem(item);
          if (props.linkToEdit) {
            //always push the item from the picker, this is already based on the linkToEdit
            newLinks.push(newLink);
          } else {
            const existingLink = getExistingLink(props.listLinks, newLink);
            if (existingLink) {
              //user choose a link that was already in the library
              newLinks.push(existingLink);
            } else {
              //user choose a new link from sharepoint
              newLinks.push(newLink);
            }
          }
        }
        props.addLinks(newLinks);
      }
      props.dismiss();
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        appContext.showNotification(t('library:Picker.FileErrorUpload', { info: graphErrorMsg }), true);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsUploading(false);
    }
  };

  const onPickDriveItem = (items: IDriveItem[] | undefined) => {
    setSelectedFile(undefined);
    setSelectedFileName(undefined);

    setFileTypeError(false);
    setLinkPickError(false);
    setFileNameError(undefined);

    if (items?.length && items.length > 0) {
      if (items[0].folder) {
        setSelectedDriveFolder(items[0]);
      }
      setSelectedDriveItems(items);
    } else {
      setSelectedDriveItems([]);
    }
  };

  const onPickFile = (item: File | undefined) => {
    setSelectedFile(item);
    setFileTypeError(false);
    setLinkPickError(false);
    setFileNameError(undefined);
    if (!item) {
      setSelectedFileName(undefined);
    }
  };

  const disableAdd = (): boolean => {
    //in the process of uploading
    if (isUploading) {
      return true;
    }

    //user selected an item from sharepoint but it is a folder
    if (
      !selectedFile &&
      (selectedDriveItems.length === 0 || selectedDriveItems.find((selectedDriveItem) => selectedDriveItem.folder))
    ) {
      return true;
    }

    //there is an error
    if (fileTypeError || linkPickError || fileNameError) {
      return true;
    }

    return false;
  };

  const pasteHandler = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    try {
      const data = event.clipboardData.items;
      if (data) {
        let file: File | null = null;
        let invalidFile: boolean = false;

        for (let i = 0; i < data.length; i++) {
          const d = data[i];
          if (d.kind === 'file') {
            const keys = Object.keys(allowedMimeTypesForUpload); //get keys array
            //find any of values contains in current keys.
            if (keys.some((f) => matchWildcard(d.type, f))) {
              file = d.getAsFile();
              invalidFile = false;
              break;
            } else {
              invalidFile = true;
            }
          }
        }

        if (file) {
          setFileTypeError(false);
          onPickFile(file);
          setSelectedFileName(file.name);
          event.preventDefault();
        } else if (invalidFile) {
          setFileTypeError(true);
        }
      }
    } catch {
      onPickFile(undefined);
    }
  };

  const validateFileName = (newName: string | undefined) => {
    if (!newName || newName.trim() === '') {
      setFileNameError(t('library:Picker.FileNameErrorEmpty'));
    } else {
      //invalid chars
      const invalidSharepointChars = '"*:<>?/\\|#%';
      for (let i = 0; i < newName.length; i++) {
        if (invalidSharepointChars.indexOf(newName.charAt(i)) !== -1) {
          setFileNameError(t('library:Picker.FileNameErrorInvalid', { chars: invalidSharepointChars }));

          return;
        }
      }
      const newNameLower = newName.toLowerCase();
      //file name cannot contain '_vti_'
      const invalidVTI = '_vti_';
      if (newNameLower.indexOf(invalidVTI) >= 0) {
        setFileNameError(t('library:Picker.FileNameErrorInvalid', { chars: invalidVTI }));

        return;
      }
      //file name cannot start with '~$'
      const invalidStart1 = '~$';
      if (newNameLower.startsWith(invalidStart1)) {
        setFileNameError(t('library:Picker.FileNameErrorInvalid', { chars: invalidStart1 }));

        return;
      }
      //file name cannot be:
      const invalidNames: string[] = [
        'desktop.ini',
        '.lock',
        'con',
        'prn',
        'aux',
        'nul',
        'com0',
        'com1',
        'com2',
        'com3',
        'com4',
        'com5',
        'com6',
        'com7',
        'com8',
        'com9',
        'lpt0',
        'lpt1',
        'lpt2',
        'lpt3',
        'lpt4',
        'lpt5',
        'lpt6',
        'lpt7',
        'lpt8',
        'lpt9',
      ];
      const nameWithoutExt = stripFileExtension(newNameLower);
      if (invalidNames.includes(nameWithoutExt)) {
        setFileNameError(t('library:Picker.FileNameErrorInvalid', { chars: invalidNames.join(', ') }));

        return;
      }

      setFileNameError(undefined);
    }

    return undefined;
  };

  const onCancel = () => {
    if (isUploading) {
      cancelGraphAsyncProces(true);
    }
    props.dismiss();
  };

  const getParentInfo = (): ParentFolderInformation | undefined => {
    if (props.list.spDriveId && props.list.spDriveItemId) {
      const parentFolderInfo: ParentFolderInformation = {
        driveId: props.list.spDriveId,
        driveItemId: props.list.spDriveItemId,
        tenantId: props.list.altTenantId,
      };

      return parentFolderInfo;
    }

    return undefined;
  };

  return (
    <Modal isBlocking isOpen={props.isOpen} onDismiss={onCancel}>
      <Stack
        verticalFill
        styles={{ root: { height: '90vh', width: 1000, minWidth: 320, maxWidth: '90vw', padding: 20 } }}
        onPaste={pasteHandler}
      >
        {/* Header */}
        <Stack.Item>
          <Stack horizontal horizontalAlign={'space-between'}>
            <Stack.Item>
              {!props.linkToEdit && (
                <Text variant="xLarge">{t('library:LinkComponents.AddItemOf', { name: props.list.name })}</Text>
              )}
              {props.linkToEdit && (
                <Text variant="xLarge">
                  {t('library:LinkComponents.ReplaceItem', { name: props.linkToEdit.linkName, list: props.list.name })}
                </Text>
              )}
            </Stack.Item>
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
          <Separator />
        </Stack.Item>
        {/* Body */}
        <Stack.Item grow>
          <Stack verticalFill tokens={globalStackTokensGapMedium}>
            <Stack.Item grow>
              <SharepointFilePicker
                onSelect={onPickDriveItem}
                invalidAuthToken={props.dismiss}
                parentFolder={getParentInfo()}
                existingLinks={props.existingLinks}
                linkToEdit={props.linkToEdit}
                singleSelect={props.singleSelect}
              />
            </Stack.Item>
            <Stack.Item>
              <FilePicker
                selectedFile={selectedFile}
                selectedFileName={selectedFileName}
                onSelect={(item: File | undefined) => {
                  onPickFile(item);
                }}
                onChangeName={(item: File, newName: string | undefined) => {
                  if (item.size === 0) {
                    setFileNameError(t('library:Picker.FileNameErrorEmpty'));
                  } else {
                    newName = newName?.trim();
                    validateFileName(newName);
                    setSelectedFileName(newName);
                  }
                }}
                fileNameError={fileNameError}
                onFileTypeError={() => setFileTypeError(true)}
                actionPending={isUploading}
              />
            </Stack.Item>
            {fileTypeError && (
              <Stack.Item>
                <Text styles={globalTextStylesCardRed}>{t('library:LinkComponents.FileTypeError')}</Text>
              </Stack.Item>
            )}
            {linkPickError && (
              <Stack.Item>
                <Text styles={globalTextStylesCardRed}>{t('library:LinkComponents.LinkPickError')}</Text>
              </Stack.Item>
            )}
            {isUploading && uploadSize > 0 && (
              <Stack.Item>
                <ProgressIndicator
                  label={`${Math.round((uploadSend / uploadSize) * 100)}%`}
                  barHeight={5}
                  percentComplete={uploadSend / uploadSize}
                />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
        {/*Footer */}
        <Stack.Item>
          <ButtonDialogFooter
            primaryText={props.linkToEdit ? t('translation:General.Button.Save') : t('translation:General.Button.Add')}
            secondaryText={t('translation:General.Button.Cancel')}
            onSubmit={() => {
              onSubmit();
            }}
            onCancel={onCancel}
            buttonPosition="end"
            disablePrimary={disableAdd}
            tertiaryText={props.hideWholeList ? undefined : t('library:LinkComponents.WholeList')}
            tertiaryTooltip={
              props.existingLinks.find((l) => l.isWholeList()) !== undefined
                ? t('library:LinkComponents.Validate.ItemExists')
                : undefined
            }
            disableTertiary={() => props.existingLinks.find((l) => l.isWholeList()) !== undefined}
            onTertiary={onSubmitWholeListItem}
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default LinkCreatorDocumentLibrary;
