import LanguageDTO from 'models/dto/languageDTO';
import Language, { Languages } from 'models/language';

export function mapFromLanguage(languageDTO: LanguageDTO | undefined): Language | undefined {
  if (!languageDTO) {
    return undefined;
  }

  const language = new Language();
  language.languageId = languageDTO.languageId;
  language.code = languageDTO.code;
  language.name = languageDTO.name;

  return language;
}

export function mapToLanguage(language: Language | undefined): LanguageDTO | undefined {
  if (!language) {
    return undefined;
  }

  const languageDTO = new LanguageDTO();
  languageDTO.languageId = language.languageId;
  languageDTO.code = language.code;
  languageDTO.name = language.name;

  return languageDTO;
}

export function mapFromLanguages(languageDTOs: LanguageDTO[]): Languages {
  const languageList: Language[] = [];

  for (const languageDTO of languageDTOs) {
    const newLanguage = new Language();
    newLanguage.languageId = languageDTO.languageId;
    newLanguage.name = languageDTO.name;
    newLanguage.code = languageDTO.code;
    languageList.push(newLanguage);
  }

  const languages = new Languages();
  languages.languages = languageList;

  return languages;
}

export function mapToLanguages(languages: Languages | undefined): LanguageDTO[] | undefined {
  if (!languages) {
    return undefined;
  }
  const languageDtos: LanguageDTO[] = [];

  for (const lang of languages.languages) {
    const newLanguage = new LanguageDTO();
    newLanguage.languageId = lang.languageId;
    newLanguage.name = lang.name;
    newLanguage.code = lang.code;
    languageDtos.push(newLanguage);
  }

  return languageDtos;
}
