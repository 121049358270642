import {
  SpinButton,
  Stack,
  TextField,
  Text,
  IconButton,
  IIconProps,
  Callout,
  Dropdown,
  getTheme,
  IDropdownOption,
} from '@fluentui/react';
import KPI, { KPITypes } from 'models/kpi/kpi';
import { validateMaxValue, validateMinValue } from '../../KPI/KPIHelpers';
import { useTranslation } from 'react-i18next';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { getEntity } from 'globalFunctions';
import { getGlobalTextFieldDisabled, globalStackTokensGapSmall } from 'globalStyles';
import AppContext from 'App/AppContext';
import KPIData from 'models/kpi/kpiData';
import { globalKPIDecimal18c8 } from 'globalConstants';
import RichTextEditor from 'components/Text/RichTextEditor';
import {
  CallOutRowStyle,
  CircleRingIcon,
  SkypeCircleCheckIcon,
  StatusErrorFullIcon,
} from 'components/Checklist/CheckListStyles';
import Entity, { EntityTypes } from 'models/entity';
import { apiRequest } from 'services/Auth/authConfig';
import TaskTypeFormElement, { TaskTypeFieldType } from 'models/tasks/taskTypeFormElement';
import EntityTagPicker from 'components/Pickers/EntityTagPicker';
import Theme from 'models/theme';
import Control from 'models/control';
import Asset from 'models/asset/asset';
import Objective from 'models/objective/objective';
import Risk from 'models/risk';
import { apiGetAssets } from 'services/Api/assetService';
import { apiGetControls } from 'services/Api/controlService';
import { apiGetObjectives } from 'services/Api/objectiveService';
import { apiGetProcesses } from 'services/Api/processService';
import { apiGetRisks } from 'services/Api/riskService';
import { apiGetThemes } from 'services/Api/themeService';
import Process from 'models/process/process';
import {
  SingleFormElementWrapper,
  SingleFormElementInfoKPI,
  SingleFormElementInfoField,
  SingleFormElementAttachmentsField,
} from './SingleFormElementWrappers';
import Task from 'models/tasks/task';
import ResourceLink from 'models/resourceLink';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import StringValue from 'models/stringValue/stringValue';
import { isEmpty } from 'utils/string';
import { addDateDays, getDateDiffDays } from 'utils/datetime';

export interface ISingleFormElementProps {
  field: TaskTypeFormElement;
  task?: Task;
  links: ResourceLink[];
  onUpdateValue: (kpi: KPI, data: KPIData) => void;
  onUpdateKPI: (kpi: KPI) => void;
  onSetKPI: (kpi: KPI) => void;
  onUpdateTaskForForm?: (task: Task) => void;
  addLinks: (links: ResourceLink[]) => void;
  setLinks: (links: ResourceLink[]) => void;
  removeLink: (link: ResourceLink) => void;
  onUpdateField?: (field: TaskTypeFormElement) => void;
  readonly: boolean;
  allowEdit: boolean;
  compact: boolean;
  hideAttachments?: boolean;
  children?: ReactNode;
  onEdit?: (field: TaskTypeFormElement) => void;
  onRemove?: (field: TaskTypeFormElement) => void;
  onMoveUp?: (field: TaskTypeFormElement) => void;
  onMoveDown?: (field: TaskTypeFormElement) => void;
}

//
// Type switcher
//
const SingleFormElement = (props: ISingleFormElementProps) => {
  const appContext = useContext(AppContext);

  try {
    if (props.field.kpi?.data.length === 0) {
      return null;
    }

    const getSingleElement = (): JSX.Element | null => {
      const newProps = { ...props };
      if (props.field.kpi?.hasAutomatedEvidence) newProps.readonly = true;

      if (props.field.fieldType === TaskTypeFieldType.KPI && props.field.kpi) {
        switch (props.field.kpi.type) {
          case KPITypes.Text:
            return <SingleFormElementText {...newProps} />;
          case KPITypes.TextMultiline:
            return <SingleFormElementTextMultiline {...newProps} />;
          case KPITypes.Number:
            return <SingleFormElementNumber {...newProps} />;
          case KPITypes.Html:
            return <SingleFormElementHtml {...newProps} />;
          case KPITypes.SuccesError:
            return <SingleFormElementSuccessError {...newProps} />;
          case KPITypes.Choice:
            return <SingleFormElementChoice {...newProps} />;
          case KPITypes.Date:
            return <SingleFormElementDate {...newProps} />;
          case KPITypes.Expression:
            return <SingleFormElementExpression {...newProps} />;
          default:
            return null;
        }
      } else {
        switch (props.field.fieldType) {
          case TaskTypeFieldType.AttachmentDocument:
          case TaskTypeFieldType.AttachmentListItem:
          case TaskTypeFieldType.AttachmentPage:
          case TaskTypeFieldType.AttachmentURL:
            return <SingleFormElementAttachment {...newProps} />;
          case TaskTypeFieldType.ContextAsset:
          case TaskTypeFieldType.ContextControl:
          case TaskTypeFieldType.ContextObjective:
          case TaskTypeFieldType.ContextProcess:
          case TaskTypeFieldType.ContextRequirement:
          case TaskTypeFieldType.ContextRisk:
            return <SingleFormElementContext {...newProps} />;
          default:
            return null;
        }
      }
    };

    return <SingleFormElementWrapper {...props}>{getSingleElement()}</SingleFormElementWrapper>;
  } catch (err) {
    appContext.setError(err);

    return null;
  }
};

export default SingleFormElement;

//
// Text
//
export const SingleFormElementText = (props: ISingleFormElementProps) => {
  const getValue = (): string | undefined => {
    const currentResult = props.field.kpi?.data[0].resultText;

    if (currentResult) {
      return currentResult;
    } else {
      if (!props.field.kpi) return undefined;
      const defVal = props.field.kpi.defValue;
      if (defVal) {
        const newData = props.field.kpi.data[0];
        if (newData.resultText !== defVal) {
          newData.resultText = defVal;
          props.onUpdateValue(props.field.kpi, newData);
        }

        return defVal;
      } else {
        return '';
      }
    }
  };

  return (
    <Stack>
      <SingleFormElementInfoKPI {...props} />
      <TextField
        readOnly={props.readonly}
        value={getValue()}
        maxLength={props.field.kpi?.maxValue ? Number.parseInt(props.field.kpi?.maxValue) : 1024}
        onChange={(ev, newValue) => {
          if (!props.field.kpi) return;
          const newData = props.field.kpi.data[0];
          newData.resultText = newValue;
          props.onUpdateValue(props.field.kpi, newData);
        }}
      />
    </Stack>
  );
};

//
// Text multiline
//
export const SingleFormElementTextMultiline = (props: ISingleFormElementProps) => {
  const getValue = (): string | undefined => {
    const currentResult = props.field.kpi?.data[0].resultText;

    if (currentResult) {
      return currentResult;
    } else {
      if (!props.field.kpi) return undefined;
      const defVal = props.field.kpi.defValue;
      if (defVal) {
        const newData = props.field.kpi.data[0];
        if (newData.resultText !== defVal) {
          newData.resultText = defVal;
          props.onUpdateValue(props.field.kpi, newData);
        }

        return defVal;
      } else {
        return '';
      }
    }
  };

  return (
    <Stack>
      <SingleFormElementInfoKPI {...props} />
      <TextField
        readOnly={props.readonly}
        multiline
        autoAdjustHeight
        style={{ resize: 'vertical', maxHeight: 200, overflowY: 'scroll' }}
        value={getValue()}
        maxLength={props.field.kpi?.maxValue ? Number.parseInt(props.field.kpi?.maxValue) : 1024}
        onChange={(ev, newValue) => {
          if (!props.field.kpi) return;
          const newData = props.field.kpi.data[0];
          newData.resultText = newValue;
          props.onUpdateValue(props.field.kpi, newData);
        }}
      />
    </Stack>
  );
};

//
// Text HTML
//
export const SingleFormElementHtml = (props: ISingleFormElementProps) => {
  const getValue = (): string | undefined => {
    const currentResult = props.field.kpi?.data[0].resultText;

    if (currentResult) {
      return currentResult;
    } else {
      if (!props.field.kpi) return undefined;
      const defVal = props.field.kpi.defValue;
      if (defVal) {
        const newData = props.field.kpi.data[0];
        if (newData.resultText !== defVal) {
          newData.resultText = defVal;
          props.onUpdateValue(props.field.kpi, newData);
        }

        return defVal;
      } else {
        return '';
      }
    }
  };

  return (
    <Stack>
      <SingleFormElementInfoKPI {...props} />
      <RichTextEditor
        directEdit={true}
        disallowFullscreen={true}
        compactToolbar={props.compact}
        html={getValue()}
        onChange={(newValue) => {
          if (!props.field.kpi) return;
          const newData = props.field.kpi.data[0];
          newData.resultText = newValue;
          props.onUpdateValue(props.field.kpi, newData);
        }}
        maxHeight={300}
        maxLength={props.field.kpi?.maxValue ? Number.parseInt(props.field.kpi?.maxValue) : 1024}
        readOnly={props.readonly}
        hideRemainingChars={props.field.kpi?.maxValue === undefined}
      />
    </Stack>
  );
};

//
// Number
//
export const SingleFormElementNumber = (props: ISingleFormElementProps) => {
  const getValue = (): string | undefined => {
    const currentResult = props.field.kpi?.data[0].resultNumber;

    if (currentResult !== undefined) {
      return currentResult.toFixed(props.field.kpi?.decimalCount);
    } else {
      if (!props.field.kpi) return undefined;
      if (props.field.kpi.defValue) {
        const defVal = Number.parseFloat(props.field.kpi.defValue ?? '0');
        if (!isNaN(defVal) && props.field.kpi.data[0].resultNumber !== defVal) {
          const newData = props.field.kpi.data[0];
          newData.resultNumber = defVal;
          props.onUpdateValue(props.field.kpi, newData);

          return defVal.toFixed(props.field.kpi?.decimalCount);
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  };

  return (
    <Stack>
      <SingleFormElementInfoKPI {...props} />
      <SpinButton
        disabled={props.readonly}
        value={getValue()}
        min={props.field.kpi?.minValue ? Number.parseFloat(props.field.kpi?.minValue) : -globalKPIDecimal18c8}
        max={props.field.kpi?.maxValue ? Number.parseFloat(props.field.kpi?.maxValue) : globalKPIDecimal18c8}
        onIncrement={(newValue) => {
          if (!props.field.kpi) return;
          if (!newValue) newValue = '0';
          newValue = (Number.parseFloat(newValue) + 1).toFixed(props.field.kpi?.decimalCount);

          return validateMaxValue(props.field.kpi, props.field.kpi?.defValue, newValue);
        }}
        onDecrement={(newValue) => {
          if (!props.field.kpi) return;
          if (!newValue) newValue = '1';
          newValue = (Number.parseFloat(newValue) - 1).toFixed(props.field.kpi?.decimalCount);

          return validateMinValue(props.field.kpi, props.field.kpi?.defValue, newValue);
        }}
        onValidate={(newValue) => {
          //normally empty values are discarded as not valid
          //also return empty strings to reset the field
          return newValue;
        }}
        onChange={(ev, newValue) => {
          if (!props.field.kpi) return;
          newValue = validateMaxValue(props.field.kpi, props.field.kpi?.defValue, newValue);
          newValue = validateMinValue(props.field.kpi, props.field.kpi?.defValue, newValue);
          const newData = props.field.kpi?.data[0];
          const newVal = newValue ? Number.parseFloat(newValue) : undefined;
          if (newVal !== newData.resultNumber) {
            newData.resultNumber = newVal;
            props.onUpdateValue(props.field.kpi, newData);
          }
        }}
        styles={{ root: { width: 150 } }}
      ></SpinButton>
    </Stack>
  );
};

//
// Success & Error
//
export enum SingleFormElementSuccessErrorState {
  Success = 1,
  Failed = 0,
}

export const SingleFormElementSuccessError = (props: ISingleFormElementProps) => {
  const [showCallout, setShowCallout] = useState<boolean>(false);
  const { t } = useTranslation(['translation', 'task']);
  const calloutId = `kpi-success-error-${props.field.kpi?.kpiId}`;

  const getStatusIcon = (state?: SingleFormElementSuccessErrorState): IIconProps => {
    switch (state) {
      case SingleFormElementSuccessErrorState.Success:
        return SkypeCircleCheckIcon;
      case SingleFormElementSuccessErrorState.Failed:
        return StatusErrorFullIcon;
      default:
        return CircleRingIcon;
    }
  };

  const updateValue = (state?: SingleFormElementSuccessErrorState) => {
    if (!props.field.kpi) return;
    const newData = props.field.kpi.data[0];
    newData.resultNumber = state;
    props.onUpdateValue(props.field.kpi, newData);
    setShowCallout(false);
  };

  return (
    <Stack>
      {/* Show label with KPI info when set, otherwise render KPI info behind text (def value) */}
      {props.field.kpi?.showLabel && <SingleFormElementInfoKPI {...props} />}
      <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="start">
        <IconButton
          id={calloutId}
          disabled={props.readonly}
          onClick={() => setShowCallout(true)}
          iconProps={getStatusIcon(props.field.kpi?.data[0].resultNumber)}
        />
        <Text styles={{ root: { paddingTop: 3, whiteSpace: 'pre-wrap' } }}>{props.field.kpi?.defValue}</Text>
        {!props.field.kpi?.showLabel && <SingleFormElementInfoKPI {...props} />}
      </Stack>
      {showCallout && (
        <Callout
          styles={{
            root: {
              padding: '10px 12px',
            },
          }}
          setInitialFocus
          target={`#${calloutId}`}
          onDismiss={() => {
            setShowCallout(false);
          }}
          beakWidth={16}
        >
          <Stack>
            <Stack
              verticalAlign="center"
              horizontal
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateValue(SingleFormElementSuccessErrorState.Success);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={SkypeCircleCheckIcon} />
              <Text>{t('task:CheckList.Success')}</Text>
            </Stack>
            <Stack
              verticalAlign="center"
              horizontal
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateValue(SingleFormElementSuccessErrorState.Failed);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={StatusErrorFullIcon} />
              <Text>{t('task:CheckList.Failed')}</Text>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateValue(undefined);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={CircleRingIcon} />
              <Text> {t('task:CheckList.Todo')}</Text>
            </Stack>
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};

//
// Choice
//
export const SingleFormElementChoice = (props: ISingleFormElementProps) => {
  const emptyKey = 1000000000; //maxIntVal + 1

  const getValue = (): string | undefined => {
    const currentResult = props.field.kpi?.data[0].resultNumber;

    if (currentResult !== undefined) {
      return currentResult.toString();
    } else {
      if (!props.field.kpi) return undefined;
      const defVal = props.field.kpi.defValue;
      if (!!defVal) {
        const newData = props.field.kpi.data[0];
        const defValInt = Number.parseInt(defVal);
        if (newData.resultNumber !== defValInt) {
          newData.resultText = props.field.kpi.choices?.find((c) => c.stringValueKey === defValInt)?.value;
          newData.resultNumber = defValInt;
          props.onUpdateValue(props.field.kpi, newData);
        }

        return defVal;
      } else {
        return emptyKey.toString();
      }
    }
  };

  const getOptions = (): IDropdownOption[] => {
    if (!props.field.kpi?.choices) return [];

    const items: IDropdownOption[] = [];
    if (!props.field.kpi.required && props.field.kpi.defValue === undefined) {
      items.push({
        key: emptyKey,
        text: '',
        data: undefined,
      });
    }

    items.push(
      ...props.field.kpi.choices.map((c) => {
        return {
          key: c.stringValueKey?.toString() ?? '-',
          text: `${c.stringValueKey} - ${c.value}`,
          data: c,
        };
      }),
    );

    return items;
  };

  return (
    <Stack>
      <SingleFormElementInfoKPI {...props} />
      <Dropdown
        options={getOptions()}
        disabled={props.readonly}
        selectedKey={getValue()}
        onChange={(ev, option) => {
          if (!props.field.kpi) return;
          const newData = props.field.kpi?.data[0];
          if (option?.data) {
            const val = option?.data as StringValue;
            newData.resultNumber = val.stringValueKey;
            newData.resultText = val.value;
          } else {
            //empty value selected
            newData.resultNumber = undefined;
            newData.resultText = undefined;
          }
          props.onUpdateValue(props.field.kpi, newData);
        }}
      />
    </Stack>
  );
};

//
// Date
//
export const SingleFormElementDate = (props: ISingleFormElementProps) => {
  const getValue = (): Date | undefined => {
    const currentResult = props.field.kpi?.data[0].resultDate;

    if (currentResult) {
      return currentResult;
    } else {
      if (!props.field.kpi) return undefined;
      if (!isEmpty(props.field.kpi.defValue)) {
        const defVal = new Date();
        const newData = props.field.kpi.data[0];
        if (newData.resultDate !== defVal) {
          newData.resultDate = defVal;
          props.onUpdateValue(props.field.kpi, newData);
        }

        return defVal;
      } else {
        return undefined;
      }
    }
  };

  const getMinVal = (): Date | undefined => {
    if (!props.field.kpi) return undefined;
    const kpi = props.field.kpi;
    if (!kpi.defValue && kpi.minValue) {
      return new Date(kpi.minValue);
    } else if (kpi.defValue && kpi.minValue) {
      const nrDays = getDateDiffDays(new Date(kpi.minValue), new Date(kpi.defValue));

      return addDateDays(new Date(), nrDays);
    } else {
      return undefined;
    }
  };

  const getMaxVal = (): Date | undefined => {
    if (!props.field.kpi) return undefined;
    const kpi = props.field.kpi;
    if (!kpi.defValue && kpi.maxValue) {
      return new Date(kpi.maxValue);
    } else if (kpi.defValue && kpi.maxValue) {
      const nrDays = getDateDiffDays(new Date(kpi.maxValue), new Date(kpi.defValue));

      return addDateDays(new Date(), nrDays);
    } else {
      return undefined;
    }
  };

  return (
    <Stack>
      <SingleFormElementInfoKPI {...props} />
      <LocalizedDatePicker
        disabled={props.readonly}
        value={getValue()}
        minDate={getMinVal()}
        maxDate={getMaxVal()}
        onDateChange={(newValue) => {
          if (!props.field.kpi) return;
          const newData = props.field.kpi.data[0];
          newData.resultDate = newValue;
          props.onUpdateValue(props.field.kpi, newData);
        }}
        allowEmptyValue={!props.field.kpi?.required}
      />
    </Stack>
  );
};

//
// Expression
//
export const SingleFormElementExpression = (props: ISingleFormElementProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'kpis']);
  const [val, setVal] = useState<string>('');
  const theme = getTheme();

  useEffect(() => {
    const result = getValue();
    setVal(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field, props.task]);

  const getValue = (): string => {
    const result = props.field.kpi?.data[0].resultNumber;
    if (result === undefined) {
      return '';
    } else if (isNaN(result)) {
      return t('kpis:Fields.InvalidExpression');
    } else {
      return result.toFixed(props.field.kpi?.decimalCount ?? 0);
    }
  };

  return (
    <Stack>
      <SingleFormElementInfoKPI {...props} />
      <TextField styles={getGlobalTextFieldDisabled(appContext, theme, 150)} readOnly={true} value={val} />
    </Stack>
  );
};

//
// Attachment
//
export const SingleFormElementAttachment = (props: ISingleFormElementProps) => {
  return (
    <Stack>
      <SingleFormElementInfoField {...props} />
      <SingleFormElementAttachmentsField {...props} />
    </Stack>
  );
};

//
// Context
//
export const SingleFormElementContext = (props: ISingleFormElementProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'forms']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [controls, setControls] = useState<Control[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [risks, setRisks] = useState<Risk[]>([]);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [type, setType] = useState<EntityTypes>(EntityTypes.NotSet);

  const defSpecificItemTagColor = '#339e80';

  useEffect(() => {
    const type = getEntityType();
    setType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field]);

  useEffect(() => {
    loadData(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const loadData = async (type: EntityTypes): Promise<Entity[]> => {
    if (type === EntityTypes.Risk) {
      return await loadRisks();
    } else if (type === EntityTypes.Objective) {
      return await loadObjectives();
    } else if (type === EntityTypes.Process) {
      return await loadProcesses();
    } else if (type === EntityTypes.Asset) {
      return await loadAssets();
    } else if (type === EntityTypes.Control) {
      return await loadControls();
    } else if (type === EntityTypes.Requirement) {
      return await loadThemes();
    } else {
      return [];
    }
  };

  const loadRisks = async (): Promise<Entity[]> => {
    try {
      setRisks([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const riskCol = await apiGetRisks(accessToken, appContext.globalDataCache);
      setRisks(riskCol.risks);

      return riskCol.risks.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadAssets = async (): Promise<Entity[]> => {
    try {
      setAssets([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const assets = await apiGetAssets(accessToken, appContext.globalDataCache);
      setAssets(assets);

      return assets.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadControls = async (): Promise<Entity[]> => {
    try {
      setControls([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const controls = await apiGetControls(accessToken, appContext.globalDataCache);
      setControls(controls);

      return controls.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadThemes = async (): Promise<Entity[]> => {
    try {
      setThemes([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const themes = await apiGetThemes(accessToken, appContext.globalDataCache);
      setThemes(themes);

      return themes.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadProcesses = async (): Promise<Entity[]> => {
    try {
      setProcesses([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const processes = await apiGetProcesses(accessToken, appContext.globalDataCache);
      setProcesses(processes);

      return processes.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadObjectives = async (): Promise<Entity[]> => {
    try {
      setObjectives([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const objectives = await apiGetObjectives(accessToken, appContext.globalDataCache);
      setObjectives(objectives);

      return objectives.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const getEntityType = (): EntityTypes => {
    switch (props.field.fieldType) {
      case TaskTypeFieldType.ContextAsset:
        return EntityTypes.Asset;
      case TaskTypeFieldType.ContextControl:
        return EntityTypes.Control;
      case TaskTypeFieldType.ContextObjective:
        return EntityTypes.Objective;
      case TaskTypeFieldType.ContextProcess:
        return EntityTypes.Process;
      case TaskTypeFieldType.ContextRequirement:
        return EntityTypes.Requirement;
      case TaskTypeFieldType.ContextRisk:
        return EntityTypes.Risk;
      default:
        return EntityTypes.Process;
    }
  };

  const getEntities = (): Entity[] => {
    switch (props.field.fieldType) {
      case TaskTypeFieldType.ContextAsset:
        return assets.map((a) => getEntity(a));
      case TaskTypeFieldType.ContextControl:
        return controls.map((a) => getEntity(a));
      case TaskTypeFieldType.ContextObjective:
        return objectives.map((a) => getEntity(a));
      case TaskTypeFieldType.ContextProcess:
        return processes.map((a) => getEntity(a));
      case TaskTypeFieldType.ContextRequirement:
        return themes.map((a) => getEntity(a));
      case TaskTypeFieldType.ContextRisk:
        return risks.map((a) => getEntity(a));
      default:
        return [];
    }
  };

  const getSelectedEntitiesFromTask = (): Entity[] => {
    if (!props.task) return [];
    const entities: Entity[] = [];

    switch (props.field.fieldType) {
      case TaskTypeFieldType.ContextAsset:
        props.task.assetIds.forEach((id) => {
          const asset = assets.find((a) => a.assetId === id);
          if (asset) entities.push(getEntity(asset));
        });
        break;
      case TaskTypeFieldType.ContextControl:
        props.task.controlIds.forEach((id) => {
          const control = controls.find((a) => a.controlId === id);
          if (control) entities.push(getEntity(control));
        });
        break;
      case TaskTypeFieldType.ContextObjective:
        props.task.objectiveIds.forEach((id) => {
          const objective = objectives.find((a) => a.objectiveId === id);
          if (objective) entities.push(getEntity(objective));
        });
        break;
      case TaskTypeFieldType.ContextProcess:
        props.task.processIds.forEach((id) => {
          const process = processes.find((a) => a.processId === id);
          if (process) entities.push(getEntity(process));
        });
        break;
      case TaskTypeFieldType.ContextRequirement:
        props.task.controlIds.forEach((id) => {
          const theme = themes.find((a) => a.themeId === id);
          if (theme) entities.push(getEntity(theme));
        });
        break;
      case TaskTypeFieldType.ContextRisk:
        props.task.riskIds.forEach((id) => {
          const risk = risks.find((a) => a.riskId === id);
          if (risk) entities.push(getEntity(risk));
        });
        break;
      default:
        return [];
    }

    return entities;
  };

  const onAdd = (item: Entity) => {
    if (!props.task) return;
    const newTask = props.task.clone();

    switch (props.field.fieldType) {
      case TaskTypeFieldType.ContextAsset:
        const asset = assets.find((c) => c.assetId === item.entityId);
        if (asset) newTask.assetIds.push(asset.assetId);
        break;
      case TaskTypeFieldType.ContextControl:
        const control = controls.find((c) => c.controlId === item.entityId);
        if (control) newTask.controlIds.push(control.controlId);
        break;
      case TaskTypeFieldType.ContextObjective:
        const objective = objectives.find((c) => c.objectiveId === item.entityId);
        if (objective) newTask.objectiveIds.push(objective.objectiveId);
        break;
      case TaskTypeFieldType.ContextProcess:
        const process = processes.find((c) => c.processId === item.entityId);
        if (process) newTask.processIds.push(process.processId);
        break;
      case TaskTypeFieldType.ContextRequirement:
        const theme = themes.find((c) => c.themeId === item.entityId);
        if (theme) newTask.controlIds.push(theme.themeId);
        break;
      case TaskTypeFieldType.ContextRisk:
        const risk = risks.find((c) => c.riskId === item.entityId);
        if (risk) newTask.riskIds.push(risk.riskId);
        break;
      default:
        return [];
    }

    if (props.onUpdateTaskForForm) props.onUpdateTaskForForm(newTask);
  };

  const onRemove = (item: Entity) => {
    if (!props.task) return;
    const newTask = props.task.clone();

    //remove the item from the task context
    switch (props.field.fieldType) {
      case TaskTypeFieldType.ContextAsset:
        newTask.assetIds = newTask.assetIds.filter((c) => c !== item.entityId);
        break;
      case TaskTypeFieldType.ContextControl:
        newTask.controlIds = newTask.controlIds.filter((c) => c !== item.entityId);
        break;
      case TaskTypeFieldType.ContextObjective:
        newTask.objectiveIds = newTask.objectiveIds.filter((c) => c !== item.entityId);
        break;
      case TaskTypeFieldType.ContextProcess:
        newTask.processIds = newTask.processIds.filter((c) => c !== item.entityId);
        break;
      case TaskTypeFieldType.ContextRequirement:
        newTask.controlIds = newTask.controlIds.filter((c) => c !== item.entityId);
        break;
      case TaskTypeFieldType.ContextRisk:
        newTask.riskIds = newTask.riskIds.filter((c) => c !== item.entityId);
        break;
    }

    if (props.onUpdateTaskForForm) props.onUpdateTaskForForm(newTask);
  };

  //
  // Main render
  //
  return (
    <Stack>
      <SingleFormElementInfoField {...props} />
      <EntityTagPicker
        entityType={getEntityType()}
        tagColor={defSpecificItemTagColor}
        suggestionLimit={undefined}
        itemLimit={props.field.getMaxCount()}
        entities={getEntities()}
        selectedEntities={getSelectedEntitiesFromTask()}
        onAddEntity={onAdd}
        onRemoveEntity={onRemove}
        loadData={async () => await loadData(type)}
        isLoading={isLoading}
        disabled={props.readonly || isLoading}
        placeholder={t('forms:Dialogs.FieldForm.Fields.ContextPlaceholder')}
      />
    </Stack>
  );
};
