import React, { Fragment, FunctionComponent, useContext, useState } from 'react';
import { Stack, Spinner, SpinnerSize, Text, Label, Dropdown, IDropdownOption } from '@fluentui/react';

import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import EntityPicker from 'components/Pickers/EntityPicker';
import Entity, { EntityTypes } from 'models/entity';
import { apiGetKPI, apiGetKPIs } from 'services/Api/kpiService';
import KPI, { KPIAggregationIntervals, KPIAggregationMethods, KPIChartTypes } from 'models/kpi/kpi';
import { apiGetWidgetDataSingleKPI } from 'services/Api/dashboardService';
import { fromApiDateTimeOptional, toApiDateTimeOptional } from 'utils/datetime';
import { getAggregationIntervalOptions, getAggregationMethodOptions } from 'components/KPI/KPIHelpers';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import TextInfo from 'components/Notification/Info';
import KPIDataView from 'components/KPI/KPIDataView';
import { KPIGraphDataConfigDTO } from 'models/dto/kpi/kpiGraphDataDTO';
import { KPIContextFilter } from 'models/kpi/kpiDataContext';
import { mapToKPIContextFilter } from 'mappings/kpiMapping';
import KPIDataContextFilter from 'components/KPI/KPIDataContextFilter';
import { IWidgetRendererProps } from '../WidgetRenderer';

export class WidgetSingleKPIConfig {
  kpiId: number;

  periodStart: string | undefined;

  periodEnd: string | undefined;

  aggregationMethod: KPIAggregationMethods;

  aggregationInterval: KPIAggregationIntervals;

  rawFilter: string;

  filter: KPIContextFilter;

  constructor() {
    this.kpiId = 0;
    this.periodStart = undefined;
    this.periodEnd = undefined;
    this.aggregationMethod = KPIAggregationMethods.Count;
    this.aggregationInterval = KPIAggregationIntervals.Month;
    this.rawFilter = '';
    this.filter = new KPIContextFilter();
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.kpiId = newRawConfig.kpiId ?? 0;
        this.aggregationMethod = newRawConfig.aggregationMethod ?? KPIAggregationMethods.Count;
        this.aggregationInterval = newRawConfig.aggregationInterval ?? KPIAggregationIntervals.Month;
        this.periodStart = newRawConfig.periodStart;
        this.periodEnd = newRawConfig.periodEnd;
        this.rawFilter = newRawConfig.rawFilter;
        if (this.rawFilter) {
          this.filter = KPIContextFilter.fromJSON(this.rawFilter);
        }
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetSingleKPIConfig {
    const newConfig = new WidgetSingleKPIConfig();
    newConfig.kpiId = this.kpiId;
    newConfig.aggregationMethod = this.aggregationMethod;
    newConfig.aggregationInterval = this.aggregationInterval;
    newConfig.periodStart = this.periodStart;
    newConfig.periodEnd = this.periodEnd;
    newConfig.filter = this.filter;
    newConfig.rawFilter = this.rawFilter;

    return newConfig;
  }
}

export const mapToKPIGraphDataConfigFromSingleKPIWidgetConfig = (
  config: WidgetSingleKPIConfig,
): KPIGraphDataConfigDTO => {
  const output = new KPIGraphDataConfigDTO();

  output.kpiId = config.kpiId;
  output.aggregationInterval = config.aggregationInterval;
  output.aggregationMethod = config.aggregationMethod;
  output.periodStart = config.periodStart;
  output.periodEnd = config.periodEnd;
  output.contextFilter = mapToKPIContextFilter(config.filter);
  output.chartType = KPIChartTypes.Bar;

  return output;
};

interface IWidgetSingleKPIProps extends IWidgetRendererProps {}

const WidgetSingleKPI: FunctionComponent<IWidgetSingleKPIProps> = (props: IWidgetSingleKPIProps) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const appContext = React.useContext(AppContext);
  const [kpi, setKPI] = React.useState<KPI | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [config, setConfig] = useState<WidgetSingleKPIConfig | undefined>(undefined);

  React.useEffect(() => {
    const loadConfig = (): WidgetSingleKPIConfig => {
      const newConfig = new WidgetSingleKPIConfig();
      newConfig.load(props.widget.widgetConfig);

      return newConfig;
    };

    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);
        setKPI(undefined);
        setConfig(undefined);

        const config = loadConfig();
        setConfig(config);

        if (config && config.kpiId) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const kpi = await apiGetKPI(config.kpiId, accessToken, undefined);
          const graphConfig = mapToKPIGraphDataConfigFromSingleKPIWidgetConfig(config);
          const data = await apiGetWidgetDataSingleKPI(graphConfig, accessToken);
          kpi.graphData = data;
          setKPI(kpi);
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!kpi) {
    //config is invalid
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:SingleKPI.Config.Invalid')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <KPIDataView
        isLoading={isLoading}
        kpi={kpi}
        chartType={KPIChartTypes.Bar}
        aggregationMethod={config?.aggregationMethod}
      />
    </Stack>
  );
};

export default WidgetSingleKPI;

//
// Config
//

interface IWidgetConfigSingleKPIProps extends IWidgetConfigRendererProps {}

export const WidgetConfigSingleKPI: FunctionComponent<IWidgetConfigSingleKPIProps> = (
  props: IWidgetConfigSingleKPIProps,
) => {
  const { t } = useTranslation(['translation', 'widgets', 'kpis']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [kpis, setKPIs] = useState<KPI[]>([]);
  const [kpiEntities, setKPIEntities] = useState<Entity[]>([]);
  const [selectedKPI, setSelectedKPI] = useState<KPI | undefined>(undefined);
  const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>(undefined);
  const [currentWidgetId, setCurrentWidgetId] = useState<number>(-1);

  const loadConfig = (): WidgetSingleKPIConfig => {
    const newConfig = new WidgetSingleKPIConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const [config, setConfig] = useState<WidgetSingleKPIConfig>(loadConfig());

  React.useEffect(() => {
    if (currentWidgetId === -1 || currentWidgetId !== props.widget.widgetId) {
      setCurrentWidgetId(props.widget.widgetId);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadData = async () => {
    try {
      if (isLoading || currentWidgetId === props.widget.widgetId) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let kpis = await apiGetKPIs(accessToken, undefined);
      kpis = kpis.filter((k) => k.isCalcType());
      setKPIs(kpis);

      const entities = getEntitiesFromKPIs(kpis);
      setKPIEntities(entities);

      if (config.kpiId) {
        const entity = entities.find((e) => e.entityId === config.kpiId);
        setSelectedEntity(entity);
        setSelectedKPI(kpis.find((k) => k.kpiId === config.kpiId));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getEntitiesFromKPIs = (kpis: KPI[]): Entity[] => {
    const entities: Entity[] = [];

    entities.push(
      ...kpis.map((c) => {
        const newEntity = new Entity();
        newEntity.entityId = c.kpiId;
        newEntity.typeOfEntity = EntityTypes.KPI;
        newEntity.entityName = c.name;

        return newEntity;
      }),
    );

    return entities;
  };

  const isConfigValid = (config: WidgetSingleKPIConfig): boolean => {
    //kpi must be selected
    if (config.kpiId <= 0) return false;

    return true;
  };

  const updateConfig = (config: WidgetSingleKPIConfig) => {
    const newConfig = config.clone();
    newConfig.rawFilter = JSON.stringify(newConfig.filter);
    setConfig(newConfig);
    props.onUpdateConfig(JSON.stringify(newConfig), isConfigValid(newConfig));
  };

  const onAddSelectedEntity = (entity: Entity) => {
    setSelectedEntity(entity);
    config.kpiId = entity.entityId;
    updateConfig(config);
    setSelectedKPI(kpis.find((k) => k.kpiId === entity.entityId));
  };

  const onRemoveSelectedEntity = () => {
    setSelectedEntity(undefined);
    config.kpiId = 0;
    updateConfig(config);
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Label>{t('widgets:SingleKPI.Config.KPI.Label')}</Label>
        <EntityPicker
          entities={kpiEntities}
          isLoading={isLoading}
          addSelectedEntity={onAddSelectedEntity}
          clearSearchText={onRemoveSelectedEntity}
          loadData={loadData}
          setSearchTextToSelectedEntity={true}
          selectedEntity={selectedEntity}
          isOnPanel={true}
        ></EntityPicker>
      </Stack.Item>
      {selectedEntity && selectedKPI && (
        <Fragment>
          <Stack.Item>
            <Dropdown
              label={t('widgets:SingleKPI.Config.AggregationMethod.Label')}
              selectedKey={config.aggregationMethod}
              options={getAggregationMethodOptions(t)}
              calloutProps={{ calloutMaxHeight: 250 }}
              onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                config.aggregationMethod = option?.key as KPIAggregationMethods;
                updateConfig(config);
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              label={t('widgets:SingleKPI.Config.AggregationInterval.Label')}
              selectedKey={config.aggregationInterval}
              options={getAggregationIntervalOptions(t)}
              calloutProps={{ calloutMaxHeight: 250 }}
              onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                config.aggregationInterval = option?.key as KPIAggregationIntervals;
                updateConfig(config);
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <TextInfo message={t('widgets:SingleKPI.Config.Period.Info')}>
              <Label>{t('widgets:SingleKPI.Config.Period.Label')}</Label>
            </TextInfo>
            <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapSmall}>
              <Stack.Item grow>
                <LocalizedDatePicker
                  value={fromApiDateTimeOptional(config.periodStart)}
                  maxDate={fromApiDateTimeOptional(config.periodEnd)}
                  showWeekNumbers={true}
                  onDateChange={(value) => {
                    config.periodStart = toApiDateTimeOptional(value);
                    updateConfig(config);
                  }}
                  allowEmptyValue={true}
                />
              </Stack.Item>
              <Stack.Item grow>
                <LocalizedDatePicker
                  value={fromApiDateTimeOptional(config.periodEnd)}
                  minDate={fromApiDateTimeOptional(config.periodStart)}
                  showWeekNumbers={true}
                  onDateChange={(value) => {
                    config.periodEnd = toApiDateTimeOptional(value);
                    updateConfig(config);
                  }}
                  allowEmptyValue={true}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <KPIDataContextFilter
              kpi={selectedKPI}
              filter={config.filter}
              onUpdate={(newFilter) => {
                const newConfig = config.clone();
                newConfig.filter = newFilter;
                updateConfig(newConfig);
              }}
            />
          </Stack.Item>
        </Fragment>
      )}
    </Stack>
  );
};
