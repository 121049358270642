import { getTheme, IconButton, ITextField, ITextFieldStyles, TextField, TooltipHost } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalFilterDelay, globalMinFilterChars } from 'globalConstants';
import { SearchResult } from 'models/search/SearchResult';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { apiGetGlobalSearchResult } from 'services/Api/globalSearchService';
import { apiRequest } from 'services/Auth/authConfig';
import { GlobalSearchResults, IGlobalSearchResults } from './GlobalSearchResults';
import { useTranslation } from 'react-i18next';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import { EntityTypes } from 'models/entity';
import { hasParentNodeId } from 'utils/dom';
import { useKeyPress } from 'globalHooks';
import { SearchRequest } from 'models/search/searchRequest';
import SingleTask from 'components/Tasks/SingleTask';
import { LinkPreviewModalOrUrl } from 'components/Links/Preview/LinkPreviewModalOrUrl';
import ResourceLink from 'models/resourceLink';
import Task from 'models/tasks/task';
import { apiGetLink } from 'services/Api/linkService';
import AppError from 'utils/appError';
import { apiGetSingleTask } from 'services/Api/taskService';
import { SharepointSearch } from 'models/setting';

interface IGlobalSearchProps {}

export enum GlobalSearchModes {
  UnFocusEmpty = 0,
  FocusEmpty = 1,
  FocusResult = 2,
  UnFocusResult = 3,
}

export const GlobalSearch = (props: IGlobalSearchProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const resultsRef = useRef<IGlobalSearchResults | null>(null);
  const searchFieldRef = useRef<ITextField | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [lastSearchText, setLastSearchText] = useState<string>('');
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [stopProcessing, setStopProcessing] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<EntityTypes>(EntityTypes.NotSet);
  const [sharePointSearch, setSharePointSearch] = useState<boolean>(false);
  const [pendingSearchRequest, setPendingSearchRequest] = useState<SearchRequest | undefined>(undefined);
  const [mode, setMode] = useState<GlobalSearchModes>(GlobalSearchModes.UnFocusEmpty);
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
  const [linkToShow, setLinkToShow] = useState<ResourceLink | undefined>(undefined);

  const theme = getTheme();

  //
  // Global hook to start search
  //
  const onStartSearch = (ev: KeyboardEvent) => {
    ev.preventDefault();
    onSetFocus(true);
  };

  useKeyPress(['ctrl+/'], onStartSearch);

  //
  // Effects
  //
  useEffect(() => {
    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsTyping(false);
    const cleanedText = lastSearchText.trim();
    if (cleanedText.length >= globalMinFilterChars) {
      search(new SearchRequest(cleanedText, selectedType, sharePointSearch));
    } else if (mode === GlobalSearchModes.FocusResult) {
      //when the user is in result mode and deletes the text, go back to empty mode
      setMode(GlobalSearchModes.FocusEmpty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSearchText, selectedType, sharePointSearch]);

  useEffect(() => {
    //run pending search
    if (!isSearching && pendingSearchRequest) {
      search(pendingSearchRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    const init = async () => {
      const spSearchEnabledGlobal = appContext.globalDataCache.settings.get(SharepointSearch) as boolean;
      const spSearchEnabledUser = getLocalStorageData(appContext, LocalStorageKeys.GlobalSearchSharePointSearch);
      setSharePointSearch(spSearchEnabledGlobal && spSearchEnabledUser !== 'false');
      await appContext.globalDataCache.tags.getItems();
      await appContext.globalDataCache.norms.getItems();
    };

    if (
      appContext.isAuthenticated === true &&
      appContext.user.login.tenantLicensed === true &&
      appContext.user.login.userLicensed == true
    ) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.isAuthenticated, appContext.user.id]);

  //
  // Mode helpers
  //
  const onSetMode = (mode: GlobalSearchModes) => {
    setMode(mode);
    console.log(GlobalSearchModes[mode]);

    switch (mode) {
      case GlobalSearchModes.FocusEmpty:
        clearSearch();
        searchFieldRef.current?.focus();
        break;
      case GlobalSearchModes.FocusResult:
        searchFieldRef.current?.focus();
        break;
      case GlobalSearchModes.UnFocusResult:
        searchFieldRef.current?.blur();
        break;
      case GlobalSearchModes.UnFocusEmpty:
        clearSearch();
        searchFieldRef.current?.blur();
        break;
      default:
        break;
    }
  };

  const clearSearch = () => {
    setSearchText('');
    setLastSearchText('');
    setResults([]);
  };

  const onSetFocus = (hasFocus: boolean) => {
    if (hasFocus) {
      if (searchText) {
        onSetMode(GlobalSearchModes.FocusResult);
      } else {
        onSetMode(GlobalSearchModes.FocusEmpty);
      }
    } else {
      if (searchText) {
        onSetMode(GlobalSearchModes.UnFocusResult);
      } else {
        onSetMode(GlobalSearchModes.UnFocusEmpty);
      }
    }
  };

  const onGoBack = () => {
    console.log('Back');
    if (mode === GlobalSearchModes.FocusResult || mode === GlobalSearchModes.UnFocusResult) {
      onSetMode(GlobalSearchModes.FocusEmpty);
    } else {
      onSetMode(GlobalSearchModes.UnFocusEmpty);
    }
  };

  const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      case 'Escape':
        ev.stopPropagation();
        onGoBack();
        break;
      case 'Enter':
        search(new SearchRequest(searchText, selectedType, sharePointSearch));
        break;
      case 'Tab':
      case 'ArrowDown':
        console.log('Set StopProcessing');
        setStopProcessing(true);
        resultsRef.current?.focus();
        break;
      default:
        break;
    }
  };

  //
  // Search
  //
  const search = async (request: SearchRequest) => {
    try {
      if (!isSearching) {
        //clean-up
        setResults([]);
        setIsSearching(true);
        setPendingSearchRequest(undefined);
        onSetMode(GlobalSearchModes.FocusResult);
        //override sharepoint search if not enabled
        const spSearchEnabled = appContext.globalDataCache.settings.get(SharepointSearch) as boolean;
        if (!spSearchEnabled) {
          request.sharePointSearch = false;
        }
        //search
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const results = await apiGetGlobalSearchResult(
          request.text,
          request.type,
          request.sharePointSearch,
          accessToken,
        );
        //sort by rank
        results.sort((a, b) => a.rank - b.rank);
        //add to recent searches
        addRecentSearch(request.text);
        //set results
        setResults(results);
        setIsSearching(false);
      } else {
        //search is already in progress, set pending request
        setPendingSearchRequest(request);
      }
    } catch (err) {
      setIsSearching(false);
      appContext.setError(err);
    }
  };

  const addRecentSearch = (text: string) => {
    const recentSearches = getLocalStorageData(appContext, LocalStorageKeys.GlobalSearchRecentSearches);
    const searches = recentSearches ? recentSearches.split('#~#') : [];

    if (searches.find((s) => s.toLowerCase() === text.toLowerCase())) {
      return;
    }

    if (searches.length >= 5) {
      searches.pop();
    }

    searches.unshift(text);
    setLocalStorageData(appContext, LocalStorageKeys.GlobalSearchRecentSearches, searches.join('#~#'));
  };

  //
  // Render helpers
  //
  const renderPrefix = (): JSX.Element => {
    let iconName: string = '';
    let tooltip: string = '';

    if (mode === GlobalSearchModes.UnFocusEmpty) {
      iconName = 'Search';
    } else {
      iconName = 'Back';
      tooltip = t('translation:GlobalSearch.Cancel');
    }

    const onClick = () => {
      if (mode === GlobalSearchModes.UnFocusEmpty) {
        console.log('Search');
        if (stopProcessing) {
          console.log('Search StopProcessing');
          setStopProcessing(false);
        } else {
          onSetMode(GlobalSearchModes.FocusEmpty);
        }
      } else {
        onGoBack();
      }
    };

    return (
      <TooltipHost content={tooltip}>
        <IconButton
          id="global-search-prefix"
          iconProps={{ iconName: iconName }}
          onClick={onClick}
          styles={{ root: { height: 24, width: 30, padding: 0 } }}
        />
      </TooltipHost>
    );
  };

  const textFieldStyles: Partial<ITextFieldStyles> = {
    prefix: { background: 'none', padding: 4 },
    fieldGroup: {
      borderRadius: 6,
      selectors: {
        '&:after': {
          borderRadius: 6,
          borderWidth: 1,
          borderColor: theme.palette.neutralTertiary,
        },
        '&:hover': {
          borderRadius: 6,
          borderColor: theme.palette.neutralTertiary,
        },
      },
    },
  };

  const loadTask = async (taskId: number) => {
    if (!taskId) {
      return;
    }

    try {
      appContext.showContentLoader();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetSingleTask(taskId, false, accessToken, appContext.globalDataCache);
      if (tasks && tasks.tasks && tasks.tasks.length > 0) {
        const task = tasks.tasks[0];
        setSelectedTask(task);
      } else {
        appContext.showNotification(t('dashboard:Errors.ItemHasBeenDeleted'));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const loadLink = async (linkId: number | undefined) => {
    try {
      if (!linkId) return;

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const resourceLink = await apiGetLink(linkId, accessToken, appContext.globalDataCache);
      if (resourceLink && resourceLink.linkId) {
        setLinkToShow(resourceLink);
      } else {
        throw new AppError('Link not found');
      }
    } catch {
      appContext.showNotification(t('translation:NotFound.Main'), true);
    }
  };

  const saveTask = async (task: Task, isNew: boolean, isCancelled: boolean) => {
    setSelectedTask(undefined);
  };

  const removeTask = async (task: Task) => {
    setSelectedTask(undefined);
  };

  const updateTask = async (task: Task) => {};

  //
  // Main render
  //
  if (!appContext.isAuthenticated || !appContext.user.login.userLicensed) {
    return null;
  }

  return (
    <Fragment>
      <TextField
        componentRef={searchFieldRef}
        id="global-search"
        value={searchText}
        maxLength={80}
        placeholder={t('translation:General.Search.Placeholder')}
        onChange={(ev, newValue) => {
          if (searchTimer) {
            clearTimeout(searchTimer);
          }
          const newVal = newValue ?? '';
          setSearchText(newVal);
          setResults([]);
          setIsTyping(true);
          setSearchTimer(setTimeout(() => setLastSearchText(newVal), globalFilterDelay * 2));
        }}
        onClick={(ev) => {
          onSetFocus(true);
        }}
        onFocus={(ev) => {
          if (ev.relatedTarget?.id === 'global-search-prefix') {
            //don't focus when user clicks on prefix
            console.log('Focus on prefix');

            return;
          }
          if (stopProcessing) {
            console.log('Focus StopProcessing');
            setStopProcessing(false);
          } else {
            console.log('focus');
            onSetFocus(true);
          }
        }}
        onBlur={(ev) => {
          if (
            ev.relatedTarget?.id === 'global-search-prefix' ||
            hasParentNodeId(ev.relatedTarget, 'global-search-callout')
          ) {
            //don't cancel search when user clicks on prefix or callout
            //when user clicks on prefix, that component will handle the cancel
            //when user clicks on callout, the search must not be canceled
            console.log('Blur to prefix or callout');

            return;
          }
          if (stopProcessing) {
            console.log('Blur StopProcessing');
            setStopProcessing(false);
          } else {
            console.log('blur');
            onSetFocus(false);
            console.log('Clear stop processing');
            setStopProcessing(false);
          }
        }}
        onKeyDown={onKeyDown}
        onRenderPrefix={renderPrefix}
        styles={textFieldStyles}
        autoComplete="off"
      />
      <GlobalSearchResults
        ref={resultsRef}
        hidden={mode === GlobalSearchModes.UnFocusEmpty || mode === GlobalSearchModes.UnFocusResult}
        results={results}
        isSearching={isSearching}
        isTyping={isTyping}
        searchText={searchText}
        targetId="global-search"
        sharePointSearch={sharePointSearch}
        onSelectRecentSearch={(text) => {
          setSearchText(text);
          setLastSearchText(text);
        }}
        onSelectType={(type) => {
          setSelectedType(type);
        }}
        onSetSharePointSearch={(value) => {
          setSharePointSearch(value);
        }}
        mode={mode}
        onSetMode={(mode, stopProcessing) => {
          if (stopProcessing) {
            console.log('Set StopProcessing');
            setStopProcessing(true);
          }
          onSetMode(mode);
        }}
        onShowTask={loadTask}
        onShowLink={loadLink}
      />
      {selectedTask && (
        <SingleTask
          task={selectedTask}
          isOpen={true}
          close={() => {
            setSelectedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
        />
      )}
      {linkToShow && (
        <LinkPreviewModalOrUrl
          isOpen={true}
          onClose={() => setLinkToShow(undefined)}
          link={linkToShow}
          confirmOpen={false}
          enableLinks={true}
        />
      )}
    </Fragment>
  );
};
