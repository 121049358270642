import { TaskTypeFormElement_TranslationDTO } from './taskTypeFormElement_TranslationDTO';
import KPIDTO from '../kpi/kpiDTO';

export default class TaskTypeFormElementDTO {
  taskTypeId: number;

  fieldId: number;

  fieldType: number;

  fieldMode?: number;

  kpiId?: number;

  sortOrder: number;

  listId?: number;

  folderId?: string;

  kpi?: KPIDTO;

  trans: TaskTypeFormElement_TranslationDTO[];

  constructor() {
    this.taskTypeId = 0;
    this.fieldId = 0;
    this.fieldType = 0;
    this.sortOrder = 0;
    this.trans = [];
  }
}
