import ISOControl from 'models/isoControl';
import Tag from 'models/tag';
import Task from 'models/tasks/task';
import { extractAlpha, extractNumber, startsWithNumber } from './string';
import Entity, { EntityTypes } from 'models/entity';
import { IColumn } from '@fluentui/react';
import { Role } from 'models/auth/role';

export const sortOnCode = (value1: string, value2: string): number => {
  //1. items that start with a number before items that start with alpha
  //2. if the string starts with a number part, extract the number and compare on that. if equal, extract the alpha part and compare on that
  //3. if the string starts with a alpha part, extract the alpha part, compare on that. If equal, compare on the number part
  const sn1 = startsWithNumber(value1);
  const sn2 = startsWithNumber(value2);

  //sort items that start with a number before items that start with alpha
  if (sn1 !== sn2) {
    if (sn1 === true) return -1;

    return 1;
  }

  if (sn1) {
    //starts with number
    const ca = extractNumber(value1);
    const cb = extractNumber(value2);
    const r1 = cb - ca;

    if (r1 === 0) {
      const aa = extractAlpha(value1);
      const ab = extractAlpha(value2);

      return aa.localeCompare(ab);
    } else {
      return cb > ca ? -1 : 1;
    }
  } else {
    //starts with alpha
    const aa = extractAlpha(value1);
    const ab = extractAlpha(value2);

    const r1 = aa.localeCompare(ab);
    if (r1 === 0) {
      const ca = extractNumber(value1);
      const cb = extractNumber(value2);

      if (ca === cb) return 0;

      return cb > ca ? -1 : 1;
    } else {
      return r1;
    }
  }
};

export const sortOnChapterNum = (sn1: string[], sn2: string[]): number => {
  const max = Math.max(sn1.length, sn2.length);

  for (let idx = 0; idx < max; idx++) {
    const p1 = sn1[idx];
    const p2 = sn2[idx];

    if (!p1) return -1;
    if (!p2) return 1;

    const v1 = Number.parseInt(p1);
    const v2 = Number.parseInt(p2);
    const NaNv1 = isNaN(v1);
    const NaNv2 = isNaN(v2);
    if (NaNv1 && !NaNv2) return 1;
    if (NaNv2 && !NaNv1) return -1;

    if (NaNv1 && NaNv2) {
      //ignore text
      continue;
    } else {
      //compare as number
      const result = v1 - v2;
      if (result !== 0) return result;
    }
  }

  return 0;
};

export const sortOnChapter = (value1: string, value2: string): number => {
  //if the depth = 0, compare on
  //1. split the chapter on .
  //2. sort on each part
  //3. if numeric chapters are the same, compare on text part
  const sn1 = value1.split('.');
  const sn2 = value2.split('.');

  if (sn1.length === sn2.length) {
    //chapters have the same depth: first compare on the text part
    const t1 = extractAlpha(value1);
    const t2 = extractAlpha(value2);
    const rResult = t1.localeCompare(t2);
    if (rResult !== 0) return rResult;
  }

  return sortOnChapterNum(sn1, sn2);
};

export const sortOnArray = <T>(a1: T[] | undefined, a2: T[] | undefined): number => {
  if (a1 === undefined && a2 !== undefined) return -1;
  if (a1 !== undefined && a2 === undefined) return 1;

  if (a1 && a2) {
    if (a1.length !== a2.length) {
      return a1.length - a2?.length;
    }
  }

  return 0;
};

export const sortOnTag = (tag1: Tag, tag2: Tag): number => {
  const r1 = tag1.tagName.localeCompare(tag2.tagName);
  if (r1 === 0) {
    return tag1.tagValue.localeCompare(tag2.tagValue);
  } else {
    return r1;
  }
};

export const sortOnDate = (t1: Date | undefined, t2: Date | undefined): number => {
  if (t1 === t2) return 0;

  if (t1 === undefined && t2 !== undefined) return -1;
  if (t1 !== undefined && t2 === undefined) return 1;

  if (t1 && t2) {
    if (t1 < t2) return -1;
  }

  return 1;
};

export const sortOnNumber = (b1: number | undefined, b2: number | undefined): number => {
  if (b1 === b2) return 0;
  if (b1 !== undefined && b2 === undefined) return -1;
  if (b1 === undefined && b2 !== undefined) return 1;
  if (b1 === undefined || b2 === undefined) return 0; //cannot happen but for type checking

  return b1 - b2;
};

export const sortOnString = (b1: string | undefined, b2: string | undefined): number => {
  if (b1 === b2) return 0;
  if (b1 && !b2) return -1;
  if (!b1 && b2) return 1;
  if (!b1 || !b2) return 0; //cannot happen but for type checking

  return b1.localeCompare(b2);
};

export const sortOnBoolean = (b1: boolean | undefined, b2: boolean | undefined): number => {
  if (b1 === undefined && b2 !== undefined) {
    return 1;
  }
  if (b1 !== undefined && b2 === undefined) {
    return -1;
  }

  if (b1 === b2) {
    return 0;
  }

  if (b1 === true && b2 === false) {
    return -1;
  } else {
    return 1;
  }
};

export const sortOnISOControl = (c1: ISOControl, c2: ISOControl): number => {
  if (c1.isoNormId === c2.isoNormId) {
    return sortOnCode(c1.code, c2.code);
  } else {
    return (c1.isoNormId || 0) - (c2.isoNormId || 0);
  }
};

export const sortOnTaskTags = (a: Task, b: Task): number => {
  const tagCountA = a.tagIds?.length || 0;
  const tagCountB = b.tagIds?.length || 0;

  if (tagCountA === 1 && tagCountB === 1 && a.tagIds && b.tagIds) {
    return a.tagIds[0] - b.tagIds[0];
  } else {
    return tagCountA - tagCountB;
  }
};

export const getEntityTypeSortIdx = (t: EntityTypes): number => {
  switch (t) {
    case EntityTypes.Process:
      return 1;
    case EntityTypes.Objective:
      return 2;
    case EntityTypes.KPI:
      return 3;
    case EntityTypes.Asset:
      return 4;
    case EntityTypes.Risk:
      return 5;
    case EntityTypes.Control:
      return 6;
    case EntityTypes.Requirement:
      return 7;
    case EntityTypes.Task:
      return 8;
    case EntityTypes.TaskType:
      return 9;
    case EntityTypes.Dashboard:
      return 10;
    case EntityTypes.Standard:
      return 11;
    case EntityTypes.List:
      return 12;
    default:
      return 0;
  }
};

export const sortOnEntityType = (t1: number, t2: number): number => {
  const nr1 = getEntityTypeSortIdx(t1);
  const nr2 = getEntityTypeSortIdx(t2);

  return sortOnNumber(nr1, nr2);
};

export const sortOnEntity = (a: Entity, b: Entity): number => {
  if (a.typeOfEntity !== b.typeOfEntity) {
    const sortType = sortOnEntityType(a.typeOfEntity, b.typeOfEntity);
    if (sortType !== 0) return sortType;
  }

  if (!a.entityCode && !b.entityCode) return sortOnString(a.entityName, b.entityName);
  if (!a.entityCode) return -1;
  if (!b.entityCode) return 1;

  return sortOnChapter(a.entityCode, b.entityCode);
};

export const sortOnTaskContext = (a: Task, b: Task): number => {
  let entityCountA = 0;
  entityCountA += a.controlIds.length;
  entityCountA += a.riskIds.length;
  entityCountA += a.processIds.length;
  entityCountA += a.objectiveIds.length;
  entityCountA += a.assetIds.length;

  let entityCountB = 0;
  entityCountB += b.controlIds.length;
  entityCountB += b.riskIds.length;
  entityCountB += b.processIds.length;
  entityCountB += b.objectiveIds.length;
  entityCountB += b.assetIds.length;

  return entityCountA - entityCountB;
};

export const applyCurrentColumnSort = (currentCols: IColumn[], newCols: IColumn[]): IColumn[] => {
  const currCol = currentCols.find((c) => c.isSorted);
  const newCol = newCols.find((c) => c.key === currCol?.key);
  if (currCol && newCol) {
    for (const col of newCols) {
      if (newCol === col) {
        col.isSortedDescending = currCol.isSortedDescending;
        col.isSorted = currCol.isSorted;
      } else {
        col.isSorted = false;
        col.isSortedDescending = false;
      }
    }
  }

  return newCols;
};

export const sortOnRole = (a: Role, b: Role): number => {
  if (a.systemRoleId && b.systemRoleId) return a.systemRoleId - b.systemRoleId;
  if (a.systemRoleId) return -1;
  if (b.systemRoleId) return 1;

  return sortOnString(a.name, b.name);
};
