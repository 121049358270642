import { useContext, useEffect, useState } from 'react';
import {
  CommandBar,
  DefaultButton,
  ICommandBarItemProps,
  IImageProps,
  ImageFit,
  Image,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Separator,
} from '@fluentui/react';
import ResourceLink from 'models/resourceLink';
import {
  cancelIcon,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalTextStylesBold,
  helpIcon,
  openExtIcon,
  sharepointIcon,
} from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { navigateToExternalUrl } from 'utils/url';
import AppContext from 'App/AppContext';
import { ResourceListType } from 'models/resourceList';
import SharepointFilePicker from 'components/SharePoint/SharepointFilePicker';
import SharepointListItemPicker from 'components/SharePoint/SharepointListItemPicker';
import { ISitePage, ITextWebPart } from 'services/Graph/SharepointInterfaces';
import { graphGetCanvasValidWebParts, graphGetSitePage } from 'services/Graph/graphServicePage';
import { apiRequest, graphSharepointPagesRequest } from 'services/Auth/authConfig';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import parse, { domToReact, HTMLReactParserOptions, Element } from 'html-react-parser';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import GenericModal from 'components/Dialogs/GenericModal';
import Config from 'services/Config/configService';
import { LibraryOpenInModal } from 'models/setting';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import AppError from 'utils/appError';
import logger from 'services/Logging/logService';
import { apiCreateActivity } from 'services/Api/activityService';
import Activity, { ActivityPriority, ActivityType } from 'models/activity';
import { EntityTypes } from 'models/entity';
import { apiGetLinkActivity } from 'services/Api/linkService';
import { ILinkPreviewProps, getPageHeaderDoc, getPageHeaderPage } from './LinkPreview';
import CopyId from 'components/Utils/CopyId';

//Custom SharePoint CSS
import 'components/SharePoint/SharePointPageRender.css';

export const LinkPreviewSharePoint = (props: ILinkPreviewProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['library']);
  const [webUrl, setWebUrl] = useState<string | undefined>(undefined);
  const [embedUrl, setEmbedUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<ISitePage | undefined>(undefined);
  const [pageContent, setPageContent] = useState<JSX.Element[] | undefined>(undefined);
  const [showConfirmOpen, setShowConfirmOpen] = useState<boolean>(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [activity, setActivity] = useState<Activity | undefined>(undefined);
  const [iFrameLoadError, setIFrameLoadError] = useState<boolean>(false);

  const imageFileNotAvailable: IImageProps = {
    src: `${Config.getImageURL()}/file-not-available.png`,
    imageFit: ImageFit.contain,
    width: 96,
    height: 96,
  };

  useEffect(() => {
    if (props.link) {
      getURLs();
    } else {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.link]);

  useEffect(() => {
    //load the page content when the page changes
    try {
      if (page) {
        const pageContent = getPageContent(page);
        setPageContent(pageContent);
      } else {
        setPageContent(undefined);
      }
    } catch (err) {
      appContext.setError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activity]);

  const openLink = () => {
    if (props.confirmOpen === true) {
      setShowConfirmOpen(true);
    } else {
      doOpenLink();
    }
  };

  const doOpenLink = () => {
    setShowConfirmOpen(false);
    if (!webUrl) return;
    navigateToExternalUrl(webUrl, '', '');
  };

  const getCommandBarItems = (): ICommandBarItemProps[] => {
    let items: ICommandBarItemProps[] = [];

    const libraryOpenInModal = appContext.globalDataCache.settings.get(LibraryOpenInModal) as boolean;
    if (libraryOpenInModal) return [];

    if (props.link?.list.listType === ResourceListType.WebURL) {
      items = [
        {
          key: 'open',
          disabled: !webUrl,
          text: t('library:PinnedLinkTabs.Commands.OpenInWeb'),
          iconProps: openExtIcon,
          onClick: openLink,
        },
      ];
    } else {
      items = [
        {
          key: 'open',
          disabled: !webUrl,
          text: t('library:PinnedLinkTabs.Commands.OpenInSharePoint'),
          iconProps: sharepointIcon,
          onClick: openLink,
        },
      ];
    }

    if (props.onClose) {
      items.push({
        key: 'close',
        disabled: false,
        text: t('library:PinnedLinkTabs.Commands.Close'),
        iconProps: cancelIcon,
        onClick: () => {
          if (props.onClose) props.onClose();
        },
      });
    }

    if (!props.hideHelp) {
      items.push({
        key: 'help',
        disabled: false,
        text: t('library:PinnedLinkTabs.Commands.Help'),
        iconProps: helpIcon,
        onClick: () => {
          setShowHelp(true);
        },
      });
    }

    if (props.extraCommandBarButtons) {
      items.push(...props.extraCommandBarButtons);
    }

    return items;
  };

  const GetPage = async (link: ResourceLink) => {
    if (link && link.list.listType === ResourceListType.SitePageLibrary && link.list.spSiteId && link.pageId) {
      const graphInterface = await appContext.getGraphInterface(
        graphSharepointPagesRequest.scopes,
        link.list.altTenantId,
      );
      const page = await graphGetSitePage(graphInterface.client, link.list.spSiteId, link.pageId, true);

      if (graphGetCanvasValidWebParts(page).length > 0) {
        return page;
      }
    }

    return undefined;
  };

  const getDontUseIFrame = (): boolean => {
    return true;
  };

  const clear = () => {
    setWebUrl(undefined);
    setEmbedUrl(undefined);
    setPage(undefined);
    setPageContent(undefined);
    setIFrameLoadError(false);
    setActivity(undefined);
  };

  const getURLs = async () => {
    try {
      if (!props.link) return;
      setIsLoading(true);
      clear();

      logger.debug('Getting link preview URLs for ' + props.link.linkName);
      const dontUseIFrame = getDontUseIFrame();

      if (props.link.list.listType === ResourceListType.SitePageLibrary && dontUseIFrame === true) {
        logger.debug('Page library');
        const webUrl = await props.link?.getWebURL(appContext);
        setWebUrl(webUrl);
        const page = await GetPage(props.link);
        setPage(page);
      } else if (!props.link?.isWholeList()) {
        logger.debug('Getting from Graph');
        const webUrl = await props.link?.getWebURL(appContext);
        let embedUrl = webUrl;
        if (props.link.list.listType !== ResourceListType.SitePageLibrary) {
          embedUrl = await props.link?.getEmbedURL(appContext);
        }
        setWebUrl(webUrl);
        setEmbedUrl(embedUrl);
      } else {
        logger.debug('Set from link');
        const webUrl: string = props.link.list.webURL || '';
        const embedUrl: string = webUrl;
        if (webUrl) setWebUrl(webUrl);
        if (embedUrl) setEmbedUrl(embedUrl);
      }

      createReadResponseActivity();
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        //ignore known errors
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createReadResponseActivity = async () => {
    try {
      if (!props.link) return;

      let newVersionActivity: Activity | undefined;
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      //check whether we have an activity set on the link we can use, otherwise retrieve it
      if (
        !props.link.activity ||
        !props.link.activity.activityId ||
        !props.link.activity.entity ||
        props.link.activity.entity.entityId !== props.link.linkId
      ) {
        newVersionActivity = await apiGetLinkActivity(props.link, ActivityType.LibraryNewVersion, accessToken);
      } else {
        newVersionActivity = props.link.activity;
      }

      setActivity(newVersionActivity);
      let activity: Activity | undefined = undefined;

      //Back-end logic:
      //---------------
      //When there is approval support of latest version
      //------------------------------------------------
      //1. When the latest version approval is not read: return activity with type LibraryNewVersion
      //2. When the latest version approval is read: return activity with type LibraryReadResponse and entityType Activity
      //3. When there is no latest version approval and link is read: return activity with type LibraryReadResponse and entityType Link
      //4. When there is no latest version approval and link is not read: return null

      //When there is NO approval support of latest version
      //---------------------------------------------------
      //1. When link is read: return activity with type LibraryReadResponse and entityType Link
      //2. When link is not read: return null

      if (newVersionActivity && newVersionActivity.typeOfActivity === ActivityType.LibraryNewVersion) {
        //1. When the latest version approval is not read: return activity with type LibraryNewVersion
        //-> send a read response with link to the activity of the new version
        activity = new Activity();
        activity.typeOfActivity = ActivityType.LibraryReadResponse;
        activity.entity.typeOfEntity = EntityTypes.Activity;
        activity.entity.entityId = newVersionActivity.activityId;
        activity.entity.entityName = props.link.linkName;
        activity.userId = appContext.user.id;
        activity.priority = ActivityPriority.Normal;
        await apiCreateActivity(activity, true, true, accessToken);
      }

      //always create read response
      activity = new Activity();
      activity.typeOfActivity = ActivityType.LibraryReadResponse;
      activity.entity.typeOfEntity = EntityTypes.Link;
      activity.entity.entityId = props.link.linkId;
      activity.entity.entityName = props.link.linkName;
      activity.userId = appContext.user.id;
      activity.priority = ActivityPriority.Normal;
      await apiCreateActivity(activity, false, false, accessToken);
    } catch (err) {
      logger.debug('createReadResponseActivity', err);
    }
  };

  const getPageContent = (page: ISitePage | undefined): JSX.Element[] | undefined => {
    if (!page) return undefined;
    const content: JSX.Element[] = [];

    content.push(getPageHeaderPage('header', page.title ?? page.name, activity, appContext.useDarkMode));

    const webparts = graphGetCanvasValidWebParts(page);
    webparts.forEach((item: ITextWebPart, index: number) => {
      const contentItem = parseWebpart(item);
      if (contentItem) {
        content.push(
          <Stack.Item
            className="sharePointPage sharePointSection"
            key={index}
            styles={{ root: { paddingLeft: 20, paddingRight: 20 } }}
          >
            {contentItem}
          </Stack.Item>,
        );
      } else {
        //ignore invalid web parts
      }
    });

    return content;
  };

  const parseWebpart = (item: ITextWebPart): string | JSX.Element | JSX.Element[] | null => {
    let output: string | JSX.Element | JSX.Element[] | null;

    try {
      const htmlString = item.innerHtml;
      output = parse(htmlString, options);
    } catch {
      output = null;
    }

    return output;
  };

  //Replace all links <a> with <span> so the user cannot click them or, when enabled, change all links
  //to open in a new window and use the fully qualified url
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      const node = domNode as Element;
      if (node.name === 'a') {
        if (props.enableLinks) {
          let url = node.attribs['data-cke-saved-href'] || node.attribs.href;
          if (url && !url.startsWith('https:')) {
            if (webUrl) {
              //url is relative, we need to add the SharePoint base URL
              const partRef = 'sharepoint.com';
              const partIdx = webUrl.indexOf(partRef);
              if (partIdx >= 0) {
                const part = webUrl.substring(0, partIdx + partRef.length);
                url = part + url;
              } else {
                url = '';
              }
            } else {
              url = '';
            }
          }

          if (!url) {
            logger.debug(
              `Cannot get correct url for SharePoint link preview : ${
                node.attribs['data-cke-saved-href'] || node.attribs.href
              }`,
            );

            return <span>{domToReact(node.children, options)}</span>;
          } else {
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {domToReact(node.children, options)}
              </a>
            );
          }
        } else {
          return <span>{domToReact(node.children, options)}</span>;
        }
      }
    },
  };

  const getHelpText = (): string => {
    if (!props.link) return '';

    if (props.link.isWholeList()) {
      return t('library:PinnedLinkTabs.Dialogs.Help.WholeList');
    } else {
      switch (props.link?.list.listType) {
        case ResourceListType.WebURL:
          return t('library:PinnedLinkTabs.Dialogs.Help.WebURL');
        case ResourceListType.CustomList:
        case ResourceListType.DocumentLibrary:
          return t('library:PinnedLinkTabs.Dialogs.Help.DocOrList');
        case ResourceListType.SitePageLibrary:
          const dontUseIFrame = getDontUseIFrame();
          if (dontUseIFrame) {
            return t('library:PinnedLinkTabs.Dialogs.Help.PageDontUseIFrame');
          } else {
            return t('library:PinnedLinkTabs.Dialogs.Help.Page');
          }
      }
    }
  };

  //
  // The following feature to display the full SharePoint pages in an i-frame is disabled
  // Microsoft has implemented a CSP which prevents this.
  // Leave it here for now for future scenarios.
  //
  // const download3rdPartyCookiesManual = () => {
  //   const lang = appContext.user.language.code.toUpperCase();
  //   const url = Config.getAppURL() + `/templates/ISOPlanner.Browser.3rdPartyCookies.${lang}.pdf`;

  //   navigateToExternalUrl(url, '', '', true);
  // };

  // const setUserSettingDontUseIFrame = async (value: boolean) => {
  //   try {
  //     setShowHelp(false);
  //     appContext.globalDataCache.userSettings.set(DontUseIFrameForSharePointWebPages, value);
  //     const accessToken = await appContext.getAccessToken(apiRequest.scopes);
  //     await apiSetUserSetting(DontUseIFrameForSharePointWebPages, value, accessToken);
  //     await getURLs();
  //   } catch (err) {
  //     appContext.setError(err);
  //   }
  // };

  // const getButtonTextIFrameSetting = (): string => {
  //   const dontUseIFrame = getUseIFrame();
  //   if (dontUseIFrame) {
  //     return t('library:PinnedLinkTabs.Dialogs.Help.ButtonPageUseIFrame');
  //   } else {
  //     return t('library:PinnedLinkTabs.Dialogs.Help.ButtonPageDontUseIFrame');
  //   }
  // };

  const getCommandBar = () => {
    if (props.hideCommandbar) return null;
    const items = getCommandBarItems();
    if (items.length === 0) return null;

    return (
      <Stack.Item>
        <Stack horizontal verticalAlign="center">
          <CommandBar items={items}></CommandBar>
          {props.link && !props.hideId && <CopyId id={props.link?.linkId} />}
        </Stack>
        <GenericModal
          title={t('library:PinnedLinkTabs.Dialogs.Help.Title')}
          isOpen={showHelp}
          onClose={() => setShowHelp(false)}
          minWidth={650}
          verticalGap={globalStackTokensGapSmall}
        >
          <Separator />
          <Stack tokens={globalStackTokensGapSmall}>
            <Text block variant="medium">
              {parse(getHelpText())}
            </Text>
            {/* This functionality is disabled and default turned off through settings*/}
            {/* {props.link?.list.listType === ResourceListType.SitePageLibrary && (
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                {!getUseIFrame() && (
                  <DefaultButton
                    text={t('library:PinnedLinkTabs.Dialogs.Help.Button3rdPartyCookies')}
                    onClick={() => download3rdPartyCookiesManual()}
                  />
                )}
                <DefaultButton
                  text={getButtonTextIFrameSetting()}
                  onClick={() => {
                    const dontUseIFrame = getUseIFrame();
                    setUserSettingDontUseIFrame(!dontUseIFrame);
                  }}
                />
              </Stack>
            )} */}
          </Stack>
        </GenericModal>
      </Stack.Item>
    );
  };

  //
  // Main render
  //
  if (!props.link) return null;

  //
  // For links of type 'whole list' render a file picker or list item picker in view-mode
  //
  if (props.link.isWholeList()) {
    switch (props.link.list.listType) {
      case ResourceListType.DocumentLibrary:
        if (!props.link.list.spDriveId || !props.link.list.spDriveItemId) return null;

        return (
          <Stack verticalFill tokens={globalStackTokensGapMedium}>
            {getCommandBar()}
            <SharepointFilePicker
              parentFolder={{
                tenantId: props.link.list.altTenantId,
                driveId: props.link.list.spDriveId,
                driveItemId: props.link.list.spDriveItemId,
              }}
              invalidAuthToken={() => {}}
              onSelect={() => {}}
              viewMode={true}
            ></SharepointFilePicker>
          </Stack>
        );
      case ResourceListType.CustomList:
        if (!props.link.list.spSiteId || !props.link.list.spListId) return null;

        return (
          <Stack verticalFill tokens={globalStackTokensGapMedium}>
            {getCommandBar()}
            <SharepointListItemPicker
              parentList={{
                tenantId: props.link.list.altTenantId,
                siteId: props.link.list.spSiteId,
                listId: props.link.list.spListId,
              }}
              invalidAuthToken={() => {}}
              onSelect={() => {}}
              viewMode={true}
            ></SharepointListItemPicker>
          </Stack>
        );
    }
  }

  //
  // For custom list items, render the list with item filter
  //
  if (props.link.list.listType === ResourceListType.CustomList) {
    if (!props.link.list.spSiteId || !props.link.list.spListId) return null;

    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        {getCommandBar()}
        <SharepointListItemPicker
          parentList={{
            tenantId: props.link.list.altTenantId,
            siteId: props.link.list.spSiteId,
            listId: props.link.list.spListId,
          }}
          invalidAuthToken={() => {}}
          onSelect={() => {}}
          viewMode={true}
          filterId={props.link.listItemId}
        ></SharepointListItemPicker>
      </Stack>
    );
  }

  const onIFrameLoadError = async () => {
    setIFrameLoadError(true);
  };

  //
  // For other individual items, render the Embed-url in an iframe or render page content
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      {getCommandBar()}
      {!embedUrl && !isLoading && !pageContent && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Text>{t('translation:General.Notifications.NotAvailable')}</Text>
        </Stack>
      )}
      {isLoading && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
      {!isLoading && pageContent && (
        <Stack verticalFill>
          <ScrollableStackItem>
            <Stack className="sharePointPage" verticalFill tokens={globalStackTokensGapSmall}>
              {pageContent}
            </Stack>
          </ScrollableStackItem>
        </Stack>
      )}
      {!isLoading && iFrameLoadError && !pageContent && embedUrl && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center" tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Image {...imageFileNotAvailable} />
          </Stack.Item>
          <Stack.Item styles={{ root: { maxWidth: '80%' } }}>
            <Text block styles={globalTextStylesBold}>
              {t('library:Preview.IFrameError')}
            </Text>
          </Stack.Item>
          <Stack.Item styles={{ root: { maxWidth: '80%' } }}>
            <DefaultButton
              iconProps={openExtIcon}
              onClick={() => navigateToExternalUrl(embedUrl, '', '')}
              text={t('library:PinnedLinkTabs.Commands.OpenInWeb')}
            ></DefaultButton>
          </Stack.Item>
        </Stack>
      )}
      {!isLoading && !iFrameLoadError && !pageContent && embedUrl && (
        <Stack verticalFill>
          {props.link.list.listType === ResourceListType.DocumentLibrary &&
            getPageHeaderDoc(props.link.linkId.toString(), props.link.linkName, activity, appContext.useDarkMode)}
          <Stack.Item grow>
            <iframe
              title={props.link.linkName}
              width="100%"
              height="95%"
              allowFullScreen={true}
              referrerPolicy="no-referrer"
              style={{ border: 'none' }}
              src={embedUrl}
              onError={onIFrameLoadError}
              //sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation"
            ></iframe>
          </Stack.Item>
        </Stack>
      )}
      <DialogYesNo
        hidden={!showConfirmOpen}
        onYes={() => doOpenLink()}
        onNo={() => setShowConfirmOpen(false)}
        title={t('library:PinnedLinkTabs.Dialogs.Open.Title')}
        subText={t('library:PinnedLinkTabs.Dialogs.Open.SubText')}
      ></DialogYesNo>
    </Stack>
  );
};
