import { useContext, useEffect, useState } from 'react';
import User from 'models/user';
import {
  IBasePickerStyleProps,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  IInputProps,
  IPersonaProps,
  IStyleFunctionOrObject,
  ISuggestionItemProps,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalFilterDelay, globalSuggestionsMaxRecords } from 'globalConstants';
import AppContext from 'App/AppContext';

interface IPickerProps extends IPersonaProps {}

interface IMultiSelectUserPicker {
  users: User[];
  usersToExclude?: User[];
  onMultipleSelect: (items: User[] | undefined) => void;
  itemLimit?: number;
  disabled?: boolean;
  styles?: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined;
  placeHolder?: string | undefined;
  showUnlicensedUsers?: boolean;
  selectedUsers: User[];
}

const createKey = (item: User): string => {
  return item.id;
};

const MultiSelectUserPicker = (props: IMultiSelectUserPicker) => {
  const { t } = useTranslation('translation');
  const appContext = useContext(AppContext);
  const [users, setUsers] = useState<User[]>(props.users);

  useEffect(() => {
    if (props.users.length === 0) {
      if (props.showUnlicensedUsers) {
        setUsers(appContext.globalDataCache.users.items);
      } else {
        setUsers(appContext.globalDataCache.users.items.filter((u) => u.hasLicense));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    onRenderNoResultFound: () => {
      return (
        <Stack styles={{ root: { padding: 10 } }}>
          <Text>{t('translation:General.Suggestions.NoData')}</Text>
        </Stack>
      );
    },
  };

  const getPersonas = (users: User[]): IPickerProps[] => {
    const personaProps: IPickerProps[] = [];

    for (const user of users) {
      const personaProp: IPickerProps = {
        text: user.name,
        key: createKey(user),
        secondaryText: user.email,
      };
      personaProps.push(personaProp);
    }

    return personaProps.sort((a, b) => {
      return a.text === b.text || !a.text || !b.text ? 0 : a.text < b.text ? -1 : 1;
    });
  };

  const getSelectedPersona = (): IPickerProps[] | undefined => {
    const personaProps: IPickerProps[] = [];
    let filterUsers: User[] = [];

    if (props.selectedUsers) {
      filterUsers = users.filter(
        (currUser) => props.selectedUsers?.some((selectUser: User) => selectUser.id === currUser.id),
      );
      filterUsers.forEach((user: User) => {
        const personaProp: IPickerProps = {
          text: user.name,
          key: createKey(user),
          secondaryText: user.email,
        };
        personaProps.push(personaProp);
      });
    }

    return personaProps;
  };

  const getNameFromItem = (persona: IPersonaProps): string => {
    return persona.text as string;
  };

  const filterAllUsersByText = (filterText: string): User[] => {
    return users.filter(
      (item) =>
        item.hasLicense === true &&
        (item.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 ||
          item.email.toLowerCase().indexOf(filterText.toLowerCase()) >= 0),
    );
  };

  const onSuggestion = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    let filteredPersonas: IPickerProps[] = [];
    let filteredUsers = filterAllUsersByText(filterText);
    filteredUsers = filteredUsers.filter((u) => !props.selectedUsers?.some((e) => e.id === u.id));
    filteredUsers = filteredUsers.filter((u) => !props.usersToExclude?.some((e) => e.id === u.id));
    filteredPersonas = getPersonas(filteredUsers);
    filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;

    return filteredPersonas;
  };

  const onChange = (personas: IPersonaProps[] | undefined): void => {
    if (!personas || personas.length === 0) {
      if (props.onMultipleSelect) {
        props.onMultipleSelect([]);
      }

      return;
    }

    let personaModified: IPickerProps[] | undefined = [];
    personaModified = personas?.map((persona) => {
      return persona as IPickerProps;
    });
    if (!personaModified) {
      return;
    }

    const items = users.filter((_user) => personaModified?.some((persona) => createKey(_user) === persona.key));

    if (props.onMultipleSelect) {
      props.onMultipleSelect([...items]);
    }
  };

  const onRenderSuggestionsItem = (
    props: IPersonaProps,
    itemProps: ISuggestionItemProps<IPersonaProps>,
  ): JSX.Element => {
    const modifiedProps: IPickerProps = props as IPickerProps;

    return (
      <Stack verticalAlign="center" tokens={{ padding: '5px' }}>
        <Persona {...modifiedProps} size={PersonaSize.size40} hidePersonaDetails={false} />
      </Stack>
    );
  };

  const onEmptyResolveSuggestions = (selectedItems?: IPersonaProps[] | undefined): IPersonaProps[] => {
    const filteredPersonas: IPickerProps[] = getPersonas(users);

    return filteredPersonas.slice(0, 30);
  };

  const inputProps: IInputProps = {
    placeholder: props.placeHolder || t('translation:General.UserPicker.Placeholder'),
  };

  //
  // Main render
  //
  return (
    <NormalPeoplePicker
      itemLimit={props.itemLimit ?? 1}
      onResolveSuggestions={onSuggestion}
      getTextFromItem={getNameFromItem}
      pickerSuggestionsProps={suggestionProps}
      selectedItems={getSelectedPersona()}
      onChange={onChange}
      resolveDelay={globalFilterDelay}
      onRenderSuggestionsItem={onRenderSuggestionsItem}
      onEmptyResolveSuggestions={onEmptyResolveSuggestions}
      disabled={props.disabled}
      styles={props.styles}
      inputProps={inputProps}
    />
  );
};
export default MultiSelectUserPicker;
