import { useContext } from 'react';
import { ScrollablePane, ScrollbarVisibility, Panel, PanelType, IconButton, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import NavMain from './NavMain';
import { globalNavBarHeight } from 'globalConstants';

interface INavRootOverlayProps {
  onClose: () => void;
}

const NavRootOverlay = (props: INavRootOverlayProps) => {
  const appContext = useContext(AppContext);

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFixedNear}
      hasCloseButton={false}
      styles={{ root: { top: globalNavBarHeight } }}
    >
      <Stack onMouseLeave={() => props.onClose()} verticalFill>
        <Stack.Item>
          <IconButton
            iconProps={{ iconName: 'GlobalNavButton' }}
            onClick={() => {
              appContext.toggleMainNavCollapsed();
              props.onClose();
            }}
            styles={{ root: { left: -20, top: -10 } }}
          ></IconButton>
        </Stack.Item>
        <Stack.Item>
          <ScrollablePane
            styles={{
              root: {
                top: 40,
                minWidth: 193,
                position: 'absolute',
              },
            }}
            scrollbarVisibility={ScrollbarVisibility.auto}
          >
            <NavMain collapsed={false} expandedOnHover={false} />
          </ScrollablePane>
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default NavRootOverlay;
