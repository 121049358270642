import Language from 'models/language';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import StringValueDTO from 'models/dto/stringValue/stringValueDTO';
import StringValue from 'models/stringValue/stringValue';
import { StringValue_TranslationDTO } from 'models/dto/stringValue/stringValue_TranslationDTO';
import { StringValue_Translation } from 'models/stringValue/stringValue_Translation';

export function mapFromStringValues(stringvalueDTOs: StringValueDTO[] | undefined): StringValue[] {
  if (!stringvalueDTOs) return [];

  const output = stringvalueDTOs.map((item) => {
    return mapFromStringValue(item);
  });

  return output;
}

export function mapFromStringValue(stringvalueDTO: StringValueDTO): StringValue {
  const output = new StringValue();

  output.stringValueId = stringvalueDTO.stringValueId;
  output.stringValueKey = stringvalueDTO.stringValueKey;
  output.stringTypeId = stringvalueDTO.stringTypeId;
  output.stringTypeEntityId = stringvalueDTO.stringTypeEntityId;
  output.sortOrder = stringvalueDTO.sortOrder;
  output.trans = mapFromStringValue_Translation(stringvalueDTO.trans);

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.value = output.trans[0].value;
    output.description = output.trans[0].description;
  }

  return output;
}

export const mapFromStringValue_Translation = (transDTOs: StringValue_TranslationDTO[]): StringValue_Translation[] => {
  const output: StringValue_Translation[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new StringValue_Translation();

    newObject_Translation.stringValueId = transDTO.stringValueId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.value = transDTO.value;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export function mapToStringValues(stringvalues: StringValue[] | undefined): StringValueDTO[] {
  if (!stringvalues) return [];

  const output = stringvalues.map((item) => {
    return mapToStringValue(item);
  });

  return output;
}

export function mapToStringValue(stringvalue: StringValue): StringValueDTO {
  const output = new StringValueDTO();

  output.stringValueId = stringvalue.stringValueId;
  output.stringValueKey = stringvalue.stringValueKey;
  output.stringTypeId = stringvalue.stringTypeId;
  output.stringTypeEntityId = stringvalue.stringTypeEntityId;
  output.sortOrder = stringvalue.sortOrder;
  output.trans = mapToStringValue_Translation(stringvalue.trans);

  if (stringvalue.trans && stringvalue.transIdx >= 0) {
    output.trans[stringvalue.transIdx].value = stringvalue.value;
    output.trans[stringvalue.transIdx].description = stringvalue.description;
  }

  return output;
}

export const mapToStringValue_Translation = (transDTOs: StringValue_Translation[]): StringValue_TranslationDTO[] => {
  const output: StringValue_TranslationDTO[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new StringValue_TranslationDTO();

    newObject_Translation.stringValueId = transDTO.stringValueId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.value = transDTO.value;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapToLanguage(transDTO.lang);

    output.push(newObject_Translation);
  }

  return output;
};
