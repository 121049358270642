import { Stack, Text, Link, IconButton } from '@fluentui/react';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import { BaseItem, DriveItem } from 'microsoft-graph';
import { IDrive, ISite } from '../../services/Graph/SharepointInterfaces';
import { ParentFolderInformation } from './DirectoryExplorer';
import { Fragment } from 'react/jsx-runtime';

interface IDirectoryParents {
  parents: DriveItem[];
  site?: ISite;
  drive?: BaseItem;
  onSelectDriveFolder: (parent: BaseItem, isChild: boolean) => void;
  onSelectDrive: (item: IDrive) => void;
  onBack: () => void;
  onSelectSite: (site?: ISite) => void;
  disableBackBeforeParentInfo?: boolean;
  parentInfo?: ParentFolderInformation;
}
const DirectoryParents = (props: IDirectoryParents) => {
  const parentsNoRoot = props.parents.filter((p) => !p.root);
  let disableBackIndex = -1;
  let disableRoot = false;

  //When disableBackBeforeLastParent is enabled, the driveItemId in the parentInfo is the last part that can be navigated back to.
  //Look up this id in the parents array to find the index of the last part. All parts before this index will be disabled.
  if (props.disableBackBeforeParentInfo) {
    if (parentsNoRoot.length === 0) {
      disableRoot = true;
    } else {
      disableBackIndex = props.parents.findIndex((p) => p.id === props.parentInfo?.driveItemId);
      if (disableBackIndex >= 0) disableRoot = true;
    }
  }

  //
  // Main render
  //
  return (
    <Stack horizontal tokens={globalStackTokensGapExtraSmall} verticalAlign="center">
      <IconButton
        iconProps={{ iconName: 'NavigateBack' }}
        onClick={props.onBack}
        disabled={
          (parentsNoRoot.length === 0 && props.drive === undefined) || props.parents.length - 1 <= disableBackIndex
        }
      />

      {/* Root site */}
      {props.site && (
        <Text block nowrap onClick={() => props.onSelectSite(props.site)}>
          <Link
            underline={parentsNoRoot.length !== 0 || props.drive !== undefined}
            disabled={(parentsNoRoot.length === 0 && !props.drive) || disableRoot}
          >
            {props.site.displayName}
          </Link>
          {(parentsNoRoot.length !== 0 || props.drive) && '  >'}
        </Text>
      )}

      {/* Root drive */}
      {props.drive && (
        <Text block nowrap>
          <Link
            underline={parentsNoRoot.length !== 0}
            disabled={parentsNoRoot.length === 0 || disableRoot}
            onClick={() => {
              if (props.drive) props.onSelectDrive(props.drive);
            }}
          >
            {props.drive.name}
          </Link>
          {parentsNoRoot.length !== 0 && '  >'}
        </Text>
      )}

      {/* Directory path */}
      {parentsNoRoot.map((parent: BaseItem, index: number) => {
        const disabled = index === parentsNoRoot.length - 1 || index < disableBackIndex;
        const underline = !disabled;

        return (
          <Text block nowrap key={index}>
            {index < parentsNoRoot.length - 1 && (
              <Fragment>
                <Link
                  disabled={disabled}
                  underline={underline}
                  onClick={() => props.onSelectDriveFolder(parent, false)}
                >
                  {parent.name}
                </Link>
                {'  >'}
              </Fragment>
            )}
            {index >= parentsNoRoot.length - 1 && <Text>{parent.name}</Text>}
          </Text>
        );
      })}
    </Stack>
  );
};

export default DirectoryParents;
