import React, { FunctionComponent } from 'react';
import {
  Text,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Spinner,
  SpinnerSize,
  IPersonaStyles,
  List,
  Persona,
  PersonaSize,
  IconButton,
  TooltipHost,
  IIconProps,
} from '@fluentui/react';

import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { sortOnDate } from 'utils/sorting';
import SingleTask from 'components/Tasks/SingleTask';
import { globalStackItemStylesPaddingSceneScroll } from 'globalStyles';
import Activity, { ActivityType } from 'models/activity';
import { EntityTypes } from 'models/entity';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { apiGetSingleTask } from 'services/Api/taskService';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { toNameInitial } from 'utils/string';
import { apiDismissActivity } from 'services/Api/dashboardService';
import { IWidgetRendererProps } from '../WidgetRenderer';
import DateDividerListItem, {
  DataGroup,
  DateComparer,
  DateDivider,
  insertDividersAndGroupsInList,
} from 'components/Utils/ListItemDateDividers';

interface IWidgetRecentlyClosedTasksProps extends IWidgetRendererProps {}

const WidgetRecentlyClosedTasks: FunctionComponent<IWidgetRecentlyClosedTasksProps> = (
  props: IWidgetRecentlyClosedTasksProps,
) => {
  const { t } = useTranslation(['translation', 'dashboard', 'widgets']);
  const appContext = React.useContext(AppContext);
  const history = useHistory();
  const [recentClosedData, setRecentClosedData] = React.useState<Activity[] | undefined>(undefined);
  const [recentClosedDataDisplay, setRecentClosedDataDisplay] = React.useState<
    (Activity | DateDivider | DataGroup<Activity>)[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTask, setSelectedTask] = React.useState<Task | undefined>(undefined);
  const [showIcon, setShowIcon] = React.useState<number | undefined>(undefined);
  const dismissIcon: IIconProps = { iconName: 'RemoveFilter' };

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget, props.sharedData]);

  React.useEffect(() => {
    if (recentClosedData && recentClosedData?.length > 0) {
      const newData = insertDividersAndGroupsInList(recentClosedData, getCompareDate, undefined, undefined);
      setRecentClosedDataDisplay(newData);
    }
     
  }, [recentClosedData]);

  const loadData = async (refresh?: boolean) => {
    try {
      if (props.onRefreshSharedData && props.widget.dashboard && refresh) {
        await props.onRefreshSharedData();

        return;
      }

      if (isLoading) return;
      setIsLoading(true);

      const activities = props.sharedData?.activities;

      setRecentClosedData(
        activities
          ?.filter((activity: Activity) => activity.typeOfActivity === ActivityType.RecentlyClosed)
          .sort((a, b) => -1 * sortOnDate(a.created, b.created)),
      );
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getCompareDate: DateComparer<Activity> = (a) => {
    return a.created;
  };

  const getTaskDetail = async (taskId: number) => {
    if (!taskId) {
      return;
    }

    try {
      appContext.showContentLoader();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetSingleTask(taskId, false, accessToken, appContext.globalDataCache);
      if (tasks && tasks.tasks && tasks.tasks.length > 0) {
        const task = tasks.tasks[0];
        setSelectedTask(task);
      } else {
        appContext.showNotification(t('dashboard:Errors.ItemHasBeenDeleted'));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const onPersonClickHandler = (activity: Activity) => {
    switch (activity.entity.typeOfEntity) {
      case EntityTypes.Requirement:
        history.push({ pathname: `theme/${activity.entity.entityId}` });
        break;
      case EntityTypes.Control:
        history.push({ pathname: `control/${activity.entity.entityId}` });
        break;
      case EntityTypes.Task:
        getTaskDetail(activity.entity.entityId);
        break;
      case EntityTypes.Risk:
        history.push({ pathname: `/risk/${activity.entity.entityId}` });
        break;
      default:
        break;
    }
  };

  const dismissActivity = async (activityId: number) => {
    try {
      appContext.showContentLoader();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiDismissActivity(activityId, accessToken);
      setRecentClosedData(recentClosedData?.filter((a) => a.activityId !== activityId));
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      marginBottom: 5,
      selectors: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  const saveTask = async (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      await loadData(true);
    }
    setSelectedTask(undefined);
  };

  const removeTask = async (task: Task) => {
    setSelectedTask(undefined);
    await loadData(true);
  };

  const updateTask = async (task: Task) => {};

  return (
    <Stack verticalFill styles={{ root: { paddingTop: 5 } }}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <List
            items={recentClosedDataDisplay}
            onRenderCell={(activity) => {
              if (!activity) return null;

              if (activity instanceof DateDivider) {
                return <DateDividerListItem {...activity} />;
              }

              if (activity instanceof DataGroup) {
                return null;
              }

              return (
                <Stack
                  horizontal
                  onMouseOver={() => {
                    setShowIcon(activity.activityId);
                    if (recentClosedData) setRecentClosedData([...recentClosedData]);
                  }}
                  onMouseLeave={() => {
                    setShowIcon(undefined);
                    if (recentClosedData) setRecentClosedData([...recentClosedData]);
                  }}
                  styles={{ root: { paddingRight: 5 } }}
                >
                  <Stack.Item styles={{ root: { width: '85%' } }}>
                    <Persona
                      styles={personaStyle}
                      size={PersonaSize.size40}
                      text={activity.entity.entityName || ''}
                      secondaryText={toLocaleDateTimeMedium(activity.created)}
                      imageInitials={toNameInitial(appContext.globalDataCache.users.get(activity.userId).name)}
                      onClick={() => onPersonClickHandler(activity)}
                    />
                  </Stack.Item>
                  {showIcon === activity.activityId && activity.dismissed === undefined && (
                    <Stack.Item>
                      <TooltipHost content={t('dashboard:ActivityDismiss.label')}>
                        <IconButton iconProps={dismissIcon} onClick={() => dismissActivity(activity.activityId)} />
                      </TooltipHost>
                    </Stack.Item>
                  )}
                </Stack>
              );
            }}
          />
        </ScrollablePane>
      </Stack.Item>
      {recentClosedData?.length === 0 && (
        <Stack.Item>
          <Text variant="medium">{t('widgets:RecentlyClosedTasks.NoItems')}</Text>
        </Stack.Item>
      )}
      {selectedTask && (
        <SingleTask
          task={selectedTask}
          isOpen={true}
          close={() => {
            setSelectedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
        />
      )}
    </Stack>
  );
};

export default WidgetRecentlyClosedTasks;
