import React from 'react';
import CurrentUser from 'models/currentuser';
import AppError from 'utils/appError';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { globalDesktopHeight, globalDesktopSize } from 'globalConstants';
import Tenant from 'models/tenant';
import AdminTenant from 'models/adminTenant';

export interface IAppContext {
  useDarkMode: boolean;
  setUseDarkMode: Function;
  isContentLoading: boolean;
  showContentLoader: Function;
  hideContentLoader: Function;
  unhandledError?: AppError;
  error?: AppError;
  setError: Function;
  showNotification: Function;
  hasScopes: Function;
  getAccessToken: Function;
  setUserProfile: Function;
  isAuthenticated: boolean;
  isAuthInProgress: boolean;
  user: CurrentUser;
  showResourcePanel: boolean;
  setShowResourcePanel: Function;
  toggleResourcePanel: Function;
  toggleSideBarPanel: Function;
  isOpenSideBarPanel: boolean;
  refreshTenantList: Function;
  isMainNavCollapsed: boolean;
  toggleMainNavCollapsed: Function;
  globalDataCache: GlobalDataCache;
  cacheMiss: Function;
  getGraphInterface: Function;
  isMobileView: boolean;
  windowSize: number;
  windowHeight: number;
  isOpenHelpPanel: boolean;
  setHelpPanel: Function;
  adminConsent: Function;
  adminConsentScope: Function;
  startQuery: string | undefined;
  clearStartQuery: Function;
  changeUserLanguage: Function;
  setLogo: Function;
  globalFilter: string[];
  setGlobalFilter: (filter: string[]) => void
  login: () => Promise<void>;
  logout: () => Promise<void>;
  switchOrg: (tenant: Tenant) => Promise<void>;
  switchOrgUnit: (tenant: Tenant) => Promise<void>;
  switchCustomer: (tenant: AdminTenant) => Promise<void>;
}

const appContext: IAppContext = {
  useDarkMode: false,
  setUseDarkMode: () => {},
  isContentLoading: false,
  showContentLoader: () => {},
  hideContentLoader: () => {},
  error: undefined,
  setError: () => {},
  showNotification: () => {},
  hasScopes: () => {},
  getAccessToken: () => {},
  setUserProfile: () => {},
  isAuthenticated: false,
  isAuthInProgress: false,
  user: CurrentUser.getEmptyUser(),
  showResourcePanel: false,
  setShowResourcePanel: () => {},
  toggleResourcePanel: () => {},
  isMainNavCollapsed: false,
  toggleMainNavCollapsed: () => {},
  globalDataCache: new GlobalDataCache(),
  cacheMiss: () => {},
  getGraphInterface: () => {},
  refreshTenantList: () => {},
  windowSize: globalDesktopSize,
  isMobileView: false,
  toggleSideBarPanel: () => {},
  isOpenSideBarPanel: false,
  windowHeight: globalDesktopHeight,
  isOpenHelpPanel: false,
  setHelpPanel: () => {},
  adminConsent: () => {},
  adminConsentScope: () => {},
  startQuery: undefined,
  clearStartQuery: () => {},
  changeUserLanguage: () => {},
  setLogo: () => {},
  login: async () => {},
  logout: async () => {},
  switchOrg: async () => {},
  switchOrgUnit: async () => {},
  switchCustomer: async () => {},
  globalFilter: [],
  setGlobalFilter: () => {},
};

const AppContext = React.createContext(appContext);
AppContext.displayName = 'Global app context';

export default AppContext;
