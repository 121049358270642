import { globalUserLang, globalDefOrgLang } from 'App/AppContextProvider';
import { DBProcesLoggerLogLevel } from './../models/dto/logDTO';
import NormDTO, { ISONormDTO, ISONormUpgradeLogDTO, ISONormUpgradeResultDTO } from 'models/dto/normDTO';
import Norm_TranslationDTO, { ISONorm_TranslationDTO } from 'models/dto/norm_TranslationDTO';
import Norm, { ISONorm, ISONormUpgradeLog, ISONormUpgradeResult } from 'models/norm';
import Norm_Translation, { ISONorm_Translation } from 'models/norm_Translation';
import { mapFromLanguage, mapToLanguage } from './dataMapping';
import { mapFromTag } from './tagMapping';
import AppError from 'utils/appError';

export function mapFromNorms(normDTOs: NormDTO[]): Norm[] {
  const normList: Norm[] = [];

  for (const normDTO of normDTOs) {
    const newNorm = mapFromNorm(normDTO);
    normList.push(newNorm);
  }

  return normList;
}

export function mapFromNorm(normDTO: NormDTO): Norm {
  const newNorm = new Norm();
  let trans_idx: number = -1;

  newNorm.normId = normDTO.normId;
  newNorm.authSchemaId = normDTO.authSchemaId;
  newNorm.active = normDTO.active;
  newNorm.isoNormId = normDTO.isoNormId;
  newNorm.status = normDTO.status;
  newNorm.trans = mapFromNorm_Translation(normDTO.trans);
  newNorm.isoNorm = normDTO.isoNorm ? mapFromISONorm(normDTO.isoNorm) : undefined;

  const tIdx = newNorm.getTransIdx(globalUserLang, globalDefOrgLang);
  if (newNorm.trans && tIdx >= 0) {
    trans_idx = tIdx;
    newNorm.transIdx = trans_idx;
    newNorm.name = newNorm.trans[trans_idx].name;
    newNorm.description = newNorm.trans[trans_idx].description;
  }

  return newNorm;
}

export function mapFromNorm_Translation(
  norm_TranslationDTOs: Norm_TranslationDTO[] | undefined,
): Norm_Translation[] | undefined {
  if (!norm_TranslationDTOs) {
    return undefined;
  }

  const norm_TranslationList: Norm_Translation[] = [];

  for (const norm_TranslationDTO of norm_TranslationDTOs) {
    const newNorm_Translation = new Norm_Translation();
    newNorm_Translation.normId = norm_TranslationDTO.normId;
    newNorm_Translation.languageId = norm_TranslationDTO.languageId;
    newNorm_Translation.name = norm_TranslationDTO.name;
    newNorm_Translation.description = norm_TranslationDTO.description;
    newNorm_Translation.lang = mapFromLanguage(norm_TranslationDTO.lang);
    norm_TranslationList.push(newNorm_Translation);
  }

  return norm_TranslationList;
}

export function mapToNorm(norm: Norm): NormDTO {
  const newNormDTO = new NormDTO();

  newNormDTO.normId = norm.normId;
  newNormDTO.authSchemaId = norm.authSchemaId;
  newNormDTO.active = norm.active;
  newNormDTO.isoNormId = norm.isoNormId;
  newNormDTO.trans = mapToNorm_Translation(norm.trans);

  if (newNormDTO.trans && norm.transIdx >= 0 && norm.transIdx < newNormDTO.trans.length) {
    newNormDTO.trans[norm.transIdx].name = norm.name;
    newNormDTO.trans[norm.transIdx].description = norm.description;
  }

  return newNormDTO;
}

export function mapToNorm_Translation(
  norm_Translations: Norm_Translation[] | undefined,
): Norm_TranslationDTO[] | undefined {
  if (!norm_Translations) {
    return undefined;
  }

  const norm_TranslationList: Norm_TranslationDTO[] = [];

  for (const norm_Translation of norm_Translations) {
    const newNorm_TranslationDTO = new Norm_TranslationDTO();
    newNorm_TranslationDTO.normId = norm_Translation.normId;
    newNorm_TranslationDTO.languageId = norm_Translation.languageId;
    newNorm_TranslationDTO.name = norm_Translation.name;
    newNorm_TranslationDTO.description = norm_Translation.description;
    newNorm_TranslationDTO.lang = mapToLanguage(norm_Translation.lang);
    norm_TranslationList.push(newNorm_TranslationDTO);
  }

  return norm_TranslationList;
}

export function mapFromISONorms(isoNormDTOs: ISONormDTO[]): ISONorm[] {
  const isoNormList: ISONorm[] = [];

  for (const isoNormDTO of isoNormDTOs) {
    const newISONorm = mapFromISONorm(isoNormDTO);
    isoNormList.push(newISONorm);
  }

  return isoNormList;
}

export function mapFromISONorm(isoNormDTO: ISONormDTO): ISONorm {
  const newISONorm = new ISONorm();
  let trans_idx: number = -1;

  newISONorm.isoNormId = isoNormDTO.isoNormId;
  newISONorm.published = isoNormDTO.published;
  newISONorm.upgradeISONormId = isoNormDTO.upgradeISONormId;
  newISONorm.installUsingPackage = isoNormDTO.installUsingPackage;
  newISONorm.norm = isoNormDTO.norm ? mapFromNorm(isoNormDTO.norm) : undefined;
  newISONorm.trans = mapFromISONorm_Translation(isoNormDTO.trans);

  const tIdx = newISONorm.getTransIdx(globalUserLang, globalDefOrgLang);
  if (newISONorm.trans && tIdx >= 0) {
    trans_idx = tIdx;
    newISONorm.transIdx = trans_idx;
    newISONorm.name = newISONorm.trans[trans_idx].name;
    newISONorm.description = newISONorm.trans[trans_idx].description;
  }

  return newISONorm;
}

export function mapToISONorm(isoNorm: ISONorm): ISONormDTO {
  const newISONormDTO = new ISONormDTO();

  newISONormDTO.isoNormId = isoNorm.isoNormId;
  newISONormDTO.published = isoNorm.published;
  newISONormDTO.upgradeISONormId = isoNorm.upgradeISONormId;
  newISONormDTO.installUsingPackage = isoNorm.installUsingPackage;
  newISONormDTO.trans = mapToISONorm_Translation(isoNorm.trans);

  if (newISONormDTO.trans && isoNorm.transIdx >= 0) {
    newISONormDTO.trans[isoNorm.transIdx].name = isoNorm.name;
    newISONormDTO.trans[isoNorm.transIdx].description = isoNorm.description;
  }

  return newISONormDTO;
}

export function mapFromISONorm_Translation(
  isoNorm_TranslationDTOs: ISONorm_TranslationDTO[] | undefined,
): ISONorm_Translation[] | undefined {
  if (!isoNorm_TranslationDTOs) {
    return undefined;
  }

  const norm_TranslationList: ISONorm_Translation[] = [];

  for (const isoNorm_TranslationDTO of isoNorm_TranslationDTOs) {
    const newISONorm_Translation = new ISONorm_Translation();
    newISONorm_Translation.isoNormId = isoNorm_TranslationDTO.isoNormId;
    newISONorm_Translation.languageId = isoNorm_TranslationDTO.languageId;
    newISONorm_Translation.name = isoNorm_TranslationDTO.name;
    newISONorm_Translation.description = isoNorm_TranslationDTO.description;
    newISONorm_Translation.lang = mapFromLanguage(isoNorm_TranslationDTO.lang);
    norm_TranslationList.push(newISONorm_Translation);
  }

  return norm_TranslationList;
}

export function mapToISONorm_Translation(
  control_Translations: ISONorm_Translation[] | undefined,
): ISONorm_TranslationDTO[] | undefined {
  if (!control_Translations) {
    return undefined;
  }

  const isoNorm_TranslationList: ISONorm_TranslationDTO[] = [];

  for (const isoNorm_Translation of control_Translations) {
    const newISONorm_TranslationDTO = new ISONorm_TranslationDTO();
    newISONorm_TranslationDTO.isoNormId = isoNorm_Translation.isoNormId;
    newISONorm_TranslationDTO.languageId = isoNorm_Translation.languageId;
    newISONorm_TranslationDTO.name = isoNorm_Translation.name;
    newISONorm_TranslationDTO.description = isoNorm_Translation.description;
    newISONorm_TranslationDTO.lang = mapToLanguage(isoNorm_Translation.lang);
    isoNorm_TranslationList.push(newISONorm_TranslationDTO);
  }

  return isoNorm_TranslationList;
}

export function mapFromISONormUpgradeResult(isoNormUpgradeResult: ISONormUpgradeResultDTO): ISONormUpgradeResult {
  const output: ISONormUpgradeResult = new ISONormUpgradeResult();

  output.log = isoNormUpgradeResult.log;
  output.error =
    isoNormUpgradeResult.log.level > DBProcesLoggerLogLevel.Info
      ? isoNormUpgradeResult.log.appError ?? new AppError('Unknown error')
      : undefined;
  output.sourceStandardId = isoNormUpgradeResult.sourceStandardId;
  output.targetStandardId = isoNormUpgradeResult.targetStandardId;
  output.tag = isoNormUpgradeResult.tag ? mapFromTag(isoNormUpgradeResult.tag) : undefined;
  output.taskCount = isoNormUpgradeResult.taskCount;
  output.upgradeLog = isoNormUpgradeResult.upgradeLog.map((l) => mapFromISONormUpgradeLog(l));

  return output;
}

export function mapFromISONormUpgradeLogs(isoNormUpgradeLogs: ISONormUpgradeLogDTO[]): ISONormUpgradeLog[] {
  return isoNormUpgradeLogs.map((l) => mapFromISONormUpgradeLog(l));
}

export function mapFromISONormUpgradeLog(isoNormUpgradeLog: ISONormUpgradeLogDTO): ISONormUpgradeLog {
  const output: ISONormUpgradeLog = new ISONormUpgradeLog();

  output.sourceControlId = isoNormUpgradeLog.sourceControlId;
  output.targetControlId = isoNormUpgradeLog.targetControlId;
  output.sourceISOControlId = isoNormUpgradeLog.sourceISOControlId;
  output.targetISOControlId = isoNormUpgradeLog.targetISOControlId;
  output.upgradeISONormId = isoNormUpgradeLog.upgradeISONormId;
  output.spUpgraded = isoNormUpgradeLog.spUpgraded;

  return output;
}
