import IdListDTO from 'models/dto/IdListDTO';
import ObjectiveContextDTO from 'models/dto/Objective/objectiveContextDTO';
import { ObjectiveDTO } from 'models/dto/Objective/objectiveDTO';
import { Objective_TranslationDTO } from 'models/dto/Objective/objective_TranslationDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import Language from 'models/language';
import Objective from 'models/objective/objective';
import { Objective_Translation } from 'models/objective/objective_Translation';
import { mapFromDashboard, mapFromProcess, mapFromTask, mapToDashboard, mapToTask } from './dataMapping';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import ObjectiveContext from 'models/objective/objectiveContext';
import { mapFromThemes } from './themeMapping';
import { mapFromControls } from './controlMappings';
import { mapFromRisk } from './riskMapping';
import { mapFromKPI } from './kpiMapping';

export const mapFromObjective = (objectiveDTO: ObjectiveDTO, globalDataCache: GlobalDataCache): Objective => {
  const output = new Objective();
  output.objectiveId = objectiveDTO.objectiveId;
  output.authSchemaId = objectiveDTO.authSchemaId;
  output.code = objectiveDTO.code;
  output.parentObjectiveId = objectiveDTO.parentObjectiveId;
  output.state = objectiveDTO.state;
  output.dashboard = objectiveDTO.dashboard ? mapFromDashboard(objectiveDTO.dashboard, globalDataCache) : undefined;
  output.commentTrailId = objectiveDTO.commentTrailId;
  output.groupId = objectiveDTO.groupId;
  output.ownerId = objectiveDTO.ownerId;
  output.ownerRoleId = objectiveDTO.ownerRoleId;
  output.tasks = objectiveDTO.tasks ? objectiveDTO.tasks.map((taskDTO) => mapFromTask(taskDTO, globalDataCache)) : [];
  output.tagIds = objectiveDTO.tagIds?.idList?.map<number>((i) => Number(i)) ?? [];
  output.auditTrailId = objectiveDTO.auditTrailId;
  output.processes = objectiveDTO.processes
    ? objectiveDTO.processes.map((item) => mapFromProcess(item, globalDataCache))
    : [];
  output.kpis = objectiveDTO.kpis ? objectiveDTO.kpis.map((item) => mapFromKPI(item, globalDataCache)) : [];
  output.trans = mapFromObjective_Translation(objectiveDTO.trans);

  if (objectiveDTO.trans && objectiveDTO.trans.length > 0) {
    output.transIdx = 0;
    output.name = objectiveDTO.trans[0].name;
    output.description = objectiveDTO.trans[0].description ?? '';
  }

  return output;
};

export const mapToObjective = (objective: Objective): ObjectiveDTO => {
  const output = new ObjectiveDTO();
  output.objectiveId = objective.objectiveId;
  output.authSchemaId = objective.authSchemaId;
  output.code = objective.code;
  output.parentObjectiveId =
    objective.parentObjectiveId && objective.parentObjectiveId >= 0 ? objective.parentObjectiveId : undefined;
  output.commentTrailId = objective.commentTrailId;
  output.groupId = objective.groupId;
  output.ownerId = objective.ownerId;
  output.ownerRoleId = objective.ownerRoleId;
  output.tasks = objective.tasks.map((task) => mapToTask(task));
  output.tagIds = new IdListDTO(objective.tagIds ? objective.tagIds.map((i) => String(i)) : []);
  output.auditTrailId = objective.auditTrailId;
  output.state = objective.state;
  output.dashboard = objective.dashboard ? mapToDashboard(objective.dashboard) : undefined;
  output.trans = mapToObjective_Translation(objective.trans);

  if (output.trans && objective.transIdx >= 0) {
    output.trans[objective.transIdx].name = objective.name;
    output.trans[objective.transIdx].description = objective.description;
  }

  return output;
};

export const mapFromObjective_Translation = (
  transDTOs: Objective_TranslationDTO[] | undefined,
): Objective_Translation[] | undefined => {
  if (!transDTOs) return undefined;

  const output: Objective_Translation[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new Objective_Translation();

    newObject_Translation.objectiveId = transDTO.objectiveId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export const mapToObjective_Translation = (
  transDTOs: Objective_Translation[] | undefined,
): Objective_TranslationDTO[] | undefined => {
  if (!transDTOs) return;

  const output: Objective_TranslationDTO[] = [];

  transDTOs.forEach((transDTO) => {
    const transItem = new Objective_TranslationDTO();
    transItem.objectiveId = transDTO.objectiveId;
    transItem.description = transDTO.description;
    transItem.name = transDTO.name;
    transItem.languageId = transDTO.languageId;
    transItem.lang = mapToLanguage(transDTO.lang);

    output.push(transItem);
  });

  return output;
};

export const mapFromObjectiveContext = (
  objectiveContextDTO: ObjectiveContextDTO,
  globalDataCache: GlobalDataCache,
): ObjectiveContext => {
  const output = new ObjectiveContext();
  output.parent = objectiveContextDTO.parent
    ? mapFromObjective(objectiveContextDTO.parent, globalDataCache)
    : undefined;
  output.children = mapFromObjectives(objectiveContextDTO.children, globalDataCache);
  output.themes = objectiveContextDTO.themes ? mapFromThemes(objectiveContextDTO.themes, globalDataCache) : [];
  output.controls = objectiveContextDTO.controls ? mapFromControls(objectiveContextDTO.controls, globalDataCache) : [];
  output.risks = objectiveContextDTO.risks
    ? objectiveContextDTO.risks.map((riskDTO) => mapFromRisk(riskDTO, globalDataCache))
    : [];

  return output;
};

export const mapFromObjectives = (
  objectivees: ObjectiveDTO[] | undefined,
  globalCache: GlobalDataCache,
): Objective[] => {
  if (!objectivees) return [];

  return objectivees.map((item) => mapFromObjective(item, globalCache));
};

export const mapToObjectives = (objectivees: Objective[] | undefined): ObjectiveDTO[] => {
  if (!objectivees) return [];

  return objectivees.map((item) => mapToObjective(item));
};
