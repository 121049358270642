import {
  ConstrainMode,
  DetailsList,
  FontIcon,
  IColumn,
  IconButton,
  IDetailsList,
  IDetailsRowProps,
  IPersonaStyles,
  Persona,
  PersonaSize,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { getEntityIcon } from 'globalFunctions';
import {
  globalStackTokensGapExtraSmall,
  globalStackTokensGapSmall,
  globalTextStylesCategory,
  globalTextStylesDisabled,
} from 'globalStyles';
import Activity, { ActivityType } from 'models/activity';
import Entity from 'models/entity';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiGetActivitiesForTypes } from 'services/Api/activityService';
import { apiRequest } from 'services/Auth/authConfig';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import { toNameInitial } from 'utils/string';

export interface IGlobalSearchEmptyState {
  focus: () => void;
}

interface IGlobalSearchEmptyStateProps {
  onSelectRecentSearch: (search: string) => void;
  onSelectRecentItem: (entity: Entity) => void;
}

export const GlobalSearchEmptyState = forwardRef(function GlobalSearchEmptyState(
  props: IGlobalSearchEmptyStateProps,
  ref,
) {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [recentItems, setRecentItems] = useState<Activity[]>([]);
  const recentSearchesRef = useRef<IDetailsList | null>(null);
  const recentItemsRef = useRef<IDetailsList | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hoveredRecentSearchItem, setHoveredRecentSearchItem] = useState<number>(-1);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(
    ref,
    (): IGlobalSearchEmptyState => {
      return {
        focus() {
          if (recentSearches.length > 0) {
            recentSearchesRef.current?.focusIndex(0);
          } else if (recentItems.length > 0) {
            recentItemsRef.current?.focusIndex(0);
          }
        },
      };
    },
    [recentItems.length, recentSearches.length],
  );

  const loadData = async () => {
    try {
      setIsLoading(true);
      loadRecentSearches();
      await loadRecentItems();
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadRecentSearches = () => {
    const searches = getLocalStorageData(appContext, LocalStorageKeys.GlobalSearchRecentSearches);

    if (searches) {
      setRecentSearches(searches.split('#~#'));
    }
  };

  const loadRecentItems = async () => {
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);
    const activities = await apiGetActivitiesForTypes(
      [ActivityType.RecentlyChanged, ActivityType.RecentlyViewed],
      10,
      false,
      accessToken,
    );
    setRecentItems(activities);
  };

  const columnsRecentSearches: IColumn[] = [
    {
      key: 'column1',
      name: 'Recent Searches',
      minWidth: 100,
      onRender: (item: string) => {
        return (
          <Stack
            horizontal
            styles={{ root: { cursor: 'pointer' } }}
            tokens={globalStackTokensGapSmall}
            verticalAlign="center"
            onClick={() => props.onSelectRecentSearch(item)}
          >
            <FontIcon iconName="History" style={{ fontSize: 16, fontWeight: 600 }} />
            <Text>{item}</Text>
          </Stack>
        );
      },
    },
    {
      key: 'column1Remove',
      name: 'Remove',
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: string, index) => {
        if (index !== hoveredRecentSearchItem) return null;

        return (
          <TooltipHost content={t('translation:GlobalSearch.RemoveRecentSearch')}>
            <IconButton
              styles={{ root: { height: 18 } }}
              iconProps={{ iconName: 'StatusCircleErrorX' }}
              onClick={() => removeRecentSearch(index)}
            />
          </TooltipHost>
        );
      },
    },
  ];

  const removeRecentSearch = (index: number) => {
    const searches = getLocalStorageData(appContext, LocalStorageKeys.GlobalSearchRecentSearches);
    const recentSearches = searches ? searches.split('#~#') : [];
    recentSearches.splice(index, 1);
    setLocalStorageData(appContext, LocalStorageKeys.GlobalSearchRecentSearches, recentSearches.join('#~#'));
    setRecentSearches(recentSearches);
  };

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      marginBottom: 5,
      selectors: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  };

  const columnsRecentItems: IColumn[] = [
    {
      key: 'column2',
      name: 'Recent Items',
      minWidth: 100,
      onRender: (item: Activity) => {
        return (
          <Persona
            styles={personaStyle}
            size={PersonaSize.size40}
            text={item.entity.entityName}
            imageInitials={toNameInitial(appContext.globalDataCache.users.get(item.userId).name)}
            onClick={() => props.onSelectRecentItem(item.entity)}
            onRenderPrimaryText={() => (
              <Stack horizontal tokens={globalStackTokensGapExtraSmall} verticalAlign="center">
                {getEntityIcon(item.entity)}
                <Text>{item.entity.entityName}</Text>
              </Stack>
            )}
            onRenderSecondaryText={() => (
              <Text variant="small" styles={globalTextStylesCategory}>
                {toLocaleDateTimeMedium(item.created)}
              </Text>
            )}
          />
        );
      },
    },
  ];

  const onKeyDownRecentSearch = (ev: React.KeyboardEvent<HTMLElement>, text: string) => {
    switch (ev.key) {
      case 'Enter':
        props.onSelectRecentSearch(text);
        break;
      default:
        break;
    }
  };

  const onKeyDownRecentItem = (ev: React.KeyboardEvent<HTMLElement>, activity: Activity) => {
    switch (ev.key) {
      case 'Enter':
        props.onSelectRecentItem(activity.entity);
        break;
      default:
        break;
    }
  };

  const onRenderRowRecentSearch = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (rowProps?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (defaultRender && rowProps) {
      return (
        <Stack
          onMouseOver={() => setHoveredRecentSearchItem(rowProps.itemIndex ?? -1)} //allow index 0
          onMouseLeave={() => setHoveredRecentSearchItem(-1)}
          onKeyDown={(ev) => onKeyDownRecentSearch(ev, rowProps.item as string)}
        >
          {defaultRender(rowProps)}
        </Stack>
      );
    } else {
      return null;
    }
  };

  const onRenderRowRecentIems = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (rowProps?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (defaultRender && rowProps) {
      return (
        <Stack onKeyDown={(ev) => onKeyDownRecentItem(ev, rowProps.item as Activity)}>{defaultRender(rowProps)}</Stack>
      );
    } else {
      return null;
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Text variant="small" styles={globalTextStylesCategory}>
          {t('translation:GlobalSearch.RecentSearches')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        {recentSearches.length === 0 && (
          <Text variant="small" styles={globalTextStylesDisabled}>
            {t('translation:GlobalSearch.NoRecentSearches')}
          </Text>
        )}
        {recentSearches.length > 0 && (
          <DetailsList
            componentRef={recentSearchesRef}
            compact
            items={recentSearches}
            isHeaderVisible={false}
            columns={columnsRecentSearches}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained} //fixes horizontal scrollbar which appears sometimes
            onRenderRow={onRenderRowRecentSearch}
          />
        )}
      </Stack.Item>
      <Stack.Item>
        <Text variant="small" styles={globalTextStylesCategory}>
          {t('translation:GlobalSearch.RecentItems')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        {isLoading && (
          <Stack horizontalAlign="start">
            <Spinner size={SpinnerSize.small} />
          </Stack>
        )}
        {!isLoading && recentItems.length === 0 && (
          <Text variant="small" styles={globalTextStylesDisabled}>
            {t('translation:GlobalSearch.NoRecentItems')}
          </Text>
        )}
        {!isLoading && recentItems.length > 0 && (
          <DetailsList
            componentRef={recentItemsRef}
            compact
            items={recentItems}
            isHeaderVisible={false}
            columns={columnsRecentItems}
            selectionMode={SelectionMode.none}
            onRenderRow={onRenderRowRecentIems}
          />
        )}
      </Stack.Item>
    </Stack>
  );
});
