import { Client } from '@microsoft/microsoft-graph-client';
import AppError from 'utils/appError';
import { Site } from 'microsoft-graph';
import logger from 'services/Logging/logService';

//
// Sharepoint Sites
//
export const graphGetSiteRoot = async (client: Client): Promise<Site> => {
  try {
    const site = await client.api(`/sites/root`).get();

    return site;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSite = async (client: Client, siteId: string): Promise<Site> => {
  try {
    const site = await client.api(`/sites/${siteId}`).get();

    return site;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSiteByWebUrl = async (client: Client, webUrl: string): Promise<Site | undefined> => {
  try {
    let relUrl = webUrl.replace('/sites/', ':/sites/');
    if (relUrl.startsWith('https://')) relUrl = relUrl.substring(8);
    const site = await client.api(`/sites/${relUrl}`).get();

    return site;
  } catch {
    return undefined;
  }
};

export const graphGetSites = async (client: Client, text: string): Promise<Site[]> => {
  try {
    let relSite;
    let urlSite;
    let searchSites;

    if (text && text.startsWith('https://')) text = text.substring(8);

    try {
      searchSites = await client.api(`/sites?search=*${text}*`).get();
      if (!searchSites || !searchSites.value) throw new AppError('Could not search sites', 'undefined');
    } catch (err) {
      searchSites = { value: [] };
      const appErr = AppError.fromGraphError(err);
      if (appErr.code !== 'itemNotFound') {
        logger.debug('graphGetSites', err);
        throw new AppError('Could not access sites', 'accessDenied');
      }
    }

    if (searchSites.value.length === 0) {
      //when the search did not return any results and no error, try to get the site based on the complete site name
      try {
        const root = await graphGetSiteRoot(client);
        if (root) {
          const host = root.siteCollection?.hostname;
          if (host) {
            relSite = await client.api(`/sites/${host}:/sites/${text}`).get();
          }
        }
      } catch {
        //ignore
      }

      //when the search did not return any results, try to get the site based on the complete site url
      //this is the only option for guest users
      if (!relSite) {
        urlSite = await graphGetSiteByWebUrl(client, text);
      }
    }

    //combine sites
    const allSites: Site[] = [];
    if (searchSites.value.length > 0) {
      allSites.push(...searchSites.value);
    }
    if (relSite) {
      allSites.push(relSite);
    }
    if (urlSite) {
      allSites.push(urlSite);
    }

    return allSites;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSuggestedSites = async (client: Client): Promise<Site[]> => {
  // return the followed sites and the top 10 of all sites

  try {
    const top: number = 10;
    let followedSites;
    let allSites;
    let followedSitesError: boolean = false;
    let allSitesError: boolean = false;

    try {
      allSites = await client.api(`/sites?search=*&top=${top}`).get();
    } catch {
      allSitesError = true;
    }

    try {
      followedSites = await client.api(`/me/followedSites`).get();
    } catch {
      followedSitesError = true;
    }

    let allSitesTotal: Site[] = [];
    if (allSitesError && followedSitesError) {
      logger.debug('graphGetSuggestedSites: Could not access sites and followed sites');

      return [];
    } else {
      let allSites1: Site[] = [];
      let followedSites1: Site[] = [];
      if (!allSitesError) {
        allSites1 = allSites.value;
      }
      if (!followedSitesError && followedSites.value) {
        followedSites1 = followedSites.value;
      }
      allSitesTotal = allSites1.filter((s) => followedSites1.findIndex((fs) => s.id === fs.id) === -1);
      allSitesTotal.push(...followedSites1);
    }

    return allSitesTotal;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};
