import { Stack, IconButton, IButtonStyles , Text, ITextStyles } from '@fluentui/react';

import { cancelIcon, globalFontBoldWeight, globalStackTokensGapExtraSmall } from 'globalStyles';
import { getBestForGroundColor } from 'utils/colorHelpers';

export interface ISingleTag {
  tagId: string | number;
  tagName: string;
  tagColor: string;
}

export const getInfoTag = (id: string | number, text: string, darkMode: boolean): ISingleTag => {
  return {
    tagId: id,
    tagName: text,
    tagColor: darkMode ? '#1e7d49' : '#39f78f',
  };
};

interface ISingleTagProps {
  tag: ISingleTag;
  compact?: boolean;
  bold?: boolean;
  width?: number;
  minWidth?: number;
  maxWidth?: number; //set this to set a maximum width for example when the parent container has a maximum size
  onRemove?: (item: ISingleTag) => void;
}

export const SingleTag = (props: ISingleTagProps) => {
  const getTagStyles = (tag: ISingleTag) => {
    return {
      root: {
        background: tag.tagColor,
        borderRadius: '10px',
        margin: '2px',
        height: props.compact ? '18px' : '22px',
        width: props.width,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
      },
    };
  };

  const getTagTextStyles = (tag: ISingleTag, bold?: boolean): ITextStyles => {
    return {
      root: {
        marginLeft: '5px',
        marginRight: '5px',
        whiteSpace: 'nowrap',
        fontWeight: bold ? globalFontBoldWeight : undefined,
        color: getBestForGroundColor(tag),
      },
    };
  };

  const tagButtonStyles: IButtonStyles = {
    root: {
      borderRadius: '8px',
      height: props.compact ? '18px' : '22px',
      width: props.compact ? '18px' : '22px',
    },
  };

  return (
    <Stack
      horizontal
      styles={getTagStyles(props.tag)}
      tokens={globalStackTokensGapExtraSmall}
      verticalAlign="center"
      horizontalAlign="space-between"
    >
      <Stack.Item style={{ overflow: 'hidden' }}>
        <Text
          block
          nowrap
          styles={getTagTextStyles(props.tag, props.bold)}
          key={props.tag.tagId.toString()}
          variant={props.compact ? 'small' : 'medium'}
        >
          {props.tag.tagName}
        </Text>
      </Stack.Item>
      {props.onRemove && (
        <Stack.Item>
          <IconButton
            onClick={() => {
              if (props.onRemove) {
                props.onRemove(props.tag);
              }
            }}
            styles={tagButtonStyles}
            iconProps={cancelIcon}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

interface ISingleTagsProps {
  compact?: boolean;
  tags: ISingleTag[];
  onRemove?: (item: ISingleTag) => void;
}

export const SingleTags = (props: ISingleTagsProps) => {
  return (
    <Stack horizontal wrap tokens={globalStackTokensGapExtraSmall}>
      {props.tags?.map((_tag, index) => {
        return (
          <Stack.Item key={'tag' + index.toString()} styles={{ root: { margin: 0 } }}>
            <SingleTag tag={_tag} onRemove={props.onRemove} compact={props.compact} />
          </Stack.Item>
        );
      })}
    </Stack>
  );
};
