import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { Languages } from './language';
import {
  globalDefaultPackageLogoLargeDark,
  globalDefaultPackageLogoLargeLight,
  globalDefaultPackageLogoSmallDark,
  globalDefaultPackageLogoSmallLight,
  globalRegExSemVer,
} from 'globalConstants';
import { EntityTypes } from './entity';
import Norm from './norm';
import { TFunction } from 'i18next';
import ResourceLink from './resourceLink';
import ResourceList from './resourceList';
import Config from 'services/Config/configService';
import { areDifferent } from 'utils/array';
import { getDateTimeDiff } from 'utils/datetime';

export enum PackagePreChecksResult {
  Succes = 0,
  LinkOrg = 1,
  NormCount = 2,
  HomePage = 3,
  DuplicateLinks = 4,
  VirtualLists = 5,
  NoAuth = 99,
}

export enum PackageCategories {
  Starter = 0,
  AddOn = 1,
  Service = 2,
}

export enum PackageStates {
  Draft = 0,
  Review = 1,
  Live = 2,
  Rejected = 3,
  Archived = 4,
  ContentUpload = 99,
}

export enum PurchaseModes {
  Buy = 0,
  Order = 1,
  None = 9,
}

export enum PackageLogos {
  SmallLight = 1,
  SmallDark = 2,
  LargeLight = 3,
  LargeDark = 4,
}

export enum PackageInstallPrecheckResult {
  None = 0,
  TypeServiceCannotBeInstalled = 1,
  StateIsNotLive = 2,
  NotAssignedToTenant = 3,
  StateIsNotPaid = 4,
  PackageForPreviewAlreadyInstalled = 5,
  PackageForAddOnNotInstalled = 6,
}

export default class Package {
  packageId: number;

  tenantId: string;

  externalId: string;

  version: string;

  code: string;

  categoryId?: number;

  created: Date;

  ownerTenantId: string;

  ownerUserId: string;

  ownerName: string;

  state: PackageStates;

  commentTrailId: number;

  auditTrailId: number;

  name: string;

  shortDescription: string;

  description: string;

  price: number;

  modified?: Date;

  modifiedBy?: string;

  modifiedById?: string;

  webURLName?: string;

  webURL?: string;

  purchaseURL?: string;

  purchaseMode: number;

  startLinkId?: number;

  notificationEmail?: string;

  languages?: Languages;

  isoControls: number[];

  primaryLanguage: string;

  isPreview: boolean;

  norm?: Norm;

  tenants: PackageTenant[];

  contents: PackageContent[];

  sharePointLists: ResourceList[];

  sharePointLinks: ResourceLink[];

  imports: PackageImport[];

  fromPackages: Package[];

  toPackages: Package[];

  logoSmallLight?: Blob;

  logoSmallDark?: Blob;

  logoLargeLight?: Blob;

  logoLargeDark?: Blob;

  constructor() {
    this.packageId = 0;
    this.tenantId = '';
    this.version = '';
    this.code = '';
    this.created = new Date();
    this.ownerTenantId = '';
    this.ownerUserId = '';
    this.ownerName = '';
    this.state = PackageStates.Draft;
    this.name = '';
    this.shortDescription = '';
    this.description = '';
    this.price = 0;
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.purchaseMode = PurchaseModes.Buy;
    this.primaryLanguage = '';
    this.tenants = [];
    this.contents = [];
    this.sharePointLinks = [];
    this.sharePointLists = [];
    this.imports = [];
    this.externalId = '';
    this.isoControls = [];
    this.isPreview = false;
    this.fromPackages = [];
    this.toPackages = [];
  }

  static getLogoType(darkmode: boolean, large: boolean): PackageLogos {
    if (darkmode && large) return PackageLogos.LargeDark;
    if (darkmode) return PackageLogos.SmallDark;
    if (large) return PackageLogos.LargeLight;

    return PackageLogos.SmallLight;
  }

  static getDefaultLogo(logoType: PackageLogos): string {
    switch (logoType) {
      case PackageLogos.SmallLight:
        return globalDefaultPackageLogoSmallLight;
      case PackageLogos.SmallDark:
        return globalDefaultPackageLogoSmallDark;
      case PackageLogos.LargeLight:
        return globalDefaultPackageLogoLargeLight;
      case PackageLogos.LargeDark:
        return globalDefaultPackageLogoLargeDark;
    }
  }

  static getLogoImageSrc = (blob: Blob | undefined, defaultSrc: string): string => {
    let url: string | undefined = undefined;
    if (blob) {
      url = URL.createObjectURL(blob);
    } else {
      url = `${Config.getImageURL()}/${defaultSrc}`;
    }

    return url;
  };

  static GetPackageCategoryText = (id: number | undefined, t: TFunction<string[]>): string => {
    switch (id) {
      case PackageCategories.Starter:
        return t('package:Categories.Starter');
      case PackageCategories.AddOn:
        return t('package:Categories.AddOn');
      case PackageCategories.Service:
        return t('package:Categories.Service');
    }

    return '';
  };

  static GetPackageStateText = (id: number | undefined, t: TFunction<string[]>): string => {
    switch (id) {
      case PackageStates.ContentUpload:
        return t('package:States.ContentUpload');
      case PackageStates.Draft:
        return t('package:States.Draft');
      case PackageStates.Live:
        return t('package:States.Live');
      case PackageStates.Rejected:
        return t('package:States.Rejected');
      case PackageStates.Review:
        return t('package:States.Review');
      case PackageStates.Archived:
        return t('package:States.Archived');
    }

    return '';
  };

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().alphanum().max(64).required().label(localizedFields['code']),
      version: Joi.string().pattern(globalRegExSemVer).max(5).required().label(localizedFields['version']),
      name: Joi.string().max(128).required().label(localizedFields['name']),
      shortDescription: Joi.string().max(128).required().label(localizedFields['shortDescription']),
      ownerName: Joi.string().max(512).required().label(localizedFields['ownerName']),
      webURL: Joi.string() //web url is required for Service packages
        .when('categoryId', {
          is: PackageCategories.Starter,
          then: Joi.allow(''),
        })
        .when('categoryId', {
          is: PackageCategories.AddOn,
          then: Joi.allow(''),
        })
        .max(2048)
        .label(localizedFields['webURL']),
      webURLName: Joi.string().allow('').max(128).label(localizedFields['webURLName']),
      purchaseURL: Joi.string().allow('').max(2048).label(localizedFields['purchaseURL']),
      price: Joi.number().precision(2).min(0).less(10000).label(localizedFields['price']),
      notificationEmail: Joi.string()
        .allow('')
        .email({ tlds: { allow: false } })
        .max(512)
        .label(localizedFields['notificationEmail']),
    })
      .prefs(getLocalizedMessageOptions())
      .unknown();

    return schema.validate(
      {
        code: this.code,
        version: this.version,
        name: this.name,
        ownerName: this.ownerName,
        webURL: this.webURL || '',
        webURLName: this.webURLName || '',
        purchaseURL: this.purchaseURL || '',
        price: this.price,
        shortDescription: this.shortDescription,
        categoryId: this.categoryId,
        notificationEmail: this.notificationEmail || '',
      },
      { abortEarly: false },
    );
  }

  isEqual(newPack: Package): boolean {
    if (newPack.packageId !== this.packageId) return false;
    if (newPack.tenantId !== this.tenantId) return false;
    if (newPack.version !== this.version) return false;
    if (newPack.code !== this.code) return false;
    if (getDateTimeDiff(newPack.created, this.created)) return false;
    if (newPack.ownerTenantId !== this.ownerTenantId) return false;
    if (newPack.ownerUserId !== this.ownerUserId) return false;
    if (newPack.ownerName !== this.ownerName) return false;
    if (newPack.state !== this.state) return false;
    if (newPack.name !== this.name) return false;
    if (newPack.description !== this.description) return false;
    if (newPack.price !== this.price) return false;
    if (newPack.commentTrailId !== this.commentTrailId) return false;
    if (newPack.modified !== this.modified) return false;
    if (newPack.modifiedBy !== this.modifiedBy) return false;
    if (newPack.modifiedById !== this.modifiedById) return false;
    if (newPack.webURLName !== this.webURLName) return false;
    if (newPack.webURL !== this.webURL) return false;
    if (newPack.purchaseURL !== this.purchaseURL) return false;
    if (!newPack.languages?.isEqual(this.languages)) return false;
    if (newPack.categoryId !== this.categoryId) return false;
    if (newPack.shortDescription !== this.shortDescription) return false;
    if (newPack.purchaseMode !== this.purchaseMode) return false;
    if (newPack.startLinkId !== this.startLinkId) return false;
    if (newPack.notificationEmail !== this.notificationEmail) return false;
    if (newPack.isPreview !== this.isPreview) return false;

    if (
      areDifferent(
        newPack.toPackages.map((p) => p.packageId),
        this.toPackages.map((p) => p.packageId),
        (a: number, b: number) => {
          return a === b;
        },
      ) === true
    ) {
      return false;
    }

    if (
      areDifferent(
        newPack.fromPackages.map((p) => p.packageId),
        this.fromPackages.map((p) => p.packageId),
        (a: number, b: number) => {
          return a === b;
        },
      ) === true
    ) {
      return false;
    }

    return true;
  }

  clone(): Package {
    const newPack = new Package();
    newPack.packageId = this.packageId;
    newPack.tenantId = this.tenantId;
    newPack.version = this.version;
    newPack.code = this.code;
    newPack.created = new Date(this.created);
    newPack.ownerTenantId = this.ownerTenantId;
    newPack.ownerUserId = this.ownerUserId;
    newPack.ownerName = this.ownerName;
    newPack.state = this.state;
    newPack.name = this.name;
    newPack.description = this.description;
    newPack.price = this.price;
    newPack.commentTrailId = this.commentTrailId;
    newPack.modified = this.modified;
    newPack.modifiedBy = this.modifiedBy;
    newPack.modifiedById = this.modifiedById;
    newPack.webURLName = this.webURLName;
    newPack.webURL = this.webURL;
    newPack.purchaseURL = this.purchaseURL;
    newPack.languages = this.languages?.clone();
    newPack.categoryId = this.categoryId;
    newPack.shortDescription = this.shortDescription;
    newPack.purchaseMode = this.purchaseMode;
    newPack.primaryLanguage = this.primaryLanguage;
    newPack.commentTrailId = this.commentTrailId;
    newPack.auditTrailId = this.auditTrailId;
    newPack.startLinkId = this.startLinkId;
    newPack.imports = this.imports;
    newPack.externalId = this.externalId;
    newPack.notificationEmail = this.notificationEmail;
    newPack.norm = this.norm?.clone();
    newPack.isPreview = this.isPreview;
    newPack.toPackages = [...this.toPackages];
    newPack.fromPackages = [...this.fromPackages];

    return newPack;
  }
}

export enum PackageTenantStates {
  Available = 0, //the package is made available to the customer
  Ordered = 1, //customer confirmed purchase on the item in the store
  Paid = 2, //cuctomer paid the invoice after ordering
  Activated = 3, //customer activated the package
  Installing = 99, //activation progress: running server-side
  ContentUpload = 100, //activation progress: running client-side
}

export class PackageTenant {
  packageId: number;

  purchaseDate?: Date;

  activateDate?: Date;

  transactionId?: string;

  state: PackageTenantStates;

  constructor() {
    this.packageId = 0;
    this.state = PackageTenantStates.Available;
  }
}

export enum PackageContentStates {
  NotSet = 0,
  Ok = 1,
  ParentHasConflict = 2,
  NameConflict = 3,
}

export class PackageContent {
  contentId: number;

  contentParentId: number;

  sourceTenantId: string;

  sourceEntityType: number;

  sourceEntityId: string;

  name: string;

  data1?: string;

  data2?: string;

  blob?: Blob;

  state: PackageContentStates;

  webUrl?: string;

  constructor() {
    this.contentId = 0;
    this.contentParentId = 0;
    this.sourceTenantId = '';
    this.sourceEntityId = '';
    this.sourceEntityType = EntityTypes.NotSet;
    this.name = '';
    this.state = PackageContentStates.Ok;
  }

  hasConflict(contents: PackageContent[]): boolean {
    if (this.state !== PackageContentStates.Ok) return true;

    return this.hasParentConflict(contents);
  }

  hasParentConflict(contents: PackageContent[]): boolean {
    let parentContent = contents.find((c) => c.contentId === this.contentParentId);
    let max = 0;

    while (parentContent !== undefined && max < 100) {
      if (parentContent.state !== PackageContentStates.Ok) return true;
       
      parentContent = contents.find((c) => c.contentId === parentContent?.contentParentId);
      max++;
    }

    return false;
  }

  clone(): PackageContent {
    const output = new PackageContent();
    output.contentId = this.contentId;
    output.contentParentId = this.contentParentId;
    output.sourceTenantId = this.sourceTenantId;
    output.sourceEntityType = this.sourceEntityType;
    output.sourceEntityId = this.sourceEntityId;
    output.name = this.name;
    output.data1 = this.data1;
    output.data2 = this.data2;
    output.blob = this.blob?.slice();

    return output;
  }
}

export enum PackageImportStates {
  Created = 0,
  Updated = 1,
  Skipped = 2,
}

export class PackageImport {
  packageId: number;

  sourceEntityType: number;

  sourceEntityId: string;

  sourceEntityIdTo: string;

  targetEntityId: string;

  targetEntityIdTo: string;

  state: number;

  name?: string;

  constructor() {
    this.packageId = 0;
    this.sourceEntityType = 0;
    this.sourceEntityId = '';
    this.sourceEntityIdTo = '';
    this.targetEntityId = '';
    this.targetEntityIdTo = '';
    this.state = 0;
  }
}
