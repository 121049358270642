import {
  ShimmeredDetailsList,
  IColumn,
  Text,
  SelectionMode,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Selection,
  DetailsListLayoutMode,
  ConstrainMode,
  IObjectWithKey,
  IDetailsRowProps,
  TooltipHost,
  DirectionalHint,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { toLocaleDateNumeric } from 'utils/datetime';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { bytesToSize } from 'utils/string';
import { Icon } from '@fluentui/react/lib/Icon';
import { FileIconType, getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { IDriveItem } from '../../services/Graph/SharepointInterfaces';
import ResourceLink from 'models/resourceLink';

interface IMyDriveItems {
  driveItems: IDriveItem[];
  onSelectFolder: (item: IDriveItem, isChild: boolean) => void;
  onSelectItems: (items: IDriveItem[] | undefined) => void;
  onOpenFile?: (item: IDriveItem) => void;
  isLoading: boolean;
  layoutMode: DetailsListLayoutMode;
  viewMode?: boolean;
  existingLinks?: ResourceLink[];
  linkToEdit?: ResourceLink;
  singleSelect?: boolean;
}

const iconSize = 20;

const MyDriveItems = (props: IMyDriveItems) => {
  const { t } = useTranslation(['sharepoint']);

  const columns: IColumn[] = [
    {
      key: 'type',
      minWidth: 20,
      maxWidth: 20,
      name: '',
      onRender: (item?: IDriveItem, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item || !item.name) return;

        if (item.folder) return <Icon {...getFileTypeIconProps({ size: iconSize, type: FileIconType.folder })} />;

        const splits = item.name?.split('.');
        const extension = splits[splits?.length - 1];

        return <Icon {...getFileTypeIconProps({ extension: extension, size: iconSize })} />;
      },
    },
    {
      key: 'name',
      minWidth: 200,
      isResizable: true,
      name: t('sharepoint:DriveItemPicker.Columns.Name'),
      onRender: (item?: IDriveItem, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item) return;

        if (item.folder)
          return (
            <Text>
              <Link underline onClick={() => props.onSelectFolder(item, true)}>
                {item.name}
              </Link>
            </Text>
          );

        if (props.viewMode && props.onOpenFile && item.webUrl)
          return (
            <Text>
              <Link
                underline
                onClick={() => {
                  if (props.onOpenFile) props.onOpenFile(item);
                }}
              >
                {item.name}
              </Link>
            </Text>
          );

        return <Text>{item.name}</Text>;
      },
    },
    {
      key: 'changed',
      minWidth: 90,
      isResizable: true,
      name: t('sharepoint:DriveItemPicker.Columns.Changed'),
      onRender: (item?: IDriveItem, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item || item.lastModifiedDateTime === undefined) return;

        return <Text>{toLocaleDateNumeric(new Date(item.lastModifiedDateTime))}</Text>;
      },
    },
    {
      key: 'changedBy',
      minWidth: 110,
      isResizable: true,
      name: t('sharepoint:DriveItemPicker.Columns.ChangedBy'),
      onRender: (item?: IDriveItem, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item) return;

        return <Text>{item.lastModifiedBy?.user?.displayName}</Text>;
      },
    },
    {
      key: 'size',
      minWidth: 80,
      isResizable: true,
      name: t('sharepoint:DriveItemPicker.Columns.Size'),
      onRender: (item?: IDriveItem, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item || item.folder || !item.size) return;

        return <Text>{bytesToSize(item.size)}</Text>;
      },
    },
  ];

  const selection = new Selection<IDriveItem>({
    canSelectItem(item) {
      return (
        item.id !== undefined &&
        !item.folder &&
        !props.existingLinks?.find(
          (existingLink) =>
            props.linkToEdit?.linkId !== existingLink.linkId &&
            (existingLink.driveItemId === item.id || existingLink.linkName.toLowerCase() === item.name?.toLowerCase()),
        )
      );
    },
    getKey(item) {
      return item.id || '';
    },
    onSelectionChanged: () => {
      const items = selection.getSelection() as IDriveItem[];
      props.onSelectItems(items);
    },
  });

  const onRenderRow = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (rowProps?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (defaultRender && rowProps?.item && !selection.canSelectItem(rowProps.item) && !rowProps.item.folder) {
      rowProps.disabled = true;

      return (
        <TooltipHost
          content={t('sharepoint:SharepointPicker.ItemExists')}
          directionalHint={DirectionalHint.topLeftEdge}
        >
          {defaultRender(rowProps)}
        </TooltipHost>
      );
    }

    if (defaultRender) {
      return defaultRender(rowProps);
    } else {
      return null;
    }
  };

  //
  // Main render
  //
  if (!props.isLoading && props.driveItems.length === 0) {
    return (
      <Stack verticalFill verticalAlign="center" horizontalAlign="center">
        <Stack.Item>
          <Text>{t('translation:General.Notifications.NoItems')}</Text>
        </Stack.Item>
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <Stack.Item grow styles={{ root: { position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            selectionPreservedOnEmptyClick={true}
            columns={columns}
            compact
            selection={selection as Selection<IObjectWithKey>}
            layoutMode={props.layoutMode}
            constrainMode={ConstrainMode.unconstrained}
            items={props.driveItems}
            enableShimmer={props.isLoading}
            shimmerLines={3}
            selectionMode={
              props.viewMode ? (props.singleSelect ? SelectionMode.single : SelectionMode.none) : SelectionMode.multiple
            }
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            onRenderRow={onRenderRow}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default MyDriveItems;
