import { Text , Stack } from '@fluentui/react';

import { useTranslation } from 'react-i18next';

const KanbanCardEmptyLane = () => {
  const { t } = useTranslation(['translation']);

  return (
    <div
      style={{
        userSelect: 'none',
        margin: '5px',
        minHeight: '100px',
        borderRadius: '3px',
        border: '1px dashed lightgrey',
      }}
    >
      <Stack horizontalAlign="center">
        <Text variant="small" styles={{ root: { paddingTop: 40, color: 'grey' } }}>
          {t('translation:General.Notifications.DropEmptyLane')}
        </Text>
      </Stack>
    </div>
  );
};

export default KanbanCardEmptyLane;
