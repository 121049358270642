import { Dialog, DialogType, DialogFooter, ScrollablePane, ScrollbarVisibility, Stack , PrimaryButton } from '@fluentui/react';

import { useTranslation } from 'react-i18next';

export interface IDialogOkProps {
  onOk: () => void;
  hidden: boolean;
  title: string;
  subText: string;
  optionalJSX?: JSX.Element;
  optionalJSXHeight?: string | number;
  optionalJSXWidth?: string | number;
}

export const DialogOk = (props: IDialogOkProps) => {
  const { t } = useTranslation(['translation']);

  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    subText: props.subText,
  };

  return (
    <Dialog hidden={props.hidden} onDismiss={props.onOk} dialogContentProps={dialogContentProps}>
      {props.optionalJSX && (
        <Stack>
          <Stack.Item
            grow
            styles={{
              root: {
                marginTop: 0,
                position: 'relative',
                width: props.optionalJSXWidth,
                height: props.optionalJSXHeight ?? '20vh',
                maxHeight: '80vh',
              },
            }}
          >
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{props.optionalJSX}</ScrollablePane>
          </Stack.Item>
        </Stack>
      )}
      <DialogFooter>
        <PrimaryButton onClick={props.onOk} text={t('translation:Dialogs.Ok.Ok')} />
      </DialogFooter>
    </Dialog>
  );
};
