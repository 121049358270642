import { HoverCard, HoverCardType, IPlainCardProps, IStackItemStyles, Stack, Text, getTheme } from '@fluentui/react';
import AppContext from 'App/AppContext';
import KanbanCardTaskGenericWithState from 'components/Kanban/Cards/KanbanCardTaskGenericWithState';
import { globalTextStylesBold } from 'globalStyles';
import Task from 'models/tasks/task';
import { useContext } from 'react';

interface ITaskDateLinkWithHoverCardProps {
  tasks: Task[];
  onTaskClick: (task: Task) => void;
}

export const TaskOverflowLinkWithHoverCard = (props: ITaskDateLinkWithHoverCardProps) => {
  const appContext = useContext(AppContext);
  const theme = getTheme();

  const onRenderCompactCardMultiple = (items: Task[]): JSX.Element => {
    return (
      <Stack horizontal wrap styles={{ root: { maxWidth: '50vw', maxHeight: '50vh', overflow: 'auto' } }}>
        {items.map((item) => {
          return (
            <Stack.Item
              key={'KanbanCardTaskForControlAssignee' + item.taskId.toString()}
              styles={{ root: { width: 210, borderRadius: '5px', border: '1px solid lightgrey', margin: 3 } }}
            >
              <KanbanCardTaskGenericWithState
                key={item.taskId.toString()}
                task={item}
                onClick={() => props.onTaskClick(item)}
              />
            </Stack.Item>
          );
        })}
      </Stack>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderCompactCardMultiple,
    renderData: props.tasks,
  };

  const getLinkStyleProps = (): IStackItemStyles => {
    const backgroundColor = appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt;

    return {
      root: {
        background: backgroundColor,
        color: appContext.useDarkMode ? theme.palette.themeLight : theme.palette.themeSecondary,
        height: 22,
        width: 30,
        borderRadius: 4,
        borderColor: theme.palette.themeSecondary,
        cursor: 'pointer',
      },
    };
  };

  //
  // Main render
  //
  return (
    <HoverCard
      cardOpenDelay={300}
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
      instantOpenOnClick={false}
    >
      <Stack.Item styles={getLinkStyleProps()}>
        <Stack horizontalAlign="center" verticalAlign="center">
          <Text variant="mediumPlus" styles={globalTextStylesBold}>
            {`+${props.tasks.length}`}
          </Text>
        </Stack>
      </Stack.Item>
    </HoverCard>
  );
};
