import {
  AlignmentType,
  Document,
  HeadingLevel,
  HeightRule,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextDirection,
  VerticalAlign,
  WidthType,
} from 'docx';
import Control, { ApplicabilityReasons } from 'models/control';
import Norm from 'models/norm';
import { TFunction } from 'i18next';
import { DocumentColors, footers, headers, pageLandscape, paragraphStyles, tableBorders } from './documentStyles';
import { htmlDecode } from 'utils/html';
import ISOControl from 'models/isoControl';

const getControlImplementationState = (child: Control, t: TFunction<string[]>): string => {
  if (!child) return t('control:SoAState.No');
  if (child.applicabilityReason.includes(ApplicabilityReasons.OutOfScope)) {
    return t('control:SoAState.OutOfScope')
  }

  return Control.getImplementationStateText(child.state, t);
};

const renderTabelControls = (
  norm: Norm | undefined,
  controls: Control[],
  isoControls: ISOControl[],
  t: TFunction<string[]>,
): TableRow[] => {
  const controlsData: TableRow[] = [];

  controls.forEach((child: Control, index: number) => {
    const hasChildren = controls.some((c) => c.parentControlId === child.controlId);

    let code = child.code;
    let name = child.name;
    let description = child.description;

    if (norm && norm.isoNormId) {
      //when an ISO standard is specified and the control has 1 related ISO control of this standard, take the code, name and description of that ISO control
      const relatedISOControls = isoControls.filter(
        (i) => i.isoNormId === norm.isoNormId && child.isoControlIds?.includes(i.isoControlId),
      );
      if (relatedISOControls.length === 1) {
        code = relatedISOControls[0].code;
        name = relatedISOControls[0].name;
        description = relatedISOControls[0].description;
      }
    } else if (child.isoControlIds && child.isoControlIds.length > 0) {
      const relatedISOControls = isoControls.filter((i) => child.isoControlIds?.includes(i.isoControlId));
      if (relatedISOControls.length === 1) {
        code = relatedISOControls[0].code;
        name = relatedISOControls[0].name;
        description = relatedISOControls[0].description;
      }
    }

    const tabelCell: TableCell[] = [];
    if (hasChildren) {
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: code,
              style: 'TableDetailBold',
              spacing: {
                after: 100,
              },
            }),
          ],
          shading: {
            type: ShadingType.SOLID,
            color: DocumentColors.cellShading2,
          },
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: name,
              style: 'TableDetailBold',
              spacing: {
                after: 100,
              },
            }),
          ],
          columnSpan: 5,
          shading: {
            type: ShadingType.SOLID,
            color: DocumentColors.cellShading2,
          },
        }),
      );
    } else {
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: code,
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: name,
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: htmlDecode(description ?? ''),
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: child.applicabilityReason.includes(ApplicabilityReasons.OutOfScope)
                ? t('translation:General.Words.No')
                : t('translation:General.Words.Yes'),
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
              alignment: AlignmentType.CENTER,
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: getControlImplementationState(child, t),
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
              alignment: AlignmentType.CENTER,
            }),
          ],
        }),
      );
      tabelCell.push(
        new TableCell({
          children: [
            new Paragraph({
              text: child.applicabilityReason.includes(ApplicabilityReasons.OutOfScope)
                ? child.outOfScopeReason || ''
                : Control.getApplicabilityReasonText(child.applicabilityReason, t),
              style: 'TableDetail',
              spacing: {
                after: 100,
              },
            }),
          ],
        }),
      );
    }

    controlsData.push(
      new TableRow({
        children: tabelCell,
      }),
    );
  });

  return controlsData;
};

export const generateSoAControls = (
  norm: Norm | undefined,
  controls: Control[],
  isoControls: ISOControl[],
  t: TFunction<string[]>,
): Document => {
  const pageProps = {
    properties: pageLandscape,
    headers: headers,
    footers: footers,
  };

  const normName = norm?.name ?? '{ISO}';

  const page1 = {
    ...pageProps,
    children: [
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.Placeholders.Logo'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.StartPage.Title'),
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 2000,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.Placeholders.Company'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: normName,
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.Placeholders.Date'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 2000,
        },
        style: 'Normal Text',
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.Placeholders.Version'),
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
        style: 'Normal Text',
      }),
    ],
  };

  const page2 = {
    ...pageProps,
    children: [
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.IntroPage.Introduction.Label'),
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.IntroPage.Introduction.Detail', { norm: normName }),
        style: 'Normal Text',
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.IntroPage.Management.Label'),
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
        spacing: {
          before: 1000,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.IntroPage.Management.Detail'),
        style: 'Normal Text',
        spacing: {
          before: 200,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.IntroPage.Scope.Label'),
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
        spacing: {
          before: 1000,
        },
      }),
      new Paragraph({
        text: t('doc_SoA_Controls:Statement.Placeholders.Scope'),
        style: 'Normal Text',
        spacing: {
          before: 200,
        },
      }),
    ],
  };

  const page3 = {
    ...pageProps,
    children: [
      new Paragraph({
        text: `${t('doc_SoA_Controls:Statement.StartPage.Title')} - ${normName}`,
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 200,
        },
      }),
      new Table({
        borders: tableBorders,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.TableHeading'),
                    style: 'TableHeading',
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                columnSpan: 2,
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowOne.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
                width: {
                  size: 25,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowOne.ColumnTwo', {
                      norm: normName,
                    }),
                    style: 'TableDetail',
                  }),
                ],
                width: {
                  size: 75,
                  type: WidthType.PERCENTAGE,
                },
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowTwo.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowTwo.ColumnTwo'),
                    style: 'TableDetail',
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowThree.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowThree.ColumnTwo'),
                    style: 'TableDetail',
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowFour.ColumnOne'),
                    style: 'TableDetailBold',
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.LegendTable.RowFour.ColumnTwo'),
                    style: 'TableDetail',
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new Paragraph({
        text: '',
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.LEFT,
      }),
      new Table({
        borders: tableBorders,
        rows: [
          new TableRow({
            height: {
              rule: HeightRule.ATLEAST,
              value: 2000,
            },
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingOne'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                columnSpan: 3,
                verticalAlign: VerticalAlign.BOTTOM,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingTwo'),
                    style: 'TableHeading',
                    alignment: AlignmentType.START,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                width: {
                  size: 800,
                  type: WidthType.DXA,
                },
                textDirection: TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT,
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingThree'),
                    style: 'TableHeading',
                    alignment: AlignmentType.START,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                width: {
                  size: 800,
                  type: WidthType.DXA,
                },
                textDirection: TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT,
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: t('doc_SoA_Controls:Statement.ApplicabilityPage.SecurityTable.TableHeading.HeadingFour'),
                    style: 'TableHeading',
                    spacing: {
                      after: 100,
                    },
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                shading: {
                  type: ShadingType.SOLID,
                  color: DocumentColors.cellShading1,
                },
                width: {
                  size: 1600,
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.BOTTOM,
              }),
            ],
          }),
          ...renderTabelControls(norm, controls, isoControls, t),
        ],
      }),
    ],
  };

  const doc = new Document({
    background: {
      color: undefined,
    },
    styles: { paragraphStyles },
    sections: [page1, page2, page3],
  });

  return doc;
};
