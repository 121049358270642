import { EntityTypes } from "models/entity";

export class SearchOptionsDTO {
    text: string;

    sharePointSearch: boolean;

    entityTypes: EntityTypes[];

    constructor() {
        this.text = '';
        this.entityTypes = [];
        this.sharePointSearch = false;
    }
}