import {
  Stack,
  PrimaryButton,
  DefaultButton,
  ColorPicker,
  ICalloutContentStyles,
  Text,
  Link,
  IBasePicker,
  ITag,
  Callout,
} from '@fluentui/react';
import { createRef, useEffect, useState } from 'react';
import { globalStackTokensGapSmall, globalTextStylesValidationError } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import SingleTagPicker from 'components/Pickers/SingleTagPicker';
import Tag from 'models/tag';
import EditTextFieldCallOut from 'components/CallOuts/EditTextFieldCallOut';
import { ISingleTag } from './SingleTag';

let newTagId: number = -1;
const linkCalloutId = 'uniqueTargetId' + Math.random().toString(36).substring(7);

interface ITagCreatorProps {
  tagCache: Tag[];
  onClose: () => void;
  onCreate: (tag: Tag) => void;
  saveButtonText: string;
  defaultGroup?: string | undefined;
}

const TagCreator = (props: ITagCreatorProps) => {
  const { t } = useTranslation(['translation', 'tag']);
  const [tagKeys, setTagKeys] = useState<ISingleTag[]>([]);
  const [tagValues, setTagValues] = useState<ISingleTag[]>([]);
  const [selectedKey, setSelectedKey] = useState<ISingleTag | undefined>(undefined);
  const [selectedValue, setSelectedValue] = useState<ISingleTag | undefined>(undefined);
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [selectedInfo, setSelectedInfo] = useState<string | undefined>(undefined);
  const [editTagInfoCallout, setEditTagInfoCallout] = useState<boolean>(false);
  const [colorCallout, setColorCallout] = useState<boolean>(false);
  const [isNewKey, setIsNewKey] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const groupPickerRef = createRef<IBasePicker<ITag>>();
  const valuePickerRef = createRef<IBasePicker<ITag>>();
  const colorLinkRef = createRef<HTMLElement>();

  useEffect(() => {
    const loadData = async () => {
      const keys: ISingleTag[] = [];

      props.tagCache.forEach((t) => {
        if (keys.findIndex((k) => k.tagName === t.tagName) === -1) {
          const newKey: ISingleTag = {
            tagId: t.tagName,
            tagName: t.tagName,
            tagColor: t.tagColor,
          };
          keys.push(newKey);
        }
      });

      setTagKeys(keys);
    };

    loadData();

    if (!props.defaultGroup) {
      groupPickerRef.current?.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tagCache]);

  useEffect(() => {
    if (selectedKey === undefined) {
      setIsNewKey(false);
    }

    if (selectedKey !== undefined && selectedValue !== undefined) {
      const exists = props.tagCache.some(
        (t) => t.tagName === selectedKey.tagName && t.tagValue === selectedValue.tagName,
      );
      if (exists) {
        setError(t('tag:Creator.NotUnique'));

        return;
      }
    }

    if (selectedKey && selectedKey.tagName.length > 32) {
      setError(t('tag:Creator.TooLong', { len: 32 }));

      return;
    }

    if (selectedValue && selectedValue.tagName.length > 64) {
      setError(t('tag:Creator.TooLong', { len: 64 }));

      return;
    }

    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey, selectedValue]);

  const setTagKey = (tag: ISingleTag, isNew: boolean) => {
    const values: ISingleTag[] = [];

    props.tagCache.forEach((t) => {
      if (t.tagName === tag.tagName && values.findIndex((k) => k.tagName === t.tagValue) === -1) {
        const newKey: ISingleTag = {
          tagId: t.tagValue,
          tagName: t.tagValue,
          tagColor: t.tagColor,
        };
        values.push(newKey);
      }
    });

    setTagValues(values);
    setSelectedKey(tag);
    setIsNewKey(isNew);
    setSelectedColor(tag.tagColor);

    valuePickerRef.current?.focus();
  };

  const setTagValue = (tag: ISingleTag) => {
    setSelectedValue(tag);
    if (isNewKey) {
      colorLinkRef.current?.focus();
    }
  };

  const getSelectedTag = (): Tag | undefined => {
    if (selectedKey === undefined || selectedValue === undefined || selectedColor === undefined) return;

    const newTag: Tag = new Tag();
    newTag.tagId = newTagId;
    newTag.tagName = selectedKey.tagName;
    newTag.tagValue = selectedValue.tagName;
    newTag.tagColor = selectedColor;
    newTag.tagInfo = selectedInfo;

    newTagId = newTagId - 1;

    return newTag;
  };

  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: '10px 12px',
      maxWidth: 600,
    },
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Text variant="medium">{t('tag:Creator.Explaination')}</Text>
      </Stack.Item>
      <Stack horizontal wrap tokens={globalStackTokensGapSmall}>
        <Stack.Item grow>
          <Text variant="medium">{t('tag:Creator.Group')}</Text>
          <SingleTagPicker
            ref={groupPickerRef}
            tags={tagKeys}
            itemLimit={1}
            selectedTags={selectedKey ? [selectedKey] : undefined}
            onAdd={setTagKey}
            onRemove={() => setSelectedKey(undefined)}
            colorForNewItems={selectedColor}
            preventTabKey={true}
            defaultValue={props.defaultGroup?.trimStart()}
          ></SingleTagPicker>
        </Stack.Item>
        <Stack.Item grow>
          <Text variant="medium">{t('tag:Creator.Value')}</Text>
          <SingleTagPicker
            ref={valuePickerRef}
            tags={tagValues}
            itemLimit={1}
            selectedTags={selectedValue ? [selectedValue] : undefined}
            onAdd={setTagValue}
            onRemove={() => setSelectedValue(undefined)}
            colorForNewItems={selectedColor}
            preventTabKey={isNewKey} //when true, focus is set on the color link, when false, tab key will go from the link to the save button
          ></SingleTagPicker>
        </Stack.Item>
      </Stack>
      <Stack.Item>
        {error && (
          <Text styles={globalTextStylesValidationError} variant="small">
            {error}
          </Text>
        )}
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapSmall}>
          <Stack.Item id={'color_callout'}>
            <Link ref={colorLinkRef} disabled={!isNewKey} onClick={() => setColorCallout(true)}>
              {t('tag:Creator.ColorPicker.Label')}
            </Link>
          </Stack.Item>
        </Stack>
        <Callout
          styles={calloutStyle}
          hidden={!colorCallout}
          setInitialFocus
          gapSpace={0}
          target={`#color_callout`}
          onDismiss={() => setColorCallout(false)}
          dismissOnTargetClick
        >
          <ColorPicker
            color={selectedColor}
            onChange={(ev, color) => {
              setSelectedColor(color ? color.str : '');
              if (selectedKey && isNewKey) {
                selectedKey.tagColor = color.str;
              }
              if (selectedValue && isNewKey) {
                selectedValue.tagColor = color.str;
              }
            }}
            alphaType={'none'}
            showPreview
          />
          <PrimaryButton autoFocus onClick={() => setColorCallout(false)}>
            {t('translation:General.Button.Close')}
          </PrimaryButton>
        </Callout>
      </Stack.Item>
      <Stack.Item grow>
        <Link id={linkCalloutId} onClick={() => setEditTagInfoCallout(true)}>
          {t('tag:Creator.Info.Label')}
        </Link>
        <EditTextFieldCallOut
          value={selectedInfo}
          targetId={linkCalloutId}
          onClose={() => setEditTagInfoCallout(false)}
          onUpdate={setSelectedInfo}
          isVisible={editTagInfoCallout}
          maxLenght={512}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall} horizontalAlign="end">
          <Stack.Item>
            <PrimaryButton
              onClick={() => {
                const newTag = getSelectedTag();
                if (newTag) {
                  props.onCreate(newTag);
                  props.onClose();
                }
              }}
              disabled={error !== undefined || selectedKey === undefined || selectedValue === undefined}
            >
              {props.saveButtonText}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              onClick={() => {
                props.onClose();
              }}
            >
              {t('translation:General.Button.Cancel')}
            </DefaultButton>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default TagCreator;
