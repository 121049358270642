import { TFunction } from 'i18next';
import Language from 'models/language';
import UserLanguage from 'models/userLanguage';
import { newGuid } from 'utils/guid';

export enum TaskCheckListItemState {
  ToDo = 0,
  Success = 1,
  Failed = 2,
}

export class TaskCheckListItem {
  id: string;

  state: TaskCheckListItemState;

  description: string;

  constructor() {
    this.id = newGuid();
    this.state = TaskCheckListItemState.ToDo;
    this.description = 'New item';
  }

  isEqual(item: TaskCheckListItem) {
    if (item.description !== this.description) return false;
    if (item.state !== this.state) return false;

    return true;
  }

  getStateText(t: TFunction<string[]>) {
    if (this.state === TaskCheckListItemState.ToDo) return t('task:CheckList.Todo');
    if (this.state === TaskCheckListItemState.Failed) return t('task:CheckList.Failed');
    if (this.state === TaskCheckListItemState.Success) return t('task:CheckList.Success');

    return '';
  }
}

export class TaskCheckList {
  items: TaskCheckListItem[];

  constructor() {
    this.items = [];
  }

  toJSON(): string | undefined {
    return JSON.stringify(this.items);
  }

  fromJSON(patternString: string | undefined) {
    try {
      if (patternString && patternString !== '') {
        this.items = JSON.parse(patternString);
        let i = 0;
        while (i < this.items.length) {
          const _taskCheckListItem = new TaskCheckListItem();
          _taskCheckListItem.id = this.items[i].id ? this.items[i].id : newGuid();
          _taskCheckListItem.description = this.items[i].description;
          _taskCheckListItem.state = this.items[i].state;
          this.items[i] = _taskCheckListItem;
          i += 1;
        }
      }
    } catch {
      this.items = [];
    }
  }

  clone(): TaskCheckList {
    const newTaskCheckList = new TaskCheckList();

    for (let i = 0; i < this.items.length; i++) {
      const newItem = new TaskCheckListItem();
      newItem.id = this.items[i].id;
      newItem.description = this.items[i].description;
      newItem.state = this.items[i].state;
      newTaskCheckList.items.push(newItem);
    }

    return newTaskCheckList;
  }

  isEqual(checklist: TaskCheckList) {
    if (checklist.items.length !== this.items.length) return false;
    for (let idx = 0; idx < checklist.items.length; idx++) {
      const item1 = checklist.items[idx];
      const item2 = this.items[idx];
      if (!item1.isEqual(item2)) return false;
    }

    return true;
  }

  getFailedCount() {
    return this.items.filter((_itm) => _itm.state === TaskCheckListItemState.Failed).length;
  }

  getCompletedCheckCount() {
    return this.items.filter(
      (_itm) => _itm.state === TaskCheckListItemState.Failed || _itm.state === TaskCheckListItemState.Success,
    ).length;
  }

  getTodoOrFailedCount() {
    return this.items.filter(
      (_itm) => _itm.state === TaskCheckListItemState.Failed || _itm.state === TaskCheckListItemState.ToDo,
    ).length;
  }

  getSuccesfulCount() {
    return this.items.filter((_itm) => _itm.state === TaskCheckListItemState.Success).length;
  }

  getBulletCheckList(t: TFunction<string[]>) {
    let output = '';
    this.items.forEach((item: TaskCheckListItem, i: number) => {
      output += `${i + 1}. ${item.getStateText(t)} ${item.description} \n`;
    });

    return output;
  }
}

export class TaskState {
  taskStateId: number;

  completed: boolean;

  sortOrder: number;

  taskCount?: number;

  trans: TaskState_Translation[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  state: string;

  constructor() {
    this.taskStateId = 0;
    this.completed = false;
    this.sortOrder = 1;
    this.trans = [];
    this.transIdx = -1;
    this.state = '';
  }

  getTransIdx(langCode: string, defLangCode: string): number {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        const t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return i;
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        const t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return i;
        }
      }
    }

    return -1;
  }

  getLanguages(): string[] {
    const languages: string[] = [];

    if (this.trans) {
      for (let i = 0; i < this.trans.length; i++) {
        const t: TaskState_Translation = this.trans[i];
        if (t.lang) {
          languages.push(t.lang.code);
        }
      }
    }

    return languages;
  }

  getTrans(langCode: string, defLangCode: string = UserLanguage.getFallBack()): TaskState_Translation | undefined {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        const t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return this.trans[i];
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        const t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return this.trans[i];
        }
      }
    }

    return undefined;
  }

  clone = (): TaskState => {
    const output = new TaskState();
    output.taskStateId = this.taskStateId;
    output.completed = this.completed;
    output.sortOrder = this.sortOrder;
    output.transIdx = this.transIdx;
    output.state = this.state;
    output.trans = this.trans.map((_) => _.clone());

    return output;
  };
}

export class TaskState_Translation {
  state: string;

  lang?: Language;

  constructor() {
    this.state = '';
  }

  clone = (): TaskState_Translation => {
    const output = new TaskState_Translation();
    output.state = this.state;
    output.lang = this.lang?.clone();

    return output;
  };
}

export class ControlTask {
  controlId: number;

  taskId: number;

  constructor() {
    this.controlId = 0;
    this.taskId = 0;
  }
}
