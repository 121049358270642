import { v4 as uuidv4 , NIL as NIL_UUID } from 'uuid';


export function newGuid(): string {
  return uuidv4();
}

export function newGuidNil(): string {
  return NIL_UUID;
}

export function isValidGuid(guid: string | undefined): boolean {
  if (!guid) return false;
  if (!/^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/.test(guid)) {
    return false;
  }

  return true;
}
