import Tenant, { TenantOnboarding } from './../../models/tenant';
import TenantDTO, { ImportOrgUnitDTO, TenantOnboardingDTO } from 'models/dto/tenantDTO';

import LicenseDTO from 'models/dto/licenseDTO';

import http from './httpService';
import {
  mapFromOnboardingState,
  mapFromTenant,
  mapFromTenantLicenses,
  mapFromTenants,
  mapToOnboardingState,
  mapToTenant,
} from 'mappings/dataMapping';
import AppError from 'utils/appError';
import { mapFromPackageTenants } from 'mappings/packageMapping';
import { PackageTenant } from 'models/package';
import { PackageTenantDTO } from 'models/dto/packageDTO';
import { SubscriptionTypes } from 'utils/subscription';
import { toBlob } from 'utils/string';
import { mapFromTimeZones } from 'mappings/timeZoneMapping';
import TimeZoneDTO from 'models/dto/timeZoneDTO';
import TimeZone from 'models/timeZone';

export async function apiGetTenant(accessToken: string): Promise<Tenant> {
  try {
    const ar = await http.get<TenantDTO>('/tenant', http.getRequestConfig(accessToken));

    return mapFromTenant(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTenant(tenant: Tenant, accessToken: string): Promise<Tenant> {
  try {
    const tenantDto = mapToTenant(tenant);
    const ar = await http.put<TenantDTO>(`/tenant`, tenantDto, http.getRequestConfig(accessToken));

    return mapFromTenant(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTenantLicenses(tenant: Tenant, accessToken: string): Promise<Tenant> {
  try {
    const ar = await http.get<LicenseDTO>('/tenant/licenses', http.getRequestConfig(accessToken));

    return mapFromTenantLicenses(tenant, ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetOrgUnitLicenses(orgUnit: Tenant, accessToken: string): Promise<Tenant> {
  try {
    const ar = await http.get<LicenseDTO>(
      `/tenant/orgunits/${orgUnit.id}/licenses`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTenantLicenses(orgUnit, ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiStartTrialPeriod(
  tenantName: string,
  onboardingState: TenantOnboarding,
  accessToken: string,
): Promise<void> {
  try {
    const onboardingStateDto = mapToOnboardingState(onboardingState);
    await http.post(`/tenant/trial?tenantName=${tenantName}`, onboardingStateDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTenantTrialOnboarding(state: TenantOnboarding, accessToken: string): Promise<void> {
  try {
    const onboardingState = mapToOnboardingState(state);

    await http.put(`/tenant/trial/onboarding`, onboardingState, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTenantOnboarding(
  state: TenantOnboarding,
  accessToken: string,
): Promise<PackageTenant[]> {
  try {
    const onboardingState = mapToOnboardingState(state);
    const ar = await http.put<PackageTenantDTO[]>(
      `/tenant/onboarding`,
      onboardingState,
      http.getRequestConfig(accessToken),
    );

    return mapFromPackageTenants(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTenantOnboardingState(accessToken: string): Promise<TenantOnboarding> {
  try {
    const ar = await http.get<TenantOnboardingDTO>(`/tenant/trial/onboarding`, http.getRequestConfig(accessToken));
    const state = mapFromOnboardingState(ar.data);
    if (!state) throw new AppError('No onboarding state returned');

    return state;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetOrgUnits(accessToken: string): Promise<Tenant[]> {
  try {
    const ar = await http.get<TenantDTO[]>(`/tenant/orgunits`, http.getRequestConfig(accessToken));

    return mapFromTenants(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTenantsForUser(accessToken: string): Promise<Tenant[]> {
  try {
    const ar = await http.get<TenantDTO[]>(`/tenant/user`, http.getRequestConfig(accessToken));

    return mapFromTenants(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddOrgUnit(
  parentId: string,
  name: string,
  isContainer: boolean,
  accessToken: string,
): Promise<Tenant> {
  try {
    const newOrgUnit = new TenantDTO();
    newOrgUnit.name = name;
    newOrgUnit.parentId = parentId;
    newOrgUnit.subscriptionType = isContainer ? SubscriptionTypes.Container : SubscriptionTypes.Premium;

    const ar = await http.post<TenantDTO>(`/tenant/orgunits`, newOrgUnit, http.getRequestConfig(accessToken));

    return mapFromTenant(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateOrgUnit(orgUnit: Tenant, accessToken: string): Promise<Tenant> {
  try {
    const orgUnitDto = mapToTenant(orgUnit);
    const ar = await http.put<TenantDTO>(`/tenant/orgunits`, orgUnitDto, http.getRequestConfig(accessToken));

    return mapFromTenant(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveOrgUnit(orgUnit: Tenant, accessToken: string): Promise<void> {
  try {
    const orgUnitDto = mapToTenant(orgUnit);
    await http.post<TenantDTO>(`/tenant/orgunits/delete`, orgUnitDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiImportOrgUnit(
  source: Tenant,
  target: Tenant,
  method: string,
  accessToken: string,
): Promise<void> {
  try {
    const importDto = new ImportOrgUnitDTO();
    importDto.sourceId = source.id;
    importDto.targetId = target.id;
    importDto.method = method;

    await http.post<TenantDTO>(`/tenant/orgunits/import`, importDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTenantLogo(logo: Blob | undefined, accessToken: string): Promise<void> {
  try {
    const formData = new FormData();
    if (logo) {
      formData.append('blob', logo);
    }

    await http.put(`/tenant/logo`, logo ? formData : undefined, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTenantLogo(accessToken: string, orgUnitId?: string): Promise<Blob | undefined> {
  try {
    const ar = await http.get(`/tenant/logo`, http.getRequestConfig(accessToken, orgUnitId));

    return toBlob(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTenantTimeZones(accessToken: string): Promise<TimeZone[]> {
  try {
    const ar = await http.get<TimeZoneDTO[]>(`/tenant/timezone`, http.getRequestConfig(accessToken));

    return mapFromTimeZones(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetOrgUnit(id: string, accessToken: string): Promise<Tenant | undefined> {
  try {
    const ar = await http.get<TenantDTO>(`/tenant/orgunit/${id}`, http.getRequestConfig(accessToken));

    return mapFromTenant(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
