import { Text, Stack, getTheme, FontIcon, TooltipHost } from '@fluentui/react';
import { getEntityIconName, getEntityName } from 'globalFunctions';
import { globalStackTokensGapExtraSmall, globalStackTokensGapSmall, globalTextStylesBoldBlue } from 'globalStyles';
import Entity from 'models/entity';
import { SearchResult } from 'models/search/SearchResult';
import { useTranslation } from 'react-i18next';
import { highlightText } from 'utils/string';
import parse from 'html-react-parser';
import { GlobalSearchResultLink } from './GlobalSearchResultLink';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import AppContext from 'App/AppContext';
import { useContext } from 'react';

interface IGlobalSearchResultItemProps {
  result: SearchResult;
  highlight: string | undefined;
  clientWidth: number;
  navigateToEntity: (entity: Entity, result: SearchResult) => void;
}

export const GlobalSearchResultItem = (props: IGlobalSearchResultItemProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const theme = getTheme();

  const entity = new Entity(
    Number(props.result.entityId),
    props.result.entityType,
    props.result.name,
    props.result.code,
    props.result.spWebUrl,
  );

  const getSnippet = (snippet: string | undefined): JSX.Element | null => {
    try {
      const html = highlightText(snippet, props.highlight, 150);
      if (!html) return null;

      return (
        <span
          onClick={() => props.navigateToEntity(entity, props.result)}
          style={{ cursor: 'pointer', whiteSpace: 'pre-wrap' }}
        >
          {parse(html)}
        </span>
      );
    } catch {
      return <Text>{snippet}</Text>;
    }
  };

  // const getSearchResultTypeLetter = (searchResultType: number): string => {
  //   switch (searchResultType) {
  //     case 1:
  //       return 'F'; //FREETEXT results
  //     case 2:
  //       return 'O'; //CONTAINSTEXT results
  //     case 3:
  //       return 'C'; //CODE results
  //     case 4:
  //       return 'L'; //LINK results
  //     case 5:
  //       return 'S'; //SHAREPOINT results
  //   }

  //   return '';
  // };

  return (
    <Stack
      horizontal
      tokens={globalStackTokensGapSmall}
      styles={{ root: { cursor: 'pointer' } }}
      onClick={(ev) => {
        props.navigateToEntity(entity, props.result);
      }}
    >
      <Stack.Item>
        <FontIcon
          style={{ fontSize: '16px', color: theme.palette.themePrimary }}
          iconName={getEntityIconName(entity.typeOfEntity)}
        />
      </Stack.Item>
      <Stack.Item grow>
        <Stack tokens={globalStackTokensGapExtraSmall}>
          <Stack horizontal tokens={globalStackTokensGapSmall} horizontalAlign="space-between" verticalAlign="start">
            <Stack.Item>
              <TooltipHost content={props.result.name}>
                <Text block nowrap styles={globalTextStylesBoldBlue(theme, props.clientWidth - 200)}>
                  {props.result.name}
                </Text>
              </TooltipHost>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="start">
                {/* <Stack.Item>
                  <Text variant="small">{getSearchResultTypeLetter(props.result.searchResultType)}</Text>
                  <Text variant="small">{props.result.rank}</Text>
                </Stack.Item> */}
                <Stack.Item>
                  <Text variant="small">{getEntityName(entity, t)}</Text>
                </Stack.Item>
                {props.result.code && (
                  <Stack.Item>
                    <Text variant="small">{props.result.code}</Text>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
          {props.result.tagIds.length > 0 && (
            <Stack.Item>
              <KeyValueTags tags={appContext.globalDataCache.tags.getItemsForId(props.result.tagIds)} compact />
            </Stack.Item>
          )}
          <Stack.Item>{getSnippet(props.result.snippet)}</Stack.Item>
          {props.result.spName && props.result.spLinkId && (
            <Stack.Item>
              <GlobalSearchResultLink result={props.result} />
              {getSnippet(props.result.spSnippet)}
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
