import { useContext } from 'react';
import { ScrollablePane, ScrollbarVisibility, Panel, PanelType, IconButton , Stack } from '@fluentui/react';

import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';
import NavMain from './NavMain';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import { globalAppName } from 'globalConstants';
import { navBarItemStyles } from 'components/NavBar/NavBarStyles';

interface IMobileNavRootProps {}

const MobileNavRoot = (props: IMobileNavRootProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);

  return (
    <Stack verticalFill>
      <Stack.Item>
        <IconButton
          iconProps={{ iconName: 'GlobalNavButton' }}
          onClick={() => appContext.toggleSideBarPanel()}
          styles={navBarItemStyles}
        ></IconButton>
      </Stack.Item>
      <Stack.Item>
        <Panel
          headerText={t('translation:App.Title', { appName: globalAppName })}
          isOpen={appContext.isOpenSideBarPanel}
          onDismiss={() => appContext.toggleSideBarPanel()}
          type={PanelType.customNear}
        >
          <Stack verticalFill tokens={globalStackTokensGapExtraSmall}>
            <Stack.Item>
              <ScrollablePane
                styles={{
                  root: {
                    top: 50,
                    position: 'absolute',
                  },
                }}
                scrollbarVisibility={ScrollbarVisibility.auto}
              >
                <NavMain collapsed={false} expandedOnHover={false} />
              </ScrollablePane>
            </Stack.Item>
          </Stack>
        </Panel>
      </Stack.Item>
    </Stack>
  );
};

export default MobileNavRoot;
