import { Fragment, useContext, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AppContext from 'App/AppContext';
import { Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import './RichTextEditor.css';
import { IRichTextEditorSpecialLinkType } from './RichTextEditor';
import { Editor as TinyMCEEditor } from 'tinymce';

export interface IRichTextEditorMCEProps {
  html?: string;
  placeholder?: string;
  height?: string | number;
  maxHeight?: number | undefined;
  maxLength?: number | undefined;
  readOnly?: boolean;
  disallowFullscreen?: boolean;
  compactToolbar?: boolean;
  hideRemainingChars?: boolean;
  onChange: (value: string) => void;
  onBadgeClick?: (badgeText: string) => void;
  onSpecialLinkClick?: (type: IRichTextEditorSpecialLinkType, text: string, href: string) => void;
}

const RichTextEditorMCE = (props: IRichTextEditorMCEProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);
  const [currentLength, setCurrentLength] = useState<number>(props.html?.length ?? 0);
  const maxLength = props.maxLength ?? 4000;
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const getPlugins = (): string[] => {
    let plugins = [
      'autoresize',
      'emoticons',
      'advlist',
      'autolink',
      'autoresize',
      'lists',
      'link',
      'image',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'fullscreen',
      'media',
      'table',
      'help',
    ];

    if (!props.disallowFullscreen) {
      plugins = ['fullscreen', ...plugins];
    }

    return plugins;
  };

  const getToolbar = (): string => {
    let toolbar: string = '';
    if (props.compactToolbar) {
      toolbar = 'undo | styles bold underline | backcolor removeformat | bullist numlist';
    } else {
      toolbar =
        'undo redo | styles fontsize lineheight | emoticons media image | bold italic underline strikethrough | forecolor backcolor removeformat | table bullist numlist | openlink link unlink | outdent indent | alignleft aligncenter alignright alignjustify | preview print code | help';
    }
    if (!props.disallowFullscreen) {
      toolbar = 'fullscreen | ' + toolbar;
    }

    return toolbar;
  };

  return (
    <Fragment>
      <Editor
        apiKey="ly09v4a3kahz4m9653scj9iqospefqevznn1shd60x0ze6mg"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={!props.html ? undefined : props.html}
        onKeyUp={(evt) => {
          if (evt.key === 'Enter') {
            editorRef.current?.editorCommands.execCommand('RemoveFormat');
          }
        }}
        onBeforeAddUndo={(evt, editor) => {
          const length = editor.getContent({ format: 'text' }).length;
          if (length > maxLength) {
            evt.preventDefault();
          }
        }}
        onEditorChange={(value: string) => {
          if (value.length <= maxLength) {
            props.onChange(value);
          }
          setCurrentLength(value.length);
        }}
        disabled={props.readOnly}
        init={{
          auto_focus: true,
          placeholder: props.placeholder,
          branding: false,
          toolbar_location: 'bottom',
          skin: appContext.useDarkMode ? 'oxide-dark' : 'oxide',
          content_css: appContext.useDarkMode ? 'dark' : 'default',
          content_style: 'body { line-height: 1.1; }',
          language: appContext.user.language.code,
          menubar: false,
          statusbar: false,
          toolbar_mode: 'sliding',
          plugins: getPlugins(),
          paste_data_images: true,
          toolbar: getToolbar(),
          fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt',
          height: props.height,
          max_height: props.maxHeight === -1 ? undefined : props.maxHeight,
          style_formats_autohide: true,
          style_formats: [
            { title: 'Paragraph', format: 'p' },
            {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '1px solid #2276d2',
                'border-radius': '5px',
                padding: '2px 5px',
                margin: '0 2px',
                color: '#2276d2',
                cursor: 'pointer',
              },
            },
            {
              title: 'Info',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#cce5ff',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            {
              title: 'Note',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#ffffcc',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            {
              title: 'Success',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#ccffe6',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            {
              title: 'Warning',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#ffb3b3',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            { title: 'Header 1', format: 'h1' },
            { title: 'Header 2', format: 'h2' },
            { title: 'Header 3', format: 'h3' },
            { title: 'Header 4', format: 'h4' },
            { title: 'Header 5', format: 'h5' },
            { title: 'Blockquote', format: 'blockquote' },
            { title: 'Code', format: 'code' },
          ],
        }}
      />
      {!props.hideRemainingChars && (
        <Text variant="small">
          {t('translation:General.Text.RichTextEditorMaxLength', { len: maxLength - currentLength })}
        </Text>
      )}
    </Fragment>
  );
};

export default RichTextEditorMCE;
