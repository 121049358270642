import { Fragment, useContext, useEffect, useState } from 'react';
import {
  Stack,
  Text,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Label,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  Separator,
  StackItem,
  FontIcon,
  ShimmeredDetailsList,
} from '@fluentui/react';
import Task from 'models/tasks/task';
import {
  deleteIcon,
  globalTextStylesMarginTopLabelPanel,
  globalTextStylesBold,
  globalStackTokensGapSmall,
  openExtIcon,
} from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import AppContext from 'App/AppContext';
import Entity, { EntityTypes } from 'models/entity';
import Control from 'models/control';
import { apiGetControls } from 'services/Api/controlService';
import { apiRequest } from 'services/Auth/authConfig';
import Theme from 'models/theme';
import EntityPicker from 'components/Pickers/EntityPicker';
import { apiGetThemes } from 'services/Api/themeService';
import { getEntity, getEntityName, onRenderDetailsHeaderNoPaddingTopGlobal } from 'globalFunctions';
import { getEntityUrl, navigateToExternalUrl } from 'utils/url';
import Objective from 'models/objective/objective';
import { apiGetObjectives } from 'services/Api/objectiveService';
import { apiGetProcesses } from 'services/Api/processService';
import Process from 'models/process/process';
import { apiGetSingleTask, apiGetTaskContext } from 'services/Api/taskService';
import { useHistory } from 'react-router-dom';
import { hasUserDataPermission } from 'services/Auth/featurePermissions';
import { TaskTask } from 'models/tasks/taskTask';
import SingleTask from './SingleTask';
import Risk from 'models/risk';
import { apiGetRisks } from 'services/Api/riskService';
import Asset from 'models/asset/asset';
import { apiGetAssets } from 'services/Api/assetService';
import { sortOnEntity } from 'utils/sorting';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';
import { canUpdateTaskField, TaskFieldTypes } from './TaskAuthHelper';

interface ITaskContextProps {
  task: Task;
  updateTask?: (task: Task) => void;
  updateTaskForForm?: (task: Task, validateForm: boolean) => void;
  allowEdit: boolean;
  hideHeaders?: boolean;
  navigateInPlace?: boolean;
  windowLevel?: number;
  navigateExternal?: boolean;
}

export const TaskContext = (props: ITaskContextProps) => {
  const history = useHistory();
  const { t } = useTranslation(['task', 'translation', 'asset', 'risk', 'control', 'theme', 'objective', 'process']);
  const appContext = useContext(AppContext);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [allEntities, setAllEntities] = useState<Entity[]>([]);
  const [isLoadingTaskRelations, setIsLoadingTaskRelations] = useState<boolean>(false);
  const [taskRelations, setTaskRelations] = useState<TaskTask[]>([]);
  const [isLoadingPicker, setIsLoadingPicker] = useState<boolean>(false);
  const [showRelatedTask, setShowRelatedTask] = useState<Task | undefined>(undefined);
  const [isBusyAddingRemoving, setIsBusyAddingRemoving] = useState<number>(0);
  const [currentTaskId, setCurrentTaskId] = useState<number>(0);
  const [canEdit] = useState<boolean>(
    props.allowEdit && hasUserDataPermission(appContext, [props.task.authSchemaId], AuthSchemaLineOperation.Update),
  );
  const [canUpdate] = useState<boolean>(
    props.allowEdit && canUpdateTaskField(props.task, canEdit, TaskFieldTypes.Context, appContext),
  );

  useEffect(() => {
    if (props.task.taskId !== currentTaskId) {
      setCurrentTaskId(props.task.taskId);
      let newEntities: Entity[] = [];
      if (props.task.RelatedEntities) {
        newEntities = props.task.RelatedEntities.map((e) => e.clone());
      }
      if (props.task.taskId !== -1) {
        loadData(newEntities);
      } else {
        //this is a new task
        loadDataFromTemplate(newEntities);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  const loadDataFromTemplate = async (existingEntities: Entity[]) => {
    try {
      const newEntities: Entity[] = [...existingEntities];

      if (props.task.templateId) {
        //Load context from the template
        setIsLoadingTaskRelations(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const context = await apiGetTaskContext(props.task.templateId, accessToken, appContext.globalDataCache);

        //create entities from the context
        const newRelatedEntities: Entity[] = [];
        newRelatedEntities.push(...context.assets.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.risks.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.controls.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.themes.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.processes.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.objectives.map((a) => getEntity(a)));

        //add new entities to the task. There could already be entities in the task from:
        // - creation of a new task from a specific context e.g. Asset
        // - user added context
        for (const newEntity of newRelatedEntities) {
          if (
            !existingEntities.some(
              (e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId,
            )
          ) {
            newEntities.push(newEntity);
          }
        }
      }

      //make sure that all related entities are set into the arrays with Ids and updated on the task
      newEntities.sort((a, b) => sortOnEntity(a, b));
      setEntities(newEntities);
      addToTask(newEntities);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingTaskRelations(false);
    }
  };

  const loadData = async (existingEntities: Entity[]) => {
    if (isLoadingTaskRelations || !props.task) return;

    try {
      setIsLoadingTaskRelations(true);

      //get context from the back-end
      //add them to the existing context in the task (where they do not exist yet)
      //push everything back to the task
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const result = await apiGetTaskContext(props.task.taskId, accessToken, appContext.globalDataCache);

      result.controls.forEach((control) => {
        const newEntity = getEntity(control);
        if (
          !existingEntities.some((e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId)
        ) {
          existingEntities.push(newEntity);
        }
      });

      result.themes.forEach((theme) => {
        const newEntity = getEntity(theme);
        if (
          !existingEntities.some((e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId)
        ) {
          existingEntities.push(newEntity);
        }
      });

      result.risks.forEach((risk) => {
        const newEntity = getEntity(risk);
        if (
          !existingEntities.some((e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId)
        ) {
          existingEntities.push(newEntity);
        }
      });

      result.assets.forEach((asset) => {
        const newEntity = getEntity(asset);
        if (
          !existingEntities.some((e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId)
        ) {
          existingEntities.push(newEntity);
        }
      });

      result.processes.forEach((process) => {
        const newEntity = getEntity(process);
        if (
          !existingEntities.some((e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId)
        ) {
          existingEntities.push(newEntity);
        }
      });

      result.objectives.forEach((objective) => {
        const newEntity = getEntity(objective);
        if (
          !existingEntities.some((e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId)
        ) {
          existingEntities.push(newEntity);
        }
      });

      existingEntities.sort((a, b) => sortOnEntity(a, b));
      addToTask(existingEntities);

      setEntities(existingEntities);
      setTaskRelations(result.relations);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingTaskRelations(false);
    }
  };

  const loadDataForPicker = async () => {
    if (isLoadingPicker || !canUpdate) return;

    try {
      setIsLoadingPicker(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const _allControlsPromise = apiGetControls(accessToken, appContext.globalDataCache);
      const _allRisksPromise = apiGetRisks(accessToken, appContext.globalDataCache);
      const _allThemesPromise = apiGetThemes(accessToken, appContext.globalDataCache);
      const _allProcessesPromise = apiGetProcesses(accessToken, appContext.globalDataCache);
      const _allObjectivesPromise = apiGetObjectives(accessToken, appContext.globalDataCache);
      const _allAssetsPromise = apiGetAssets(accessToken, appContext.globalDataCache);

      const [_allControlData, _allProcessesData, _allObjectivesData, _allThemesData, _allRisksData, _allAssetsData] =
        await Promise.allSettled([
          _allControlsPromise,
          _allProcessesPromise,
          _allObjectivesPromise,
          _allThemesPromise,
          _allRisksPromise,
          _allAssetsPromise,
        ]);

      const _allEntities: Entity[] = [];
      let errorMessage = '';

      if (_allControlData.status === 'fulfilled') {
        _allControlData.value.forEach((_control: Control) => {
          _allEntities.push(getEntity(_control));
        });
      } else {
        errorMessage += `${t('control:Title')} : ${_allControlData.reason}`;
      }

      if (_allThemesData.status === 'fulfilled') {
        _allThemesData.value.forEach((_theme: Theme) => {
          _allEntities.push(getEntity(_theme));
        });
      } else {
        errorMessage += `${t('theme:Title')} : ${_allThemesData.reason}`;
      }

      if (_allObjectivesData.status === 'fulfilled') {
        _allObjectivesData.value.forEach((_objective: Objective) => {
          _allEntities.push(getEntity(_objective));
        });
      } else {
        errorMessage += `${t('objective:Title')} : ${_allObjectivesData.reason}`;
      }

      if (_allProcessesData.status === 'fulfilled') {
        _allProcessesData.value.forEach((_process: Process) => {
          _allEntities.push(getEntity(_process));
        });
      } else {
        errorMessage += `${t('process:Title')} : ${_allProcessesData.reason}`;
      }

      if (_allRisksData.status === 'fulfilled') {
        _allRisksData.value.risks.forEach((_risk: Risk) => {
          _allEntities.push(getEntity(_risk));
        });
      } else {
        errorMessage += `${t('risk:Title')} : ${_allRisksData.reason}`;
      }

      if (_allAssetsData.status === 'fulfilled') {
        _allAssetsData.value.forEach((_asset: Asset) => {
          _allEntities.push(getEntity(_asset));
        });
      } else {
        errorMessage += `${t('asset:Title')} : ${_allAssetsData.reason}`;
      }

      if (errorMessage) appContext.setError(errorMessage);

      setAllEntities(_allEntities);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingPicker(false);
    }
  };

  const navigateToEntity = (entity: Entity) => {
    const url = getEntityUrl(entity);
    if (props.navigateInPlace === true) {
      history.push(url);
    } else {
      navigateToExternalUrl(url, appContext.user.tenant.azureTenantId, appContext.user.login.tenantId);
    }
  };

  const loadRelatedTask = async (task: Task | undefined) => {
    try {
      if (!task) return;
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const relatedTaskCol = await apiGetSingleTask(task.taskId, false, accessToken, appContext.globalDataCache);
      if (relatedTaskCol && relatedTaskCol.tasks.length > 0) {
        setShowRelatedTask(relatedTaskCol.tasks[0]);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingPicker(false);
    }
  };

  //
  // Relations
  //
  const getColumnsRelations = (): IColumn[] => {
    return [
      {
        key: 'relation',
        name: t('task:Context.RelationList.Relation'),
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        onRender: (item?: TaskTask, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{item.relationText(props.task.taskId, t as TFunction<string[]>)}</Text>;
        },
      },
      {
        key: 'name',
        name: t('task:Context.RelationList.Name'),
        minWidth: 100,
        maxWidth: 400,
        isResizable: true,
        isMultiline: true,
        onRender: (item?: TaskTask, index?: number, column?: IColumn) => {
          if (!item) return;

          return (
            <Text>
              <Link onClick={() => loadRelatedTask(item.task())}>{item.task()?.name}</Link>
            </Text>
          );
        },
      },
    ];
  };

  //
  // Context
  //

  const getColumnsContext = () => {
    return [
      {
        key: 'type',
        name: t('task:Context.List.Type'),
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        onRender: (item?: Entity, index?: number, column?: IColumn) => {
          if (!item) return;

          return <Text>{getEntityName(item, t as TFunction<string[]>)}</Text>;
        },
      },
      {
        key: 'name',
        name: t('task:Context.List.Name'),
        minWidth: 100,
        maxWidth: 400,
        isResizable: true,
        isMultiline: true,
        onRender: (item?: Entity, index?: number, column?: IColumn) => {
          if (!item) return;

          if (props.navigateExternal) {
            return (
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <StackItem>
                  <FontIcon {...openExtIcon} style={{ fontSize: 20, color: 'blue' }} />
                </StackItem>
                <Stack.Item>
                  <Text>
                    <Link onClick={() => navigateToEntity(item)}>{item.entityName}</Link>
                  </Text>
                </Stack.Item>
              </Stack>
            );
          } else {
            return (
              <Text>
                <Link onClick={() => navigateToEntity(item)}>{item.entityName}</Link>
              </Text>
            );
          }
        },
      },
      {
        key: 'remove',
        name: t('task:Context.List.Remove'),
        minWidth: canUpdate ? 50 : 1,
        maxWidth: canUpdate ? 50 : 1,
        onRender: (item?: Entity, index?: number, column?: IColumn) => {
          if (!canUpdate) return null;

          return (
            <IconButton
              iconProps={deleteIcon}
              onClick={() => {
                if (!item) return;

                onRemove(item);
              }}
              styles={{ root: { height: 18 } }}
            />
          );
        },
      },
    ];
  };

  const onRemove = (entity: Entity) => {
    try {
      if (isBusyAddingRemoving) return;

      setIsBusyAddingRemoving(entity.entityId);
      const newTask = props.task.clone();

      switch (entity.typeOfEntity) {
        case EntityTypes.Requirement:
        case EntityTypes.Control: {
          newTask.controlIds = newTask.controlIds?.filter((c) => c !== entity.entityId);
          break;
        }
        case EntityTypes.Process: {
          newTask.processIds = newTask.processIds?.filter((c) => c !== entity.entityId);
          break;
        }
        case EntityTypes.Objective: {
          newTask.objectiveIds = newTask.objectiveIds?.filter((c) => c !== entity.entityId);
          break;
        }
        case EntityTypes.Risk: {
          newTask.riskIds = newTask.riskIds?.filter((c) => c !== entity.entityId);
          break;
        }
        case EntityTypes.Asset: {
          newTask.assetIds = newTask.assetIds?.filter((c) => c !== entity.entityId);
          break;
        }
      }

      //signal that the form needs to be updated/validated for the removal
      if (props.updateTaskForForm) props.updateTaskForForm(newTask, true);

      //set the removed entity as deleted in the collection
      const idx = entities.findIndex((c) => c.typeOfEntity === entity.typeOfEntity && c.entityId === entity.entityId);
      if (idx >= 0) entities[idx].isDeleted = true;

      const newEntities = entities.map((e) => e.clone());
      setEntities(newEntities);

      if (props.updateTask) {
        newTask.RelatedEntities = newEntities;
        props.updateTask(newTask);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsBusyAddingRemoving(0);
    }
  };

  const filterEntities = (all: Entity[], related: Entity[]): Entity[] => {
    return all.filter((e) => {
      const foundControl = related.find((r) => {
        return r.entityId === e.entityId && r.typeOfEntity === e.typeOfEntity;
      });
      if (foundControl) return false;

      return true;
    });
  };

  const addToTask = (entities: Entity[]) => {
    const newTask = props.task.clone();
    newTask.addEntities(entities);

    if (props.updateTask) {
      newTask.RelatedEntities = entities.map((e) => e.clone());
      props.updateTask(newTask);
    }
  };

  const onAdd = (entity: Entity) => {
    try {
      if (isLoadingTaskRelations || !props.task) return false;
      if (isBusyAddingRemoving) return;

      setIsBusyAddingRemoving(entity.entityId);
      const newTask = props.task.clone();

      newTask.addEntity(entity);

      if (props.updateTaskForForm) props.updateTaskForForm(newTask, true);
      const newEntities = [...entities, entity];
      setEntities(newEntities);

      if (props.updateTask) {
        newTask.RelatedEntities = newEntities;
        props.updateTask(newTask);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsBusyAddingRemoving(0);
    }
  };

  const saveTask = (task: Task, isNew: boolean, isCancelled: boolean) => {
    updateTask(task);
  };

  const removeTask = (task: Task) => {
    const newRelations = taskRelations.filter((t) => t.taskIdFrom !== task.taskId && t.taskIdTo !== task.taskId);
    setTaskRelations(newRelations);
  };

  const updateTask = (task: Task) => {
    const newRelations: TaskTask[] = [];

    for (let idx = 0; idx < taskRelations.length; idx++) {
      const t = taskRelations[idx];
      if (t.taskFrom && t.taskIdFrom === task.taskId) {
        t.taskFrom = task;
      }
      if (t.taskIdTo && t.taskIdTo === task.taskId) {
        t.taskTo = task;
      }
      newRelations.push(t);
    }

    setTaskRelations(newRelations);
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill styles={{ root: { position: 'relative' } }}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <Stack verticalFill tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Text styles={globalTextStylesBold} variant="medium">
              {t('task:Context.Title')}
            </Text>
          </Stack.Item>
          {taskRelations.length > 0 && (
            <Fragment>
              <Stack.Item>
                <ShimmeredDetailsList
                  compact
                  items={taskRelations}
                  enableShimmer={isLoadingTaskRelations}
                  shimmerLines={1}
                  columns={getColumnsRelations()}
                  isHeaderVisible={!props.hideHeaders}
                  selectionMode={SelectionMode.none}
                  layoutMode={DetailsListLayoutMode.justified}
                />
              </Stack.Item>
              {!props.hideHeaders && (
                <Stack.Item>
                  <Separator />
                </Stack.Item>
              )}
            </Fragment>
          )}
          <Stack.Item>
            <ShimmeredDetailsList
              compact
              items={entities.filter((e) => !e.isDeleted)}
              shimmerLines={3}
              enableShimmer={isLoadingTaskRelations}
              columns={getColumnsContext()}
              isHeaderVisible={!props.hideHeaders}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={onRenderDetailsHeaderNoPaddingTopGlobal}
            />
          </Stack.Item>
          {canUpdate && (
            <Stack.Item>
              <Label styles={globalTextStylesMarginTopLabelPanel}>{t('task:Context.AddText')}</Label>
            </Stack.Item>
          )}
          {canUpdate && (
            <Stack.Item
              grow
              styles={{
                root: {
                  minHeight: 200,
                },
              }}
            >
              <EntityPicker
                entities={filterEntities(allEntities, entities)}
                addSelectedEntity={onAdd}
                loadData={loadDataForPicker}
                isLoading={isLoadingPicker}
                isOnPanel={false}
                showHeader={false}
                isBusyAdding={isBusyAddingRemoving}
              />
            </Stack.Item>
          )}
        </Stack>
      </ScrollablePane>
      {showRelatedTask && (
        <SingleTask
          task={showRelatedTask}
          isOpen={showRelatedTask !== undefined}
          close={() => {
            setShowRelatedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
          windowLevel={(props.windowLevel ?? 0) + 1}
          navigateExternal={props.navigateExternal}
        />
      )}
    </Stack>
  );
};
