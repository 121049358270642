import { mapFromObjectives } from './objectiveMapping';
import { mapFromProcesses } from './processMapping';
import Theme, { ThemeTaskStats } from 'models/theme';
import ThemeScene from 'models/themeScene';
import Theme_Translation from 'models/theme_Translation';
import ThemeDTO, { ThemeTaskStatsDTO } from 'models/dto/themeDTO';
import ThemeSceneDTO from 'models/dto/themeSceneDTO';
import Theme_TranslationDTO from 'models/dto/theme_TranslationDTO';
import {
  mapFromLanguage,
  mapToLanguage,
  mapFromControls,
  mapFromRisksArray,
  mapFromEntity,
  mapFromISOControls,
 mapFromTasksArray } from './dataMapping';

import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import ThemeContext from 'models/themeContext';
import ThemeContextDTO from 'models/dto/themeContextDTO';

export function mapFromThemes(themeDTOs: ThemeDTO[], globalDataCache: GlobalDataCache): Theme[] {
  const themeList: Theme[] = [];

  for (const themeDTO of themeDTOs) {
    const newTheme = mapFromTheme(themeDTO, globalDataCache);
    themeList.push(newTheme as Theme);
  }

  return themeList;
}

export function mapFromTheme_Translation(
  theme_TranslationDTOs: Theme_TranslationDTO[] | undefined,
): Theme_Translation[] | undefined {
  if (!theme_TranslationDTOs) {
    return undefined;
  }

  const theme_TranslationList: Theme_Translation[] = [];

  for (const theme_TranslationDTO of theme_TranslationDTOs) {
    const newTheme_Translation = new Theme_Translation();
    newTheme_Translation.themeId = theme_TranslationDTO.themeId;
    newTheme_Translation.languageId = theme_TranslationDTO.languageId;
    newTheme_Translation.name = theme_TranslationDTO.name;
    newTheme_Translation.description = theme_TranslationDTO.description;
    newTheme_Translation.background = theme_TranslationDTO.background;
    newTheme_Translation.implementation = theme_TranslationDTO.implementation;
    newTheme_Translation.outOfScopeReason = theme_TranslationDTO.outOfScopeReason;
    newTheme_Translation.lang = mapFromLanguage(theme_TranslationDTO.lang);
    theme_TranslationList.push(newTheme_Translation);
  }

  return theme_TranslationList;
}

export function mapToThemes(themes: Theme[]): ThemeDTO[] {
  const themeList: ThemeDTO[] = [];

  for (const theme of themes) {
    const newThemeDto = mapToTheme(theme);
    themeList.push(newThemeDto as ThemeDTO);
  }

  return themeList;
}

export function mapToTheme(theme: Theme): ThemeDTO {
  const output = new ThemeDTO();

  output.tenantId = theme.tenantId;
  output.themeId = theme.themeId;
  output.parentThemeId = theme.parentThemeId;
  output.code = theme.code;
  output.groupId = theme.groupId;
  output.ownerId = theme.ownerId;
  output.ownerRoleId = theme.ownerRoleId;
  output.commentTrailId = theme.commentTrailId;
  output.auditTrailId = theme.auditTrailId;
  output.state = theme.state;
  output.trans = mapToTheme_Translation(theme.trans);

  if (output.trans && theme.transIdx >= 0) {
    output.trans[theme.transIdx].name = theme.name;
    output.trans[theme.transIdx].description = theme.description;
    output.trans[theme.transIdx].background = theme.background;
    output.trans[theme.transIdx].implementation = theme.implementation;
  }

  return output;
}

export function mapFromTheme(themeDTO: ThemeDTO | undefined, cache: GlobalDataCache): Theme | undefined {
  if (!themeDTO) {
    return undefined;
  }

  const output = new Theme();

  output.tenantId = themeDTO.tenantId;
  output.themeId = themeDTO.themeId;
  output.parentThemeId = themeDTO.parentThemeId;
  output.code = themeDTO.code;
  output.state = themeDTO.state;
  output.groupId = themeDTO.groupId;
  output.ownerId = themeDTO.ownerId;
  output.ownerRoleId = themeDTO.ownerRoleId;
  output.commentTrailId = themeDTO.commentTrailId;
  output.auditTrailId = themeDTO.auditTrailId;
  output.isoNormIds = cache.norms.filterISOIds(themeDTO.normIds?.idList?.map<number>((i) => Number(i)));
  output.customNormIds = cache.norms.filterCustomIds(themeDTO.normIds?.idList?.map<number>((i) => Number(i)));
  output.isoControlIds = themeDTO.isoControlIds?.idList?.map<number>((i) => Number(i));
  output.trans = mapFromTheme_Translation(themeDTO.trans);
  output.tasks = mapFromTasksArray(themeDTO.tasks, cache);
  output.tagIds = themeDTO.tagIds?.idList?.map<number>((i) => Number(i)) ?? [];
  output.monitoringParent = themeDTO.monitoringParent ? mapFromEntity(themeDTO.monitoringParent) : undefined;
  output.isoControls = themeDTO.isoControls ? mapFromISOControls(themeDTO.isoControls, cache) : undefined;
  output.inheritTenantId = themeDTO.inheritTenantId;
  output.inheritThemeId = themeDTO.inheritThemeId;

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
    output.background = output.trans[0].background;
    output.implementation = output.trans[0].implementation;
  }

  return output;
}

export function mapToTheme_Translation(
  theme_Translations: Theme_Translation[] | undefined,
): Theme_TranslationDTO[] | undefined {
  if (!theme_Translations) {
    return undefined;
  }

  const theme_TranslationList: Theme_TranslationDTO[] = [];

  for (const theme_Translation of theme_Translations) {
    const newITheme_TranslationDTO = new Theme_TranslationDTO();
    newITheme_TranslationDTO.themeId = theme_Translation.themeId;
    newITheme_TranslationDTO.languageId = theme_Translation.languageId;
    newITheme_TranslationDTO.name = theme_Translation.name;
    newITheme_TranslationDTO.description = theme_Translation.description;
    newITheme_TranslationDTO.background = theme_Translation.background;
    newITheme_TranslationDTO.implementation = theme_Translation.implementation;
    newITheme_TranslationDTO.outOfScopeReason = theme_Translation.outOfScopeReason;
    newITheme_TranslationDTO.lang = mapToLanguage(theme_Translation.lang);
    theme_TranslationList.push(newITheme_TranslationDTO);
  }

  return theme_TranslationList;
}

export function mapFromThemeScene(themeSceneDTO: ThemeSceneDTO, globalDataCache: GlobalDataCache): ThemeScene {
  const themeScene = new ThemeScene();

  themeScene.themes = mapFromThemes(themeSceneDTO.themes, globalDataCache);

  return themeScene;
}

export function mapFromThemeContext(themeContextDTO: ThemeContextDTO, globalDataCache: GlobalDataCache) {
  const themeContext = new ThemeContext();
  themeContext.children = mapFromThemes(themeContextDTO.children, globalDataCache);
  themeContext.parent = mapFromTheme(themeContextDTO.parent, globalDataCache);
  themeContext.themes = mapFromThemes(themeContextDTO.themes, globalDataCache);
  themeContext.controls = mapFromControls(themeContextDTO.controls, globalDataCache);
  themeContext.risks = mapFromRisksArray(themeContextDTO.risks, globalDataCache);
  themeContext.processes = mapFromProcesses(themeContextDTO.processes, globalDataCache);
  themeContext.objectives = mapFromObjectives(themeContextDTO.objectives, globalDataCache);

  return themeContext;
}

export function mapFromThemeTaskStats(themeTaskStatsDTOs: ThemeTaskStatsDTO[]): ThemeTaskStats[] {
  const themeTaskStats: ThemeTaskStats[] = [];

  for (const themeTaskStatsDTO of themeTaskStatsDTOs) {
    const themeTaskStat = new ThemeTaskStats();
    themeTaskStat.themeId = themeTaskStatsDTO.themeId;
    themeTaskStat.taskTotalCount = themeTaskStatsDTO.taskTotalCount;
    themeTaskStat.taskCompletedCount = themeTaskStatsDTO.taskCompletedCount;

    themeTaskStats.push(themeTaskStat);
  }

  return themeTaskStats;
}
