import { useContext, useEffect, useState } from 'react';
import Group from 'models/group';
import {
  IBasePickerSuggestionsProps,
  IInputProps,
  IPersonaProps,
  ISuggestionItemProps,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalFilterDelay, globalSuggestionsMaxRecords } from 'globalConstants';
import AppContext from 'App/AppContext';

interface IPickerProps extends IPersonaProps {}

interface IGroupPickerProps {
  onSelect?: (item: Group | undefined, removedId?: string) => void;
  itemLimit?: number;
  selectedItemId?: string;
  disabled?: boolean;
  placeHolder?: string | undefined;
}

const createKey = (item: Group): string => {
  return item.id;
};

const GroupPicker = (props: IGroupPickerProps) => {
  const { t } = useTranslation('translation');
  const appContext = useContext(AppContext);
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(undefined);
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    setGroups(appContext.globalDataCache.groups.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedItem = (id: string | undefined) => {
    setSelectedItemId(id);
  };

  useEffect(() => {
    const setInitialSelectedGroup = () => {
      const group = groups.find((_group) => {
        return _group.id === props.selectedItemId;
      });
      if (group) {
        setSelectedItem(group.id);
      } else {
        setSelectedItem(undefined);
      }
    };

    setInitialSelectedGroup();

     
  }, [groups, props.selectedItemId]);

  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    onRenderNoResultFound: () => {
      if (selectedItemId) {
        return (
          <Stack styles={{ root: { padding: 10 } }}>
            <Text>{t('translation:General.Suggestions.AlreadySelected')}</Text>
          </Stack>
        );
      } else {
        return (
          <Stack styles={{ root: { padding: 10 } }}>
            <Text>{t('translation:General.Suggestions.NoData')}</Text>
          </Stack>
        );
      }
    },
  };

  const getPersonas = (groups: Group[]): IPickerProps[] => {
    const personaProps: IPickerProps[] = [];

    for (const group of groups) {
      const personaProp: IPickerProps = {
        text: group.name,
        key: createKey(group),
        secondaryText: group.email,
      };
      personaProps.push(personaProp);
    }

    return personaProps.sort((a, b) => {
      return a.text === b.text || !a.text || !b.text ? 0 : a.text < b.text ? -1 : 1;
    });
  };

  const getSelectedPersona = (): IPickerProps[] | undefined => {
    const personaProps: IPickerProps[] = [];

    for (const group of groups) {
      if (group.id === selectedItemId) {
        const personaProp: IPickerProps = {
          text: group.name,
          key: createKey(group),
          secondaryText: group.email,
        };
        personaProps.push(personaProp);

        return personaProps;
      }
    }

    return personaProps;
  };

  const getNameFromItem = (persona: IPersonaProps): string => {
    return persona.text as string;
  };

  const filterAllGroupsByText = (filterText: string): Group[] => {
    return groups.filter(
      (item) =>
        item.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 ||
        item.email.toLowerCase().indexOf(filterText.toLowerCase()) >= 0,
    );
  };

  const onSuggestion = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText && !selectedItemId) {
      const filteredGroups = filterAllGroupsByText(filterText);
      let filteredPersonas: IPickerProps[] = getPersonas(filteredGroups);
      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;

      return filteredPersonas;
    } else {
      return [];
    }
  };

  const onChange = (personas: IPersonaProps[] | undefined): void => {
    if (!personas || personas.length === 0) {
      //Optionally execute onSelect of Item
      if (props.onSelect) {
        props.onSelect(undefined, selectedItemId);
      }
      setSelectedItem(undefined);

      return;
    }

    let personaModified: IPickerProps[] | undefined = [];

    personaModified = personas?.map((persona) => {
      return persona as IPickerProps;
    });
    if (!personaModified) {
      return;
    }

    const persona = personaModified[0];
    const item = groups.find((_group) => {
      return createKey(_group) === persona.key;
    });
    setSelectedItem((item as Group).id);
    if (props.onSelect) {
      props.onSelect(item, selectedItemId);
    }
  };

  const onRenderSuggestionsItem = (
    props: IPersonaProps,
    itemProps: ISuggestionItemProps<IPersonaProps>,
  ): JSX.Element => {
    const modifiedProps: IPickerProps = props as IPickerProps;

    return (
      <Stack verticalAlign="center" tokens={{ padding: '5px' }}>
        <Persona {...modifiedProps} size={PersonaSize.size40} hidePersonaDetails={false} />
      </Stack>
    );
  };

  const onEmptyResolveSuggestions = (selectedItems?: IPersonaProps[] | undefined): IPersonaProps[] => {
    const filteredPersonas: IPickerProps[] = getPersonas(groups);

    return filteredPersonas.slice(0, 32);
  };

  const inputProps: IInputProps = {
    placeholder: props.placeHolder || t('translation:General.GroupPicker.Placeholder'),
  };

  //
  // Main render
  //
  return (
    <NormalPeoplePicker
      itemLimit={props.itemLimit || 1}
      onResolveSuggestions={onSuggestion}
      getTextFromItem={getNameFromItem}
      pickerSuggestionsProps={suggestionProps}
      selectedItems={getSelectedPersona()}
      onChange={onChange}
      resolveDelay={globalFilterDelay}
      onRenderSuggestionsItem={onRenderSuggestionsItem}
      onEmptyResolveSuggestions={onEmptyResolveSuggestions}
      disabled={props.disabled}
      styles={{ itemsWrapper: { height: 30 }, input: { height: 30 } }}
      inputProps={inputProps}
    />
  );
};
export default GroupPicker;
