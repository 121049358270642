import React, { useContext, useEffect, useState } from 'react';
import {
  TagPicker,
  ITag,
  IBasePickerSuggestionsProps,
  IPickerItemProps,
  Stack,
  IconButton,
  IButtonStyles,
  ValidationState,
  ISuggestionItemProps,
  getTheme,
  IInputProps,
 Text, ITextStyles } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalSuggestionsMaxRecords } from 'globalConstants';

import { cancelIcon } from 'globalStyles';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';

export interface ILinkTag {
  tagId: string | number;
  tagName: string;
  link: ResourceLink;
}

interface ILinkTagProps {
  tag: ILinkTag;
  onRemove?: (item: ILinkTag) => void;
  useBackground?: boolean;
}

const LinkTag = (props: ILinkTagProps) => {
  const appContext = useContext(AppContext);
  const theme = getTheme();

  const getTagStyles = (tag: ILinkTag) => {
    return {
      root: {
        margin: '2px',
        background: props.useBackground
          ? appContext.useDarkMode
            ? theme.palette.neutralDark
            : theme.palette.neutralLight
          : undefined,
        height: '26px',
      },
    };
  };

  const tagTextStyles: ITextStyles = {
    root: {
      background: props.useBackground
        ? appContext.useDarkMode
          ? theme.palette.neutralDark
          : theme.palette.neutralLight
        : undefined,
      marginLeft: '2px',
      marginRight: '2px',
      height: '22px',
      padding: '2px',
      whiteSpace: 'nowrap',
    },
  };

  const tagButtonStyles: IButtonStyles = {
    root: {
      background: props.useBackground
        ? appContext.useDarkMode
          ? theme.palette.neutralDark
          : theme.palette.neutralLight
        : undefined,
      height: '24px',
      width: '24px',
    },
  };

  return (
    <Stack key={props.tag.tagId} horizontal styles={getTagStyles(props.tag)} verticalAlign="center">
      <Stack.Item>
        <Text block nowrap styles={tagTextStyles} key={props.tag.tagId.toString()} variant="medium">
          {props.tag.tagName}
        </Text>
      </Stack.Item>
      {props.onRemove && (
        <Stack.Item>
          <IconButton
            onClick={() => {
              if (props.onRemove) {
                props.onRemove(props.tag);
              }
            }}
            styles={tagButtonStyles}
            iconProps={cancelIcon}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

interface ILinkPickerProps {
  tags: ILinkTag[];
  selectedTags?: ILinkTag[];
  itemLimit?: number;
  disabled?: boolean;
  onRemove?: (item: ILinkTag) => void;
  onAdd?: (item: ILinkTag, isNew: boolean) => void;
  onCreate?: (item: ILinkTag) => void;
  isLoading?: boolean;
  preventTabKey?: boolean;
}

const LinkPicker = (props: ILinkPickerProps) => {
  const { t } = useTranslation(['translation', 'tag']);
  const [selectedTags, setSelectedTags] = useState<ILinkTag[] | undefined>(props.selectedTags);

  useEffect(() => {
    setSelectedTags(props.selectedTags);
     
  }, [props.selectedTags]);

  const getITag = (tag: ILinkTag): ITag => {
    return {
      name: tag.tagName,
      key: tag.tagId.toString(),
    };
  };

  const getITags = (tags: ILinkTag[] | undefined): ITag[] | undefined => {
    if (!tags) return [];

    return tags?.map((t) => getITag(t));
  };

  const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
    if (filter && props.tags) {
      const filterLower = filter.toLowerCase();

      return props.tags
        .filter(
          (tag: ILinkTag) =>
            !selectedTags?.some((t) => t.tagId === tag.tagId) && tag.tagName.toLowerCase().includes(filterLower),
        )
        .sort((a, b) => {
          return a.tagName.localeCompare(b.tagName);
        })
        .map((tag: ILinkTag): ITag => {
          return getITag(tag);
        });
    }

    return [];
  };

  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    noResultsFoundText: t('translation:General.Suggestions.NoData'),
  };

  const onRenderSuggestionsItem = (pickerProps: ITag, itemProps: ISuggestionItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps;
    if (!props.tags) return <span />;

    const tag = props.tags.find((t) => t.tagId.toString() === tagItem.key);
    if (!tag) return <span />;

    return <LinkTag tag={tag} />;
  };

  const removeSelectedItem = (tag: ILinkTag) => {
    const items = props.selectedTags?.filter((t) => t.tagId !== tag.tagId);
    setSelectedTags(items);
    if (props.onRemove) {
      props.onRemove(tag);
    }
  };

  const onRenderItem = (pickerProps: IPickerItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps.item;
    const tag = selectedTags?.find((t) => t.tagId.toString() === tagItem.key);
    if (tag) {
      return <LinkTag tag={tag} useBackground={true} onRemove={removeSelectedItem} />;
    }

    return <span />;
  };

  const onSelectItem = (item?: ITag | undefined) => {
    if (item) {
      const tag = props.tags?.find((t) => t.tagId.toString() === item.key);
      if (tag) {
        const newSelectedTags = selectedTags?.slice(0) || [];
        newSelectedTags.push(tag);
        setSelectedTags(newSelectedTags);
        if (props.onAdd) {
          props.onAdd(tag, false);
        }

        return item;
      }
    }

    return null;
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (props.preventTabKey && e.key === 'Tab') {
      e.preventDefault();
    }
  };

  const inputProps: IInputProps = {
    placeholder: props.disabled ? '' : t('library:Picker.Placeholder'),
  };

  return (
    <Stack onKeyDown={onKeyPress}>
      <TagPicker
        itemLimit={props.itemLimit}
        onResolveSuggestions={onResolveSuggestions}
        pickerSuggestionsProps={suggestionProps}
        selectedItems={getITags(selectedTags)}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        disabled={props.disabled}
        onRenderItem={onRenderItem}
        inputProps={inputProps}
        createGenericItem={(input: string) => {
          return { key: '', name: input } as ITag;
        }}
        onItemSelected={onSelectItem}
        onValidateInput={(input: string) => {
          return input ? ValidationState.valid : ValidationState.invalid;
        }}
      />
    </Stack>
  );
};

export default LinkPicker;
