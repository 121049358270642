import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DefaultButton,
  IconButton,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TooltipHost,
  Link,
  IPivotItemProps,
} from '@fluentui/react';
import {
  cancelIcon,
  globalStackTokensGapSmall,
  globalStackItemStylesPaddingSmall,
  globalStackStylesHeight100,
  globalStackTokensGapExtraSmall,
  globalTextStylesBold,
} from 'globalStyles';
import Task, { TaskWorkflowStatus } from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import CheckList from 'components/Checklist/Checklist';
import Joi from 'joi';
import ChecklistProgress from 'components/Checklist/ChecklistProgress';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import AuditTrail from 'components/Audit/AuditTrail';
import TaskLinkList from 'components/Links/LinkLists/TaskLinkList';
import { ToastContainer } from 'react-toastify';
import { ISingleTaskTypeProps } from '../SingleTask';
import { CheckListFollowUp } from 'components/Checklist/ChecklistFollowUp';
import { globalToastDelay } from 'globalConstants';
import ActivityTrail from 'components/Activity/ActivityTrail';
import OverlayLoader from 'components/Loading/OverlayLoader';
import TaskInstanceTab from '../TaskInstanceTab';
import { TaskContext } from '../TaskContext';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import TaskTopTasksBar from '../TaskTopTasksBar';
import TaskTypeForm from '../Forms/TaskTypeForm';
import { MonitoringTaskDetail } from './MonitoringTaskDetail';
import { SystemTaskTypes } from 'models/tasks/taskType';
import TaskWorkflows from '../TaskWorkflows';
import InfoMessage from 'components/Notification/InfoMessage';
import WarningMessage from 'components/Notification/WarningMessage';
import TaskWorkflowDetailsModal from './TaskWorkflowDetailsModal';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetWebhooksForType, apiRunWebhooksForTask } from 'services/Api/webhookService';
import { DialogOk } from 'components/Dialogs/DialogOk';
import { TaskCheckListItem, TaskCheckList } from 'models/tasks/taskHelperClasses';
import { TaskDetailOwner } from './Details/TaskDetailOwner';
import { getCompactViewSwitch, getTaskViewModalStackStyles, getTaskViewModalStyles } from '../View/TaskRenderHelpers';
import { capitalizeFirstLetter } from 'utils/string';
import { canUpdateTaskField, TaskFieldTypes } from '../TaskAuthHelper';

interface IMonitoringTaskProps extends ISingleTaskTypeProps {
  onSaveAsNewTask: () => void;
  onChangeCompletionDate: (completionDate: Date) => void;
}

const MonitoringTask = (props: IMonitoringTaskProps) => {
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent']);
  const appContext = useContext(AppContext);
  const [err, setErr] = useState<Joi.ValidationError | undefined>(undefined);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [showWorkflowDetails, setShowWorkflowDetails] = useState<boolean>(false);
  const [newTaskHasWorkflows, setNewTaskHasWorkflows] = useState<boolean>(false);
  const [webhooksLoadedForSeries, setWebhooksLoadedForSeries] = useState<number>(0);
  const [showNoWorkflowsToRunDialog, setShowNoWorkflowsToRunDialog] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  //
  // Effects
  //
  useEffect(() => {
    const _localizedFields = {
      assignment: t('task:Assignment.Label'),
      startDateTime: t('task:Start.Label'),
      endDateTime: t('task:End.ErrorMessage'),
      name: t('task:Name.Label'),
      deadline: t('task:Deadline.ErrorMessage'),
    };

    const loadWebhooks = async () => {
      try {
        if (!props.task.taskMasterId) return;
        //check if the template that created this event has connected webhooks (which trigger a workflow)
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const webHooks = await apiGetWebhooksForType('task', props.task.taskMasterId, accessToken);
        setNewTaskHasWorkflows(webHooks.length > 0);
      } catch (err) {
        appContext.setError(err);
      }
    };

    const result = props.task.validate(_localizedFields);
    setErr(result.error);

    if (props.task.taskId === -1 && props.task.taskMasterId && props.task.taskMasterId !== webhooksLoadedForSeries) {
      setWebhooksLoadedForSeries(props.task.taskMasterId);
      loadWebhooks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  //
  // Helpers
  //
  const validate = (fieldName: string): string | undefined => {
    if (err) {
      const fieldError = err.details.find((e) => {
        return e.context?.key === fieldName || e.message.toLowerCase().includes(fieldName.toLowerCase());
      });

      return fieldError ? fieldError.message : undefined;
    }

    return undefined;
  };

  const hasErrors = (): boolean => {
    return err ? true : false;
  };

  const cannotSaveTask = (): boolean => {
    return (props.task.taskId !== -1 && props.task.isEqual(props.orgTask)) || hasErrors();
  };

  const getIsOutlookCreateDisabled = (): boolean => {
    return (
      !props.task.isAssigned() ||
      hasErrors() ||
      props.isActionPending ||
      (props.disallowSchedule ?? false) ||
      !canUpdateTaskField(props.task, props.canUpdate, TaskFieldTypes.OutlookCreate, appContext)
    );
  };

  const onCancel = () => {
    if (cannotSaveTask()) {
      props.onCancel();
    } else {
      setShowCancelDialog(true);
    }
  };

  const addCommentFromCheckList = (item: TaskCheckListItem) => {
    props.setSelectedPivot('Comments');
  };

  const onUpdateCheckList = (newCheckList: TaskCheckList) => {
    const copyTask = props.task.clone();
    copyTask.checkList = newCheckList;
    props.onUpdateTask(copyTask);
  };

  const onToggleTaskFollowUp = () => {
    const copyTask = props.task.clone();
    copyTask.followUp = copyTask.followUp ? false : true;
    props.onUpdateTask(copyTask);
  };

  const getTaskDetails = () => {
    return (
      <MonitoringTaskDetail
        task={props.task}
        orgTask={props.orgTask}
        onUpdate={(newTask: Task) => {
          props.onUpdateTask(newTask);
        }}
        getErrorMessage={validate}
        isActionPending={props.isActionPending}
        tags={props.tags}
        addTagToTaskState={props.addTagToTaskState}
        removeTagFromTaskState={props.removeTagFromTaskState}
        onChangeCompletionDate={props.onChangeCompletionDate}
        canUpdate={props.canUpdate}
        compactView={props.compactView}
      />
    );
  };

  const onClickFinish = () => {
    const newTask = props.task.clone();
    newTask.setComplete();
    props.onUpdateTask(newTask);
    props.onSave(newTask, false);
  };

  const onClickForm = (taskTypeId: number) => {
    const newTask = props.task.clone();
    newTask.taskTypeId = taskTypeId > 0 ? taskTypeId : undefined;

    if (taskTypeId > 0) {
      newTask.systemTaskType =
        appContext.globalDataCache.taskTypes.get(taskTypeId).systemTaskType ?? SystemTaskTypes.None;
    } else {
      newTask.systemTaskType = SystemTaskTypes.None;
    }

    props.onUpdateTask(newTask);

    if (taskTypeId > 0) {
      props.setSelectedPivot('TaskType');
    } else if (appContext.isMobileView) {
      props.setSelectedPivot('TaskDetails');
    } else {
      props.setSelectedPivot('Checklist');
    }
  };

  //
  // Render helpers
  //
  const getWorkflowTaskMessage = (newEventHasWorkflows: boolean): JSX.Element | null => {
    if (props.task.taskId === -1 && newEventHasWorkflows) {
      //not implemented: monitoring tasks cannot be created manually
      return null;
    } else {
      if (props.task.webhookStatus === TaskWorkflowStatus.Success) {
        return (
          <InfoMessage>
            <Stack horizontal tokens={globalStackTokensGapExtraSmall}>
              <Text variant="small"> {t('task:Workflows.Messages.TaskSuccess')} </Text>
              <Link onClick={() => setShowWorkflowDetails(true)}>{t('task:Workflows.Messages.DetailLink')}</Link>
            </Stack>
          </InfoMessage>
        );
      } else if (props.task.webhookStatus === TaskWorkflowStatus.Failure) {
        return (
          <WarningMessage>
            <Stack horizontal tokens={globalStackTokensGapExtraSmall}>
              <Text styles={{ root: { color: 'black' } }} variant="small">
                {t('task:Workflows.Messages.TaskFailure')}
              </Text>
              <Link onClick={() => setShowWorkflowDetails(true)}>{t('task:Workflows.Messages.DetailLink')}</Link>
            </Stack>
          </WarningMessage>
        );
      } else {
        return null;
      }
    }
  };

  const getOutlookButton = (): JSX.Element | null => {
    if (!props.task.isSeries()) {
      if (!props.task.eventId) {
        //create
        return (
          <Stack>
            <Stack.Item>
              <PrimaryButton
                className="redlab-usetiful-task-scheduleinoutlook"
                disabled={getIsOutlookCreateDisabled()}
                onClick={() => {
                  props.createInOutlook();
                }}
              >
                {t('task:Outlook.CreateOutlook')}
              </PrimaryButton>
            </Stack.Item>
          </Stack>
        );
      } else if (props.task.eventId) {
        //remove
        return (
          <Stack>
            <Stack.Item>
              <PrimaryButton
                text={t('task:Outlook.RemoveOutlook')}
                disabled={
                  hasErrors() ||
                  props.isActionPending ||
                  !canUpdateTaskField(props.task, props.canUpdate, TaskFieldTypes.OutlookRemove, appContext)
                }
                onClick={() => {
                  props.removeFromOutlook();
                }}
              />
            </Stack.Item>
          </Stack>
        );
      }
    }

    return null;
  };

  const onRunTemplateWorkflows = async (task: Task) => {
    try {
      if (!task.taskMasterId) return;

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const webHooks = await apiGetWebhooksForType('task', task.taskMasterId, accessToken);
      if (webHooks.length === 0) {
        setShowNoWorkflowsToRunDialog(true);
      } else {
        setIsUpdating(true);
        const result = await apiRunWebhooksForTask(task.taskId, accessToken);
        const newTask = props.task.clone();
        newTask.webhookStatus = result;
        props.onUpdateTask(newTask);
        setShowWorkflowDetails(true);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const onRenderTaskTypePivotItem = (
    itemProps?: IPivotItemProps,
    defaultRender?: (itemProps?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null => {
    //itemScope contains true when in compact view
    if (!itemProps || !itemProps.itemKey || !defaultRender) return null;

    //render default for compact mode
    if (props.compactView === true) {
      return defaultRender(itemProps);
    }

    return (
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text variant="medium" styles={itemProps.itemKey === props.selectedPivot ? globalTextStylesBold : undefined}>
            {itemProps.headerText}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <TooltipHost content={t('forms:View.EnterFormMode')}>
            <IconButton iconProps={{ iconName: 'Fullscreen' }} onClick={() => props.setFormMode(true)} />
          </TooltipHost>
        </Stack.Item>
      </Stack>
    );
  };

  //
  // Footer
  //
  const footer = (
    <Stack horizontal={!appContext.isMobileView} verticalAlign="end" tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            disabled={cannotSaveTask() || props.isActionPending}
            onClick={async () => {
              props.onSave(props.task, false);
            }}
          >
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              onCancel();
            }}
          >
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack>
      </Stack.Item>
      <Stack.Item grow>
        <Stack horizontal horizontalAlign={appContext.isMobileView ? undefined : 'space-around'}>
          {getOutlookButton()}
        </Stack>
      </Stack.Item>
    </Stack>
  );

  //
  // Main render
  //
  return (
    <Modal isOpen={props.isOpen} styles={getTaskViewModalStyles(props.windowLevel)}>
      <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="templateTask" />
      {(props.isLoading || isUpdating) && <OverlayLoader text={t('task:Progress.Save')} />}
      <Stack
        verticalFill
        tokens={globalStackTokensGapSmall}
        styles={getTaskViewModalStackStyles(appContext.isMobileView, props.compactView)}
      >
        <Stack.Item>
          <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Stack.Item styles={{ root: { width: props.compactView ? '90%' : undefined } }}>
              {props.task.taskId === -1 && (
                <Text block nowrap variant="xxLarge">
                  {t('task:Title.Monitoring.New')}
                </Text>
              )}
              {props.task.taskId !== -1 && props.compactView && (
                <TooltipHost content={props.task.name}>
                  <Text block nowrap variant="xxLarge">
                    {capitalizeFirstLetter(props.task.name)}
                  </Text>
                </TooltipHost>
              )}
              {props.task.taskId !== -1 && !props.compactView && (
                <Text variant="xxLarge">
                  {props.task.isSeries() && t('task:Title.Monitoring.Series.Edit')}
                  {!props.task.isSeries() && t('task:Title.Monitoring.Edit')}
                </Text>
              )}
            </Stack.Item>
            {!props.compactView && (
              <Stack.Item>
                <TaskTopTasksBar
                  task={props.task}
                  onClickTemplate={props.copyFromTemplate}
                  onClickFinish={!props.task.isSeries() ? onClickFinish : undefined}
                  onClickFollowUp={props.task.isInstance() ? props.createFromTemplate : undefined}
                  onClickRemove={() => setShowRemoveDialog(true)}
                  onClickForm={props.task.isSeries() ? onClickForm : undefined}
                  onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
                  onSaveAsNewTask={props.onSaveAsNewTask}
                  onRunTemplateWorkflows={!props.task.isSeries() ? onRunTemplateWorkflows : undefined}
                  navigateExternal={props.navigateExternal}
                  canUpdate={props.canUpdate}
                  canDelete={props.canDelete}
                  orgTask={props.orgTask}
                  onConvertChecklist={props.task.isSeries() ? props.onConvertChecklist : undefined}
                  canSave={!hasErrors()}
                />
              </Stack.Item>
            )}
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <TaskDetailOwner
              orgTask={props.orgTask}
              task={props.task}
              onUpdate={props.onUpdateTask}
              canUpdate={props.canUpdate}
            />
          </Stack.Item>
          <Separator />
          {getWorkflowTaskMessage(newTaskHasWorkflows)}
        </Stack.Item>
        {props.compactView && (
          <Stack.Item>
            <TaskTopTasksBar
              task={props.task}
              onClickTemplate={props.copyFromTemplate}
              onClickFinish={!props.task.isSeries() ? onClickFinish : undefined}
              onClickFollowUp={props.task.isInstance() ? props.createFromTemplate : undefined}
              onClickRemove={() => setShowRemoveDialog(true)}
              onClickForm={props.task.isSeries() ? onClickForm : undefined}
              onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
              onSaveAsNewTask={props.onSaveAsNewTask}
              onRunTemplateWorkflows={!props.task.isSeries() ? onRunTemplateWorkflows : undefined}
              navigateExternal={props.navigateExternal}
              compact={true}
              canUpdate={props.canUpdate}
              canDelete={props.canDelete}
              orgTask={props.orgTask}
              onConvertChecklist={props.task.isSeries() ? props.onConvertChecklist : undefined}
              canSave={!hasErrors()}
            />
          </Stack.Item>
        )}
        <Stack.Item grow>
          <Stack horizontal styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
            {!props.compactView && (
              <Fragment>
                <Stack.Item styles={{ root: { width: '50%' } }}>{getTaskDetails()}</Stack.Item>
                <Separator vertical></Separator>
              </Fragment>
            )}
            <Stack.Item styles={!props.compactView ? { root: { width: '50%' } } : { root: { width: '100%' } }}>
              <Stack verticalFill tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <Stack horizontal verticalAlign="start">
                    {getCompactViewSwitch(
                      props.task,
                      appContext.isMobileView,
                      props.compactView,
                      t,
                      props.setCompactView,
                      appContext,
                    )}
                    <Stack.Item>
                      <Pivot
                        selectedKey={props.selectedPivot}
                        onLinkClick={(item) => props.setSelectedPivot(item?.props.itemKey ?? '')}
                        overflowBehavior="menu"
                      >
                        {props.compactView && (
                          <PivotItem key="TaskDetails" itemKey="TaskDetails" headerText={t('task:Pivot.TaskDetails')} />
                        )}
                        {props.hasFormFeature && props.task.taskTypeId && props.task.taskTypeId > 0 && (
                          <PivotItem
                            key="TaskType"
                            itemKey="TaskType"
                            headerText={appContext.globalDataCache.taskTypes.get(props.task.taskTypeId).tabName}
                            onRenderItemLink={appContext.isMobileView ? undefined : onRenderTaskTypePivotItem}
                          />
                        )}
                        {props.hasPAFeature &&
                          props.task.taskTypeId &&
                          props.task.taskTypeId > 0 &&
                          props.task.isSeries() && (
                            <PivotItem key="Workflows" itemKey="Workflows" headerText={t('task:Pivot.Workflows')} />
                          )}
                        {(!props.task.taskTypeId || props.task.taskTypeId === 0 || !props.hasFormFeature) && (
                          <PivotItem key="Checklist" itemKey="Checklist" headerText={t('task:Pivot.Checklist')} />
                        )}
                        <PivotItem key="Context" itemKey="Context" headerText={t('task:Pivot.Context')} />
                        <PivotItem key="Resources" itemKey="Resources" headerText={t('task:Pivot.Resources')} />
                        {props.task.commentTrailId > 0 && (
                          <PivotItem key="Activities" itemKey="Activities" headerText={t('task:Pivot.Activities')} />
                        )}
                        {(props.task.isSeries() || (props.task.isInstance() && props.task.taskMasterId)) && (
                          <PivotItem key="Instances" itemKey="Instances" headerText={t('task:Pivot.Instances')} />
                        )}
                        {props.task.auditTrailId > 0 && (
                          <PivotItem key="Audit" itemKey="Audit" headerText={t('task:Pivot.Audit')} />
                        )}
                      </Pivot>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                {props.selectedPivot === 'TaskDetails' && (
                  <Fragment>
                    <Stack.Item grow style={{ height: appContext.isMobileView ? '40vh' : 0 }}>
                      {getTaskDetails()}
                    </Stack.Item>
                  </Fragment>
                )}
                <TaskTypeForm
                  isOpen={props.selectedPivot === 'TaskType'}
                  task={props.task}
                  links={props.links}
                  compact={true}
                  compactTaskView={props.compactView}
                  readonly={props.task.isCompleted() || props.task.isSeries()} //cannot fill KPI data on the series
                  onUpdateData={props.onUpdateKPIData}
                  onSetData={props.onSetKPIData}
                  onUpdateTaskForForm={props.onUpdateTaskForForm}
                  addLinks={props.addLinkToTaskState}
                  setLinks={props.setLinkToTaskState}
                  removeLink={props.removeLinkFromTaskState}
                  showErrors={props.showFormErrors}
                  onValidate={props.onValidateForm}
                  formMode={props.formMode}
                  onExitFormMode={() => props.setFormMode(false)}
                  onClickFinish={onClickFinish}
                ></TaskTypeForm>
                {props.selectedPivot === 'Workflows' && <TaskWorkflows task={props.task} />}
                {props.selectedPivot === 'Checklist' && (
                  <Fragment>
                    <Stack.Item styles={globalStackItemStylesPaddingSmall}>
                      <ChecklistProgress
                        totalChecks={props.task.checkList.items ? props.task.checkList.items.length : 0}
                        completedChecks={props.task.checkList.getCompletedCheckCount()}
                        followUp={props.task.followUp ? true : false}
                      />
                    </Stack.Item>
                    <Stack.Item grow>
                      <CheckList
                        allowFill={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistFill,
                          appContext,
                        )}
                        allowEdit={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistEdit,
                          appContext,
                        )}
                        checkList={props.task.checkList}
                        updateCheckList={onUpdateCheckList}
                        onComment={addCommentFromCheckList}
                      />
                    </Stack.Item>
                    {props.task.taskId !== -1 && props.canUpdate && (
                      <Stack.Item style={{ paddingLeft: '5px' }}>
                        <CheckListFollowUp followUp={props.task.followUp} toggle={onToggleTaskFollowUp} />
                      </Stack.Item>
                    )}
                  </Fragment>
                )}
                {props.selectedPivot === 'Resources' && (
                  <TaskLinkList
                    taskLinks={props.links}
                    task={props.task}
                    addLinks={props.addLinkToTaskState}
                    setLinks={props.setLinkToTaskState}
                    removeLink={props.removeLinkFromTaskState}
                    navigateExternal={props.navigateExternal}
                  />
                )}
                {props.selectedPivot === 'Activities' && (
                  <Stack.Item grow>
                    <ActivityTrail
                      auditTrailId={props.task.auditTrailId}
                      commentTrailId={props.task.commentTrailId}
                      taskId={props.task.taskId}
                      authSchemaIds={[props.task.authSchemaId]}
                    />
                  </Stack.Item>
                )}
                {props.selectedPivot === 'Instances' && (
                  <Stack.Item grow>
                    <TaskInstanceTab
                      taskId={
                        props.task.isInstance()
                          ? props.task.taskMasterId
                            ? props.task.taskMasterId
                            : props.task.taskId
                          : props.task.taskId
                      }
                      activeTaskId={props.task.isInstance() ? props.task.taskId : undefined}
                      windowLevel={props.windowLevel}
                      navigateExternal={props.navigateExternal}
                    />
                  </Stack.Item>
                )}
                {props.selectedPivot === 'Audit' && (
                  <Stack.Item grow>
                    <AuditTrail auditTrailId={props.task.auditTrailId} />
                  </Stack.Item>
                )}
                {props.selectedPivot === 'Context' && (
                  <Stack.Item grow>
                    <TaskContext
                      task={props.task}
                      updateTaskForForm={props.onUpdateTaskForForm}
                      updateTask={props.onUpdateTask}
                      allowEdit={canUpdateTaskField(
                        props.task,
                        props.canUpdate,
                        TaskFieldTypes.ChecklistEdit,
                        appContext,
                      )}
                      windowLevel={props.windowLevel}
                      navigateExternal={props.navigateExternal}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Separator styles={{ root: { height: 1 } }} />
        </Stack.Item>
        <Stack.Item>{footer}</Stack.Item>
      </Stack>
      <DialogConfirmDelete
        onNo={() => {
          setShowRemoveDialog(false);
        }}
        onYes={() => {
          setShowRemoveDialog(false);
          props.onRemove();
        }}
        title={props.task.isSeries() ? t('task:Template.RemoveDialogTitle') : t('task:Remove.DialogTitle')}
        subText={props.task.isSeries() ? t('task:Template.RemoveDialogSubText') : t('task:Remove.DialogSubTitle')}
        hidden={!showRemoveDialog}
        confirmText={!props.task.isSeries() ? t('task:Remove.RemoveDialogConfirmText') : undefined}
      />
      <DialogYesNo
        onNo={() => {
          setShowCancelDialog(false);
        }}
        onYes={() => {
          setShowCancelDialog(false);

          props.onCancel();
        }}
        title={t('task:Cancel.DialogTitle')}
        subText={t('task:Cancel.DialogSubTitle')}
        hidden={!showCancelDialog}
      />
      <DialogOk
        onOk={() => {
          setShowNoWorkflowsToRunDialog(false);
        }}
        title={t('task:Workflows.Dialogs.NoWorkflowsToRun.Title')}
        subText={t('task:Workflows.Dialogs.NoWorkflowsToRun.SubText')}
        hidden={!showNoWorkflowsToRunDialog}
      />
      <TaskWorkflowDetailsModal
        hidden={!showWorkflowDetails}
        task={props.task}
        onClose={() => {
          if (cannotSaveTask()) props.reload();
          setShowWorkflowDetails(false);
        }}
      />
    </Modal>
  );
};

export default MonitoringTask;
