import { Checkbox, DetailsListLayoutMode, IColumn, Label, Stack } from '@fluentui/react';
import { SelectionMode } from '@fluentui/utilities';
import AppContext from 'App/AppContext';
import AddEditRemoveButtons from 'components/Buttons/AddEditRemoveButtons';
import UserRoleGroupPicker, {
  getUserRoleGroupItem,
  renderUserRoleGroupItem,
} from 'components/Pickers/UserRoleGroupPicker';
import BaseTableList from 'components/TableList/BaseTableList';
import ValidationIcon from 'components/Utils/ValidationIcon';
import { AuthSchemaLine } from 'models/auth/authSchemaLine';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRandomId } from 'utils/string';

interface IAuthSchemaLineEditorProps {
  lines: AuthSchemaLine[];
  readonly?: boolean;
  onUpdate?: (lines: AuthSchemaLine[]) => void;
}

export const AuthSchemaLineEditor = (props: IAuthSchemaLineEditorProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'adminAuth']);
  const [selectedLines, setSelectedLines] = useState<AuthSchemaLine[]>([]);
  const [selectionResetKey, setSelectionResetKey] = useState<string>(getRandomId());

  //
  // Columns
  //
  const getColumns = (): IColumn[] => {
    return [
      {
        key: 'member',
        name: t('adminAuth:TabPermissions.LineEditor.Columns.Member'),
        minWidth: 200,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: 'op_read',
        name: t('adminAuth:TabPermissions.LineEditor.Columns.OpRead'),
        minWidth: 80,
        maxWidth: 80,
      },
      {
        key: 'op_update',
        name: t('adminAuth:TabPermissions.LineEditor.Columns.OpUpdate'),
        minWidth: 80,
        maxWidth: 80,
      },
      {
        key: 'op_delete',
        name: t('adminAuth:TabPermissions.LineEditor.Columns.OpDelete'),
        minWidth: 80,
        maxWidth: 80,
      },
    ];
  };

  const [columns, setColumns] = useState<IColumn[]>(getColumns());

  const onRenderItemColumn = (item?: AuthSchemaLine, column?: IColumn | undefined): JSX.Element | null => {
    if (!item || !column) return null;

    switch (column.key) {
      case 'member':
        const member = item.getMember(appContext);

        if (props.readonly && member) {
          const item = getUserRoleGroupItem(member);

          return renderUserRoleGroupItem(item, true, undefined, true);
        } else {
          return (
            <UserRoleGroupPicker
              allowedItems={[]}
              itemsToExclude={props.lines.map((l) => l.getMember(appContext))}
              selectedItems={member ? [member] : []}
              onSelect={(items) => {
                if (items.length === 0) {
                  item.setMember(undefined);
                } else {
                  item.setMember(items[0]);
                }
                onUpdate(item);
              }}
              itemLimit={1}
              onRemove={() => {
                item.setMember(undefined);
                onUpdate(item);
              }}
              allowUsers={true}
              allowGroups={true}
              allowRoles={true}
            />
          );
        }
      case 'op_read':
        if (props.readonly) {
          return <ValidationIcon status={true} />;
        } else {
          return <Checkbox checked={true} disabled={true} />;
        }
      case 'op_update':
        if (props.readonly) {
          return <ValidationIcon status={item.opUpdate} />;
        } else {
          return (
            <Checkbox
              checked={item.opUpdate}
              onChange={(ev, checked) => {
                item.opUpdate = checked ?? false;
                onUpdate(item);
              }}
            />
          );
        }
      case 'op_delete':
        if (props.readonly) {
          return <ValidationIcon status={item.opDelete} />;
        } else {
          return (
            <Checkbox
              checked={item.opDelete}
              onChange={(ev, checked) => {
                item.opDelete = checked ?? false;
                onUpdate(item);
              }}
            />
          );
        }
    }

    return null;
  };

  //
  // CRUD
  //
  const onAdd = () => {
    if (!props.onUpdate) return;
    const newLine = new AuthSchemaLine();
    props.onUpdate([newLine, ...props.lines]);
  };

  const onUpdate = (line: AuthSchemaLine) => {
    if (!props.onUpdate) return;
    props.onUpdate(props.lines.map((l) => (l.id === line.id ? line.clone() : l)));
  };

  const onRemove = (lines: AuthSchemaLine[]) => {
    if (!props.onUpdate) return;
    props.onUpdate(props.lines.filter((l) => !lines.some((line) => line.id === l.id)));
    setSelectionResetKey(getRandomId());
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill>
      {!props.readonly && (
        <Stack.Item>
          <Label>{t('adminAuth:TabPermissions.LineEditor.Label')}</Label>
        </Stack.Item>
      )}
      {!props.readonly && (
        <Stack.Item>
          <AddEditRemoveButtons
            onClickNew={onAdd}
            onClickRemove={() => onRemove(selectedLines)}
            removeDisabled={selectedLines.length === 0}
            noLeftPadding
          />
        </Stack.Item>
      )}
      <Stack.Item grow>
        <BaseTableList
          items={props.lines}
          compact={props.readonly}
          columns={columns}
          selectionMode={props.readonly ? SelectionMode.none : SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.justified}
          onRenderCol={onRenderItemColumn}
          setColumns={setColumns}
          disableSort={true}
          selectionResetKey={selectionResetKey}
          updateSelection={setSelectedLines}
          disableEmptyState={true}
        />
      </Stack.Item>
    </Stack>
  );
};
