import {
  IStackStyles,
  IStackTokens,
  IStackItemStyles,
  ITextStyles,
  ILayerProps,
  IIconProps,
  ITextFieldStyles,
  IComboBoxStyles,
  ISearchBoxStyles,
  ITheme,
  IDetailsColumnStyles,
  Theme,
} from '@fluentui/react';
import { IAppContext } from 'App/AppContext';

//
// App global
//
export const globalFontBoldWeight: string = '600';
export const globalLayerRightSideBarProps: ILayerProps = { hostId: 'layerHostRightSideBar' };
export const globalBoxShadow: string = '0 1px 2px 0 #c9c9c9';
export const globalBoxBorder: string = '1px solid #c9c9c9';
export const globalBoxBorderRadius: string = '5px';
//
// Scene global
//
export const globalPaddingScene: number = 20;

export const globalSceneBarItemStyles: IStackItemStyles = {
  root: {
    height: 44,
    justifyContent: 'center',
  },
};

//
// Stacks styles
//
export const globalStackStylesHeight100: IStackStyles = {
  root: {
    height: '100%',
  },
};

export const globalStackStylesPaddingScene: IStackStyles = {
  root: {
    paddingLeft: globalPaddingScene,
    paddingRight: globalPaddingScene,
  },
};

export const globalStackStylesHeight100PaddingScene: IStackStyles = {
  root: {
    height: '100%',
    paddingLeft: globalPaddingScene,
    paddingRight: globalPaddingScene,
  },
};

export const globalStackStylesHeight100PaddingSmall: IStackStyles = {
  root: {
    height: '100%',
    padding: 10,
  },
};

export const globalStackStylesHeight100PaddingMedium: IStackStyles = {
  root: {
    height: '100%',
    padding: 20,
  },
};

export const globalStackStylesNoHeightPaddingSmall: IStackStyles = {
  root: {
    padding: 10,
  },
};

export const globalStackStylesBottomSmall: IStackStyles = {
  root: {
    paddingBottom: 10,
  },
};

export const globalStackStylesResourcePanel: IStackStyles = {
  root: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 10,
  },
};

export const globalStackStylesPanel: IStackStyles = {
  root: {
    paddingTop: 30,
    paddingBottom: 10,
  },
};

export const globalStackStylesPanelFooter: IStackStyles = {
  root: {
    padding: 30,
  },
};

//
// Card styles
//
export const globalStackStylesCard: IStackStyles = {
  root: {
    height: '100%',
    paddingLeft: 15,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

export const globalStackItemStylesCard: IStackItemStyles = {
  root: {
    padding: 0,
    margin: 0,
  },
};

export const globalStackTokensGapCard: IStackTokens = {
  childrenGap: 0,
};

export const globalTextStylesCard: ITextStyles = {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export const globalTextStylesCardRed: ITextStyles = {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    color: 'red',
  },
};

export const globalTextStylesCardBold: ITextStyles = {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: globalFontBoldWeight,
  },
};

export const globalTextStylesCardItalic: ITextStyles = {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    fontStyle: 'italic',
  },
};

export const globalTextStylesNoWrap: ITextStyles = {
  root: {
    whiteSpace: 'nowrap',
  },
};

//
// Stacks item styles
//
export const globalStackItemStylesPaddingScene: IStackItemStyles = {
  root: {
    paddingLeft: globalPaddingScene,
    paddingRight: globalPaddingScene,
  },
};

export const globalStackItemStylesScroll: IStackItemStyles = {
  root: {
    position: 'relative',
  },
};

export const globalStackItemStylesPaddingSceneScroll: IStackItemStyles = {
  root: {
    paddingLeft: globalPaddingScene,
    paddingRight: globalPaddingScene,
    position: 'relative',
  },
};

export const getGlobalStackItemStylesPaddingSceneScrollMinHeight = (minHeight: number): IStackItemStyles => {
  return {
    root: {
      minHeight: minHeight,
      paddingLeft: globalPaddingScene,
      paddingRight: globalPaddingScene,
      position: 'relative',
    },
  };
};

export const globalStackItemStylesPaddingSmall: IStackItemStyles = {
  root: {
    paddingLeft: 10,
    paddingRight: 10,
  },
};

//
// Stack tokens
//
export const globalStackTokensGapExtraSmall: IStackTokens = {
  childrenGap: 5,
};

export const globalStackTokensGapSmall: IStackTokens = {
  childrenGap: 10,
};

export const globalStackTokensGapMedium: IStackTokens = {
  childrenGap: 20,
};

export const globalStackTokensGapLarge: IStackTokens = {
  childrenGap: 30,
};

export const globalStackTokensGapLargePlus5: IStackTokens = {
  childrenGap: 35,
};

export const globalStackTokensPropertySet: IStackTokens = {
  childrenGap: '5 35',
};

export const globalStackItemStylesPaddingSceneWithFixedHeight = (fixedHeight: number): IStackItemStyles => {
  return {
    root: {
      paddingLeft: globalPaddingScene,
      paddingRight: globalPaddingScene,
      height: fixedHeight,
    },
  };
};

//
// Searchbox styles
//
export const globalSearchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 200 } };

//
// Combobox styles
//
export const globalComboContainerHeight: Partial<IComboBoxStyles> = {
  optionsContainer: {
    maxHeight: 350,
  },
};

//
// Text styles
//
export const globalTextStylesBold: ITextStyles = {
  root: {
    fontWeight: globalFontBoldWeight,
  },
};

export const globalTextStylesBoldBlack: ITextStyles = {
  root: {
    fontWeight: globalFontBoldWeight,
    color: 'black',
  },
};

export const globalTextStylesBoldWhite: ITextStyles = {
  root: {
    fontWeight: globalFontBoldWeight,
    color: 'white',
  },
};

export const globalTextStylesBoldBlue = (theme: Theme, width?: number): ITextStyles => {
  return {
    root: {
      fontWeight: globalFontBoldWeight,
      color: theme.palette.themePrimary,
      width: width,
    },
  };
};

export const globalTextStylesPaddingScene: ITextStyles = {
  root: {
    paddingLeft: globalPaddingScene,
    paddingRight: globalPaddingScene,
  },
};

export const globalTextStylesPaddingSceneBold: ITextStyles = {
  root: {
    paddingLeft: globalPaddingScene,
    paddingRight: globalPaddingScene,
    fontWeight: globalFontBoldWeight,
  },
};

export const globalTextStylesPaddingSmallBold: ITextStyles = {
  root: {
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: globalFontBoldWeight,
  },
};

export const globalTextStylesBoldPaddingSmall: ITextStyles = {
  root: {
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: globalFontBoldWeight,
  },
};

export const globalTextStylesPaddingSmall: ITextStyles = {
  root: {
    paddingLeft: 10,
    paddingRight: 10,
  },
};

export const globalTextStylesPaddingLabelPanel: ITextStyles = {
  root: {
    paddingBottom: 6,
    fontWeight: globalFontBoldWeight,
  },
};

export const globalTextStylesMarginTopLabelPanel: ITextStyles = {
  root: {
    marginTop: 10,
  },
};

export const globalTextStylesError: ITextStyles = {
  root: {
    color: '#BC1E1E',
  },
};

export const globalTextStylesValidationError: ITextStyles = {
  root: {
    color: '#BC1E1E',
    fontSize: 12,
  },
};

export const globalTextStylesDisabled: ITextStyles = {
  root: {
    color: '#909090',
  },
};

export const globalTextStylesCategory: ITextStyles = {
  root: {
    color: '#909090',
  },
};

//
// Text field styles
//
export const globalTextBig: Partial<ITextStyles> = {
  root: {
    fontWeight: globalFontBoldWeight,
    fontSize: 20,
    paddingLeft: 0,
    lineHeight: '150%',
    maxHeight: 100,
    overflowY: 'scroll',
  },
};

export const globalTextFieldBig: Partial<ITextFieldStyles> = {
  field: {
    fontWeight: globalFontBoldWeight,
    fontSize: 20,
    paddingLeft: 0,
    lineHeight: '150%',
    maxHeight: 100,
    overflowY: 'scroll',
  },
  fieldGroup: { fontWeight: globalFontBoldWeight, fontSize: 20 },
};

export const getGlobalTextFieldDisabled = (
  appContext: IAppContext,
  theme: ITheme,
  width?: number,
): Partial<ITextFieldStyles> => {
  return {
    root: {
      width: width,
    },
    fieldGroup: {
      background: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
    },
  };
};

//
// DetailsList styles
//
export const columnCenterStyle: Partial<IDetailsColumnStyles> = {
  root: {
    textAlign: 'center',
  },
};

//
// IconButtons
//
export const acceptIcon: IIconProps = { iconName: 'Accept' };
export const approvalIcon: IIconProps = { iconName: 'DocumentApproval' };
export const assetIcon: IIconProps = { iconName: 'CompanyDirectory' };
export const backIcon: IIconProps = { iconName: 'Back' };
export const cancelIcon: IIconProps = { iconName: 'Cancel' };
export const checkIcon: IIconProps = { iconName: 'CheckMark' };
export const checkSmallIcon: IIconProps = { iconName: 'StatusCircleCheckmark' };
export const collapseIcon: IIconProps = { iconName: 'CaretHollowMirrored' };
export const expandIcon: IIconProps = { iconName: 'CaretHollow' };
export const commentIcon: IIconProps = { iconName: 'Comment' };
export const configIcon: IIconProps = { iconName: 'ConfigurationSolid' };
export const controlIcon: IIconProps = { iconName: 'Bullseye' };
export const copyIcon: IIconProps = { iconName: 'Copy' };
export const deleteIcon: IIconProps = { iconName: 'Delete' };
export const discardIcon: IIconProps = { iconName: 'Undo' };
export const documentIcon: IIconProps = { iconName: 'TextDocument' };
export const downArrowIcon: IIconProps = { iconName: 'ChevronDown' };
export const editIcon: IIconProps = { iconName: 'Edit' };
export const errorIcon: IIconProps = { iconName: 'StatusErrorFull' };
export const errorSmallIcon: IIconProps = { iconName: 'StatusCircleErrorX' };
export const eventIcon: IIconProps = { iconName: 'Event' };
export const extLinkIcon: IIconProps = { iconName: 'OpenInNewWindow' };
export const feedbackIcon: IIconProps = { iconName: 'Feedback' };
export const filterICon: IIconProps = { iconName: 'Filter' };
export const formIcon: IIconProps = { iconName: 'EntitlementPolicy' };
export const groupIcon: IIconProps = { iconName: 'Group' };
export const guestIcon: IIconProps = { iconName: 'UserOptional' };
export const helpIcon: IIconProps = { iconName: 'Help' };
export const homeIcon: IIconProps = { iconName: 'Home' };
export const incidentIcon: IIconProps = { iconName: 'IncidentTriangle' };
export const infoIcon: IIconProps = { iconName: 'Info' };
export const kpiIcon: IIconProps = { iconName: 'Chart' };
export const leftArrowIcon: IIconProps = { iconName: 'ChevronLeft' };
export const linkIcon: IIconProps = { iconName: 'Link' };
export const listTypeCustomListIcon: IIconProps = { iconName: 'customList' };
export const listTypeDocLibraryIcon: IIconProps = { iconName: 'DocLibrary' };
export const listTypeSitePageLibraryIcon: IIconProps = { iconName: 'Page' };
export const listTypeWebURLIcon: IIconProps = { iconName: 'Globe' };
export const libraryIcon: IIconProps = { iconName: 'DocumentSet' };
export const moreIcon: IIconProps = { iconName: 'More' };
export const newIcon: IIconProps = { iconName: 'Add' };
export const o365Icon: IIconProps = { iconName: 'WaffleOffice365' };
export const objectiveIcon: IIconProps = { iconName: 'VersionControlPush' };
export const openExtIcon: IIconProps = { iconName: 'OpenInNewTab' };
export const orgIcon: IIconProps = { iconName: 'Org' };
export const outlookIcon: IIconProps = { iconName: 'OutlookLogo' };
export const pendingIcon: IIconProps = { iconName: 'CircleRing' };
export const pinIcon: IIconProps = { iconName: 'Pin' };
export const processIcon: IIconProps = { iconName: 'SplitObject' };
export const refreshIcon: IIconProps = { iconName: 'Refresh' };
export const rejectIcon: IIconProps = { iconName: 'CalculatorMultiply' };
export const removeIcon: IIconProps = { iconName: 'Remove' };
export const renameIcon: IIconProps = { iconName: 'Rename' };
export const replaceIcon: IIconProps = { iconName: 'SingleColumnEdit' };
export const resourcePanelIcon: IIconProps = { iconName: 'Relationship' };
export const rightArrowIcon: IIconProps = { iconName: 'ChevronRight' };
export const riskIcon: IIconProps = { iconName: 'Warning12' };
export const saveIcon: IIconProps = { iconName: 'Save' };
export const selectionIcon: IIconProps = { iconName: 'MultiSelect' };
export const setOwnerIcon: IIconProps = { iconName: 'FabricUserFolder' };
export const settingsIcon: IIconProps = { iconName: 'Settings' };
export const shareIcon: IIconProps = { iconName: 'Share' };
export const sharepointIcon: IIconProps = { iconName: 'SharepointLogo' };
export const standardsIcon: IIconProps = { iconName: 'MapLayers' };
export const permissionsIcon: IIconProps = { iconName: 'Permissions' };
export const tagIcon: IIconProps = { iconName: 'Tag' };
export const taskIcon: IIconProps = { iconName: 'TaskLogo' };
export const taskStartIcon: IIconProps = { iconName: 'ReminderTime' };
export const teamsIcon: IIconProps = { iconName: 'TeamsLogo' };
export const templateIcon: IIconProps = { iconName: 'scopeTemplate' };
export const themeIcon: IIconProps = { iconName: 'ButtonControl' };
export const unPinIcon: IIconProps = { iconName: 'Unpin' };
export const unknownIcon: IIconProps = { iconName: 'UnknownSolid' };
export const upArrowIcon: IIconProps = { iconName: 'ChevronUp' };
export const uploadIcon: IIconProps = { iconName: 'Upload' };
export const userIcon: IIconProps = { iconName: 'UserFollowed' };
export const validIcon: IIconProps = { iconName: 'SkypeCircleCheck' };
export const validateAllIcon: IIconProps = { iconName: 'ReleaseGateCheck' };
export const validateIcon: IIconProps = { iconName: 'ReleaseGate' };
export const videoIcon: IIconProps = { iconName: 'MSNVideosSolid' };
export const viewIcon: IIconProps = { iconName: 'View' };
export const webIcon: IIconProps = { iconName: 'World' };
export const workflowIcon: IIconProps = { iconName: 'WorkFlow' };
export const applyIcon: IIconProps = { iconName: 'DependencyAdd' };
