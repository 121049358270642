import Asset from 'models/asset/asset';
import Task from '../models/tasks/task';
import Control from 'models/control';
import Objective from 'models/objective/objective';
import Process from 'models/process/process';
import Theme from 'models/theme';
import KPI from 'models/kpi/kpi';
import Risk from 'models/risk';
import { SearchResult } from 'models/search/SearchResult';

export const getUniqueTagIds = (
  items: Task[] | Asset[] | Risk[] | Process[] | Objective[] | Control[] | Theme[] | KPI[] | SearchResult[],
): number[] => {
  let ids: number[] = [];

  for (let idx = 0; idx < items.length; idx++) {
    const item = items[idx];
    if (item.tagIds) {
      ids.push(...item.tagIds);
    }
  }

  ids = [...new Set(ids)];

  return ids;
};
