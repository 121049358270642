import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Stack,
  Separator,
  Nav,
  INavLinkGroup,
  Text,
  Label,
  Checkbox,
  ComboBox,
  Spinner,
  SpinnerSize,
  Dropdown,
  IDropdownOption,
} from '@fluentui/react';
import {
  globalComboContainerHeight,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalTextStylesError,
  groupIcon,
  guestIcon,
  permissionsIcon,
  standardsIcon,
  tagIcon,
  taskStartIcon,
  userIcon,
  workflowIcon,
} from 'globalStyles';
import { useTranslation } from 'react-i18next';
import GenericModal from 'components/Dialogs/GenericModal';
import React, { FormEvent, useEffect } from 'react';
import AppContext from 'App/AppContext';
import AppError from 'utils/appError';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import Tag from 'models/tag';
import Group from 'models/group';
import GroupPicker from 'components/Pickers/GroupPicker';
import { apiCreateTag } from 'services/Api/tagService';
import { apiRequest } from 'services/Auth/authConfig';
import { getEntityStatusTextList } from 'globalFunctions';
import { EntityStatus } from 'models/entityStatus';
import Norm from 'models/norm';
import { KeyValueTag } from 'components/Tags/KeyValueTag';
import { AuthSchema } from 'models/auth/authSchema';
import AuthSchemaPicker from 'components/Pickers/AuthSchemaPicker';
import Task from 'models/tasks/task';
import { OwnerPicker } from 'components/Pickers/OwnerPicker';
import { IOwner, Owner } from 'models/owner';
import { TaskAssignment } from 'components/Tasks/TaskTypes/Details/TaskAssignment';
import { getObjectiveStatusTextList, ObjectiveStatus } from 'models/objective/objective';
import Control, { ApplicabilityReasons } from 'models/control';
import OutOfScopeDialog from 'components/Control/OutOfScopeDialog';
import RichTextReadOnly from 'components/Text/RichTextReadOnly';
import { getRiskStatusTextList } from 'models/riskState';

export enum BulkActionType {
  None = 0,
  Tag = 1,
  Owner = 2,
  Group = 3,
  Assignee = 4,
  StartDate = 5,
  Status = 6,
  Standards = 7,
  Permissions = 8,
  ObjectiveStatus = 9,
  Applicability = 10,
  RiskStatus = 11,
}

//
// Actions
//
export interface IBulkAction {
  actionType: BulkActionType;
}

export interface IBulkActionTag extends IBulkAction {
  tagsToAdd: Tag[];
  tagsToRemove: Tag[];
}

export interface IBulkActionOwner extends IBulkAction {
  newOwner: Owner | undefined;
}

export interface IBulkActionGroup extends IBulkAction {
  newGroup: Group | undefined;
}

export interface IBulkActionAssignee extends IBulkAction {
  newAuthSchemaId: number | undefined;
  newAssigneeId: string | undefined;
}

export interface IBulkActionStatus extends IBulkAction {
  newStatus: EntityStatus;
}

export interface IBulkActionObjectiveStatus extends IBulkAction {
  newStatus: ObjectiveStatus;
}

export interface IBulkActionStandards extends IBulkAction {
  normsToAdd: Norm[];
  normsToRemove: Norm[];
}

export interface IBulkActionPermissions extends IBulkAction {
  newPermission: AuthSchema | undefined;
}

export interface IBulkActionApplicability extends IBulkAction {
  newApplicabilities: ApplicabilityReasons[] | undefined;
  newOutOfScopeReason: string | undefined;
}

export interface IBulkActionRiskStatus extends IBulkAction {
  newStatus: number | undefined;
}

//
// Action states
//
export interface IBulkActionState {}

export interface IBulkActionStateTags extends IBulkActionState {
  tags: Tag[];
  checkboxState: ICheckboxState[];
}

export interface IBulkActionStateStandards extends IBulkActionState {
  checkboxState: ICheckboxState[];
}

export enum ICheckboxState {
  unchecked = 0,
  checked = 1,
  indetermined = 2,
}

//
// Component state
//
interface IBulkActionsModalProps {
  title: string;
  itemCount: number;
  noAuthCount: number;
  isOpen: boolean;
  actionTypes: BulkActionType[];
  disableRemoveOwner?: boolean;
  disallowOwnerRoles?: boolean;
  onClose: () => void;
  onExecute: (actions: IBulkAction[]) => void;
  onGetState?: (actionType: BulkActionType) => Promise<IBulkActionState | undefined>;
}

const BulkActionsModal = (props: IBulkActionsModalProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = React.useContext(AppContext);
  const [selectedAction, setSelectedAction] = React.useState<BulkActionType | undefined>(undefined);
  const [selectedTagsToAdd, setSelectedTagsToAdd] = React.useState<Tag[]>([]);
  const [selectedTagsToRemove, setSelectedTagsToRemove] = React.useState<Tag[]>([]);
  const [selectedNewOwner, setSelectedNewOwner] = React.useState<IOwner | undefined>(undefined);
  const [removeOwner, setRemoveOwner] = React.useState<boolean>(false);
  const [selectedNewGroup, setSelectedNewGroup] = React.useState<Group | undefined>(undefined);
  const [removeGroup, setRemoveGroup] = React.useState<boolean>(false);
  const [dummyTask, setDummyTask] = React.useState<Task>(new Task());
  const [removeAssignee, setRemoveAssignee] = React.useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = React.useState<EntityStatus | undefined>(undefined);
  const [selectedObjectiveStatus, setSelectedObjectiveStatus] = React.useState<ObjectiveStatus | undefined>(undefined);
  const [selectedApplicability, setSelectedApplicability] = React.useState<ApplicabilityReasons[] | undefined>(
    undefined,
  );
  const [currentStates, setCurrentStates] = React.useState<Record<number, IBulkActionState>>({ 0: {} });
  const [selectedStandardsToAdd, setSelectedStandardsToAdd] = React.useState<Norm[]>([]);
  const [selectedStandardsToRemove, setSelectedStandardsToRemove] = React.useState<Norm[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedNewPermission, setSelectedNewPermission] = React.useState<AuthSchema | undefined>(undefined);
  const [removePermission, setRemovePermission] = React.useState<boolean>(false);
  const [showOutOfScopeDialog, setShowOutOfScopeDialog] = React.useState<boolean>(false);
  const [selectedOutOfScopeReason, setSelectedOutOfScopeReason] = React.useState<string | undefined>(undefined);
  const [selectedRiskStatus, setSelectedRiskStatus] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    if (props.isOpen) {
      const groups = getNavGroups();
      if (groups[0].links.length === 1) {
        loadSelectedAction(Number.parseInt(groups[0].links[0].key ?? '0'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const getNavGroups = (): INavLinkGroup[] => {
    const navLinks: INavLinkGroup[] = [];

    const mainGroup: INavLinkGroup = { links: [] };

    if (props.actionTypes.includes(BulkActionType.Tag)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Tags'),
            url: '',
            key: BulkActionType.Tag.toString(),
            icon: tagIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Tag),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.Owner)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Owner'),
            url: '',
            key: BulkActionType.Owner.toString(),
            icon: guestIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Owner),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.Assignee)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Assignee'),
            url: '',
            key: BulkActionType.Assignee.toString(),
            icon: userIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Assignee),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.StartDate)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.StartDate'),
            url: '',
            key: BulkActionType.StartDate.toString(),
            icon: taskStartIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.StartDate),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.Status)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Status'),
            url: '',
            key: BulkActionType.Status.toString(),
            icon: workflowIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Status),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.ObjectiveStatus)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Status'),
            url: '',
            key: BulkActionType.ObjectiveStatus.toString(),
            icon: workflowIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.ObjectiveStatus),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.RiskStatus)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Status'),
            url: '',
            key: BulkActionType.RiskStatus.toString(),
            icon: workflowIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.RiskStatus),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.Applicability)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Applicability'),
            url: '',
            key: BulkActionType.Applicability.toString(),
            icon: workflowIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Applicability),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.Standards)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Standards'),
            url: '',
            key: BulkActionType.Standards.toString(),
            icon: standardsIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Standards),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.Group)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Group'),
            url: '',
            key: BulkActionType.Group.toString(),
            icon: groupIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Group),
          },
        ],
      );
    }

    if (props.actionTypes.includes(BulkActionType.Permissions)) {
      mainGroup.links.push(
        ...[
          {
            name: t('translation:BulkActionsModal.Actions.Permissions'),
            url: '',
            key: BulkActionType.Permissions.toString(),
            icon: permissionsIcon.iconName,
            onclick: () => setSelectedAction(BulkActionType.Permissions),
          },
        ],
      );
    }

    navLinks.push(mainGroup);

    return navLinks;
  };

  //
  // Actions
  //
  const getActionContent = (
    action: BulkActionType | undefined,
    states: Record<number, IBulkActionState>,
  ): JSX.Element | null => {
    try {
      if (!action) return null;
      let content: JSX.Element | null;

      switch (action) {
        case BulkActionType.Tag:
          content = getActionContentTag(states);
          break;
        case BulkActionType.Owner:
          content = getActionContentOwner();
          break;
        case BulkActionType.Group:
          content = getActionContentGroup();
          break;
        case BulkActionType.Assignee:
          content = getActionContentAssignee();
          break;
        case BulkActionType.Status:
          content = getActionContentStatus();
          break;
        case BulkActionType.ObjectiveStatus:
          content = getActionContentObjectiveStatus();
          break;
        case BulkActionType.Standards:
          content = getActionContentStandards(states);
          break;
        case BulkActionType.Permissions:
          content = getActionContentPermissions();
          break;
        case BulkActionType.Applicability:
          content = getActionContentApplicability();
          break;
        case BulkActionType.RiskStatus:
          content = getActionContentRiskStatus();
          break;
        default:
          throw new AppError('Not implemented');
      }

      if (content) {
        return content;
      } else {
        throw new AppError('State not provided');
      }
    } catch (err) {
      const appErr = err as AppError;

      return <Text styles={globalTextStylesError}>{appErr.message}</Text>;
    }
  };

  const getActionContentStandards = (states: Record<number, IBulkActionState>): JSX.Element | null => {
    const state = states[BulkActionType.Standards] as IBulkActionStateStandards;
    if (!state || !state.checkboxState || state.checkboxState.length < appContext.globalDataCache.norms.items.length) {
      return null;
    }

    return (
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Label>{t('translation:BulkActionsModal.Standards.Label')}</Label>
        </Stack.Item>
        <Stack.Item>
          <Stack tokens={globalStackTokensGapSmall}>
            {appContext.globalDataCache.norms.items.map((norm, index) => {
              return (
                <Stack.Item key={norm.normId}>
                  <Checkbox
                    label={norm.name}
                    defaultChecked={state.checkboxState[index] === ICheckboxState.checked}
                    defaultIndeterminate={state.checkboxState[index] === ICheckboxState.indetermined}
                    onChange={(ev, checked) => {
                      const newState = checked ?? false;

                      if (newState && !selectedStandardsToAdd.some((n) => n.normId === norm.normId)) {
                        if (selectedStandardsToRemove.some((n) => n.normId === norm.normId)) {
                          setSelectedStandardsToRemove(
                            selectedStandardsToRemove.filter((n) => n.normId !== norm.normId),
                          );
                        }
                        const newStandardsToAdd = [...selectedStandardsToAdd, norm];
                        setSelectedStandardsToAdd(newStandardsToAdd);
                      } else if (!newState && !selectedStandardsToRemove.some((n) => n.normId === norm.normId)) {
                        if (selectedStandardsToAdd.some((n) => n.normId === norm.normId)) {
                          setSelectedStandardsToAdd(selectedStandardsToAdd.filter((n) => n.normId !== norm.normId));
                        }
                        const newStandardsToRemove = [...selectedStandardsToRemove, norm];
                        setSelectedStandardsToRemove(newStandardsToRemove);
                      }
                    }}
                  />
                </Stack.Item>
              );
            })}
          </Stack>
        </Stack.Item>
      </Stack>
    );
  };

  const getActionContentStatus = (): JSX.Element => {
    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Label>{t('translation:BulkActionsModal.Status.Label')}</Label>
          <ComboBox
            selectedKey={selectedStatus}
            options={getEntityStatusTextList(t)}
            onChange={(ev, option) => {
              setSelectedStatus(option?.key as EntityStatus);
            }}
            allowFreeform={false}
            styles={globalComboContainerHeight}
            scrollSelectedToTop={true}
            useComboBoxAsMenuWidth={true}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getActionContentObjectiveStatus = (): JSX.Element => {
    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Label>{t('translation:BulkActionsModal.Status.Label')}</Label>
          <ComboBox
            selectedKey={selectedObjectiveStatus}
            options={getObjectiveStatusTextList(t)}
            onChange={(ev, option) => {
              setSelectedObjectiveStatus(option?.key as ObjectiveStatus);
            }}
            allowFreeform={false}
            styles={globalComboContainerHeight}
            scrollSelectedToTop={true}
            useComboBoxAsMenuWidth={true}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getActionContentRiskStatus = (): JSX.Element => {
    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Label>{t('translation:BulkActionsModal.Status.Label')}</Label>
          <ComboBox
            selectedKey={selectedRiskStatus}
            options={getRiskStatusTextList(appContext)}
            onChange={(ev, option) => {
              setSelectedRiskStatus(option?.key as number);
            }}
            allowFreeform={false}
            styles={globalComboContainerHeight}
            scrollSelectedToTop={true}
            useComboBoxAsMenuWidth={true}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const updateApplicability = (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption<ApplicabilityReasons> | undefined,
    index?: number | undefined,
  ) => {
    if (option) {
      let newApplicability = [...(selectedApplicability ?? [])];
      if ((option.key as ApplicabilityReasons) === ApplicabilityReasons.OutOfScope) {
        setShowOutOfScopeDialog(true);
      } else {
        //remove not applicable
        newApplicability = newApplicability.filter((c) => c !== ApplicabilityReasons.OutOfScope);
        setSelectedOutOfScopeReason(undefined);
        //add/remove selected options
        if (option.selected) {
          newApplicability.push(option.key as ApplicabilityReasons);
        } else {
          newApplicability = newApplicability.filter((c) => c !== (option.key as ApplicabilityReasons));
        }

        setSelectedApplicability(newApplicability);
      }
    }
  };

  const setOutOfScope = (reason: string | undefined) => {
    setSelectedApplicability([ApplicabilityReasons.OutOfScope]);
    setSelectedOutOfScopeReason(reason);
  };

  const getActionContentApplicability = (): JSX.Element => {
    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Dropdown
            label={t('control:TabDetails.Fields.Applicability.Label')}
            selectedKeys={selectedApplicability}
            multiSelect
            options={Control.getApplicabilityReasonList(t)}
            calloutProps={{ calloutMaxHeight: 300 }}
            onChange={updateApplicability}
            styles={{ root: { minWidth: 200 } }}
          />
        </Stack.Item>
        {selectedOutOfScopeReason && (
          <Stack.Item>
            <RichTextReadOnly>{selectedOutOfScopeReason}</RichTextReadOnly>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const getActionContentTag = (states: Record<number, IBulkActionState>): JSX.Element | null => {
    const state = states[BulkActionType.Tag] as IBulkActionStateTags;
    if (!state || !state.checkboxState || !state.tags || state.checkboxState.length !== state.tags.length) {
      return null;
    }

    const addTagToState = (tag: Tag) => {
      const state = states[BulkActionType.Tag] as IBulkActionStateTags;
      const curIdx = state.tags.findIndex((t) => t.tagId === tag.tagId);
      if (curIdx >= 0) {
        if (state.checkboxState[curIdx] !== ICheckboxState.checked) {
          state.checkboxState[curIdx] = ICheckboxState.checked;
          setSelectedTagsToAdd([...selectedTagsToAdd, tag]);
          setSelectedTagsToRemove(selectedTagsToRemove.filter((s) => s.tagId !== tag.tagId));
        }
      } else {
        tag.usedCount = 0;
        state.tags.push(tag);
        state.checkboxState.push(ICheckboxState.checked);
        setSelectedTagsToAdd([...selectedTagsToAdd, tag]);
      }

      const newStates = { ...states };
      newStates[BulkActionType.Tag] = state;
      setCurrentStates(newStates);
    };

    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Stack>
            {state.tags.map((tag, index) => {
              return (
                <Stack.Item key={tag.tagId}>
                  <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapSmall}>
                    <Stack.Item>
                      <Checkbox
                        checked={
                          !selectedTagsToRemove.some((t) => t.tagId === tag.tagId) &&
                          (state.checkboxState[index] === ICheckboxState.checked ||
                            selectedTagsToAdd.some((t) => t.tagId === tag.tagId))
                        }
                        indeterminate={
                          !selectedTagsToRemove.some((t) => t.tagId === tag.tagId) &&
                          !selectedTagsToAdd.some((t) => t.tagId === tag.tagId) &&
                          state.checkboxState[index] === ICheckboxState.indetermined
                        }
                        onChange={(ev, checked) => {
                          if (checked) {
                            setSelectedTagsToAdd([...selectedTagsToAdd, tag]);
                            setSelectedTagsToRemove(selectedTagsToRemove.filter((s) => s.tagId !== tag.tagId));
                          } else {
                            setSelectedTagsToAdd(selectedTagsToAdd.filter((s) => s.tagId !== tag.tagId));
                            setSelectedTagsToRemove([...selectedTagsToRemove, tag]);
                          }
                        }}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <KeyValueTag tag={tag} />
                    </Stack.Item>
                    <Stack.Item>
                      <Text variant="small">
                        {t('translation:General.List.ItemCount', {
                          count: tag.usedCount,
                        })}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              );
            })}
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Label>{t('translation:BulkActionsModal.Tag.LabelAdd')}</Label>
          <KeyValueTagPicker
            selectedTags={[]}
            onAdd={(tag) => addTagToState(tag)}
            onRemove={() => {}}
            onCreate={(tag) => addTagToState(tag)}
            isLoading={false}
            allowCreate={true}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getActionContentPermissions = (): JSX.Element => {
    return (
      <Stack tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <AuthSchemaPicker
            label={t('translation:BulkActionsModal.Permissions.LabelSet')}
            selectedAuthSchemaId={selectedNewPermission?.authSchemaId}
            onSelect={(authSchema) => {
              if (!authSchema) {
                setSelectedNewPermission(undefined);
              } else {
                setSelectedNewPermission(authSchema);
              }
            }}
            disabled={removePermission}
          />
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={t('translation:BulkActionsModal.Permissions.LabelRemove')}
            checked={removePermission}
            onChange={(ev, checked) => {
              setRemovePermission(checked ?? false);
              setSelectedNewPermission(undefined);
            }}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getActionContentOwner = (): JSX.Element => {
    return (
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Label>{t('translation:BulkActionsModal.Owner.LabelSet')}</Label>
          <OwnerPicker
            selectedItem={selectedNewOwner}
            onSelect={(item, owner) => {
              setSelectedNewOwner(owner);
            }}
            disabled={removeOwner}
            hideLabel={true}
            disallowRoles={props.disableRemoveOwner}
          />
        </Stack.Item>
        {!props.disableRemoveOwner && (
          <Stack.Item>
            <Checkbox
              label={t('translation:BulkActionsModal.Owner.LabelRemove')}
              checked={removeOwner}
              onChange={(ev, checked) => {
                setRemoveOwner(checked ?? false);
                setSelectedNewOwner(undefined);
              }}
            />
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const getActionContentAssignee = (): JSX.Element => {
    return (
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <TaskAssignment
            orgTask={dummyTask.clone()}
            task={dummyTask}
            canUpdate={undefined}
            onUpdate={setDummyTask}
            disabled={removeAssignee}
          />
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={t('translation:BulkActionsModal.Assignee.LabelRemove')}
            checked={removeAssignee}
            onChange={(ev, checked) => {
              setRemoveAssignee(checked ?? false);
              setDummyTask(new Task());
            }}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getActionContentGroup = (): JSX.Element => {
    return (
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Label>{t('translation:BulkActionsModal.Group.LabelSet')}</Label>
          <GroupPicker
            selectedItemId={selectedNewGroup?.id}
            onSelect={(group, removeId) => {
              if (removeId) {
                setSelectedNewGroup(undefined);
              } else {
                setSelectedNewGroup(group);
              }
            }}
            disabled={removeGroup}
          />
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={t('translation:BulkActionsModal.Group.LabelRemove')}
            checked={removeGroup}
            onChange={(ev, checked) => {
              setRemoveGroup(checked ?? false);
              setSelectedNewGroup(undefined);
            }}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getActions = (): IBulkAction[] => {
    const newActions: IBulkAction[] = [];

    if (selectedTagsToAdd.length > 0 || selectedTagsToRemove.length > 0) {
      const newAction: IBulkActionTag = {
        actionType: BulkActionType.Tag,
        tagsToAdd: selectedTagsToAdd,
        tagsToRemove: selectedTagsToRemove,
      };

      newActions.push(newAction);
    }

    if (selectedNewOwner || removeOwner) {
      const newAction: IBulkActionOwner = {
        actionType: BulkActionType.Owner,
        newOwner: new Owner(selectedNewOwner, appContext),
      };

      newActions.push(newAction);
    }

    if (selectedNewGroup || removeGroup) {
      const newAction: IBulkActionGroup = {
        actionType: BulkActionType.Group,
        newGroup: selectedNewGroup,
      };

      newActions.push(newAction);
    }

    if (dummyTask.authSchemaId || dummyTask.userId || removeAssignee) {
      const newAction: IBulkActionAssignee = {
        actionType: BulkActionType.Assignee,
        newAuthSchemaId: dummyTask.authSchemaId,
        newAssigneeId: dummyTask.userId,
      };

      newActions.push(newAction);
    }

    if (selectedStatus !== undefined) {
      const newAction: IBulkActionStatus = {
        actionType: BulkActionType.Status,
        newStatus: selectedStatus,
      };

      newActions.push(newAction);
    }

    if (selectedObjectiveStatus !== undefined) {
      const newAction: IBulkActionObjectiveStatus = {
        actionType: BulkActionType.ObjectiveStatus,
        newStatus: selectedObjectiveStatus,
      };

      newActions.push(newAction);
    }

    if (selectedApplicability !== undefined) {
      const newAction: IBulkActionApplicability = {
        actionType: BulkActionType.Applicability,
        newApplicabilities: selectedApplicability,
        newOutOfScopeReason: selectedOutOfScopeReason,
      };

      newActions.push(newAction);
    }

    if (selectedStandardsToAdd.length > 0 || selectedStandardsToRemove.length > 0) {
      const newAction: IBulkActionStandards = {
        actionType: BulkActionType.Standards,
        normsToAdd: selectedStandardsToAdd,
        normsToRemove: selectedStandardsToRemove,
      };

      newActions.push(newAction);
    }

    if (selectedNewPermission || removePermission) {
      const newAction: IBulkActionPermissions = {
        actionType: BulkActionType.Permissions,
        newPermission: selectedNewPermission,
      };

      newActions.push(newAction);
    }

    if (selectedRiskStatus) {
      const newAction: IBulkActionRiskStatus = {
        actionType: BulkActionType.RiskStatus,
        newStatus: selectedRiskStatus,
      };

      newActions.push(newAction);
    }

    return newActions;
  };

  const preExecuteActions = async (actions: IBulkAction[]): Promise<IBulkAction[]> => {
    //add new tags
    const action = actions.find((a) => a.actionType === BulkActionType.Tag);
    if (action) {
      const actionTag = action as IBulkActionTag;
      if (actionTag.tagsToAdd) {
        for (let idx = 0; idx < actionTag.tagsToAdd.length; idx++) {
          const tag = actionTag.tagsToAdd[idx];
          if (tag.tagId < 0) {
            const accessToken = await appContext.getAccessToken(apiRequest.scopes);
            const newTag = await apiCreateTag(tag, accessToken);
            actionTag.tagsToAdd[idx] = newTag;
            appContext.globalDataCache.tags.add(newTag);
          }
        }
      }
    }

    return actions;
  };

  const onExecute = async () => {
    try {
      let newActions: IBulkAction[] = getActions();
      newActions = await preExecuteActions(newActions);
      props.onExecute(newActions);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const hasActions = (): boolean => {
    return getActions().length > 0;
  };

  const loadSelectedAction = async (actionType: BulkActionType) => {
    try {
      if (props.onGetState && !currentStates[actionType]) {
        setIsLoading(true);
        const newState = await props.onGetState(actionType);
        if (newState) {
          const newStates = { ...currentStates };
          newStates[actionType] = newState;
          setCurrentStates(newStates);
        }
      }

      setSelectedAction(actionType);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubTitle = (): string => {
    if (props.noAuthCount === 0) {
      return t('translation:BulkActionsModal.SubTitle', { count: props.itemCount });
    } else {
      return t('translation:BulkActionsModal.SubTitleNoAuth', {
        count: props.itemCount - props.noAuthCount,
        noAuth: props.noAuthCount,
      });
    }
  };

  //
  // Main render
  //
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      subTitle={getSubTitle()}
      width={appContext.isMobileView ? '95vw' : '50vw'}
      height={650}
      minHeight={300}
      maxWidth={'95vw'}
      maxHeight={'95vh'}
      verticalGap={globalStackTokensGapSmall}
      hideFooter={true}
    >
      <Stack verticalFill>
        <Stack.Item>
          <Separator />
        </Stack.Item>
        <Stack.Item grow>
          <Stack horizontal tokens={globalStackTokensGapMedium}>
            <Stack.Item styles={{ root: { width: 200, minWidth: appContext.isMobileView ? undefined : 200 } }}>
              <Nav
                selectedKey={selectedAction?.toString()}
                groups={getNavGroups()}
                onLinkClick={(ev, item) => {
                  const actionKey = Number.parseInt(item?.key ?? '0');
                  loadSelectedAction(actionKey as BulkActionType);
                }}
              />
            </Stack.Item>
            <Separator vertical />
            {isLoading && (
              <Stack.Item grow>
                <Stack verticalAlign="center" horizontalAlign="center">
                  <Spinner size={SpinnerSize.large} />
                </Stack>
              </Stack.Item>
            )}
            {!isLoading && <Stack.Item grow>{getActionContent(selectedAction, currentStates)}</Stack.Item>}
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Separator />
        </Stack.Item>
        <Stack.Item>
          <DialogFooter>
            <PrimaryButton
              onClick={onExecute}
              disabled={!hasActions()}
              text={t('translation:General.Button.Execute')}
            />
            <DefaultButton
              onClick={() => {
                props.onClose();
              }}
              text={t('translation:General.Button.Cancel')}
            />
          </DialogFooter>
        </Stack.Item>
      </Stack>
      <OutOfScopeDialog
        isActionPending={false}
        reason={selectedOutOfScopeReason}
        isOutOfScope={selectedApplicability?.includes(ApplicabilityReasons.OutOfScope)}
        isOpen={showOutOfScopeDialog}
        dismiss={() => setShowOutOfScopeDialog(false)}
        setOutOfScope={setOutOfScope}
      ></OutOfScopeDialog>
    </GenericModal>
  );
};

export default BulkActionsModal;
