import SettingDTO from 'models/dto/settingDTO';
import Setting, { Settings } from 'models/setting';
import UserSetting, { UserSettings } from 'models/userSetting';

export function mapFromSettings(settingDTOs: SettingDTO[]): Settings {
  const settingList: Setting[] = [];

  for (const settingDTO of settingDTOs) {
    const newSetting = new Setting();
    newSetting.settingName = settingDTO.settingName;
    newSetting.settingValue = settingDTO.settingValue;
    settingList.push(newSetting);
  }

  const settings = new Settings();
  settings.items = settingList;

  return settings;
}

export function mapFromSettingsToUserSettings(settingDTOs: SettingDTO[]): UserSettings {
  const settingList: UserSetting[] = [];

  for (const settingDTO of settingDTOs) {
    const newSetting = new UserSetting();
    newSetting.settingName = settingDTO.settingName;
    newSetting.settingValue = settingDTO.settingValue;
    settingList.push(newSetting);
  }

  const settings = new UserSettings();
  settings.items = settingList;

  return settings;
}

export function mapToSetting(setting: Setting): SettingDTO {
  const settingDTO = new SettingDTO();

  settingDTO.settingName = setting.settingName;
  settingDTO.settingValue = setting.settingValue;

  return settingDTO;
}

export function mapFromSettingToUserSetting(settingDTO: SettingDTO): UserSetting {
  const newSetting = new UserSetting();

  newSetting.settingName = settingDTO.settingName;
  newSetting.settingValue = settingDTO.settingValue;

  return newSetting;
}

export function mapFromSetting(settingDTO: SettingDTO): Setting {
  const newSetting = new Setting();

  newSetting.settingName = settingDTO.settingName;
  newSetting.settingValue = settingDTO.settingValue;

  return newSetting;
}

