import { Callout, ICalloutContentStyles, Stack, Text } from '@fluentui/react';
import Tag from 'models/tag';
import TagCreator from 'components/Tags/TagCreator';
import { globalStackTokensGapMedium } from 'globalStyles';
import { useTranslation } from 'react-i18next';

interface ICreateTagCallOut {
  targetId: string;
  tagCache: Tag[];
  onClose: () => void;
  onCreate: (tag: Tag) => void;
  isVisible: boolean;
  defaultGroup?: string | undefined;
}

const CreateTagCallOut = (props: ICreateTagCallOut) => {
  const { t } = useTranslation(['translation', 'tags']);

  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: '10px 12px',
      maxWidth: 600,
    },
  };

  return (
    <Callout
      hidden={!props.isVisible}
      styles={calloutStyle}
      setInitialFocus
      gapSpace={0}
      target={`#${props.targetId}`}
      onDismiss={() => {
        props.onClose();
      }}
    >
      <Stack tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Text variant="mediumPlus">{t('tag:Creator.Label')}</Text>
        </Stack.Item>
        <TagCreator
          tagCache={props.tagCache}
          onClose={props.onClose}
          onCreate={props.onCreate}
          saveButtonText={t('translation:General.Button.Add')}
          defaultGroup={props.defaultGroup}
        />
      </Stack>
    </Callout>
  );
};

export default CreateTagCallOut;
