import { IAppContext } from 'App/AppContext';
import { TFunction } from 'i18next';
import { AuditTrailActions } from 'utils/auditTrailActions';
import { toLocaleDateShort, toLocaleDateTimeMedium } from 'utils/datetime';
import AuditTrailAction from './auditTrailAction';
import Control, { ApplicabilityReasons } from './control';
import Package, { PackageStates } from './package';
import { EntityStatus } from './entityStatus';
import RecurringPattern from './recurringPattern';
import Risk, { RiskMethod } from './risk';
import User from './user';
import UserLanguage from './userLanguage';
import Asset, { AssetState } from './asset/asset';
import { Approval, ApprovalState } from './approval';
import { getEntityStatusText, getPDCAStatusText } from 'globalFunctions';
import RiskState from './riskState';
import { TaskState } from './tasks/taskHelperClasses';
import { getObjectiveStatusText } from './objective/objective';

export default class AuditTrailEntry {
  tenantId: string;

  auditTrailId: number;

  auditTrailEntryId: number;

  auditTrailActionId: number;

  auditTrailAction?: AuditTrailAction;

  actionCode: string;

  created: Date;

  createdBy?: User;

  userId: string;

  userName: string;

  property: string;

  oldValue: string;

  newValue: string;

  constructor() {
    this.tenantId = '';
    this.auditTrailId = 0;
    this.auditTrailEntryId = 0;
    this.auditTrailActionId = 0;
    this.auditTrailAction = new AuditTrailAction();
    this.actionCode = '';
    this.created = new Date();
    this.createdBy = User.getEmptyUser();
    this.userId = '';
    this.userName = '';
    this.property = '';
    this.oldValue = '';
    this.newValue = '';
  }

  getActivityText(t: TFunction<string[]>, appContext: IAppContext): string {
    let expl: string = '';
    const user: string = this.userName;
    let oldValue: string = AuditTrailEntry.translateValue(
      this.auditTrailActionId,
      this.oldValue,
      t,
      appContext,
      this.property,
      false,
    );
    let newValue: string = AuditTrailEntry.translateValue(
      this.auditTrailActionId,
      this.newValue,
      t,
      appContext,
      this.property,
      true,
    );

    if (!oldValue) {
      oldValue = t('auditTrail:ActivityTrans.NoValue');
    }

    if (!newValue) {
      newValue = t('auditTrail:ActivityTrans.NoValue');
    }

    switch (this.auditTrailActionId) {
      case AuditTrailActions.Control_state_updated:
        expl = t('auditTrail:ActivityTrans.Control.Control_state_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Control_group_updated:
        expl = t('auditTrail:ActivityTrans.Control.Control_group_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Control_recurring_pattern_updated:
        expl = t('auditTrail:ActivityTrans.Control.Control_recurring_pattern_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Control_applicabilityreason_updated:
        expl = t('auditTrail:ActivityTrans.Control.Control_applicabilityreason_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Task_state_updated:
        expl = t('auditTrail:ActivityTrans.Task.Task_state_updated', { userName: user, from: oldValue, to: newValue });
        break;
      case AuditTrailActions.Task_assignee_updated:
        expl = t('auditTrail:ActivityTrans.Task.Task_assignee_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Task_deadline_updated:
        expl = t('auditTrail:ActivityTrans.Task.Task_deadline_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Task_followup_updated:
        expl = t('auditTrail:ActivityTrans.Task.Task_followup_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Task_start_updated:
        expl = t('auditTrail:ActivityTrans.Task.Task_start_updated', { userName: user, from: oldValue, to: newValue });
        break;
      case AuditTrailActions.Task_recurring_pattern_updated:
        expl = t('auditTrail:ActivityTrans.Task.Task_recurring_pattern_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Task_duration_updated:
        expl = t('auditTrail:ActivityTrans.Task.Task_duration_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Risk_state_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_state_updated', { userName: user, from: oldValue, to: newValue });
        break;
      case AuditTrailActions.Risk_chance_start_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_chance_start_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Risk_chance_current_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_chance_current_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Risk_chance_goal_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_chance_goal_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Risk_impact_start_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_impact_start_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Risk_impact_current_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_impact_current_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Risk_impact_goal_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_impact_goal_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Risk_owner_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_owner_updated', { userName: user, from: oldValue, to: newValue });
        break;
      case AuditTrailActions.Risk_group_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_group_updated', { userName: user, from: oldValue, to: newValue });
        break;
      case AuditTrailActions.Risk_method_updated:
        expl = t('auditTrail:ActivityTrans.Risk.Risk_method_updated', { userName: user, from: oldValue, to: newValue });
        break;
      case AuditTrailActions.Package_state_updated:
        expl = t('auditTrail:ActivityTrans.Package.Package_state_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Package_content_upload_finished:
        expl = t('auditTrail:ActivityTrans.Package.Package_content_upload_finished', {
          userName: user,
        });
        break;
      case AuditTrailActions.Package_submitted:
        expl = t('auditTrail:ActivityTrans.Package.Package_submitted', {
          userName: user,
        });
        break;
      case AuditTrailActions.Package_code_updated:
        expl = t('auditTrail:ActivityTrans.Package.Package_code_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Package_version_updated:
        expl = t('auditTrail:ActivityTrans.Package.Package_version_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Package_price_updated:
        expl = t('auditTrail:ActivityTrans.Package.Package_price_updated', {
          userName: user,
          from: oldValue,
          to: newValue,
        });
        break;
      case AuditTrailActions.Package_activated:
        expl = t('auditTrail:ActivityTrans.Package.Package_activated', {
          userName: user,
          name: newValue,
        });
        break;
      default:
        expl = '';
    }

    return expl;
  }

  static translateValue = (
    auditTrailActionId: number,
    value: string,
    t: TFunction<string[]>,
    appContext: IAppContext,
    property: string,
    isNewValue: boolean | undefined,
  ): string => {
    let output: string | undefined;

    try {
      switch (auditTrailActionId) {
        case AuditTrailActions.Control_recurring_pattern_updated:
        case AuditTrailActions.Task_recurring_pattern_updated:
          const recurring = new RecurringPattern();
          recurring.fromJSON(value);
          output = recurring.getSummary();
          break;
        case AuditTrailActions.Conv_PDCA_Objective:
          const stateObj = Number.parseInt(value);
          if (isNewValue === true) {
            output = getObjectiveStatusText(stateObj, t);
          } else if (isNewValue === false) {
            output = getPDCAStatusText(stateObj, t);
          }
          break;
        case AuditTrailActions.Conv_PDCA_Control:

        case AuditTrailActions.Conv_PDCA_Process:
        case AuditTrailActions.Conv_PDCA_Requirement:
          const state = Number.parseInt(value);
          if (isNewValue === true) {
            output = getEntityStatusText(state, t);
          } else if (isNewValue === false) {
            output = getPDCAStatusText(state, t);
          }
          break;
        case AuditTrailActions.Task_followup_updated:
        case AuditTrailActions.Risk_feature1_updated:
        case AuditTrailActions.Risk_feature2_updated:
        case AuditTrailActions.Risk_feature3_updated:
        case AuditTrailActions.Risk_feature4_updated:
        case AuditTrailActions.Risk_feature5_updated:
        case AuditTrailActions.Risk_feature6_updated:
          const bit: boolean = value.toLowerCase() === 'true';
          output = bit ? t('translation:Dialogs.YesNo.Yes') : t('translation:Dialogs.YesNo.No');
          break;
        case AuditTrailActions.Risk_method_updated:
          const method: RiskMethod = Number.parseInt(value);
          output = Risk.getRiskMethodText(method, t);
          break;
        case AuditTrailActions.Control_state_updated:
          const stateControl: EntityStatus = Number.parseInt(value);
          output = getEntityStatusText(stateControl, t);
          break;
        case AuditTrailActions.Process_state_updated:
          const stateProcess: EntityStatus = Number.parseInt(value);
          output = getEntityStatusText(stateProcess, t);
          break;
        case AuditTrailActions.Objective_state_updated:
          const stateObjective: EntityStatus = Number.parseInt(value);
          output = getEntityStatusText(stateObjective, t);
          break;
        case AuditTrailActions.Control_applicabilityreason_updated:
          const ar_raw: string[] = JSON.parse(value);
          const ar: ApplicabilityReasons[] = ar_raw.map((a) => Number.parseInt(a));
          output = Control.getApplicabilityReasonText(ar, t);
          break;
        case AuditTrailActions.Risk_state_updated:
          //states are always logged as strings in the default language
          //translate them back to the user language using the global data cache
          const riskState = appContext.globalDataCache.riskStates.items.find(
            (s: RiskState) => s.getTrans(UserLanguage.getFallBack())?.state.toLowerCase() === value.toLowerCase(),
          );
          const riskTrans = riskState?.getTrans(appContext.user.language.code);
          output = riskTrans?.state;
          break;
        case AuditTrailActions.Task_state_updated:
          //states are always logged as strings in the default language
          //translate them back to the user language using the global data cache
          const taskState = appContext.globalDataCache.taskStates.items.find(
            (s: TaskState) => s.getTrans(UserLanguage.getFallBack())?.state.toLowerCase() === value.toLowerCase(),
          );
          const taskTrans = taskState?.getTrans(appContext.user.language.code);
          output = taskTrans?.state;
          break;
        case AuditTrailActions.Task_deadline_updated:
        case AuditTrailActions.Task_start_updated:
        case AuditTrailActions.Task_end_updated:
        case AuditTrailActions.Task_completed_updated:
          const d1: Date = new Date(value);
          output = toLocaleDateTimeMedium(d1);
          break;
        case AuditTrailActions.Asset_lifecyclestart_updated:
        case AuditTrailActions.Asset_lifecycleend_updated:
          const d2: Date = new Date(value);
          output = toLocaleDateShort(d2);
          break;
        case AuditTrailActions.Task_checklist_updated:
          break;
        case AuditTrailActions.Package_state_updated:
          const ps: PackageStates = Number.parseInt(value);
          output = Package.GetPackageStateText(ps, t);
          break;
        case AuditTrailActions.Asset_state_updated:
          //states are always logged as strings in the default language
          //translate them back to the user language
          output = Asset.getStateText(Number.parseInt(value) as AssetState, t as TFunction<string[]>);
          break;
        case AuditTrailActions.Task_approved_updated:
          const approvedBit: boolean = value.toLowerCase() === 'true';
          const approvedStatus = approvedBit ? ApprovalState.Approved : ApprovalState.Rejected;

          output = Approval.getStatusText(approvedStatus, t as TFunction<string[]>);
          break;
        case AuditTrailActions.AuthSchema_line_added:
        case AuditTrailActions.AuthSchema_line_updated:
        case AuditTrailActions.AuthSchema_line_removed:
          const values = value.split(';');
          const user = appContext.globalDataCache.users.get(values[0]);
          const group = appContext.globalDataCache.groups.get(values[0]);
          const role = appContext.globalDataCache.roles.get(values[0]);
          const assignment = user.name ? user.name : group.name ? group.name : role.name;

          const bitUpdate: boolean = values[1] === '1';
          const hasUpdate = bitUpdate ? t('translation:Dialogs.YesNo.Yes') : t('translation:Dialogs.YesNo.No');

          const bitDelete: boolean = values[2] === '1';
          const hasDelete = bitDelete ? t('translation:Dialogs.YesNo.Yes') : t('translation:Dialogs.YesNo.No');

          output = `${assignment} => ${t('adminAuth:TabPermissions.LineEditor.Columns.OpUpdate')}: ${hasUpdate} ${t(
            'adminAuth:TabPermissions.LineEditor.Columns.OpDelete',
          )}: ${hasDelete}`;
          break;
        case AuditTrailActions.Asset_auth_updated:
        case AuditTrailActions.KPI_auth_updated:
        case AuditTrailActions.Risk_auth_updated:
        case AuditTrailActions.Task_auth_updated:
        case AuditTrailActions.Process_auth_updated:
        case AuditTrailActions.Objective_auth_updated:
          const schema = appContext.globalDataCache.authSchemas.get(Number(value));

          output = schema.name || 'Id: ' + value;
          break;
        case AuditTrailActions.Record_updated:
          if (property) {
            const code = property.split(' ');
            let name = '';

            switch (code[0]) {
              case 'ResourceListAuth':
                name = 'Library category sharing permission';
                break;
              case 'StandardAuth':
                name = 'Standard sharing permission';
                break;
              case 'TaskTypeAuth':
                name = 'Form sharing permission';
                break;
              default:
                name = '';
            }

            if (name) {
              const id = code[1];
              if (id) {
                name += ` (${id})`;
              }
              name += ': ';
              const schema = appContext.globalDataCache.authSchemas.get(Number(value));
              output = name + schema.name || 'Id: ' + value;
            } else {
              output = value;
            }
          } else {
            output = value;
          }

          break;
        default:
          output = value;
      }
    } catch {
      output = value;
    }

    return output || '';
  };
}
