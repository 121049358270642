import { IList, IListItem } from './SharepointInterfaces';
import { Client } from '@microsoft/microsoft-graph-client';
import AppError from 'utils/appError';
import { ColumnDefinition, List, ListItem, ListItemVersion } from 'microsoft-graph';
import { globalMaxSharePointListSize } from 'globalConstants';
import logger from 'services/Logging/logService';

//
// Sharepoint Lists
//

export const graphGetListsForSite = async (client: Client, siteId: string): Promise<List[]> => {
  try {
    const allLists = await client.api(`/sites/${siteId}/lists`).get();
    const lists: List[] = [];

    for (const list of allLists.value) {
      if (list.list && list.list.hidden === false && list.list.template === 'genericList') {
        lists.push(list);
      }
    }

    return lists;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetAllListsForSite = async (client: Client, siteId: string): Promise<List[]> => {
  try {
    const allLists = await client.api(`/sites/${siteId}/lists`).get();
    const lists: List[] = [];

    for (const list of allLists.value) {
      lists.push(list);
    }

    return lists;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetFieldsToSelectFromColumn = (cols: ColumnDefinition[]): string => {
  let output = '';
  if (cols) {
    cols.forEach((col: ColumnDefinition, i: number) => {
      if (col.name) {
        if (i !== cols.length - 1) output = output + col.name + ',';
        else output = output + col.name;
      }
    });
  }

  return output;
};

export const graphHasValidDataType = (column: ColumnDefinition): boolean => {
  const output: boolean = false;

  if (column.dateTime) return true;
  if (column.boolean) return true;
  if (column.text) return true;
  if (column.choice) return true;
  if (column.number) return true;
  if (column.currency) return true;
  if (column.personOrGroup) return true;

  return output;
};

export const graphGetListItems = async (
  client: Client,
  siteId: string,
  listId: string,
  fields: string = '',
  filter: string = '',
): Promise<ListItem[]> => {
  try {
    let res;
    if (filter) {
      res = await client
        .api(`/sites/${siteId}/lists/${listId}/items?expand=fields(select=${fields})&$filter=${filter}`)
        .get();
    } else if (fields) {
      res = await client.api(`/sites/${siteId}/lists/${listId}/items?expand=fields(select=${fields})`).get();
    } else {
      res = await client.api(`/sites/${siteId}/lists/${listId}/items?expand=fields`).get();
    }

    let nextLink = res['@odata.nextLink'];
    const items: IListItem[] = [...res.value];

    while (nextLink) {
      res = await client.api(nextLink).get();
      items.push(...res.value);
      nextLink = res['@odata.nextLink'];
      if (items.length > globalMaxSharePointListSize) break;
    }

    return items;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetListColumns = async (
  client: Client,
  siteId: string,
  listId: string,
): Promise<ColumnDefinition[]> => {
  const systemColNames: string[] = [
    'id',
    'author',
    'editor',
    'created',
    'modified',
    'complianceassetId',
    'attachments',
    'edit',
    'linktitlenomenu',
    'linktitle',
    'docicon',
    'itemchildcount',
    'folderchildcount',
    'appauthor',
    'appeditor',
    'complianceassetid',
  ];

  try {
    const allColumns: ColumnDefinition[] = (await client.api(`/sites/${siteId}/lists/${listId}/columns`).get()).value;
    const output: ColumnDefinition[] = [];

    //apply the display name of the system LinkTitle column to the Title column
    //this is needed when you manually rename the first 'system' column in SharePoint
    const linkTitleCol = allColumns.find((c) => c.name?.toLowerCase() === 'linktitle');
    if (linkTitleCol) {
      const titleCol = allColumns.find((c) => c.name?.toLowerCase() === 'title');
      if (titleCol) {
        titleCol.displayName = linkTitleCol.displayName;
      }
    }

    for (const col of allColumns) {
      //Skip hidden columns
      if (col.columnGroup !== '_Hidden' && col.hidden === false) {
        //Skip system columns
        if (!col.name?.startsWith('_') && systemColNames.find((c) => c === col.name?.toLowerCase()) === undefined) {
          //Skip columns without a valid data type
          if (graphHasValidDataType(col)) {
            output.push(col);
          }
        }
      }
    }

    return output;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetList = async (client: Client, siteId: string, listId: string): Promise<List> => {
  try {
    const item: List = await client.api(`sites/${siteId}/lists/${listId}`).get();

    return item;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphValidateListItem = async (
  client: Client,
  siteId: string,
  listId: string,
  listItemId: string,
): Promise<boolean> => {
  try {
    await client.api(`sites/${siteId}/lists/${listId}/items/${listItemId}`).get();

    return true;
  } catch (err) {
    logger.debug('graphValidateListItem', err);

    return false;
  }
};

export const graphGetListItem = async (
  client: Client,
  siteId: string,
  listId: string,
  listItemId: string,
): Promise<ListItem | undefined> => {
  try {
    const item = await client.api(`sites/${siteId}/lists/${listId}/items/${listItemId}`).get();

    return item;
  } catch (err) {
    logger.debug('graphGetListItem', err);

    return undefined;
  }
};

export const graphDeleteList = async (
  client: Client,
  siteId: string,
  listId: string,
): Promise<void> => {
  try {
    await client.api(`sites/${siteId}/lists/${listId}`).delete();
  } catch (err) {
    logger.debug('graphDeleteList', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphDeleteListItem = async (
  client: Client,
  siteId: string,
  listId: string,
  listItemId: string,
): Promise<void> => {
  try {
    await client.api(`sites/${siteId}/lists/${listId}/items/${listItemId}`).delete();
  } catch (err) {
    logger.debug('graphDeleteListItem', err);
    const appErr = AppError.fromGraphError(err);
    if (appErr.code?.toLowerCase() === 'itemNotFound') {
      return; //oke
    } else {
      throw appErr;
    }
  }
};

export const graphGetListItemVersions = async (
  client: Client,
  siteId: string,
  listId: string,
  listItemId: string,
): Promise<ListItemVersion[]> => {
  try {
    const item = await client.api(`sites/${siteId}/lists/${listId}/items/${listItemId}/versions`).get();

    return item.value;
  } catch (err) {
    logger.debug('graphGetListItemVersions', err);

    return [];
  }
};

export const graphGetListItemWithFields = async (
  client: Client,
  siteId: string,
  listId: string,
  listItemId: string,
  fields: string = '',
): Promise<ListItem | undefined> => {
  try {
    const item = await client
      .api(`sites/${siteId}/lists/${listId}/items/${listItemId}?expand=fields(select=${fields})`)
      .get();

    return item;
  } catch (err) {
    logger.debug('graphGetListItemWithFields', err);

    return undefined;
  }
};

export const graphValidateListAndItems = (
  client: Client,
  siteId: string,
  list: IList,
  items: IListItem[],
  columns: ColumnDefinition[],
): AppError | undefined => {
  // 1. Microsoft Lists columns can have a unique constraint that is not enforced
  //    e.g. you can switch on unique value validation after the list already contains duplicate values
  for (const c of columns) {
    if (c.enforceUniqueValues) {
      const values: string[] = [];
      items.forEach((i) => {
        const value = graphGetListItemValue(i, c);
        if (value !== undefined) values.push(value);
      });
      const uniqueValues = [...new Set(values)];
      if (uniqueValues.length !== values.length) {
        return new AppError(`Values of column [${c.name}] are not unique`, 'column_values_duplicate');
      }
    }
  }

  // 2. When a column has a unique constraint and a default value, inserting the second item that has the default value will result in an error
  // This can be complicated by the fact that the default value can be calculated so that we cannot check upfront whether the list values are valid
  // Therefore, we do not support default values on columns that have an unique constraint
  for (const c of columns) {
    if (c.enforceUniqueValues && c.defaultValue) {
      return new AppError(
        `Column [${c.name}] has a unique constraint and a default value`,
        'column_unique_and_default',
      );
    }
  }

  return undefined;
};

export const graphGetListItemValue = (item: IListItem, column: ColumnDefinition): string | undefined => {
  if (!column.name) return undefined;
  if (column.personOrGroup) return undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyFields = item.fields as any;

  return anyFields[column.name];
};

export const graphCreateList = async (
  client: Client,
  siteId: string,
  list: IList,
  columns: ColumnDefinition[],
  copy: boolean,
): Promise<List> => {
  try {
    let newColumns: ColumnDefinition[] = columns.map((c) => {
      const newCol = c;
      newCol.id = undefined;

      return newCol;
    });

    //filter Title column because this is already an included column from the genericList template
    newColumns = newColumns.filter((c) => c.name?.toLowerCase() !== 'title');

    const newList: IList = {
      displayName: copy ? list.displayName + ' - Copy' : list.displayName,
      description: list.description,
      columns: newColumns,
    };

    const item: List = await client.api(`sites/${siteId}/lists`).post(newList);

    return item;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphCreateListItem = async (
  client: Client,
  siteId: string,
  listId: string,
  item: IListItem,
  columns: ColumnDefinition[],
): Promise<ListItem | undefined> => {
  try {
    const newListItem: IListItem = {
      fields: item.fields,
    };

    if (newListItem.fields) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const anyFields = newListItem.fields as any;
      delete anyFields['@odata.etag']; //delete the e-tag
      delete anyFields['id']; //delete the id

      //delete all fields that are not in the column specification
      for (const field in anyFields) {
        const col = columns.find((c) => c.name?.toLowerCase() === field.toLowerCase());
        if (!col) {
          delete anyFields[field];
        }
      }

      // delete any values of PersonOrGroup type since they make no sense in a new list
      columns.forEach((c) => {
        if (c.personOrGroup) {
          if (c.name && anyFields[c.name]) {
            delete anyFields[c.name];
          }
        }
      });

      //delete anyFields['Dienst'];
      //delete anyFields['KPIlaatstbeoordeeld'];
    } else {
      //do not create the item when there are no field values
      return undefined;
    }

    const newItem: ListItem = await client.api(`sites/${siteId}/lists/${listId}/items`).post(newListItem);

    return newItem;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};
