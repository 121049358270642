import Norm, { ISONorms } from 'models/norm';

export enum ReportContext {
  Risks = 1,
  Schedule = 2,
  Standards = 3,
  Controls = 4,
  Assets = 5,
  Processes = 6,
  Requirements = 7,
  Objectives = 8,
  Library = 9,
  Tasks = 10
}

export enum ReportType {
  RiskAssesment = 1,
  RiskTreatmentPlan = 2,
  OperationalPlanning = 3,
  SOA_ISO27001_2013 = 4,
  SOA_ISO27001_2022 = 5,
  SOA_Controls = 6,
  Assets = 7,
  ControlImplementation = 8,
  ProcessRiskControl = 9,
  AssetRiskControl = 10,
  RequirementImplementation = 11,
  ProcessDetails = 12,
  ObjectiveDetails = 13,
  AssetDetails = 14,
  LibraryApprovals = 15,
  Tasks = 16,
  RiskChanges = 17,
  ControlSpecification = 18,
}

export enum ReportHandler {
  Server = 1,
  Client = 2,
}

export default class ReportDefinition {
  id: ReportType;

  handler: ReportHandler;

  name: string;

  description: string;

  icon: string;

  context: ReportContext[];

  contextUrl?: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.handler = ReportHandler.Server;
    this.name = name;
    this.description = '';
    this.icon = '';
    this.context = [];
  }

  isEnabled(standards: Norm[]): boolean {
    switch (this.id) {
      case ReportType.SOA_ISO27001_2013:
        const standardSOA_ISO27001_2013 = standards.find((n) => n.isoNormId === ISONorms.ISO270012013);

        return standardSOA_ISO27001_2013 !== undefined;
      case ReportType.SOA_ISO27001_2022:
        const standardSOA_ISO27001_2022 = standards.find((n) => n.isoNormId === ISONorms.ISO270012022);

        return standardSOA_ISO27001_2022 !== undefined;
      default:
        return true;
    }
  }
}
