import { EntityTypes } from 'models/entity';

export class SearchRequest {
  public text: string;

  public type: EntityTypes;

  public sharePointSearch: boolean;

  constructor(text: string, type: EntityTypes, sharePointSearch: boolean) {
    this.text = text;
    this.type = type;
    this.sharePointSearch = sharePointSearch;
  }
}
