import IdListDTO from 'models/dto/IdListDTO';
import User from 'models/user';
import http from './httpService';
import AppError from 'utils/appError';
import { appRoles } from 'services/Auth/appRoles';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { RoleDTO } from 'models/dto/auth/roleDTO';
import {
  mapFromAuthSchema,
  mapFromAuthSchemaLines,
  mapFromAuthSchemas,
  mapFromRole,
  mapFromRoles,
  mapFromUserSystemRoles,
  mapToAuthSchema,
  mapToRole,
} from 'mappings/authMapping';
import { Role } from 'models/auth/role';
import UserRoleDTO, { UserSystemRoleDTO } from 'models/dto/auth/userRoleDTO';
import { PermissionTypes } from 'models/auth/rolePermission';
import GroupRoleDTO from 'models/dto/auth/groupRoleDTO';
import { AuthSchema } from 'models/auth/authSchema';
import { AuthSchemaDTO } from 'models/dto/auth/authSchemaDTO';
import { AuthSchemaLine } from 'models/auth/authSchemaLine';
import { AuthSchemaLineDTO } from 'models/dto/auth/authSchemaLineDTO';
import EntityDTO from 'models/dto/entityDTO';
import { mapFromEntities, mapToEntity } from 'mappings/activityMapping';
import Entity, { EntityTypes } from 'models/entity';
import { mapFromUsers } from 'mappings/userMapping';
import UserDTO from 'models/dto/userDTO';

export async function apiGetRoles(accessToken: string, cache: GlobalDataCache | undefined): Promise<Role[]> {
  try {
    const ar = await http.get<RoleDTO[]>(`/auth/roles`, http.getRequestConfig(accessToken));

    return mapFromRoles(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRolesForCache(accessToken: string, cache: GlobalDataCache | undefined): Promise<Role[]> {
  try {
    const ar = await http.get<RoleDTO[]>(`/auth/roles/cache`, http.getRequestConfig(accessToken));

    return mapFromRoles(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetUsersBySystemRole(
  roleId: appRoles,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<User[]> {
  try {
    if (roleId === appRoles.OrgAdmin) {
      const ar = await http.get<UserDTO[]>(`/auth/roles/system/orgadmin/users`, http.getRequestConfig(accessToken));

      return mapFromUsers(ar.data, cache);
    } else {
      const ar = await http.get<UserSystemRoleDTO[]>(
        `/auth/roles/system/${roleId}/users`,
        http.getRequestConfig(accessToken),
      );

      return mapFromUserSystemRoles(ar.data, cache);
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetUserRole(userId: string, roleId: string, add: boolean, accessToken: string): Promise<void> {
  try {
    const userRoleDTO = new UserRoleDTO();
    userRoleDTO.userId = userId;
    userRoleDTO.roleId = roleId;
    await http.post(`/auth/roles/users?add=${add}`, userRoleDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetUserSystemRole(
  userId: string,
  roleId: number,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const userRoleDTO = new UserSystemRoleDTO();
    userRoleDTO.userId = userId;
    userRoleDTO.roleId = roleId;
    await http.post(`/auth/roles/system/users?add=${add}`, userRoleDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetGroupRole(
  groupId: string,
  roleId: string,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const groupRoleDTO = new GroupRoleDTO();
    groupRoleDTO.groupId = groupId;
    groupRoleDTO.roleId = roleId;
    await http.post(`/auth/roles/groups?add=${add}`, groupRoleDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetRolePermission(
  roleId: appRoles,
  permissionId: PermissionTypes,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    await http.post<IdListDTO>(
      `/auth/roles/${roleId}/permission/${permissionId}?add=${add}`,
      null,
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRolePermissions(roleId: string, accessToken: string): Promise<number[]> {
  try {
    const ar = await http.get<number[]>(`/auth/roles/${roleId}/permissions`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetRolePermissions(roleId: string, permissions: number[], accessToken: string): Promise<void> {
  try {
    const idList = new IdListDTO(permissions.map((p) => p.toString()));
    await http.post(`/auth/roles/${roleId}/permissions`, idList, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export const apiAddRole = async (role: Role, accessToken: string): Promise<Role> => {
  try {
    const roleDTO = mapToRole(role);
    const ar = await http.post<RoleDTO>(`/auth/roles`, roleDTO, http.getRequestConfig(accessToken));

    return mapFromRole(ar.data, undefined);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiUpdateRole = async (role: Role, accessToken: string): Promise<Role> => {
  try {
    const roleDTO = mapToRole(role);
    const ar = await http.put<RoleDTO>(`/auth/roles`, roleDTO, http.getRequestConfig(accessToken));

    return mapFromRole(ar.data, undefined);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiRemoveRole = async (id: string, accessToken: string): Promise<void> => {
  try {
    await http.post<RoleDTO>(`/auth/roles/delete/${id}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export async function apiGetAuthSchema(authSchemaId: number, accessToken: string): Promise<AuthSchema | undefined> {
  try {
    const ar = await http.get<AuthSchemaDTO>(`/auth/schemas/${authSchemaId}`, http.getRequestConfig(accessToken));
    if (!ar || !ar.data) return undefined;

    return mapFromAuthSchema(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAuthSchemaForUser(
  authSchemaId: number,
  accessToken: string,
): Promise<AuthSchema | undefined> {
  try {
    const ar = await http.get<AuthSchemaDTO>(`/auth/schemas/${authSchemaId}/user`, http.getRequestConfig(accessToken));
    if (!ar || !ar.data) return undefined;

    return mapFromAuthSchema(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAuthSchemaLines(authSchemaId: number, accessToken: string): Promise<AuthSchemaLine[]> {
  try {
    const ar = await http.get<AuthSchemaLineDTO[]>(
      `/auth/schemas/${authSchemaId}/users`,
      http.getRequestConfig(accessToken),
    );
    if (!ar || !ar.data) return [];

    return mapFromAuthSchemaLines(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAuthSchemas(accessToken: string): Promise<AuthSchema[]> {
  try {
    const ar = await http.get<AuthSchemaDTO[]>(`/auth/schemas`, http.getRequestConfig(accessToken));

    return mapFromAuthSchemas(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAuthSchemasForFilter(
  filterUserId: string | undefined,
  accessToken: string,
): Promise<AuthSchema[]> {
  try {
    let url = `/auth/schemas/filter`;
    if (filterUserId) {
      url += `?filterUserId=${filterUserId}`;
    }
    const ar = await http.get<AuthSchemaDTO[]>(url, http.getRequestConfig(accessToken));

    return mapFromAuthSchemas(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAuthCheckDelete(type: EntityTypes, id: number, accessToken: string): Promise<boolean> {
  try {
    const url = `/auth/checks/delete`;
    const entityDto = new EntityDTO();
    entityDto.entityId = id;
    entityDto.typeOfEntity = type;

    const ar = await http.post<boolean>(url, entityDto, http.getRequestConfig(accessToken));

    return ar.data || false;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export const apiAddAuthSchema = async (authschema: AuthSchema, accessToken: string): Promise<AuthSchema> => {
  try {
    const authschemaDTO = mapToAuthSchema(authschema);
    const ar = await http.post<AuthSchemaDTO>(`/auth/schemas`, authschemaDTO, http.getRequestConfig(accessToken));

    return mapFromAuthSchema(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiUpdateAuthSchema = async (authschema: AuthSchema, accessToken: string): Promise<AuthSchema> => {
  try {
    const authschemaDTO = mapToAuthSchema(authschema);
    const ar = await http.put<AuthSchemaDTO>(`/auth/schemas`, authschemaDTO, http.getRequestConfig(accessToken));

    return mapFromAuthSchema(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiRemoveAuthSchema = async (id: number, accessToken: string): Promise<void> => {
  try {
    await http.post<AuthSchemaDTO>(`/auth/schemas/delete/${id}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetAuthSchemaUsage = async (id: number, accessToken: string): Promise<Entity[]> => {
  try {
    const ar = await http.get<EntityDTO[]>(`/auth/schemas/${id}/usage`, http.getRequestConfig(accessToken));

    return mapFromEntities(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiUpdateAuthSchemaOfEntity = async (item: Entity, accessToken: string): Promise<void> => {
  try {
    await http.put<AuthSchemaDTO>(
      `/auth/schemas/${item.data ?? 0}/apply`,
      mapToEntity(item),
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};
