import { useContext, useState } from 'react';
import { IconButton, Modal, Separator, Stack, Text } from '@fluentui/react';
import ResourceList from 'models/resourceList';
import { cancelIcon } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import ButtonDialogFooter from 'components/Footer/ButtonDialogFooter';
import AppContext from 'App/AppContext';
import SharepointSitePagePicker, { ParentSiteInformation } from 'components/SharePoint/SharepointSitePagePicker';
import ResourceLink from 'models/resourceLink';

import { ISitePage } from 'services/Graph/SharepointInterfaces';

interface ILinkCreatorSitePageLibraryProps {
  isOpen: boolean;
  list: ResourceList;
  dismiss: () => void;
  addLinks: (links: ResourceLink[]) => void;
  listLinks: ResourceLink[];
  existingLinks: ResourceLink[];
  listLoading: boolean;
  linkToEdit?: ResourceLink;
  entityContext: boolean;
  hideWholeList?: boolean;
  singleSelect?: boolean;
}

const LinkCreatorSitePageLibrary = (props: ILinkCreatorSitePageLibraryProps) => {
  const { t } = useTranslation(['library', 'translation']);
  const appContext = useContext(AppContext);
  const [selectedSitePages, setSelectedSitePages] = useState<ISitePage[]>([]);

  const createNewLinkFromSitePage = (item: ISitePage): ResourceLink => {
    let output: ResourceLink;
    if (props.linkToEdit) {
      output = props.linkToEdit.clone();
    } else {
      output = new ResourceLink();
    }

    output.listId = props.list.listId;
    output.list = props.list;
    output.linkName = item.title || item.name;
    output.linkURL = item.webUrl;
    output.pageId = item.id;

    return output;
  };

  const onSubmitWholeListItem = () => {
    const existingLink = props.listLinks.find((l) => l.isWholeList());
    let newLink: ResourceLink;
    if (existingLink) {
      newLink = existingLink;
    } else {
      newLink = new ResourceLink();
      newLink.setWholeList(props.list);
    }
    props.addLinks([newLink]);
    props.dismiss();
  };

  const getExistingLink = (links: ResourceLink[], newLink: ResourceLink): ResourceLink | undefined => {
    const linkWithId = links.find((link) => link.pageId === newLink.pageId);
    const linkWithName = links.find((link) => link.linkName.toLowerCase() === newLink.linkName.toLowerCase());

    if (linkWithId) {
      return linkWithId;
    } else if (linkWithName) {
      const linkCopies = links.filter((link) =>
        link.linkName.toLowerCase().startsWith(newLink.linkName.toLowerCase() + ' ('),
      );
      newLink.linkName += ` (${linkCopies.length + 2})`;

      return newLink;
    }

    return undefined;
  };

  const onSubmit = async () => {
    if (disableAdd() || !selectedSitePages.length) return;

    try {
      appContext.showContentLoader();

      const linksToAdd: ResourceLink[] = [];
      selectedSitePages.forEach((page) => {
        const newLink = createNewLinkFromSitePage(page);
        if (props.linkToEdit) {
          //always push the item from the picker, this is already based on the linkToEdit
          linksToAdd.push(newLink);
        } else {
          const existingLink = getExistingLink(props.listLinks, newLink);
          if (existingLink) {
            //user chose a link that was already in the library
            linksToAdd.push(existingLink);
          } else {
            //user chose a new link from sharepoint
            linksToAdd.push(newLink);
          }
        }
      });

      props.addLinks(linksToAdd);
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
      props.dismiss();
    }
  };

  const onPickSitePage = (items: ISitePage[]) => {
    if (items.length) {
      setSelectedSitePages(items);
    } else {
      setSelectedSitePages([]);
    }
  };

  const disableAdd = () => {
    if (!selectedSitePages.length) return true;

    return false;
  };

  const onCancel = () => {
    props.dismiss();
  };

  const getParentInfo = (): ParentSiteInformation | undefined => {
    if (props.list.spSiteId) {
      const parentFolderInfo: ParentSiteInformation = {
        siteId: props.list.spSiteId,
        tenantId: props.list.altTenantId,
      };

      return parentFolderInfo;
    }

    return undefined;
  };

  return (
    <Modal isBlocking={true} isOpen={props.isOpen} onDismiss={onCancel}>
      <Stack
        verticalFill
        styles={{ root: { height: '90vh', width: 1000, minWidth: 320, maxWidth: '90vw', padding: 20 } }}
      >
        {/*Header*/}
        <Stack.Item>
          <Stack horizontal horizontalAlign={'space-between'}>
            <Stack.Item>
              {!props.linkToEdit && (
                <Text variant="xLarge">{t('library:LinkComponents.AddItemOf', { name: props.list.name })}</Text>
              )}
              {props.linkToEdit && (
                <Text variant="xLarge">
                  {t('library:LinkComponents.ReplaceItem', { name: props.linkToEdit.linkName, list: props.list.name })}
                </Text>
              )}
            </Stack.Item>
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Separator />
        {/*Body */}
        <Stack.Item grow>
          <SharepointSitePagePicker
            invalidAuthToken={onCancel}
            onSelect={onPickSitePage}
            parentSite={getParentInfo()}
            existingLinks={props.existingLinks}
            listPages={props.listLinks}
            entityContext={props.entityContext}
            listPagesLoading={props.listLoading}
            linkToEdit={props.linkToEdit}
            singleSelect={props.singleSelect}
          />
        </Stack.Item>
        {/*Footer*/}
        <Stack.Item>
          <ButtonDialogFooter
            primaryText={props.linkToEdit ? t('translation:General.Button.Save') : t('translation:General.Button.Add')}
            secondaryText={t('translation:General.Button.Cancel')}
            onSubmit={() => {
              onSubmit();
            }}
            onCancel={onCancel}
            buttonPosition="end"
            disablePrimary={disableAdd}
            tertiaryText={props.hideWholeList ? undefined : t('library:LinkComponents.WholeList')}
            tertiaryTooltip={
              props.existingLinks.find((l) => l.isWholeList()) !== undefined
                ? t('library:LinkComponents.Validate.ItemExists')
                : undefined
            }
            disableTertiary={() => props.existingLinks.find((l) => l.isWholeList()) !== undefined}
            onTertiary={onSubmitWholeListItem}
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default LinkCreatorSitePageLibrary;
