import React, { useContext, useEffect, useState } from 'react';
import {
  IGroup,
  DetailsListLayoutMode,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  IDetailsGroupRenderProps,
  Selection,
  IColumn,
  Text,
  IObjectWithKey,
  TooltipHost,
  DirectionalHint,
  IDetailsRowProps,
 Stack } from '@fluentui/react';

import { useTranslation } from 'react-i18next';
import { globalStackItemStylesScroll, globalStackTokensGapSmall, globalTextStylesDisabled } from 'globalStyles';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { globalFilterDelay } from 'globalConstants';
import ResourceLink from 'models/resourceLink';
import ResourceList from 'models/resourceList';
import AppContext from 'App/AppContext';

interface ILibraryPickerProps {
  isLoading?: boolean;
  lists: ResourceList[];
  links: ResourceLink[];
  multiSelect?: boolean;
  showHeader?: boolean;
  onSelect: (links: ResourceLink[]) => void;
  disallowVirtual?: boolean;
  expandGroups?: boolean;
  existingLinks?: ResourceLink[];
}

const LibraryPicker = (props: ILibraryPickerProps) => {
  const { t } = useTranslation(['library', 'translation', 'sharepoint']);
  const appContext = useContext(AppContext);
  const [links, setLinks] = useState<ResourceLink[]>([]);
  const [lists, setLists] = useState<ResourceList[]>([]);
  const [linksDisplay, setLinksDisplay] = useState<ResourceLink[]>([]);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [groups, setGroups] = useState<IGroup[] | undefined>(undefined);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [selectedList, setSelectedList] = useState<ResourceList | undefined>(undefined);

  useEffect(() => {
    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newLists = [...props.lists];
    let newLinks = [...props.links];

    //sort lists on name
    newLists.sort((a: ResourceList, b: ResourceList) => {
      return a.name.localeCompare(b.name);
    });

    //filter out whole list items
    newLinks = newLinks.filter((n) => !n.isWholeList());

    //sort on list name to divide in groups, within the group on link name
    newLinks.sort((a: ResourceLink, b: ResourceLink) => {
      if (a.listId !== b.listId) return a.list.name.localeCompare(b.list.name);

      return a.linkName.localeCompare(b.linkName);
    });

    setLists(newLists);
    setLinks(newLinks);
    setLinksDisplay(newLinks);
    calcGroups(newLists, newLinks, searchText, selectedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.links, props.lists]);

  const createSelection = () => {
    return new Selection({
      canSelectItem: (item) => {
        if (!props.existingLinks) return true;

        return !props.existingLinks.some((e) => e.linkId === item.linkId);
      },
      getKey: (item: ResourceLink) => {
        return item.linkId;
      },
      onSelectionChanged: () => {
        let items = selection.getSelection() as ResourceLink[];
        if (props.disallowVirtual) {
          items = items.filter((l) => !l.list.isVirtual);
        }
        if (props.multiSelect === true) {
          props.onSelect(items);
        }
      },
    });
  };

  const selection = createSelection();

  const getGroups = (
    lists: ResourceList[],
    links: ResourceLink[],
    searchText: string | undefined,
    selectedList: ResourceList | undefined,
  ): IGroup[] | undefined => {
    let start: number = 0;

    const groups = lists.map((list) => {
      const count = links.filter((l) => l.listId === list.listId).length;
      const group: IGroup = {
        key: list.listId.toString(),
        name: list.name,
        startIndex: start,
        count: count,
        level: 0,
        isCollapsed:
          (searchText && count > 0) || list.listId === selectedList?.listId || props.expandGroups ? false : true,
      };
      start += count;

      return group;
    });

    return groups.filter((g) => g.count > 0);
  };

  const calcGroups = (
    lists: ResourceList[],
    links: ResourceLink[],
    searchText: string | undefined,
    selectedList: ResourceList | undefined,
  ) => {
    setGroups(getGroups(lists, links, searchText, selectedList));
  };

  const onChangeText = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string): void => {
    if (filterText) {
      const filteredLinks = links.filter(
        (link) =>
          link.linkURL.toLowerCase().indexOf(filterText) >= 0 || link.linkName.toLowerCase().indexOf(filterText) >= 0,
      );
      setSearchText(filterText.toLowerCase());
      setLinksDisplay(filteredLinks);
      calcGroups(lists, filteredLinks, filterText, selectedList);
    } else {
      setLinksDisplay(links);
      calcGroups(lists, links, undefined, selectedList);
      setSearchText('');
    }
  };

  const onGroupClick = (group: IGroup) => {
    const listId = Number.parseInt(group.key);
    const list = lists.find((l) => l.listId === listId);
    if (list) {
      setSelectedList(list);
      calcGroups(lists, links, searchText, list);
      const keysToSelect = linksDisplay.filter((l) => l.listId === list.listId).map((l) => l.linkId);
      keysToSelect.forEach((k) => selection.setKeySelected(k.toString(), true, false));
    }
  };

  const getGroupProps = (): IDetailsGroupRenderProps | undefined => {
    return {
      showEmptyGroups: false,
      isAllGroupsCollapsed: !props.expandGroups ?? true,
      headerProps: {
        onGroupHeaderClick: onGroupClick,
      },
    };
  };

  const getColumns = (): IColumn[] => {
    return [
      {
        isMultiline: true,
        key: 'linkName',
        name: t('library:DetailList.Header.Name'),
        minWidth: 100,
        maxWidth: 600,
        isSorted: true,
        isSortedDescending: false,
        isResizable: true,
        onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
          if (!item) return;
          const virtual = props.disallowVirtual && item.list.isVirtual;

          return (
            <TooltipHost
              content={virtual ? t('library:DetailList.PickVirtualItemMessage') : undefined}
              hidden={!virtual}
            >
              <Text styles={virtual ? globalTextStylesDisabled : undefined}>{item.linkName}</Text>
            </TooltipHost>
          );
        },
      },
    ];
  };

  const [columns, setColumns] = useState<IColumn[]>(getColumns());

  const onColumnClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
    if (!column) return;
    sortByColumn(column.key, false);
  };

  const sortByColumn = (columnKey: string, keepDirection: boolean, setDescending?: boolean) => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn = newColumns.find((currCol) => columnKey === currCol.key);
    if (!currColumn) return;

    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === currColumn.key) {
        if (!keepDirection) {
          if (setDescending !== undefined) {
            currColumn.isSortedDescending = setDescending;
          } else {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
          }
        }
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    const links = copyAndSort(linksDisplay, columnKey, currColumn.isSortedDescending);
    setColumns(newColumns);
    setLinksDisplay(links);

    calcGroups(lists, links, searchText, selectedList);
  };

  const copyAndSort = (links: ResourceLink[], columnKey: string, isSortedDescending?: boolean): ResourceLink[] => {
    if (columnKey === 'linkName') {
      lists.sort((a: ResourceList, b: ResourceList) => {
        if (isSortedDescending) {
          return b.name.localeCompare(a.name);
        } else {
          return a.name.localeCompare(b.name);
        }
      });

      return links.sort((a: ResourceLink, b: ResourceLink) => {
        if (isSortedDescending) {
          if (a.list.listId !== b.list.listId) {
            return b.list.name.localeCompare(a.list.name);
          } else {
            return b.linkName.localeCompare(a.linkName);
          }
        } else {
          if (a.list.listId !== b.list.listId) {
            return a.list.name.localeCompare(b.list.name);
          } else {
            return a.linkName.localeCompare(b.linkName);
          }
        }
      });
    }

    return links.slice();
  };

  const onRenderRow = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (rowProps?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (defaultRender && rowProps?.item && !selection.canSelectItem(rowProps.item)) {
      rowProps.disabled = true;

      return (
        <TooltipHost content={t('sharepoint:SharepointPicker.ItemExists')} directionalHint={DirectionalHint.topLeftEdge}>
          {defaultRender(rowProps)}
        </TooltipHost>
      );
    }

    if (defaultRender) {
      return defaultRender(rowProps);
    } else {
      return null;
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill styles={{ root: { minHeight: 200 } }} tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <SearchBox
          autoFocus={!appContext.isMobileView}
          placeholder={t('translation:General.Filter.Placeholder')}
          underlined
          id="search"
          onChange={(ev, newValue) => {
            if (searchTimer) {
              clearTimeout(searchTimer);
            }
            setSearchTimer(setTimeout(() => onChangeText(ev, newValue), globalFilterDelay));
          }}
          styles={{ root: { width: 300 } }}
        />
      </Stack.Item>
      <Stack.Item grow styles={globalStackItemStylesScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            compact
            shimmerLines={5}
            enableShimmer={props.isLoading ?? false}
            layoutMode={DetailsListLayoutMode.justified}
            items={linksDisplay}
            columns={columns}
            groups={groups}
            groupProps={getGroupProps()}
            onColumnHeaderClick={onColumnClick}
            isHeaderVisible={props.showHeader ?? true}
            selectionMode={props.multiSelect === true ? SelectionMode.multiple : SelectionMode.none}
            selection={selection as Selection<IObjectWithKey>}
            onActiveItemChanged={(item) => {
              if (!props.multiSelect && !(props.disallowVirtual && item.list.isVirtual)) props.onSelect([item]);
            }}
            onRenderRow={onRenderRow}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default LibraryPicker;
