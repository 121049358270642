import { Fragment, useContext, useEffect, useState } from 'react';
import { Stack, Text } from '@fluentui/react';
import { globalStackStylesHeight100, globalStackTokensGapMedium } from 'globalStyles';
import ResourceLink from 'models/resourceLink';
import ResourceList from 'models/resourceList';
import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';
import SingleTypeResourceList from './SingleTypeResourceList';

interface ILinkList {
  items: ResourceLink[];
  navigateExternal?: boolean;
  readonly: boolean;
  disableEmptyState?: boolean;
  onDelete: (item: ResourceLink) => void;
  onPin?: (item: ResourceLink, pinned: boolean) => void;
}

const LinkList = (props: ILinkList) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [usedLists, setUsedLists] = useState<ResourceList[]>([]);

  useEffect(() => {
    const ids = props.items.map((i) => i.listId); //get all used list Id's
    const uniqueIds = Array.from(new Set(ids)); //de-duplicate
    const lists = appContext.globalDataCache.lists.getItemsForId(uniqueIds); //get lists from cache for used Id's
    lists.sort((a, b) => a.name.localeCompare(b.name)); //sort on name
    setUsedLists(lists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  //
  // Main render
  //
  return (
    <Fragment>
      {usedLists.length === 0 && !props.disableEmptyState && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Text variant="medium">{t('translation:General.Notifications.NoItems')}</Text>
        </Stack>
      )}
      {usedLists.length > 0 && (
        <Stack verticalFill styles={globalStackStylesHeight100} tokens={globalStackTokensGapMedium}>
          {usedLists.map((list) => {
            return (
              <Stack.Item key={list.listId}>
                <SingleTypeResourceList
                  type={list.listType}
                  items={props.items.filter((_itm) => {
                    return _itm.list.listId === list.listId;
                  })}
                  onDelete={props.onDelete}
                  onPin={props.onPin}
                  navigateExternal={props.navigateExternal}
                  readonly={props.readonly}
                />
              </Stack.Item>
            );
          })}
        </Stack>
      )}
    </Fragment>
  );
};

export default LinkList;
