import {
  ScrollablePane,
  Stack,
  ScrollbarVisibility,
  Text,
  IColumn,
  DetailsList,
  SelectionMode,
  Selection,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  DetailsListLayoutMode,
  ConstrainMode,
  FontIcon,
  DirectionalHint,
  IDetailsRowProps,
  TooltipHost,
  IObjectWithKey,
} from '@fluentui/react';
import { globalStackTokensGapSmall, listTypeSitePageLibraryIcon } from 'globalStyles';
import ResourceLink from 'models/resourceLink';
import { useTranslation } from 'react-i18next';
import { toLocaleDateNumeric } from 'utils/datetime';
import { ISitePage } from '../../services/Graph/SharepointInterfaces';

interface IMySitePagesProps {
  pages: ISitePage[];
  onSelect?: (items: ISitePage[]) => void;
  isListLoading: boolean;
  existingLinks: ResourceLink[];
  linkToEdit?: ResourceLink;
  singleSelect?: boolean;
}

const MySitePages = (props: IMySitePagesProps) => {
  const { t } = useTranslation(['sharepoint']);

  const columns: IColumn[] = [
    {
      key: 'icon',
      minWidth: 15,
      maxWidth: 15,
      onRender: () => {
        return (
          <Stack verticalAlign="center">
            <FontIcon {...listTypeSitePageLibraryIcon} className={classes.icon} />
          </Stack>
        );
      },
      name: '',
    },
    {
      key: 'page',
      minWidth: 200,
      name: t('sharepoint:SitePagePicker.Columns.Page'),
      onRender: (item?: ISitePage, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item) return;

        return <Text>{item.title || item.name}</Text>;
      },
    },
    {
      key: 'changed',
      minWidth: 90,
      isResizable: true,
      name: t('sharepoint:SitePagePicker.Columns.Changed'),
      onRender: (item?: ISitePage, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item || item.lastModifiedDateTime === undefined) return;

        return <Text>{toLocaleDateNumeric(new Date(item.lastModifiedDateTime))}</Text>;
      },
    },
    {
      key: 'changedBy',
      minWidth: 110,
      isResizable: true,
      name: t('sharepoint:SitePagePicker.Columns.ChangedBy'),
      onRender: (item?: ISitePage, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item) return;

        return <Text>{item.lastModifiedBy?.user?.displayName}</Text>;
      },
    },
  ];

  const classes = mergeStyleSets({
    icon: {
      fontSize: '16px',
    },
  });

  if (props.isListLoading) {
    return (
      <Stack verticalFill verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  const selection = new Selection<ISitePage>({
    canSelectItem(item) {
      return (
        item.id !== undefined &&
        !props.existingLinks?.find(
          (existingLink) =>
            props.linkToEdit?.linkId !== existingLink.linkId &&
            (existingLink.pageId === item.id || existingLink.linkName.toLowerCase() === item.name?.toLowerCase()),
        )
      );
    },
    getKey(item) {
      return item.id || '';
    },
    onSelectionChanged: () => {
      const item = selection.getSelection() as ISitePage[];
      if (props.onSelect) props.onSelect(item);
    },
  });

  if (props.pages.length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('sharepoint:SharepointPicker.NoData')}</Text>
      </Stack>
    );
  }

  const onRenderRow = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (rowProps?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (defaultRender && rowProps?.item && !selection.canSelectItem(rowProps.item) && !rowProps.item.folder) {
      rowProps.disabled = true;

      return (
        <TooltipHost
          content={t('sharepoint:SharepointPicker.ItemExists')}
          directionalHint={DirectionalHint.topLeftEdge}
        >
          {defaultRender(rowProps)}
        </TooltipHost>
      );
    }

    if (defaultRender) {
      return defaultRender(rowProps);
    } else {
      return null;
    }
  };

  //
  // Main render
  //
  return (
    <Stack grow styles={{ root: { position: 'relative' } }} verticalFill>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <Stack.Item tokens={globalStackTokensGapSmall} grow verticalFill>
          <DetailsList
            selection={selection as Selection<IObjectWithKey>}
            columns={columns}
            items={props.pages}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            isHeaderVisible={true}
            selectionMode={
              props.onSelect ? (props.singleSelect ? SelectionMode.single : SelectionMode.multiple) : SelectionMode.none
            }
            onRenderRow={onRenderRow}
          />
        </Stack.Item>
      </ScrollablePane>
    </Stack>
  );
};

export default MySitePages;
