import { useState, useEffect, useContext, FormEvent } from 'react';
import { TextField, PrimaryButton, Text, DefaultButton, DialogFooter, Modal, Stack, IconButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium, globalStackStylesHeight100PaddingMedium, cancelIcon } from 'globalStyles';
import { isEmpty } from 'utils/string';
import AppContext from 'App/AppContext';

interface IOutOfScopeDialogProps {
  isActionPending: boolean;
  isOutOfScope?: boolean;
  reason?: string;
  isOpen: boolean;
  dismiss: () => void;
  setOutOfScope: (reason: string | undefined) => void;
}

const OutOfScopeDialog = (props: IOutOfScopeDialogProps) => {
  const { t } = useTranslation(['control']);
  const [reason, setReason] = useState<string | undefined>('');
  const appContext = useContext(AppContext);

  const getErrorMessage = (): string => {
    if (isEmpty(reason)) {
      return t('control:TabDetails.Fields.OutOfScope.ReasonDialog.Error');
    }

    return '';
  };

  useEffect(() => {
    setReason(props.reason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.dismiss}>
      <Stack styles={globalStackStylesHeight100PaddingMedium} tokens={globalStackTokensGapMedium}>
        <Stack horizontal horizontalAlign={'space-between'}>
          <Text variant="xLarge">{t('control:TabDetails.Fields.OutOfScope.ReasonDialog.Title')}</Text>
          <IconButton iconProps={cancelIcon} onClick={props.dismiss} />
        </Stack>
        <Text variant="medium">{t('control:TabDetails.Fields.OutOfScope.ReasonDialog.SubText')}</Text>
        <TextField
          autoFocus={!appContext.isMobileView}
          styles={{ root: { minWidth: 400 } }}
          value={reason ?? ''}
          multiline
          rows={10}
          maxLength={2048}
          onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setReason(newValue);
          }}
          errorMessage={getErrorMessage()}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={props.isActionPending || isEmpty(reason)}
            onClick={() => {
              props.dismiss();
              props.setOutOfScope(reason);
            }}
            text={t('control:TabDetails.Fields.OutOfScope.ReasonDialog.SetOutOfScope')}
          />
          <DefaultButton
            disabled={props.isActionPending}
            onClick={() => {
              props.dismiss();
            }}
            text={t('control:TabDetails.Fields.OutOfScope.ReasonDialog.Cancel')}
          ></DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default OutOfScopeDialog;
