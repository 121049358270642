import { Checkbox, Persona, PersonaSize, Text, Stack } from '@fluentui/react';
import { ColumnDefinition } from 'microsoft-graph';
import { useContext } from 'react';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import LocaleCurrency from 'locale-currency';
import { ISingleTag, SingleTag, SingleTags } from 'components/Tags/SingleTag';
import AppContext from 'App/AppContext';
import { IListItem } from 'services/Graph/SharepointInterfaces';

interface IColumnRendererProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any;
  column: ColumnDefinition;
}

export const onRenderForColumn = (item: IListItem, column: ColumnDefinition) => {
  if (column.dateTime) return <DateTimeRenderer fields={item.fields} column={column} />;

  if (column.boolean) return <BooleanRenderer fields={item.fields} column={column} />;

  if (column.text) return <TextRenderer fields={item.fields} column={column} />;

  if (column.choice) return <ChoiceRenderer fields={item.fields} column={column} />;

  if (column.number) return <NumberRenderer fields={item.fields} column={column} />;

  if (column.currency) return <CurrencyRenderer fields={item.fields} column={column} />;

  if (column.personOrGroup) return <PersonOrGroupRenderer fields={item.fields} column={column} />;

  return <Text>{`[${column.name}]`}</Text>;
};

 
export const DateTimeRenderer = (props: IColumnRendererProps): JSX.Element | null => {
  try {
    if (!props.column.name || !props.column.dateTime || props.fields[props.column.name] === undefined) return null;

    const objectToDisplay = props.fields[props.column.name];

    return <Text>{toLocaleDateTimeMedium(objectToDisplay)}</Text>;
  } catch (err) {
    return <>{JSON.stringify(err)}</>;
  }
};

 
export const BooleanRenderer = (props: IColumnRendererProps): JSX.Element | null => {
  try {
    if (!props.column.name || !props.column.boolean || props.fields[props.column.name] === undefined) return null;

    const objectToDisplay = props.fields[props.column.name];

    return <Checkbox checked={objectToDisplay} />;
  } catch (err) {
    return <>{JSON.stringify(err)}</>;
  }
};

 
export const PersonOrGroupRenderer = (props: IColumnRendererProps): JSX.Element | null => {
  try {
    if (!props.column.name || !props.column.personOrGroup || props.fields[props.column.name] === undefined) return null;

    const objectToDisplay = props.fields[props.column.name];

    if (typeof objectToDisplay === 'string') {
      return <Persona text={objectToDisplay} size={PersonaSize.size24} />;
    } else {
      const values = objectToDisplay as [];

      return (
        <Stack tokens={globalStackTokensGapExtraSmall}>
          {values.map((v, idx) => {
            return <Persona key={idx} text={v['LookupValue']} size={PersonaSize.size24} />;
          })}
        </Stack>
      );
    }
  } catch (err) {
    return <>{JSON.stringify(err)}</>;
  }
};

 
export const TextRenderer = (props: IColumnRendererProps): JSX.Element | null => {
  try {
    if (!props.column.name || !props.column.text || props.fields[props.column.name] === undefined) return null;

    const objectToDisplay = props.fields[props.column.name] as string;
    const rows = objectToDisplay.split(/\r?\n/);

    return (
      <Stack>
        {rows.map((row: string, index: number) => {
          return (
            <Stack.Item key={'row' + index.toString()}>
              <Text block nowrap>
                {row ? row : <span>&nbsp;&nbsp;</span>}
              </Text>
            </Stack.Item>
          );
        })}
      </Stack>
    );
  } catch (err) {
    return <>{JSON.stringify(err)}</>;
  }
};

 
export const ChoiceRenderer = (props: IColumnRendererProps): JSX.Element | null => {
  const appContext = useContext(AppContext);

  try {
    if (!props.column.name || !props.column.choice || props.fields[props.column.name] === undefined) return null;

    const objectToDisplay = props.fields[props.column.name];

    if (objectToDisplay instanceof Array) {
      let tagId: number = 1;
      const tags = objectToDisplay.map((obj) => {
        tagId = tagId + 1;
        const tag: ISingleTag = {
          tagId: tagId,
          tagName: obj,
          tagColor: appContext.useDarkMode ? 'blue' : 'lightblue',
        };

        return tag;
      });

      return <SingleTags tags={tags}></SingleTags>;
    } else {
      const tag: ISingleTag = {
        tagId: 1,
        tagName: objectToDisplay,
        tagColor: appContext.useDarkMode ? 'blue' : 'lightblue',
      };

      return <SingleTag tag={tag}></SingleTag>;
    }
  } catch (err) {
    return <>{JSON.stringify(err)}</>;
  }
};

 
export const CurrencyRenderer = (props: IColumnRendererProps): JSX.Element | null => {
  try {
    if (!props.column.name || !props.column.currency || props.fields[props.column.name] === undefined) return null;

    const objectToDisplay = props.fields[props.column.name];
    const locale = props.column.currency.locale as string;
    const currency = LocaleCurrency.getCurrency(locale);
    const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currency });

    return <Text>{formatter.format(objectToDisplay)}</Text>;
  } catch (err) {
    return <>{JSON.stringify(err)}</>;
  }
};

 
export const NumberRenderer = (props: IColumnRendererProps): JSX.Element | null => {
  try {
    if (!props.column.name || !props.column.number || props.fields[props.column.name] === undefined) return null;
    const numberToDisplay = Number(props.fields[props.column.name]);

    let objectToDisplay: string = '';

    if (props.column.number.decimalPlaces === 'zero') objectToDisplay = numberToDisplay.toFixed(0);
    else if (props.column.number.decimalPlaces === 'none') objectToDisplay = numberToDisplay.toFixed(0);
    else if (props.column.number.decimalPlaces === 'one') objectToDisplay = numberToDisplay.toFixed(1);
    else if (props.column.number.decimalPlaces === 'two') objectToDisplay = numberToDisplay.toFixed(2);
    else if (props.column.number.decimalPlaces === 'three') objectToDisplay = numberToDisplay.toFixed(3);
    else if (props.column.number.decimalPlaces === 'four') objectToDisplay = numberToDisplay.toFixed(4);
    else if (props.column.number.decimalPlaces === 'five') objectToDisplay = numberToDisplay.toFixed(5);
    else if (props.column.number.decimalPlaces === 'six') objectToDisplay = numberToDisplay.toFixed(6);
    else if (props.column.number.decimalPlaces === 'seven') objectToDisplay = numberToDisplay.toFixed(7);
    else if (props.column.number.decimalPlaces === 'eight') objectToDisplay = numberToDisplay.toFixed(8);
    else if (props.column.number.decimalPlaces === 'nine') objectToDisplay = numberToDisplay.toFixed(9);
    else if (props.column.number.decimalPlaces === 'ten') objectToDisplay = numberToDisplay.toFixed(10);
    else objectToDisplay = numberToDisplay.toFixed(2);

    return <Text>{objectToDisplay}</Text>;
  } catch (err) {
    return <>{JSON.stringify(err)}</>;
  }
};
