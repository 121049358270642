import React, { FunctionComponent, useContext, useState } from 'react';
import { Stack, Spinner, SpinnerSize, Text, Label, Checkbox, Link } from '@fluentui/react';

import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { FluentUIPieChart, IPieChartDataPoint } from 'components/Charts/PieChart';
import { apiGetWidgetDataTaskTag } from 'services/Api/dashboardService';
import { generatePalette } from 'utils/color';
import Tag from 'models/tag';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import { useHistory } from 'react-router-dom';
import { IWidgetRendererProps } from '../WidgetRenderer';
import SingleTagPicker from 'components/Pickers/SingleTagPicker';
import { ISingleTag } from 'components/Tags/SingleTag';

export class WidgetTaskTagConfig {
  tagId: number;

  showOpenTasks: boolean;

  showAllTaskLink: boolean;

  useOrgTagColors: boolean;

  constructor() {
    this.tagId = 0;
    this.showOpenTasks = false;
    this.showAllTaskLink = false;
    this.useOrgTagColors = false;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.tagId = newRawConfig.tagId ?? [];
        this.showAllTaskLink = newRawConfig.showAllTaskLink ?? false;
        this.useOrgTagColors = newRawConfig.useOrgTagColors ?? false;
        this.showOpenTasks = newRawConfig.showOpenTasks ?? false;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetTaskTagConfig {
    const newConfig = new WidgetTaskTagConfig();
    newConfig.tagId = this.tagId;
    newConfig.showAllTaskLink = this.showAllTaskLink;
    newConfig.useOrgTagColors = this.useOrgTagColors;
    newConfig.showOpenTasks = this.showOpenTasks;

    return newConfig;
  }
}

interface IWidgetTaskTagProps extends IWidgetRendererProps {}

const WidgetTaskTag: FunctionComponent<IWidgetTaskTagProps> = (props: IWidgetTaskTagProps) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const history = useHistory();
  const appContext = React.useContext(AppContext);
  const [tags, setTags] = React.useState<Tag[]>([]);
  const [tagColors, setTagColors] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [config, setConfig] = React.useState<WidgetTaskTagConfig | undefined>(undefined);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        const tags = await appContext.globalDataCache.tags.getItems();
        const config = loadConfig();
        setConfig(config);

        const baseTag = tags.find((t) => t.tagId === config.tagId);
        if (baseTag) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          //save copy of the tags so we can change the color for the gradient
          const filterTags = tags.filter((t) => t.tagName === baseTag.tagName).map((t) => t.clone());
          const data = await apiGetWidgetDataTaskTag(filterTags, config.showOpenTasks, accessToken);
          setTags(data);
          if (!config.useOrgTagColors) {
            setTaskTagColors(baseTag, data);
          }
          setDataCount(data.reduce((sum, current) => sum + (current.usedCount ?? 0), 0));
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetTaskTagConfig => {
    const newConfig = new WidgetTaskTagConfig();
    newConfig.load(props.widget.widgetConfig);
    newConfig.tagId = appContext.globalDataCache.tags.get(newConfig.tagId).tagId;

    return newConfig;
  };

  const setTaskTagColors = (baseTag: Tag, tags: Tag[]) => {
    const startColor = baseTag.tagColor;
    const colors = generatePalette(tags.length, startColor);
    setTagColors(colors);
  };

  const getData = (): IPieChartDataPoint[] => {
    if (!config) return [];

    const graphData: IPieChartDataPoint[] = tags.map((s, idx) => {
      return {
        name: s.tagValue,
        value: s.usedCount ?? 0,
        color: s.tagColor,
        link: `/tasks/alltasks?tags=${s.tagId.toString()}${config.showOpenTasks ? '&open=true' : ''}`,
      };
    });

    graphData.sort((a, b) => b.value - a.value);

    if (!config.useOrgTagColors) {
      graphData.forEach((t, idx) => (t.color = tagColors[idx]));
    }

    return graphData;
  };

  const navigateToAllTasks = () => {
    if (!config) return;
    const tagStr = `?tags=${tags.map((t) => t.tagId.toString()).join(',')}`;
    const url = `/tasks/alltasks${tagStr}${config.showOpenTasks ? '&open=true' : ''}`;
    history.push(url);
  };

  const getHeight = (): number => {
    if (config && config.showAllTaskLink) {
      return 210;
    }

    return 250;
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (dataCount === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:TaskTag.NoTasks')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill horizontalAlign="center" tokens={globalStackTokensGapSmall}>
      <Stack.Item grow>
        <FluentUIPieChart
          data={getData()}
          height={getHeight()}
          width={280}
          label={dataCount.toString()}
        ></FluentUIPieChart>
      </Stack.Item>
      {config && config.showAllTaskLink && (
        <Link onClick={() => navigateToAllTasks()}>{t('widgets:TaskTag.AllTaskLink')}</Link>
      )}
    </Stack>
  );
};

export default WidgetTaskTag;

//
// Config
//

interface IWidgetConfigWidgetTaskTagProps extends IWidgetConfigRendererProps {}

export const WidgetConfigTaskTag: FunctionComponent<IWidgetConfigWidgetTaskTagProps> = (
  props: IWidgetConfigWidgetTaskTagProps,
) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<ISingleTag | undefined>(undefined);
  const [showAllTasks, setShowAllTasks] = useState<boolean>(false);
  const [showOpenTasks, setShowOpenTasks] = useState<boolean>(false);
  const [useOrgTaskColors, setUseOrgTaskColors] = useState<boolean>(false);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetTaskTagConfig => {
    let newRawConfig = new WidgetTaskTagConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
      newRawConfig.tagId = appContext.globalDataCache.tags.get(newRawConfig.tagId).tagId;
    }

    return newRawConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await appContext.globalDataCache.tags.getItems();
      const config = loadConfig();

      if (config.tagId) {
        setSelectedTag(appContext.globalDataCache.tags.get(config.tagId));
      }

      setShowAllTasks(config.showAllTaskLink);
      setShowOpenTasks(config.showOpenTasks);
      setUseOrgTaskColors(config.useOrgTagColors);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfig = (
    tag: ISingleTag | undefined,
    showAllTasks: boolean,
    useOrgTaskColors: boolean,
    openTasks: boolean,
  ): WidgetTaskTagConfig => {
    const config = new WidgetTaskTagConfig();
    config.tagId = (tag?.tagId as number) ?? 0;
    config.showAllTaskLink = showAllTasks;
    config.useOrgTagColors = useOrgTaskColors;
    config.showOpenTasks = openTasks;

    return config;
  };

  const onAdd = (tag: ISingleTag) => {
    setSelectedTag(tag);
    const config = getConfig(tag, showAllTasks, useOrgTaskColors, showOpenTasks);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onRemove = () => {
    setSelectedTag(undefined);
    const config = getConfig(undefined, showAllTasks, useOrgTaskColors, showOpenTasks);
    props.onUpdateConfig(JSON.stringify(config), false);
  };

  const onSetShowAllTasks = (checked: boolean) => {
    setShowAllTasks(checked);
    const config = getConfig(selectedTag, checked, useOrgTaskColors, showOpenTasks);
    props.onUpdateConfig(JSON.stringify(config), selectedTag !== undefined);
  };

  const onSetShowOpenTasks = (checked: boolean) => {
    setShowOpenTasks(checked);
    const config = getConfig(selectedTag, showAllTasks, useOrgTaskColors, checked);
    props.onUpdateConfig(JSON.stringify(config), selectedTag !== undefined);
  };

  const onSetUseOrgTaskColors = (checked: boolean) => {
    setUseOrgTaskColors(checked);
    const config = getConfig(selectedTag, showAllTasks, checked, showOpenTasks);
    props.onUpdateConfig(JSON.stringify(config), selectedTag !== undefined);
  };

  const getTagKeys = (): ISingleTag[] => {
    const keys: ISingleTag[] = [];

    appContext.globalDataCache.tags.items.forEach((t) => {
      if (keys.findIndex((k) => k.tagName === t.tagName) === -1) {
        const newKey: ISingleTag = {
          tagId: t.tagId,
          tagName: t.tagName,
          tagColor: t.tagColor,
        };
        keys.push(newKey);
      }
    });

    return keys;
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <Label>{t('widgets:TaskTag.Config.Label')}</Label>
        <SingleTagPicker
          tags={getTagKeys()}
          itemLimit={1}
          selectedTags={selectedTag ? [selectedTag] : []}
          onAdd={onAdd}
          onRemove={onRemove}
          isLoading={isLoading}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={useOrgTaskColors}
          label={t('widgets:TaskTag.Config.OrgTaskColors')}
          onChange={(ev, checked) => {
            onSetUseOrgTaskColors(checked ?? false);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={showOpenTasks}
          label={t('widgets:TaskTag.Config.LabelShowOpenTasks')}
          onChange={(ev, checked) => {
            onSetShowOpenTasks(checked ?? false);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={showAllTasks}
          label={t('widgets:TaskTag.Config.LabelShowAllTasks')}
          onChange={(ev, checked) => {
            onSetShowAllTasks(checked ?? false);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
