import {
  Dropdown,
  FontIcon,
  getTheme,
  IconButton,
  IDropdownOption,
  ITextFieldProps,
  Label,
  Stack,
  TextField,
  Text,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import {
  editIcon,
  getGlobalTextFieldDisabled,
  globalStackTokensGapExtraSmall,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
} from 'globalStyles';
import Task from 'models/tasks/task';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dropdownStyles, iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import TaskModifyCompletionDate from 'components/Tasks/TaskModifyCompletionDate';
import { hasUserFeature, FeatureTypes } from 'services/Auth/featurePermissions';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';

interface ITaskDetailStatusProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  showCompleted: boolean;
  onUpdate: (newTask: Task) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  controlsOnly?: boolean;
}

export const TaskDetailStatus = (props: ITaskDetailStatusProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation']);
  const [showEditCompletionDate, setShowEditCompletionDate] = useState<boolean>(false);
  const theme = getTheme();
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Status, appContext);

  const getStatusOptions = (): IDropdownOption[] => {
    return appContext.globalDataCache.taskStates.items.map((_taskState) => {
      return {
        key: _taskState.taskStateId.toString(),
        text: _taskState.state,
      };
    });
  };

  const getCompletedLabel = (
    textProps?: ITextFieldProps,
    defaultRender?: (textProps?: ITextFieldProps) => JSX.Element | null,
  ): JSX.Element => {
    if (hasUserFeature(appContext, FeatureTypes.TaskChangeCompletionDate) && props.task.completed) {
      return (
        <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
          <Label>{t('task:Completed.Label')}</Label>
          <IconButton
            onClick={() => {
              setShowEditCompletionDate(true);
            }}
            iconProps={editIcon}
            styles={{ root: { height: 18 } }}
          />
          <TaskModifyCompletionDate
            task={props.orgTask}
            isOpen={showEditCompletionDate}
            onSave={(newCompletionDate) => {
              if (newCompletionDate) props.onChangeCompletionDate(newCompletionDate);
            }}
            onClose={() => setShowEditCompletionDate(false)}
          />
        </Stack>
      );
    } else {
      return <Label>{t('task:Completed.Label')}</Label>;
    }
  };

  const getContent = () => {
    if (isReadonly) {
      return (
        <Fragment>
          <Stack.Item styles={{ root: { width: '50%' } }}>
            <Label>{t('task:Status.Label')}</Label>
            <Text>{appContext.globalDataCache.taskStates.get(props.task.taskStateId).state}</Text>
          </Stack.Item>
          {props.showCompleted && (
            <Stack.Item styles={{ root: { width: '50%' } }}>
              <Label>{t('task:Completed.Label')}</Label>
              <Text>{toLocaleDateTimeMedium(props.task.completed) || '-'}</Text>
            </Stack.Item>
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Stack.Item grow>
            <Dropdown
              selectedKey={props.task.taskStateId.toString()}
              options={getStatusOptions()}
              label={t('task:Status.Label')}
              styles={dropdownStyles}
              calloutProps={{ calloutMaxHeight: 250 }}
              onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                if (!option) return;
                const copyTask = props.task.clone();
                copyTask.taskStateId = +option.key;
                props.onUpdate(copyTask);
              }}
            />
          </Stack.Item>
          {props.showCompleted && (
            <Stack.Item>
              <TextField
                onRenderLabel={getCompletedLabel}
                styles={getGlobalTextFieldDisabled(appContext, theme, 220)}
                readOnly
                value={toLocaleDateTimeMedium(props.task.completed)}
              ></TextField>
            </Stack.Item>
          )}
        </Fragment>
      );
    }
  };

  //
  // Main render
  //
  if (props.controlsOnly) {
    return getContent();
  } else {
    return (
      <Stack
        horizontal
        tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
        verticalAlign="start"
        styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace } } : undefined}
      >
        <Stack.Item>
          {!appContext.isMobileView && <FontIcon iconName="FavoriteStarFill" style={iconFontSize} />}
        </Stack.Item>
        {getContent()}
      </Stack>
    );
  }
};
