import Language from '../language';

export class TaskType_Translation {
  taskTypeId: number;

  languageId: number;

  name: string;

  description?: string;

  tabName?: string;

  lang?: Language;

  constructor() {
    this.taskTypeId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
