export default class UserDTO {
  userId: string;

  name: string;

  email: string;

  created?: string;

  lastLogin?: string;

  hasLicense: boolean;

  userLanguageCode?: string;

  roles?: string[];

  groups?: string[];

  userTimeZoneCode?: string;

  systemRoleIds?: number[];
  
  constructor() {
    this.userId = '';
    this.name = '';
    this.email = '';
    this.hasLicense = false;
  }
}
