import { Role } from 'models/auth/role';
import { Role_Translation } from 'models/auth/role_Translation';
import { RoleDTO } from 'models/dto/auth/roleDTO';
import { Role_TranslationDTO } from 'models/dto/auth/role_TranslationDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import User from 'models/user';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import Language from 'models/language';
import { UserSystemRoleDTO } from 'models/dto/auth/userRoleDTO';
import { AuthSchemaDTO } from 'models/dto/auth/authSchemaDTO';
import { AuthSchema } from 'models/auth/authSchema';
import { AuthSchema_Translation } from 'models/auth/authSchema_Translation';
import { AuthSchema_TranslationDTO } from 'models/dto/auth/authSchema_TranslationDTO';
import { AuthSchemaLine } from 'models/auth/authSchemaLine';
import { AuthSchemaLineDTO } from 'models/dto/auth/authSchemaLineDTO';

//
// Roles
//
export function mapFromRoles(roleDTOs: RoleDTO[], cache: GlobalDataCache | undefined): Role[] {
  return roleDTOs.map((r) => mapFromRole(r, cache));
}

export function mapFromRole(roleDTO: RoleDTO, cache: GlobalDataCache | undefined): Role {
  const output = new Role();
  output.roleId = roleDTO.roleId;
  output.linkId = roleDTO.linkId;
  output.systemRoleId = roleDTO.systemRoleId;
  output.trans = mapFromRole_Translation(roleDTO.trans);

  if (cache) {
    output.users = cache.users.getItemsForId(roleDTO.userIds?.idList);
    output.groups = cache.groups.getItemsForId(roleDTO.groupIds?.idList);
  }

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
  }

  return output;
}

export const mapFromRole_Translation = (transDTOs: Role_TranslationDTO[]): Role_Translation[] => {
  const output: Role_Translation[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new Role_Translation();

    newObject_Translation.roleId = transDTO.roleId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export function mapFromUserSystemRoles(userRoleDTOs: UserSystemRoleDTO[], cache: GlobalDataCache): User[] {
  const userList: User[] = [];

  for (const userRoleDTO of userRoleDTOs) {
    const newUser = cache.users.get(userRoleDTO.userId);
    userList.push(newUser);
  }

  return userList;
}

export function mapToRole(role: Role): RoleDTO {
  const output = new RoleDTO();
  output.roleId = role.roleId;
  output.linkId = role.linkId;
  output.trans = mapToRole_Translation(role.trans);

  if (role.trans && role.transIdx >= 0) {
    output.trans[role.transIdx].name = role.name;
    output.trans[role.transIdx].description = role.description;
  }

  return output;
}

export const mapToRole_Translation = (transDTOs: Role_Translation[]): Role_TranslationDTO[] => {
  const output: Role_TranslationDTO[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new Role_TranslationDTO();

    newObject_Translation.roleId = transDTO.roleId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapToLanguage(transDTO.lang);

    output.push(newObject_Translation);
  }

  return output;
};

//
// Auth schemas
//
export function mapFromAuthSchemas(authSchemaDTOs: AuthSchemaDTO[]): AuthSchema[] {
  return authSchemaDTOs.map((r) => mapFromAuthSchema(r));
}

export function mapFromAuthSchema(authschemaDTO: AuthSchemaDTO): AuthSchema {
  const output = new AuthSchema();
  output.authSchemaId = authschemaDTO.authSchemaId;
  output.auditTrailId = authschemaDTO.auditTrailId;
  output.active = authschemaDTO.active;
  output.trans = mapFromAuthSchema_Translation(authschemaDTO.trans);
  output.lines = mapFromAuthSchemaLines(authschemaDTO.lines ?? []);

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
  }

  return output;
}

export const mapFromAuthSchema_Translation = (transDTOs: AuthSchema_TranslationDTO[]): AuthSchema_Translation[] => {
  const output: AuthSchema_Translation[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new AuthSchema_Translation();

    newObject_Translation.authSchemaId = transDTO.authSchemaId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export function mapToAuthSchema(authschema: AuthSchema): AuthSchemaDTO {
  const output = new AuthSchemaDTO();
  output.authSchemaId = authschema.authSchemaId;
  output.active = authschema.active;
  output.trans = mapToAuthSchema_Translation(authschema.trans);
  output.lines = mapToAuthSchemaLines(authschema.lines);

  if (authschema.trans && authschema.transIdx >= 0) {
    output.trans[authschema.transIdx].name = authschema.name;
    output.trans[authschema.transIdx].description = authschema.description;
  }

  return output;
}

export const mapToAuthSchema_Translation = (transDTOs: AuthSchema_Translation[]): AuthSchema_TranslationDTO[] => {
  const output: AuthSchema_TranslationDTO[] = [];

  for (const transDTO of transDTOs) {
    const newObject_Translation = new AuthSchema_TranslationDTO();

    newObject_Translation.authSchemaId = transDTO.authSchemaId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapToLanguage(transDTO.lang);

    output.push(newObject_Translation);
  }

  return output;
};

export function mapFromAuthSchemaLines(authschemaLineDTOs: AuthSchemaLineDTO[]): AuthSchemaLine[] {
  return authschemaLineDTOs.map((r) => mapFromAuthSchemaLine(r));
}

export function mapFromAuthSchemaLine(authschemaLineDTO: AuthSchemaLineDTO): AuthSchemaLine {
  const output = new AuthSchemaLine();
  output.authSchemaId = authschemaLineDTO.authSchemaId;
  output.authSchemaLineId = authschemaLineDTO.authSchemaLineId;
  output.userId = authschemaLineDTO.userId;
  output.groupId = authschemaLineDTO.groupId;
  output.roleId = authschemaLineDTO.roleId;
  output.opUpdate = authschemaLineDTO.opUpdate;
  output.opDelete = authschemaLineDTO.opDelete;

  return output;
}

export function mapToAuthSchemaLines(authschemaLines: AuthSchemaLine[]): AuthSchemaLineDTO[] {
  return authschemaLines.filter(r => r.hasMember()).map((r) => mapToAuthSchemaLine(r));
}

export function mapToAuthSchemaLine(authschemaLine: AuthSchemaLine): AuthSchemaLineDTO {
  const output = new AuthSchemaLineDTO();
  output.authSchemaId = authschemaLine.authSchemaId;
  output.authSchemaLineId = authschemaLine.authSchemaLineId;
  output.userId = authschemaLine.userId;
  output.groupId = authschemaLine.groupId;
  output.roleId = authschemaLine.roleId;
  output.opUpdate = authschemaLine.opUpdate;
  output.opDelete = authschemaLine.opDelete;

  return output;
}
