import { IHSL, IRGB } from '@fluentui/react';
import { ISingleTag } from 'components/Tags/SingleTag';
import Tag from 'models/tag';

export type RGB = [number, number, number];

export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomHslColor(): IHSL {
  return {
    h: randomInt(0, 360), 
    s: randomInt(40, 60),
    l: randomInt(40, 60), // Slightly controlled lightness for more aesthetic colors
  };
}

//colorChannelA and colorChannelB are ints ranging from 0 to 255
export function colorChannelMixer(colorChannelA: number, colorChannelB: number, amountToMix: number): number {
  const channelA = colorChannelA * amountToMix;
  const channelB = colorChannelB * (1 - amountToMix);

  return Math.floor(channelA + channelB);
}

export function HEXToIRGB(color: string): IRGB {
  const parts: number[] = [0, 0, 0];
  parts[0] = parseInt(color.substring(1, 3), 16);
  parts[1] = parseInt(color.substring(3, 5), 16);
  parts[2] = parseInt(color.substring(5, 7), 16);

  return { r: parts[0], g: parts[1], b: parts[2] };
}

export function IRGBToHEX(rgb: IRGB): string {
  const R = Math.min(Math.max(Math.floor(rgb.r), 0), 255);
  const G = Math.min(Math.max(Math.floor(rgb.g), 0), 255);
  const B = Math.min(Math.max(Math.floor(rgb.b), 0), 255);

  const color = '#' + ((1 << 24) | (R << 16) | (G << 8) | B).toString(16).slice(1);

  return color;
}

export function HEXToRGB(color: string): RGB {
  const parts: RGB = [0, 0, 0];
  parts[0] = parseInt(color.substring(1, 3), 16);
  parts[1] = parseInt(color.substring(3, 5), 16);
  parts[2] = parseInt(color.substring(5, 7), 16);

  return parts;
}

export function RGBToHEX(rgb: RGB): string {
  const R = Math.min(Math.max(Math.floor(rgb[0]), 0), 255);
  const G = Math.min(Math.max(Math.floor(rgb[1]), 0), 255);
  const B = Math.min(Math.max(Math.floor(rgb[2]), 0), 255);

  const color = '#' + ((1 << 24) | (R << 16) | (G << 8) | B).toString(16).slice(1);

  return color;
}

export function HSLToHEX(hsl: IHSL): string {
  const { h, s, l } = hsl;

  const hDecimal = l / 100;
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };

  return `#${f(0)}${f(8)}${f(4)}`;
}

export function HEXToHSL(hex: string): IHSL {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    throw new Error('Could not parse Hex Color');
  }

  const rHex = parseInt(result[1], 16);
  const gHex = parseInt(result[2], 16);
  const bHex = parseInt(result[3], 16);

  const r = rHex / 255;
  const g = gHex / 255;
  const b = bHex / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  let l = h;

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
  }
  h /= 6;

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return { h, s, l };
}

export function HSLToRGB(hsl: IHSL): IRGB {
  const { h, s, l } = hsl;

  const hDecimal = h / 100;
  const sDecimal = s / 100;
  const lDecimal = l / 100;

  if (s === 0) {
    return { r: lDecimal, g: lDecimal, b: lDecimal };
  }

  const HueToRGB = (p: number, q: number, t: number): number => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

    return p;
  };

  const q = lDecimal < 0.5 ? lDecimal * (1 + sDecimal) : lDecimal + sDecimal - lDecimal * sDecimal;
  const p = 2 * lDecimal - q;

  const r = HueToRGB(p, q, hDecimal + 1 / 3);
  const g = HueToRGB(p, q, hDecimal);
  const b = HueToRGB(p, q, hDecimal - 1 / 3);

  return { r: r * 255, g: g * 255, b: b * 255 };
}

export function RGBToHSL(rgb: IRGB): IHSL {
  const { r: r255, g: g255, b: b255 } = rgb;

  const r = r255 / 255;
  const g = g255 / 255;
  const b = b255 / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  const l = h;

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  s = l >= 0.5 ? d / (2 - (max + min)) : d / (max + min);
  switch (max) {
    case r:
      h = ((g - b) / d + 0) * 60;
      break;
    case g:
      h = ((b - r) / d + 2) * 60;
      break;
    case b:
      h = ((r - g) / d + 4) * 60;
      break;
  }

  return { h: Math.round(h), s: Math.round(s * 100), l: Math.round(l * 100) };
}

export function getBestForGroundColor(tag: Tag | ISingleTag): string {
  const rgb = HEXToRGB(tag.tagColor);
  const cDark = contrastBlack(rgb);
  const cLight = contrastWhite(rgb);

  return cLight > cDark ? '#FFFFFF' : '#000000';
}

export function contrast(foregroundColor: RGB, backgroundColor: RGB): number {
  const foregroundLumiance = luminance(foregroundColor);
  const backgroundLuminance = luminance(backgroundColor);

  return backgroundLuminance > foregroundLumiance
    ? (backgroundLuminance + 0.05) / (foregroundLumiance + 0.05)
    : (foregroundLumiance + 0.05) / (backgroundLuminance + 0.05);
}

export function contrastBlack(backgroundColor: RGB): number {
  const backgroundLuminance = luminance(backgroundColor);

  return (backgroundLuminance + 0.05) / 0.05;
}

export function contrastWhite(backgroundColor: RGB): number {
  const backgroundLuminance = luminance(backgroundColor);

  return 1.05 / (backgroundLuminance + 0.05);
}

export function luminance(rgb: RGB): number {
  const [r, g, b] = rgb.map((v) => {
    v /= 255;

    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return r * 0.2126 + g * 0.7152 + b * 0.0722;
}
