import { ReportRequest6DTO } from 'models/dto/Reports/ReportRequests_6_DTO';
import { IAppContext } from 'App/AppContext';
import { Packer, Document } from 'docx';
import saveAs from 'file-saver';
import { t, TFunction } from 'i18next';
import { ControlTypes } from 'models/control';
import ReportRequestDTO from 'models/dto/Reports/ReportRequestDTO';
import { ISONorms } from 'models/norm';
import { ReportType } from 'models/reports/ReportDefinition';
import { generateSoAControls } from 'components/Documents/SoA_Controls';
import { generateSoAISO27001_2013 } from 'components/Documents/SoA_ISO27001_2013';
import { generateSoAISO27001_2022 } from 'components/Documents/SoA_ISO27001_2022';
import { apiGetControls } from 'services/Api/controlService';
import { apiGetISOControls } from 'services/Api/isoControlService';
import { apiRequest } from 'services/Auth/authConfig';
import { sortOnChapter } from 'utils/sorting';

export const handleClientReport = async (appContext: IAppContext, reportRequest: ReportRequestDTO) => {
  let doc: Document | undefined = undefined;
  const accessToken = await appContext.getAccessToken(apiRequest.scopes);

  switch (reportRequest.reportDefinition?.id) {
    case ReportType.SOA_ISO27001_2013:
      const standardsSOA_ISO27001_2013 = appContext.globalDataCache.norms.getItemsISO();
      const isoNormIISO270012013 = standardsSOA_ISO27001_2013.find((n) => n.isoNormId === ISONorms.ISO270012013);
      if (!isoNormIISO270012013) return;
      const isoControlsISO270012013 = await apiGetISOControls(accessToken, appContext.globalDataCache);
      const filteredControlsISO270012013 = isoControlsISO270012013.filter(
        (ic) => ic.control?.controlType === ControlTypes.Measure,
      );
      doc = generateSoAISO27001_2013(isoNormIISO270012013, filteredControlsISO270012013, t as TFunction<string[]>);
      break;
    case ReportType.SOA_ISO27001_2022:
      const standardsISO270012022 = await appContext.globalDataCache.norms.getItemsISO();
      const isoNormISO270012022 = standardsISO270012022.find((n) => n.isoNormId === ISONorms.ISO270012022);
      if (!isoNormISO270012022) return;
      const isoControlsISO270012022 = await apiGetISOControls(accessToken, appContext.globalDataCache);
      const filteredControlsISO270012022 = isoControlsISO270012022.filter(
        (ic) => ic.control?.controlType === ControlTypes.Measure,
      );
      doc = generateSoAISO27001_2022(isoNormISO270012022, filteredControlsISO270012022, t as TFunction<string[]>);
      break;
    case ReportType.SOA_Controls:
      const request = reportRequest as ReportRequest6DTO;
      if (request.controls.length === 0) {
        request.controls = await apiGetControls(accessToken, appContext.globalDataCache);
        request.controls.sort((a, b) => sortOnChapter(a.code, b.code));
      }
      const isoControls = await apiGetISOControls(accessToken, appContext.globalDataCache);
      doc = generateSoAControls(request.norm, request.controls, isoControls, t as TFunction<string[]>);
      break;
  }

  if (doc) {
    Packer.toBlob(doc).then((buffer) => {
      saveAs(buffer, reportRequest.reportDefinition?.name + '.docx');
    });
  }
};
