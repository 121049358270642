import { FontIcon, Stack, TextField, Text, Label } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapExtraSmall, globalStackTokensGapMedium } from 'globalStyles';
import Task from 'models/tasks/task';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';

interface ITaskDetailDescriptionProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  onUpdate: (newTask: Task) => void;
}

export const TaskDetailDescription = (props: ITaskDetailDescriptionProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation']);
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Description, appContext);

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
      verticalAlign="start"
      styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace } } : undefined}
    >
      <Stack.Item>{!appContext.isMobileView && <FontIcon iconName="TextDocument" style={iconFontSize} />}</Stack.Item>
      <Stack.Item grow>
        {isReadonly && (
          <Fragment>
            <Label>{t('task:Description.Label')}</Label>
            <Text styles={{ root: { maxHeight: 300, overflowY: 'scroll' } }}>{props.task.description ?? '-'}</Text>
          </Fragment>
        )}
        {!isReadonly && (
          <TextField
            style={{ resize: 'vertical', maxHeight: 300, overflowY: 'scroll' }}
            label={t('task:Description.Label')}
            placeholder={t('task:Description.Placeholder')}
            multiline
            autoAdjustHeight
            value={props.task.description ? props.task.description : ''}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
              const copyTask = props.task.clone();
              copyTask.description = newValue;
              props.onUpdate(copyTask);
            }}
            maxLength={4000}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};
